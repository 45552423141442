import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Select from "@deliverr/react-select-v1";
import { barcodeLabelDescriptions } from "@deliverr/inbound-client";

import { PrintBarcodesSelectProps, usePrintBarcodesSelect } from "./usePrintBarcodesSelect";

import cls from "./PrintBarcodesSelect.less";
import cn from "classnames";

const barcodeLabelOptions = Object.entries(barcodeLabelDescriptions).map(([value, label]) => ({
  value,
  label,
}));

export const PrintBarcodesSelect: FC<PrintBarcodesSelectProps> = ({
  disabled = false,
  filename,
  items,
  onDownloadComplete,
  validateDownload,
  className,
}) => {
  const { handleOptionSelectDownload } = usePrintBarcodesSelect({
    filename,
    items,
    onDownloadComplete,
    validateDownload,
  });

  return (
    <Select
      className={cn(cls.printBarcodesSelect, className)}
      disabled={disabled}
      onChange={handleOptionSelectDownload}
      options={barcodeLabelOptions}
      placeholder={<FormattedMessage id="PrintBarcodesSelect.placeholder" defaultMessage="Download Barcodes" />}
      searchable={false}
    />
  );
};
