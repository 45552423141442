import { defineMessages } from "react-intl";

export const returnsPackageDetailsMessages = defineMessages({
  emptyPackage: {
    id: "returns.package.details.emptyPackage",
    defaultMessage: "Empty Package",
  },
  emptyPackageDescription: {
    id: "returns.package.details.emptyPackageDescription",
    defaultMessage: "No Items have been added to this box yet!",
  },
  unknownProduct: {
    id: "returns.package.details.unknownProduct",
    defaultMessage: "Unknown Product",
  },
  productName: {
    id: "returns.package.details.productName",
    defaultMessage: "Product Name",
  },
  quantity: {
    id: "returns.package.details.quantity",
    defaultMessage: "Quantity",
  },
  returnOrders: {
    id: "returns.package.details.returnOrders",
    defaultMessage: "Return Orders",
  },
  rtmSource: {
    id: "returns.package.details.rtmSource",
    defaultMessage: "Source",
  },
  rtmDestination: {
    id: "returns.package.details.rtmDestination",
    defaultMessage: "Destination",
  },
  rtmCarrier: {
    id: "returns.package.details.rtmCarrier",
    defaultMessage: "Carrier",
  },
  titleBarcode: {
    id: "returns.package.details.titleBarcode",
    defaultMessage: "Box Barcode #{boxBarcode}",
  },
  titlePackageId: {
    id: "returns.package.details.titlePackageId",
    defaultMessage: "Package #{packageId}",
  },
  getPackageError: {
    notFound: {
      id: "returns.package.details.getPackageError.notFound",
      defaultMessage: "Return Package Not Found",
    },
    unknownError: {
      id: "returns.package.details.getPackageError.unknownError",
      defaultMessage: "Error Fetching Package Details, please contact support.",
    },
  },
});
