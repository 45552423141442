import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Address from "common/components/Address";

import { Anchor, Card, DefaultTheme, Stack } from "common/components/ui";
import styled from "@emotion/styled";
import { usePackageRtmDetails } from "./usePackageRtmDetails";
import { returnsPackageDetailsMessages } from "./ReturnsPackageDetailsMessages";

const PackageAddress = styled.dl(
  () => `
    padding: 20px 40px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 32px;
  `
);

const Container = styled(Card)<{}, DefaultTheme>(
  ({ theme }) => `
  border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
`
);

const InfoLabel = styled.dt<{}, DefaultTheme>(
  ({ theme }) => `
    display: block;
    font-size: ${theme.font.size.F1};
    font-weight: ${theme.font.weight.BOLD};
    color: ${theme.colors.NEUTRAL[200]};
  `
);

const AddressContainer = styled.dd`
  white-space: pre-line;
`;

export const ReturnsRtmDetail: FC = () => {
  const { packageSourceAddress, packageDestinationAddress, trackingCode, carrier, labelUrl } = usePackageRtmDetails();
  return (
    <Container>
      <PackageAddress>
        {packageSourceAddress && (
          <Stack gap={"S1"}>
            <InfoLabel>
              <FormattedMessage {...returnsPackageDetailsMessages.rtmSource} />
            </InfoLabel>
            <AddressContainer>
              <Address address={packageSourceAddress} />
            </AddressContainer>
          </Stack>
        )}
        {packageDestinationAddress && (
          <Stack gap={"S1"}>
            <InfoLabel>
              <FormattedMessage {...returnsPackageDetailsMessages.rtmDestination} />
            </InfoLabel>
            <AddressContainer>
              <Address address={packageDestinationAddress} />
            </AddressContainer>
          </Stack>
        )}
        {labelUrl && (
          <Stack gap={"S1"}>
            <InfoLabel>
              <FormattedMessage {...returnsPackageDetailsMessages.rtmCarrier} />
            </InfoLabel>
            <span>{carrier}</span>
            <Anchor href={labelUrl} appearance={"DEFAULT"}>
              {trackingCode}
            </Anchor>
          </Stack>
        )}
      </PackageAddress>
    </Container>
  );
};
