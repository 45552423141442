import { SPThunkAction } from "common/ReduxUtils";
import { getAndSetReplenishmentOrderHistoryState } from "transfers/create/actions/replenishmentOrder/getAndSetReplenishmentOrderHistoryState";
import { getAndSetReplenishmentOrderState } from "transfers/create/actions/replenishmentOrder/getAndSetReplenishmentOrderState";
import { getAndSetReplenishmentOrderShipmentAttachmentState } from "transfers/create/actions/replenishmentOrder/getAndSetReplenishmentOrderShipmentAttachmentState";
import { fetchAndSetOrderCosts } from "transfers/detail/store/replenishment/fetchAndSetOrderCosts";
import { OrderResponse, OrderStatus } from "@deliverr/replenishment-client";

export const getAndSetReplenishmentOrder =
  (orderId: string): SPThunkAction =>
  async (dispatch): Promise<OrderResponse> => {
    const fetchOptions = {
      includeShipments: true,
      includeShipmentTrackings: true,
      includeKitComponentOrderItems: true,
      includeNonCompliances: true,
      includeCarrierAccounts: true,
      includeOrderAttributes: true,
    };
    const replenishmentOrder = await getAndSetReplenishmentOrderState(orderId, fetchOptions, dispatch);

    ![OrderStatus.DRAFT, OrderStatus.CREATED, OrderStatus.ARCHIVED].includes(replenishmentOrder.orderStatus) &&
      (await dispatch(fetchAndSetOrderCosts(orderId)));
    /* eslint-disable  @typescript-eslint/no-floating-promises */
    getAndSetReplenishmentOrderHistoryState(replenishmentOrder.orderId, dispatch);
    if (replenishmentOrder?.shipments) {
      getAndSetReplenishmentOrderShipmentAttachmentState(replenishmentOrder?.shipments, dispatch);
    }

    return replenishmentOrder;
  };
