import { Select, SelectOptionType, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { useSPDispatch, useSPSelector } from "common/ReduxUtils";
import { NonComplianceListActionTypes } from "../actions/NonComplianceListActionTypes";
import { SUPPORTED_SELLER_PROBLEM_STATUSES } from "common/problems/SellerProblemStatus";
import { DEFAULT_NC_STATUS_LABEL_MAP } from "inbounds/NCShipmentSummaryDataStatus";

const StyledFilters = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;

    > div {
      margin-right: ${theme.spacing.S2};
    }

    label {
      margin-bottom: 0;
    }
`
);

const StyledSelectWrapper = styled.div`
  min-width: 100px;

  & > label {
    min-width: 100%;
  }
`;

const StyledProbemStatusSelect = styled(Select)`
  min-width: 200px;
`;

export const NonComplianceListStatusFilter: FC = () => {
  const dispatch = useSPDispatch();
  const { searchFilters } = useSPSelector((state) => state.nonComplianceList);
  const selectedProblemStatuses = searchFilters.problemStatuses;

  const options: SelectOptionType[] = SUPPORTED_SELLER_PROBLEM_STATUSES.map((status) => {
    return {
      value: status,
      label: DEFAULT_NC_STATUS_LABEL_MAP[status].defaultMessage ?? status,
    };
  });

  const formattedProblemStatuses = selectedProblemStatuses.map((status) => ({
    value: status,
    label: DEFAULT_NC_STATUS_LABEL_MAP[status].defaultMessage ?? status,
  }));

  return (
    <StyledFilters>
      <StyledSelectWrapper>
        <StyledProbemStatusSelect
          multiple
          flexWidth
          pluralEntityName="problem statuses"
          onChange={(statuses) => {
            dispatch({
              type: NonComplianceListActionTypes.SET_SEARCH_FILTERS,
              searchFilters: {
                ...searchFilters,
                problemStatuses: statuses.map((status) => status.value),
              },
            });
          }}
          value={formattedProblemStatuses}
          options={options}
        />
      </StyledSelectWrapper>
    </StyledFilters>
  );
};
