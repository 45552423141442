import React from "react";

import { FormattedMessage } from "react-intl";

export const SaveNewInboundError = (
  <FormattedMessage
    id="inbounds.errors.saveNewInboundError"
    defaultMessage="There was an error creating your inbound. Please try again later."
  />
);

export const DuplicateExternalShippingPlanIdError = () => (
  <FormattedMessage
    id="inbounds.errors.duplicateExternalShippingPlanIdError"
    defaultMessage="You have already created a shipping plan with this shipping Plan External Id, and you cannot reuse shipping Plan External Ids. Please go back to the origin address page, and enter a new shipping Plan External ID."
  />
);
