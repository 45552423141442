import { PackagingType } from "@deliverr/commons-objects";
import React from "react";
import { FormattedMessage } from "react-intl";
import { LabelText, PackagingSelectButton, StyledModal } from "./style";
import sharedCls from "../InventoryDetail.less";
import { Appearance } from "common/components/ui/shared";
import {
  Button,
  DefaultTheme,
  defaultTheme,
  Grid,
  Icon,
  Notification,
  RadioTileGroup,
  Stack,
  Title,
} from "common/components/ui";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { InputError } from "common/components/InputError";
import { packagingSelectModalId, usePackagingSelect } from "./usePackagingSelect";
import { Product } from "@deliverr/business-types";
import styled from "@emotion/styled";
import { SIOC_DIMENSIONS_LIMITS } from "common/constants/inbounds/inbounds.const";
import { supportLink } from "../Batteries/BatteryRequirements";

const polybagTitle = (
  <FormattedMessage id={"InventoryDetail.FulfillmentDetails.PolyTitle"} defaultMessage={"Flexport Recommended"} />
);

const TextMessage = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors.BLUE["300"]};
    font-weight: ${theme.font.weight.BOLD};
    cursor: pointer;
  `
);

const WarningElevioButtn = styled(ElevioBorderlessButton)`
  margin-left: 4px;
`;

export const packagingTitle: Record<PackagingType, JSX.Element> = {
  [PackagingType.POLY_BAG]: polybagTitle,
  [PackagingType.BUBBLE_MAILER]: polybagTitle,
  [PackagingType.BOX]: (
    <FormattedMessage id={"InventoryDetail.FulfillmentDetails.BoxTitle"} defaultMessage={"Box Only"} />
  ),
  [PackagingType.SHIPS_IN_OWN_CONTAINER]: (
    <FormattedMessage id={"InventoryDetail.FulfillmentDetails.SCIOCTitle"} defaultMessage={"Ready to Ship"} />
  ),
};

const polybagDesc = (
  <FormattedMessage
    id={"InventoryDetail.FulfillmentDetails.PolyDesc"}
    defaultMessage={
      "Likely to ship in a polybag or bubble mailer if possible. Heavier products will likely ship in a box."
    }
  />
);

export const packagingDesc: Record<PackagingType, JSX.Element> = {
  [PackagingType.POLY_BAG]: polybagDesc,
  [PackagingType.BUBBLE_MAILER]: polybagDesc,
  [PackagingType.BOX]: (
    <FormattedMessage
      id={"InventoryDetail.FulfillmentDetails.BoxDesc"}
      defaultMessage={"Ship in a box. Recommended for fragile items."}
    />
  ),
  [PackagingType.SHIPS_IN_OWN_CONTAINER]: (
    <FormattedMessage
      id={"InventoryDetail.FulfillmentDetails.SCIOCDesc"}
      defaultMessage={"Ship in own container (SIOC) with no additional packaging."}
    />
  ),
};

const showSIOCWarning = ({
  shouldShowSIOCWarning,
  selectedPackaging,
}: {
  shouldShowSIOCWarning: boolean;
  selectedPackaging: PackagingType;
}) => shouldShowSIOCWarning && selectedPackaging !== PackagingType.SHIPS_IN_OWN_CONTAINER;

const buildSIOCWarning = ({ isChangePackagingButtonDisabled }: { isChangePackagingButtonDisabled: boolean }) => {
  const siocWarningMessage = isChangePackagingButtonDisabled
    ? "This item will need to be inbounded in hazmat-approved ready-to-ship container/packaging to avoid SIOC non-compliance charges. Please <a>contact support</a> to change package type for hazmat SIOC packaging approval."
    : "Please ensure that this item is inbounded ready-to-ship in container/packaging to avoid SIOC non-compliance charges.";
  return (
    <div>
      <FormattedMessage
        id="InventoryDetail.PackagingSelectModal.SIOCWarning"
        defaultMessage={`This product's dimensions exceed our standard dimension requirements of {length}" x {width}" x {height}" and {weight}lbs. ${siocWarningMessage}`}
        values={{
          length: SIOC_DIMENSIONS_LIMITS.length,
          width: SIOC_DIMENSIONS_LIMITS.width,
          height: SIOC_DIMENSIONS_LIMITS.height,
          weight: SIOC_DIMENSIONS_LIMITS.weight,
          a: supportLink,
        }}
      />
      <WarningElevioButtn articleId={ElevioArticleId.DimensionalRequirements}>
        <FormattedMessage
          id="InventoryDetail.PackagingSelectModal.DimensionalRequirementsForSIOC"
          defaultMessage="Learn more"
        />
      </WarningElevioButtn>
    </div>
  );
};

const ExistingPackagingContainer = ({
  showModal,
  initialSelectedPackaging,
  shouldShowSIOCWarning,
  isChangePackagingButtonDisabled,
}: {
  showModal: () => void;
  initialSelectedPackaging: PackagingType;
  shouldShowSIOCWarning: boolean;
  isChangePackagingButtonDisabled: boolean;
}) => {
  const labelText = showSIOCWarning({
    shouldShowSIOCWarning: !!shouldShowSIOCWarning,
    selectedPackaging: initialSelectedPackaging,
  }) ? (
    <InputError
      isBold={false}
      appearance={Appearance.WARNING}
      message={buildSIOCWarning({ isChangePackagingButtonDisabled })}
    />
  ) : (
    packagingDesc[initialSelectedPackaging]
  );
  return (
    <>
      <LabelText className={sharedCls.labelName}>
        <FormattedMessage id={"InventoryDetail.Packaging"} defaultMessage={"Packaging"} />
      </LabelText>
      <PackagingSelectButton onClick={showModal} data-testid="changePackagingModal_trigger">
        {packagingTitle[initialSelectedPackaging]}
        <Icon type={"pen"} color={defaultTheme.colors.BLUE[300]} />
      </PackagingSelectButton>
      <LabelText className={sharedCls.labelName}>{labelText}</LabelText>
    </>
  );
};

export const PackagingSelect = ({
  product,
  hideExistingPackaging = false,
  packagingOptionsOverride,
  isUpdateFromInboundPage = false,
}: {
  product?: Product;
  hideExistingPackaging?: boolean;
  packagingOptionsOverride?: { label: JSX.Element; content: JSX.Element; value: PackagingType }[];
  isUpdateFromInboundPage?: boolean;
}) => {
  const {
    initialSelectedPackaging,
    showModal,
    packagingOptions,
    selectedPackaging,
    shouldShowSIOCWarning,
    setSelectedPackaging,
    changePackaging,
    resetPackagingSelect,
    isChangePackagingButtonDisabled,
    isLoading,
  } = usePackagingSelect(
    product,
    isUpdateFromInboundPage,
    hideExistingPackaging ? packagingOptionsOverride![0]?.value : undefined
  );
  const shouldShowSIOCWarningFlag = !!shouldShowSIOCWarning;

  return (
    <>
      <StyledModal id={packagingSelectModalId} size={"md"} hasCloseButton={true}>
        <Stack gap="S5">
          <Title as="h2" displayAs="h3">
            <FormattedMessage id="InventoryDetail.PackagingSelectModal.title" defaultMessage="Change Packaging" />
          </Title>
          <p>
            <FormattedMessage
              id="InventoryDetail.PackagingSelectModal.desc"
              defaultMessage="Changing the shipping container will inform our fulfillment centers to follow these instructions and change this product’s fulfillment fee."
            />
          </p>
          {showSIOCWarning({ shouldShowSIOCWarning: shouldShowSIOCWarningFlag, selectedPackaging }) && (
            <Notification
              appearance="WARNING"
              content={{
                description: buildSIOCWarning({ isChangePackagingButtonDisabled }),
              }}
            />
          )}
          <div>
            <RadioTileGroup
              currentValue={selectedPackaging}
              direction="VERTICAL"
              header="Change Packaging"
              name="changePackaging"
              onChange={(e) => setSelectedPackaging(e.target.value as typeof selectedPackaging)}
              block={true}
              options={packagingOptionsOverride ?? packagingOptions}
            />
          </div>
          <Grid columns="1fr 1fr" gap="S5" fullWidth>
            <Button secondary appearance="DEFAULT" block onClick={resetPackagingSelect}>
              <FormattedMessage id="cancel" defaultMessage={"Cancel"} />
            </Button>
            <Button
              appearance="DEFAULT"
              block
              onClick={changePackaging}
              data-testid="changePackagingModal_confirm"
              disabled={isChangePackagingButtonDisabled}
              loading={isLoading}
            >
              <FormattedMessage
                id="InventoryDetail.PackagingSelectModal.changePackaging"
                defaultMessage={"Change packaging"}
              />
            </Button>
          </Grid>
        </Stack>
      </StyledModal>
      {hideExistingPackaging ? (
        <TextMessage onClick={showModal}>Change packaging</TextMessage>
      ) : (
        <ExistingPackagingContainer
          showModal={showModal}
          initialSelectedPackaging={initialSelectedPackaging}
          shouldShowSIOCWarning={shouldShowSIOCWarningFlag}
          isChangePackagingButtonDisabled={isChangePackagingButtonDisabled}
        />
      )}
    </>
  );
};
