import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useState } from "react";

import { useInboundFreightTrackingInfo } from "inbounds/steps/ship/view/cards/useInboundFreightTrackingInfo";
import { updateFreightTrackingInfo } from "inbounds/store/actions/freight/updateFreightTrackingInfo";
import { getLoadedShipment } from "inbounds/store/selectors/shipments";
import { FLEXPORT_TRACKING_PREFIX } from "inbounds/steps/ship/view/FlexportAutomatedTracking/constants";
import { usePalletAppointmentInstructions } from "inbounds/steps/ship/view/cards/confirmation/LtlExternalConfirmation/usePalletAppointmentInstructions";
import { triggerCarrierEmail, updateShipment } from "inbounds/InboundActions";
import { toast } from "common/components/ui/Toast";

export function useAddReceivingInfoForm(isFlexport = false) {
  const {
    bolId,
    proId,
    updateInboundFreightTrackingInfo,
    hasReceivingInfoChanged,
    estimatedShipDate,
    hasShipmentConfirmationInfoChanged,
  } = useInboundFreightTrackingInfo();
  const {
    minDate,
    onDateChange,
    handleCarrierSelectionChange,
    carrier,
    carrierEmail,
    hasCarrierEmailError,
    handleCarrierEmailBlur,
    handlCarrierEmailFocus,
    checkAndSetCarrierEmailError,
    onCarrierEmailChange,
    warehouseAppointmentLink,
    updateCarrierEmailInfoToStore,
  } = usePalletAppointmentInstructions();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    id: shipmentId,
    carrierEmail: existingCarrierEmail,
    containerId: existingContainerId,
  } = useSelector(getLoadedShipment);
  const [containerId, setContainerId] = useState(existingContainerId);

  const setBolId = (val: string) => {
    updateInboundFreightTrackingInfo({ bolId: val });
  };

  const setProId = (val: string) => {
    if (isFlexport) {
      const validated = validateFlexportID(val);
      if (validated) {
        updateInboundFreightTrackingInfo({ proId: validated });
      }
    } else {
      updateInboundFreightTrackingInfo({ proId: val });
    }
  };

  const onSubmit = async () => {
    if (hasReceivingInfoChanged) {
      await dispatch(updateFreightTrackingInfo(shipmentId!, { bolId, proId, estimatedShipDate }));
    }
    if (containerId !== existingContainerId) {
      await dispatch(updateShipment(shipmentId, { containerId }));
    }
    toast.success(
      formatMessage({
        id: "useAddReceivingInfoForm.onSubmit.toast.success",
        defaultMessage: "Shipment details updated successfully.",
      }),
      {
        autoClose: 2000,
        toastId: "shipmentUpdateSuccess",
      }
    );
  };

  const validateFlexportID = (value: string): string | undefined => {
    const hasFlexIdPrefix = value.toLowerCase().startsWith(FLEXPORT_TRACKING_PREFIX.toLowerCase());

    if (!hasFlexIdPrefix) {
      return FLEXPORT_TRACKING_PREFIX;
    }

    const onlyNumbersRegex = /^[0-9]*$/;
    const [, flexSuffix] = value.split(FLEXPORT_TRACKING_PREFIX);
    if (flexSuffix.match(onlyNumbersRegex)) {
      return value;
    }

    return undefined;
  };

  const bolIdPlaceholder = formatMessage({
    id: "inbounds.ltlExternalConfirmation.addReceivingInfoForm.bolIdPlaceholder",
    defaultMessage: "Input your BOL #",
  });

  const proIdPlaceholder = formatMessage({
    id: "inbounds.ltlExternalConfirmation.addReceivingInfoForm.proIdPlaceholder",
    defaultMessage: "Input your PRO #",
  });

  const containerIdPlaceholder = formatMessage({
    id: "inbounds.ltlExternalConfirmation.addReceivingInfoForm.proIdPlaceholder",
    defaultMessage: "Input your Container Id",
  });

  const updateDeliveryAppointmentDetails = async () => {
    if (hasShipmentConfirmationInfoChanged) {
      await dispatch(updateFreightTrackingInfo(shipmentId!, { shippingProvider: carrier }));
    }
    if (carrierEmail !== existingCarrierEmail) {
      await dispatch(triggerCarrierEmail(carrierEmail));
      updateCarrierEmailInfoToStore();
    }

    toast.success(
      formatMessage({
        id: "useAddReceivingInfoForm.updateDeliveryAppointmentDetails.toast.success",
        defaultMessage: "Delivery appointment details updated successfully.",
      }),
      {
        autoClose: 2000,
        toastId: "deliveryAppointmentUpdateSuccess",
      }
    );
  };

  return {
    bolId,
    setBolId,
    proId,
    setProId,
    containerId,
    setContainerId,
    bolIdPlaceholder,
    proIdPlaceholder,
    containerIdPlaceholder,
    submitDisabled: !hasReceivingInfoChanged && containerId === existingContainerId,
    onSubmit,
    minDate,
    estimatedShipDate,
    setShipmentDate: onDateChange,
    handleCarrierSelectionChange,
    carrier,
    carrierEmail,
    hasCarrierEmailError,
    handleCarrierEmailBlur,
    handlCarrierEmailFocus,
    checkAndSetCarrierEmailError,
    onCarrierEmailChange,
    warehouseAppointmentLink,
    updateDeliveryAppointmentDetails,
    disableUpdateDeliveryAppointmentButton:
      !carrierEmail ||
      hasCarrierEmailError ||
      (!hasShipmentConfirmationInfoChanged && carrierEmail === existingCarrierEmail),
  };
}
