import { MessageDescriptor, defineMessages } from "react-intl";

export const InvalidKitsType = {
  LOT_AND_FEFO: "LOT_AND_FEFO",
  FEFO: "FEFO",
  LOT: "LOT",
  ALL_ENABLED: "ALL_ENABLED",
};

export type InvalidKitsTypeValues = (typeof InvalidKitsType)[keyof typeof InvalidKitsType];

export const INVALID_KITS_TYPE_LABELS: Record<InvalidKitsTypeValues, MessageDescriptor> = defineMessages({
  [InvalidKitsType.LOT_AND_FEFO]: {
    id: "kit.invalidKitType.lotAndFefo",
    defaultMessage: "Lot Info & FEFO",
  },
  [InvalidKitsType.FEFO]: {
    id: "kit.invalidKitType.fefo",
    defaultMessage: "FEFO",
  },
  [InvalidKitsType.LOT]: {
    id: "kit.invalidKitType.lot",
    defaultMessage: "Lot Info",
  },
  [InvalidKitsType.ALL_ENABLED]: {
    id: "kit.invalidKitType.allEnabled",
    defaultMessage: "All Enabled",
  },
});
