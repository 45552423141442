import React, { FC } from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "common/components/ui";
import { FormattedMessage, MessageDescriptor } from "react-intl";

export const DropdownTitle = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-size: ${theme.font.size.F2};
`
);

export const DropdownDescription = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  font-size: ${theme.font.size.F1};
`
);

interface ViewShipmentHeaderDownloadsDropdownOptionProps {
  title: MessageDescriptor;
  text: MessageDescriptor;
}

export const ViewShipmentHeaderDownloadsDropdownOption: FC<ViewShipmentHeaderDownloadsDropdownOptionProps> = ({
  title,
  text,
}) => {
  return (
    <>
      <DropdownTitle>
        <FormattedMessage {...title} />
      </DropdownTitle>
      <DropdownDescription>
        <FormattedMessage {...text} />
      </DropdownDescription>
    </>
  );
};
