import React from "react";
import { FbaV3LoadingAndErrorDisplay } from "../commons/FbaV3LoadingAndErrorDisplay";
import { FbaV3EntityName } from "../commons/FbaV3Constants";
import { ManagedStep } from "common/components/StepContainer";
import { useOrderConfirmation } from "./useOrderConfirmation";

export const OrderConfirmationPolling: React.FC = () => {
  const {
    isNextDisabled,
    isRetryOperationLoading,
    orderConfirmationErrors,
    hasRetryLimitReached,
    isOrderConfirmationLoading,
    onRetryAfterErrorClick,
    onRetryAfterRetryLimitReachedClick,
  } = useOrderConfirmation();

  return (
    <ManagedStep data-testid="fbav3-create-transfer-order-confirmation-polling" isNextDisabled={isNextDisabled}>
      <FbaV3LoadingAndErrorDisplay
        operationErrors={orderConfirmationErrors}
        entityName={FbaV3EntityName.ORDER_CONFIRMATION}
        isOperationLoading={isOrderConfirmationLoading}
        onRetryAfterErrorClick={onRetryAfterErrorClick}
        onRetryAfterRetryLimitReachedClick={onRetryAfterRetryLimitReachedClick}
        hasRetryLimitReached={hasRetryLimitReached}
        isRetryOperationLoading={isRetryOperationLoading}
      />
    </ManagedStep>
  );
};
