export enum SellerReportType {
  BillingCreditsBalance = "Billing-Credits_Balance",
  ClaimsSubmitted = "Claims-Claims_Submitted",
  FulfillmentFees = "Pricing-Fulfillment_Fees",
  InboundPackages = "Inbounds-Packages",
  InboundsInventory = "Inbounds-Inventory_Reconciliation",
  InboundsMonthly = "Inbounds-Monthly_Reconciliation",
  InboundsShippingPlan = "Inbounds-Shipping_Plan_Reconciliation",
  IncidentImpactReport = "Incident-Impact_Report",
  InventoryAge = "Inventory-Units_In_Long_Term_Storage",
  InventoryEcommerceMonthlyReconciliation = "Inventory-Ecommerce_Monthly_Reconciliation",
  InventoryEcommerceMonthlyReconciliationUsingLedger = "Inventory-Ecommerce_Monthly_Reconciliation_Using_Ledger",
  InventoryEcommerceTransactionHistory = "Inventory-Ecommerce_Transaction_History",
  InventoryLevels = "Inventory-Levels_Today",
  InventoryLevelsUsingLedger = "Inventory-Levels_Today_Using_Ledger",
  InventoryLotTrackingFEFOAllProducts = "Inventory-Lot_Tracking_FEFO_All_Products",
  InventoryLotTrackingFEFOAllProductsUsingLedger = "Inventory-Lot_Tracking_FEFO_All_Products_Using_Ledger",
  InventoryLotTrackingFEFOSingleProduct = "Inventory-Lot_Tracking_FEFO_Single_Product",
  InventoryLotTrackingFEFOSingleProductUsingLedger = "Inventory-Lot_Tracking_FEFO_Single_Product_Using_Ledger",
  InventoryDetailReserveStorageUsingLedger = "Inventory-Detail_Reserve_Storage_Using_Ledger",
  InventoryHourlyVisibilityReport = "Inventory-Hourly_Visibility_Report",
  OrderPackages = "Orders-Shipments",
  Orders = "Orders-All_Orders",
  Parcel = "Parcel-All_Parcels",
  ParcelTracking = "Parcel_All_Parcels_Tracking_Details",
  ProductsAllSKUsWithDimsAndAliasCounts = "Products-All_SKUs_With_Dims_And_Alias_Counts",
  ProductsAvailableSKUsWithAliasCounts = "Products-Available_SKUs_With_Alias_Counts",
  ProductsAvailableSKUsWithNoAliases = "Products-Available_SKUs_With_No_Aliases",
  ProductsSKUsWithLinkedAliases = "Products-SKUs_With_Linked_Aliases",
  ProductsAllCasePacksWithDimsAndInventory = "Products-All_CasePacks_With_Dims_And_Inventory",
  ReplenishmentItemizedReport = "Replenishment-Order_Item_Report",
  ReserveStorageOrdersAndTransfers = "Reserve-Storage-Orders_And_Transfers",
  ReserveStoragePalletInventory = "Reserve-Storage-Pallet-Inventory",
  ReturnItems = "Returns-All_SKUs",
  Returns = "Returns-All_Returns",
}

export enum DeprecatedReportType {
  ProductsAllSKUsWithAliasCounts = "Products-All_SKUs_With_Alias_Counts",
}
