import { PackagingType } from "@deliverr/commons-objects";
import { ShippingSpecificationSourceType } from "@deliverr/commons-clients";

export const hasHazmatFragileSpec = (
  selectedPackaging: PackagingType,
  sourceType: ShippingSpecificationSourceType
): boolean => sourceType === ShippingSpecificationSourceType.HAZMAT && selectedPackaging === PackagingType.BOX;

export const isOptionDisabled = (
  option: PackagingType,
  selectedPackaging: PackagingType,
  sourceType: ShippingSpecificationSourceType
): boolean =>
  hasHazmatFragileSpec(selectedPackaging, sourceType)
    ? option === PackagingType.POLY_BAG || option === PackagingType.SHIPS_IN_OWN_CONTAINER
    : false;
