import { ATPMap } from "./ATP/ATPMap";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { DeliverrError } from "@deliverr/errors";
import { InventoryError } from "./InventoryError";
import { SellerInventorySummary } from "../onboarding/SellerInventorySummary";
import { WarehouseATP } from "./WarehouseATP";
import { chunk } from "lodash/fp";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { AggregatedSnapshot } from "@deliverr/inventory-client";

export type InventoryClientConfig = ApiClientConfig;

const DEFAULT_HEADERS = {
  "X-Request-Caller": "seller-portal",
  "X-Direct-Caller": "seller-portal",
};

const INVENTORY_CLIENT_BATCH_LIMIT = 50;

export class InventoryClient {
  private readonly DEFAULT_POOL = "DEFAULT";
  private apiClient: ApiClient;

  constructor(
    { baseURL, headers }: InventoryClientConfig = {
      baseURL: mustBeDefined(process.env.INVENTORY_SERVICE_URL),
      headers: DEFAULT_HEADERS,
    }
  ) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}`,
      headers,
    });
  }

  async getATP(dskus: string[], getAgingData?: boolean, poolName?: string): Promise<ATPMap> {
    try {
      const atpMapArr: ATPMap[] = await Promise.all(
        chunk(INVENTORY_CLIENT_BATCH_LIMIT, dskus).map(
          async (dskuChunk) =>
            (await this.apiClient.get({
              url: "/api/v1/inventory/stockkeepingunits/atp/",
              params: {
                dskus: dskuChunk.join(","),
                getAgingData,
                poolName: poolName ?? this.DEFAULT_POOL,
              },
              authentication: isAuthenticated,
            })) as ATPMap
        )
      );
      return atpMapArr.reduce((acc, atpMap) => ({ ...acc, ...atpMap }), {});
    } catch (ex) {
      switch (ex.response.status) {
        case 404:
          return {};
        default:
          throw new Error(ex);
      }
    }
  }

  async getATPByWarehouse(dskus: string[], poolName?: string, getAgingData?: boolean): Promise<ATPMap> {
    try {
      const atpMapArr: ATPMap[] = await Promise.all(
        chunk(INVENTORY_CLIENT_BATCH_LIMIT, dskus).map(
          async (dskuChunk) =>
            (await this.apiClient.get({
              url: "/api/v1/inventory/stockkeepingunits/atp/warehouse",
              params: {
                dskus: dskuChunk.join(","),
                getAgingData,
                poolName: poolName ?? this.DEFAULT_POOL,
              },
              authentication: isAuthenticated,
            })) as ATPMap
        )
      );
      return atpMapArr.reduce((acc, atpMap) => ({ ...acc, ...atpMap }), {});
    } catch (ex) {
      switch (ex.response.status) {
        case 404:
          return {};
        default:
          throw new Error(ex);
      }
    }
  }

  async getWarehouseATP(skus: string[]): Promise<WarehouseATP> {
    try {
      return await this.apiClient.post({
        url: "/api/v1/inventory/stockkeepingunits/atp/warehouse",
        body: {
          skus,
        },
        authentication: isAuthenticated,
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 404:
          return {};
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "getWarehouseATP",
            payload: ex.response.data,
          });
      }
    }
  }

  /**
   * Returns number of available and unavailable skus for sale, given a seller id
   * @param sellerId - the sellerId to get inventory summary
   */
  async getInventorySummary(sellerId: string): Promise<SellerInventorySummary> {
    try {
      return await this.apiClient.get({
        url: `/api/v1/inventory/stockkeepingunits/summary/${sellerId}`,
        params: {},
        authentication: isAuthenticated,
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 400:
          throw new DeliverrError(InventoryError.BAD_REQUEST);
        case 404:
          throw new DeliverrError(InventoryError.UNKNOWN_SELLER);
        default:
          throw new Error(ex);
      }
    }
  }

  async getAvailableSkusForSeller(sellerId: string): Promise<string[]> {
    try {
      return await this.apiClient.get({
        url: `/api/v1/inventory/stockkeepingunits/available/${sellerId}`,
        params: {},
        authentication: isAuthenticated,
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 400:
          throw new DeliverrError(InventoryError.BAD_REQUEST);
        case 404:
          throw new DeliverrError(InventoryError.UNKNOWN_SELLER);
        default:
          throw new Error(ex);
      }
    }
  }

  async getATPAvailableForSeller(seller: string, poolName?: string): Promise<ATPMap> {
    try {
      return await this.apiClient.get({
        url: `/api/v1/inventory/stockkeepingunits/atp/available`,
        params: {
          seller,
          poolName: poolName ?? this.DEFAULT_POOL,
        },
        authentication: isAuthenticated,
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 400:
          throw new DeliverrError(InventoryError.BAD_REQUEST);
        case 404:
          throw new DeliverrError(InventoryError.UNKNOWN_SELLER);
        default:
          throw new Error(ex);
      }
    }
  }

  async getSnapshot(dsku: string): Promise<AggregatedSnapshot> {
    try {
      return await this.apiClient.get({
        url: `/api/v2/snapshots/${dsku}`,
        authentication: isAuthenticated,
      });
    } catch (ex) {
      throw new Error("Failed to get snapshot");
    }
  }
}
