import { InboundBooking } from "@deliverr/inbound-client";
import { CreateShipmentFormState } from "../../../CreateShipmentState";
import { parseBookingType } from "../../../utils/parseBookingType";

type DestinationSelectState = Pick<CreateShipmentFormState, "destinationSelect">;

export const getDestinationState = (booking: InboundBooking): DestinationSelectState => {
  const { isByFlexport, isByAmazon, isDTCNetwork, isP2P } = parseBookingType(booking.bookingType!);

  return {
    destinationSelect: {
      isByFlexport,
      isByAmazon,
      isDTCNetwork,
      isOwnDestination: isP2P,
    },
  };
};
