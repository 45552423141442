import React, { FC } from "react";
import { isEmpty } from "lodash/fp";
import { useMobile } from "common/components/ui";
import { ProblemsTable } from "common/components/ProblemsTable";
import { NonComplianceEmptyList } from "../components/NonComplianceEmptyList";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { NonComplianceListMobile } from "./NonComplianceListMobile";
import { NonComplianceListBlade } from "./blades";
import { useNonComplianceSpvList } from "./useNonComplianceSpvList";
import { RootState } from "RootReducer";
import { useSelector } from "react-redux";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { NonComplianceListTableControls } from "./NonComplianceListTableControls";
const DEFAULT_PROBLEMS_PER_PAGE = 10;

export const NonComplianceSpvList: FC = () => {
  const pageSize =
    useSelector((state: RootState) => state.user.resultsPerPage.inboundProblemsList) ?? DEFAULT_PROBLEMS_PER_PAGE;

  const { columns, rows, onRowClick, isLoading, activeCase, totalPages, currentPage, savePage } =
    useNonComplianceSpvList(pageSize);

  const onPageChange = (page: number) => {
    savePage(page);
  };

  const isMobile = useMobile();

  if (isLoading) {
    return (
      <>
        <NonComplianceListTableControls />
        <SimpleLoader />
      </>
    );
  }

  if (isEmpty(rows)) {
    return (
      <>
        <NonComplianceListTableControls />
        <NonComplianceEmptyList />
      </>
    );
  }

  return (
    <>
      <NonComplianceListTableControls />
      <NonComplianceListBlade nonComplianceCase={activeCase as ProblemRowData} />
      {isMobile ? (
        <NonComplianceListMobile rows={rows as unknown as ProblemRowData[]} onRowClick={onRowClick} />
      ) : (
        <ProblemsTable
          columns={columns}
          rows={rows as unknown as ProblemRowData[]}
          onRowClick={onRowClick}
          hidePaginate={false}
          pagingInfo={{
            totalPages: totalPages ?? 0,
            currentPage,
          }}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
