import { useSelector } from "react-redux";
import { RootState } from "RootReducer";

export const useReturnsPackageDetailHeader = () => {
  const { packageDetail } = useSelector((state: RootState) => state.returnsPackageDetail);

  return {
    packageId: packageDetail?.id,
    boxBarcode: packageDetail?.boxBarcode,
    boxStatus: packageDetail?.boxStatus,
  };
};
