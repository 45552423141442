import { createSelector } from "reselect";
import { getStorageInboundDetail } from "./getStorageInboundDetail";
import { CarrierType } from "@deliverr/storage-client";
import { InboundShipmentStatus } from "@deliverr/inbound-client";

export const isActionNeededForStorageShipment = createSelector(
  getStorageInboundDetail,
  ({ storageInboundOrder = {} }) => {
    return (
      !storageInboundOrder.carrierEmailAddress &&
      storageInboundOrder.carrierType !== CarrierType.DELIVERR &&
      storageInboundOrder.status === InboundShipmentStatus.READY_FOR_WAREHOUSE
    );
  }
);
