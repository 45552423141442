import { Button, IconV2, Pill, Stack } from "common/components/ui";
import { DefaultTheme, Direction, ThemeProps } from "common/components/ui/shared";
import styled from "@emotion/styled";
import { Card } from "common/components/Card";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { RmtHeaderInfoSection, RmtLogo, RmtName } from "returns/rmt/components/ManageRmt/RmtStyles";
import { RmtCardProps, rmtConfig } from "returns/rmt/RmtUtils";
import { DisconnectRmt } from "../SetupRmt/DisconnectRmt";
import { RmtConnectionStatus, RmtStatusPill } from "../RmtStatusPill";
import { useManageRmtCard } from "./useManageRmtCard";
import { useAmazonOAuthError } from "../SetupRmt/useAmazonOAuthError";
import { RmtDisabledReason } from "@deliverr/returns-client";
import { manageRmtMessages } from "../../RmtMessages";

const ConnectionActions = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    gap: ${theme.spacing.S5};
    height: 2.5rem;
    justify-content: right;

    @media (min-width: ${theme.breakpoints.MD}) {
      gap: ${theme.spacing.S6};
    }
  `
);
const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: initial;
  margin: ${theme.spacing.S7} auto;
  box-shadow: ${theme.shadow.S1};
  border-radius: ${theme.border.radius.R4};
  padding: 2rem 2.5rem;
`
);

export const Content = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    display: grid;
    gap: ${theme.spacing.S4};

    @media (min-width: ${theme.breakpoints.MD}) {
      align-items: flex-start;
      grid-template-columns: repeat(2, 1fr);
      gap: ${theme.spacing.S5};
    }
  `
);

const StyledButton = styled(Button)(`
  font-size: 14px;
  font-weight: 700;
  color: black;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  `);

export const ManageRmtCard: FC<RmtCardProps> = ({ rmt }) => {
  const {
    onConnectRmtClick,
    onDisconnectRmtClick,
    onUpdateCredentialsClick,
    showDisconnectModal,
    hideDisconnectModal,
  } = useManageRmtCard(rmt);

  const config = rmtConfig(rmt.rmtId);
  const isConnected = rmt.isActive;
  const areCredentialsInvalid = !isConnected && rmt.disabledReason === RmtDisabledReason.INVALID_CREDENTIALS;
  useAmazonOAuthError();

  return (
    <>
      <DisconnectRmt deactivateRmt={onDisconnectRmtClick} hideDisconnectModal={hideDisconnectModal} rmtId={rmt.rmtId} />
      <StyledCard>
        <Content data-testid="returns-rmt-manage-title">
          <RmtHeaderInfoSection>
            <RmtLogo>{config.roundedLogo}</RmtLogo>
            <Stack gap="S2">
              <div>
                <RmtName as="h3">{config.name}</RmtName>
              </div>
              <div>
                <Stack direction={Direction.HORIZONTAL} gap="S2">
                  <RmtStatusPill
                    status={isConnected ? RmtConnectionStatus.CONNECTED : RmtConnectionStatus.DISCONNECTED}
                  />
                  {areCredentialsInvalid && (
                    <Pill color="RED">{<FormattedMessage {...manageRmtMessages.invalidCredentials} />}</Pill>
                  )}
                </Stack>
              </div>
            </Stack>
          </RmtHeaderInfoSection>
          <ConnectionActions>
            {rmt.isActive ? (
              <StyledButton onClick={showDisconnectModal} data-testid="returns-rmt-manage-disconnect-button">
                <IconV2 type="link-broken" />
                <FormattedMessage {...manageRmtMessages.disconnect} />
              </StyledButton>
            ) : (
              <StyledButton onClick={onConnectRmtClick} data-testid="returns-rmt-manage-connect-button">
                <IconV2 type="link" />
                <FormattedMessage {...manageRmtMessages.connect} />
              </StyledButton>
            )}
            <StyledButton onClick={onUpdateCredentialsClick} data-testid="returns-rmt-manage-update-credentials-button">
              <IconV2 type="history" />
              <FormattedMessage {...manageRmtMessages.updateCredentials} />
            </StyledButton>
          </ConnectionActions>
        </Content>
      </StyledCard>
    </>
  );
};
