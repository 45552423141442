import { RootState } from "RootReducer";
import { useDispatch, useSelector } from "react-redux";
import { searchNonCompliance } from "./actions/searchNonCompliance";
import { setCurrentSearchTerm } from "./actions/setCurrentSearchTerm";
import { useDebounce } from "react-use";

export const useNonComplianceListSearch = () => {
  const dispatch = useDispatch();
  const currentSearchTerm = useSelector((state: RootState) => state.nonComplianceList.searchTerm);

  useDebounce(
    () => {
      dispatch(searchNonCompliance());
    },
    Number(process.env.DEFAULT_SEARCH_THROTTLE_MS),
    [currentSearchTerm]
  );

  const handleOnChange = (value: string) => {
    dispatch(setCurrentSearchTerm(value));
  };

  return {
    currentSearchTerm,
    handleOnChange,
  };
};
