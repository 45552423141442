import { SmbClientOrderBatteriesPackingType } from "@deliverr/commons-clients";
import { InboundBooking, InboundBookingProduct } from "@deliverr/inbound-client";
import { addLocalTimeZoneOffset } from "common/date/addLocalTimeZoneOffset";
import { CargoInputType, PackageSummary } from "inbounds/createShipment/CreateShipmentTypes";
import {
  CreateShipmentFormState,
  createShipmentInitialFormState as defaults,
} from "inbounds/createShipment/store/CreateShipmentState";
import { mapSingleSkuInboundPackageSummaryToDtcPackageCollection } from "inbounds/createShipment/store/utils";

type CargoDetailsState = Pick<
  CreateShipmentFormState,
  | "cargoInputType"
  | "isCargoMixedSku"
  | "singleSkuPackages"
  | "mixedSkuPackages"
  | "shipmentTotals"
  | "unitSystem"
  | "bookingProducts"
  | "cargoReadyDate"
  | "hasDangerousGoods"
  | "dangerousGoods"
  | "lithiumBatteryPackaging"
>;

const getCargoInputType = (booking: InboundBooking): CargoDetailsState["cargoInputType"] => {
  if (booking.bookingProducts?.some((product) => Boolean(product.dsku))) {
    return CargoInputType.PRODUCTS;
  }

  return CargoInputType.TOTALS;
};

export const getCargoDetailsState = (booking: InboundBooking): CargoDetailsState => {
  const isCargoMixedSku = !booking.packages?.map((pkg) => pkg.items.length === 1)?.every((isSingleSku) => isSingleSku);
  return {
    cargoInputType: getCargoInputType(booking),
    isCargoMixedSku: isCargoMixedSku ?? false,
    singleSkuPackages: isCargoMixedSku
      ? {}
      : mapSingleSkuInboundPackageSummaryToDtcPackageCollection(booking.packages ?? []),
    mixedSkuPackages: isCargoMixedSku ? ((booking.packages ?? []) as unknown as PackageSummary[]) : [],
    bookingProducts: (booking.bookingProducts as InboundBookingProduct[]) ?? defaults.bookingProducts,
    unitSystem: booking.cargoUnitSystem ?? defaults.unitSystem,
    shipmentTotals: {
      volume: booking.cargoTotalVolume ?? defaults.shipmentTotals.volume,
      weight: booking.cargoTotalWeight ?? defaults.shipmentTotals.weight,
      boxes: booking.cargoTotalBoxes ?? defaults.shipmentTotals.boxes,
      units: booking.cargoTotalItems ?? defaults.shipmentTotals.units,
      pallets: booking.palletCount ?? defaults.shipmentTotals.pallets,
    },
    cargoReadyDate: booking.cargoReadyDate ? addLocalTimeZoneOffset(booking.cargoReadyDate) : defaults.cargoReadyDate,
    hasDangerousGoods: booking.dangerousGoods ?? defaults.hasDangerousGoods,
    dangerousGoods: {
      hasLithiumBatteries: booking.lithiumBatteries ?? defaults.dangerousGoods.hasLithiumBatteries,
      hasNonLithiumBatteries: booking.nonLithiumBatteries ?? defaults.dangerousGoods.hasNonLithiumBatteries,
      hasOther: booking.otherDangerousGoods ?? defaults.dangerousGoods.hasOther,
      // hasNoDangerousGoods can only be true when no other DG options are selected (so when all are false)
      // can't rely on hasDangerousGoods since that can be true or false for an order with DG
      hasNoDangerousGoods: !booking.otherDangerousGoods && !booking.lithiumBatteries && !booking.nonLithiumBatteries,
    },
    lithiumBatteryPackaging: {
      packedLoose:
        booking.lithiumBatteryPackaging?.includes(SmbClientOrderBatteriesPackingType.PACKED_LOOSE) ??
        defaults.lithiumBatteryPackaging.packedLoose,
      packedEquipment:
        booking.lithiumBatteryPackaging?.includes(SmbClientOrderBatteriesPackingType.PACKED_WITH_EQUIPMENT) ??
        defaults.lithiumBatteryPackaging.packedEquipment,
      inEquipment:
        booking.lithiumBatteryPackaging?.includes(SmbClientOrderBatteriesPackingType.CONTAINED_IN_EQUIPMENT) ??
        defaults.lithiumBatteryPackaging.inEquipment,
      inVehicle:
        booking.lithiumBatteryPackaging?.includes(
          SmbClientOrderBatteriesPackingType.CONTAINED_IN_BATTERY_POWERED_VEHICLE
        ) ?? defaults.lithiumBatteryPackaging.inVehicle,
    },
  };
};
