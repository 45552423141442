import { getContentType } from "./getContentType";

export const uploadFile = async (url: string, file: File) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/pdf");
  const response = await fetch(url, {
    method: "PUT",
    headers,
    body: file,
    mode: "cors",
  });

  if (!response.ok) {
    throw new Error(`Failed to upload file: ${response.statusText}`);
  }

  return response;
};

export const uploadSheet = async (url: string, file: File, fileExtension: string) => {
  const headers = new Headers();
  const acceptedContentType = getContentType(fileExtension);
  if (acceptedContentType) {
    headers.append("Content-Type", acceptedContentType);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: file,
      mode: "cors",
    });

    if (!response.ok) {
      throw new Error(`Failed to upload file: ${response.statusText}`);
    }

    return response;
  } else {
    throw new Error(`Invalid file extension: ${fileExtension}`);
  }
};
