import React from "react";
import { useIntl } from "react-intl";
import { BULK_UPLOAD_ERROR_MESSAGES } from "./BulkUploadErrorMessages";
import { BULK_UPLOAD_CONTENT_MESSAGES } from "./BulkUploadContentMessages";
import { BulkUploadValidationError } from "./useBulkUploadModal";
import { BulkUploadTemplateType } from "./BulkUploadTemplateType";
import { bulkUploadTemplates } from "./BulkUploadTemplates";
import { FeatureName, useFeatureOn } from "common/Split";

const getLinkChunk = (url: string, content: string) => ({
  link: (
    <a target="_blank" rel="noreferrer" href={url} key={url}>
      {content}
    </a>
  ),
});

export const useBulkUploadModalContent = ({ templateType }: { templateType: BulkUploadTemplateType }) => {
  const { formatMessage } = useIntl();
  const isPackagesBulkUploadEnabled = useFeatureOn(FeatureName.SmbPackagesBulkUpload);
  const isSellerLPNsEnabledForExcel = useFeatureOn(FeatureName.isSellerLPNsEnabledForExcelUpload);

  const getTitleContent = (hasError: boolean) => {
    if (hasError) {
      return formatMessage(BULK_UPLOAD_ERROR_MESSAGES.UNABLE_TO_IMPORT_PRODUCTS);
    }

    return formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.UPLOAD_FILE);
  };

  const getSubtitleContent = (hasRequestError: boolean, validationErrors?: BulkUploadValidationError[]) => {
    let downloadTemplateType;
    if (
      templateType === BulkUploadTemplateType.INDIVIDUAL &&
      isPackagesBulkUploadEnabled &&
      isSellerLPNsEnabledForExcel
    ) {
      downloadTemplateType = BulkUploadTemplateType.INDIVIDUAL_ENHANCED_WITH_SELLER_LPNS;
    } else if (templateType === BulkUploadTemplateType.INDIVIDUAL && isPackagesBulkUploadEnabled) {
      downloadTemplateType = BulkUploadTemplateType.INDIVIDUAL_ENHANCED;
    } else {
      downloadTemplateType = templateType;
    }
    const downloadTemplate = bulkUploadTemplates[downloadTemplateType];
    if (hasRequestError) {
      return formatMessage(
        BULK_UPLOAD_ERROR_MESSAGES.UNABLE_TO_IMPORT_PRODUCTS_TRY_AGAIN,
        getLinkChunk(downloadTemplate, formatMessage(BULK_UPLOAD_ERROR_MESSAGES.UNABLE_TO_IMPORT_PRODUCTS_LINK))
      );
    }

    if (validationErrors != null) {
      return formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.MAKE_FOLLOWING_CHANGES);
    }

    return formatMessage(
      BULK_UPLOAD_CONTENT_MESSAGES.DOWNLOAD_TEMPLATE,
      getLinkChunk(downloadTemplate, formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.DOWNLOAD_TEMPLATE_LINK))
    );
  };

  return {
    getTitleContent,
    getSubtitleContent,
  };
};
