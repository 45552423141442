import { Box, Text, defaultTheme } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  LightDivider,
  ReplenishmentErrorContainer,
  ReplenishmentErrorTitle,
} from "./ReplenishmentOrderCreationErrorStyles";
import { ReplenishmentOrderErrorMessages } from "./ReplenishmentErrorMessages";
import { InfoTooltip } from "dashboard/common";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";

const FlexDiv = styled.div(`
  display: flex;
  padding-bottom: 0.25rem;
`);

interface OrderErrorProps {
  orderErrors: {
    errorMessage: string;
    errorCode?: string;
  }[];
  hideTitle?: boolean;
}

export const ReplenishmentOrderError: FC<OrderErrorProps> = ({ orderErrors, hideTitle }) => {
  return !isEmpty(orderErrors) ? (
    <ReplenishmentErrorContainer>
      {!hideTitle ? (
        <ReplenishmentErrorTitle>
          <FormattedMessage {...ReplenishmentOrderErrorMessages.OrderErrorTitle} />
        </ReplenishmentErrorTitle>
      ) : null}

      <LightDivider />

      {orderErrors.map((orderError, index) => (
        <div data-testid="replenishment-order-error-description" key={`order_error_${index}`}>
          <ReplenishmentErrorDescription errorMessage={orderError.errorMessage} errorCode={orderError.errorCode} />
          <LightDivider />
        </div>
      ))}
    </ReplenishmentErrorContainer>
  ) : null;
};

export const ReplenishmentErrorDescription: FC<{
  errorMessage: string;
  errorCode?: string;
}> = ({ errorMessage, errorCode }) => {
  return (
    <>
      <FlexDiv>
        {!isEmpty(errorCode) && (
          <Box paddingRight={"S1"}>
            <InfoTooltip
              message={
                <FormattedMessage
                  {...ReplenishmentOrderErrorMessages.errorCode}
                  values={{
                    errorCode,
                  }}
                />
              }
              width={500}
              infoIconColor={defaultTheme.colors.RED[300]}
            />
          </Box>
        )}

        <Box paddingLeft={"S1"}>
          <Text>{errorMessage}</Text>
        </Box>
      </FlexDiv>
    </>
  );
};
