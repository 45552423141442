import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { selectTransferSelectedProductsQty } from "transfers/create/store/selectTransferSelectedProductsQty";
import { getShipmentItems } from "./utils/getShipmentItems";
import { SPThunkAction } from "common/ReduxUtils";
import { RootState } from "RootReducer";
import {
  DistributionChannel,
  OrderItemDto,
  OrderResponse,
  ShipmentResponse,
  UpdateOrderRequest,
} from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { isInvalidItemOrder } from "transfers/create/steps/common/replenishment/details/invalid-items/InvalidItemOrderUtils";
import { fetchCorrectComponentQuantity } from "../../../utils/fetchCorrectComponentQuantity";
import { PrepOption } from "@deliverr/business-types";
import { emptyAddress } from "common/AddressUtils";

const UPDATABLE_DISTRIBUTION_CHANNELS = [DistributionChannel.ECOM_DIRECT_TO_FC, DistributionChannel.ECOM_VIA_CROSSDOCK];

export const updateReplenishmentOrder =
  (orderId: string, submitDraft: boolean, createDraftOrderInChannel?: boolean): SPThunkAction<Promise<OrderResponse>> =>
  async (dispatch, getState: () => RootState) => {
    const updateOrderRequest = await buildReplenishmentUpdateOrderRequest(
      dispatch,
      getState,
      submitDraft,
      createDraftOrderInChannel
    );

    await replenishmentClient.updateOrder(orderId, updateOrderRequest);

    const orderResponse: OrderResponse = (
      await replenishmentClient.getOrder(orderId, {
        includeShipments: true,
        includeInvalidOrderItems: true,
        includeKitComponentOrderItems: true,
      })
    ).value;

    return orderResponse;
  };

export const buildReplenishmentUpdateOrderRequest = async (
  dispatch,
  getState: () => RootState,
  submitDraft: boolean,
  createDraftOrderInChannel?: boolean
) => {
  const state = getState();
  const {
    originStorageWarehouse,
    selectedProductsLotNumbers,
    replenishmentOrder,
    prepOption,
    prepOptions,
    kitComponentOrderItems,
  } = selectTransferCreate(state);

  let orderItems: OrderItemDto[] = [];

  if (isInvalidItemOrder(replenishmentOrder!)) {
    orderItems = replenishmentOrder?.orderItems ?? [];
  } else {
    const selectedProductsQty = selectTransferSelectedProductsQty(state);
    orderItems = await dispatch(
      getShipmentItems(selectedProductsQty, originStorageWarehouse, selectedProductsLotNumbers)
    );
  }

  let updateOrderRequest: UpdateOrderRequest = {
    fulfillStrategy: replenishmentOrder?.fulfillStrategy,
    originWarehouseId: originStorageWarehouse?.warehouse?.id,
    orderItems,
    submitDraft,
    prepOptions: prepOption ? [prepOption] : [],
    createDraftOrderInChannel,
  };

  if (prepOptions.includes(PrepOption.KITTING)) {
    updateOrderRequest = {
      ...updateOrderRequest,
      prepOptions,
      kitComponentOrderItems: fetchCorrectComponentQuantity(kitComponentOrderItems ?? []),
    };
  }

  const { distributionChannel } = replenishmentOrder ?? {};

  if (distributionChannel && UPDATABLE_DISTRIBUTION_CHANNELS.includes(distributionChannel)) {
    updateOrderRequest = {
      ...updateOrderRequest,
      distributionChannel,
    };
    if (distributionChannel === DistributionChannel.ECOM_DIRECT_TO_FC) {
      updateOrderRequest = addShipments(updateOrderRequest, replenishmentOrder?.shipments);
    }
  }

  return updateOrderRequest;
};

const addShipments = (
  updateOrderRequest: UpdateOrderRequest,
  shipments: ShipmentResponse[] | undefined
): UpdateOrderRequest => ({
  ...updateOrderRequest,
  shipments: [
    {
      destinationWarehouseId: shipments?.[0].destinationWarehouseId,
      destinationWarehouseAddress: shipments?.[0].destinationWarehouseAddress ?? emptyAddress,
    },
  ],
});
