import { InboundBooking } from "@deliverr/inbound-client";
import { useCallback, useRef } from "react";
import { useSelector, useStore } from "react-redux";
import {
  INTERNATIONAL_RATE_STEPS,
  InboundBookingRequestV2,
  InboundBookingV2,
  ShipmentSteps,
} from "../CreateShipmentTypes";
import { selectBookingRequestByStep, selectCurrentStep } from "../store/selectors";
import { FeatureName, getFeatureSelector } from "common/Split";
import { selectBookingRequestByStepV2 } from "../store/selectors/selectBookingRequestByStepV2";
import { selectCreatedByV1 } from "../store/selectors/selectCreatedByV1";

type StepBookingRequestMap = Partial<Record<ShipmentSteps, InboundBookingRequestV2>>;
type StepBookingResponseMap = Partial<Record<ShipmentSteps, InboundBookingV2>>;

export const useStepBookingRequestCache = () => {
  const { getState } = useStore();
  const currentStep = useSelector(selectCurrentStep);
  const state = getState();

  /** we cache the request and response for each step */
  const stepBookingRequestMap = useRef<StepBookingRequestMap>({});
  const stepBookingResponseMap = useRef<StepBookingResponseMap>({});

  /** determine if the step has pending changes */
  const isIpbBookingV2FeatureOn = getFeatureSelector(FeatureName.SmbIpbBookingV2)(state);
  const isCreatedByV1 = selectCreatedByV1(state);
  const shouldUseBookingV2 = isIpbBookingV2FeatureOn && !isCreatedByV1;
  const currentRequest = shouldUseBookingV2
    ? selectBookingRequestByStepV2(currentStep, false)(state)
    : selectBookingRequestByStep(currentStep, false)(state);
  const lastRequest = stepBookingRequestMap.current[currentStep];
  const hasPendingChanges = JSON.stringify(currentRequest) !== JSON.stringify(lastRequest);

  /** only cache intl steps */
  const getIsCacheable = (step: ShipmentSteps) => INTERNATIONAL_RATE_STEPS.includes(step as any);

  const getResponseFromCache = useCallback(
    (request: InboundBookingRequestV2): InboundBookingV2 | undefined => {
      const cachedReq = stepBookingRequestMap.current[currentStep];
      if (JSON.stringify(cachedReq) === JSON.stringify(request)) {
        return stepBookingResponseMap.current[currentStep];
      }
      return undefined;
    },
    [currentStep]
  );

  const cacheRequest = useCallback(
    (step: ShipmentSteps, request: InboundBookingRequestV2, response: InboundBookingV2) => {
      if (getIsCacheable(step)) {
        stepBookingRequestMap.current[step] = request;
        stepBookingResponseMap.current[step] = response;
      }
    },
    []
  );

  /** prepopulate the cache after a draft booking is loaded */
  const populateCacheFromDraft = useCallback(
    (bookingResponse: InboundBooking) => {
      INTERNATIONAL_RATE_STEPS.forEach((step) => {
        const currentState = getState();
        const request = shouldUseBookingV2
          ? selectBookingRequestByStepV2(step, false)(currentState)
          : selectBookingRequestByStep(step, false)(currentState);
        cacheRequest(step, request, bookingResponse);
      });
    },
    [getState, cacheRequest, shouldUseBookingV2]
  );

  return { cacheRequest, getResponseFromCache, populateCacheFromDraft, hasPendingChanges };
};
