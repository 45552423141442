import { EntityError, FBAPrepCategory, FBAPrepType } from "@deliverr/replenishment-client";
import { useMemo, useState } from "react";
import { FbaV3Loaders, SelectablePrepCategories, SelectablePrepTypes } from "../commons/FbaV3Constants";
import { useDispatch } from "react-redux";
import { isEmpty, isNil } from "lodash";
import { useLoader } from "common/components/WithLoader/useLoader";
import { setFbaPrepDetailsAction } from "./actions/SetFbaPrepDetailsAction";
import { useIntl } from "react-intl";
import { FbaPrepDetailsLabels } from "./FbaPrepDetailsLabels";
import { SelectOptionType } from "common/components/ui";

export const useSetFbaPrepDetailsModal = ({ dsku, onCloseModal }: { dsku: string; onCloseModal?: () => void }) => {
  const [selectedPrepTypes, setSelectedPrepTypes] = useState<FBAPrepType[]>([]);
  const [selectedPrepCategory, setSelectedPrepCategory] = useState<FBAPrepCategory | undefined>(undefined);
  const [prepDetailsSubmissionErrors, setPrepDetailsSubmissionErrors] = useState<EntityError[]>([]);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const submitLoaderId = FbaV3Loaders.SET_PREP_DETAILS;
  const isSubmitLoading = useLoader(submitLoaderId);
  const isSubmitDisabled = isSubmitLoading || isNil(selectedPrepCategory) || isEmpty(selectedPrepTypes);

  const onSubmit = () => {
    setPrepDetailsSubmissionErrors([]);
    dispatch(
      setFbaPrepDetailsAction(
        dsku,
        selectedPrepCategory!,
        selectedPrepTypes,
        setPrepDetailsSubmissionErrors,
        onCloseModal
      )
    );
  };

  const prepCategoryOptions = useMemo(
    () =>
      SelectablePrepCategories.map((category) => ({
        value: category,
        label: formatMessage(FbaPrepDetailsLabels.prepCategoryLabels[category]) ?? category,
      })),
    [formatMessage]
  );

  const prepTypeOptions = useMemo(
    () =>
      SelectablePrepTypes.map((prepType) => ({
        value: prepType,
        label: formatMessage(FbaPrepDetailsLabels.prepTypeLabels[prepType]) ?? prepType,
      })),
    [formatMessage]
  );

  const selectedPrepCategoryOption = useMemo(
    () =>
      !isNil(selectedPrepCategory)
        ? {
            value: selectedPrepCategory,
            label: formatMessage(FbaPrepDetailsLabels.prepCategoryLabels[selectedPrepCategory]) ?? selectedPrepCategory,
          }
        : undefined,
    [selectedPrepCategory, formatMessage]
  );

  const selectedPrepTypeOptions = useMemo(
    () =>
      selectedPrepTypes.map((prepType) => ({
        value: prepType,
        label: formatMessage(FbaPrepDetailsLabels.prepTypeLabels[prepType]) ?? prepType,
      })),
    [selectedPrepTypes, formatMessage]
  );

  const onChangePrepTypes = (options?: SelectOptionType[]) => {
    setSelectedPrepTypes(options?.map(({ value }) => value as FBAPrepType) ?? []);
  };

  const onChangePrepCategory = (option: SelectOptionType) => {
    setSelectedPrepCategory(option.value as FBAPrepCategory);
  };

  return {
    isSubmitDisabled,
    isSubmitLoading,
    prepCategoryOptions,
    prepTypeOptions,
    selectedPrepCategoryOption,
    selectedPrepTypeOptions,
    prepDetailsSubmissionErrors,
    onSubmit,
    onChangePrepTypes,
    onChangePrepCategory,
  };
};
