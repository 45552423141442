import React, { FC } from "react";
import { useProductCasePackSelect } from "./useProductCasePackSelect";
import { NewProductCasePackModal } from "../NewProductCasePackModal";
import { isEmpty } from "lodash";
import { DetailedSelect, DetailedSelectOption } from "../DetailedSelect/DetailedSelect";
import { Box, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { LoadingSpinner } from "../LoadingSpinner";
import { ProductCasePackDisplayData, ProductDisplayData } from "./ProductCasePackSelect.types";
import { ProductCasePack, ProductCasePackData } from "@deliverr/inbound-client/lib/legacy-copy-pasta/product";

const NewPackModalLink = styled.a`
  cursor: pointer;
`;

export interface ProductCasePackSelectProps {
  product: ProductDisplayData;
  productCasePacks: ProductCasePackDisplayData[];
  selectedCasePack?: ProductCasePack;
  onSelectCasePack?: (productCasePack: ProductCasePack) => void;
  onNewCasePackFormSubmit?: (productCasePackData: ProductCasePackData) => void;
  includeNewCasePackOption?: boolean;
  showAvailableStorageInventory?: boolean;
  noMargin?: boolean;
  isIntlDtc?: boolean;
  showProductCasePackErrors?: boolean;
  maximumSelectableCaseQty?: number;
}

export const ProductCasePackSelect: FC<ProductCasePackSelectProps> = ({
  product,
  productCasePacks,
  selectedCasePack,
  onSelectCasePack,
  includeNewCasePackOption,
  onNewCasePackFormSubmit,
  showAvailableStorageInventory,
  showProductCasePackErrors,
  noMargin,
  isIntlDtc,
  maximumSelectableCaseQty,
}) => {
  const {
    selectionOptions,
    selectedOption,
    newTemplateOption,
    onCasePackChange,
    hideModal,
    selected_option_key,
    formatMessage,
  } = useProductCasePackSelect({
    productCasePacks,
    selectedCasePack,
    onSelectCasePack,
    product,
    includeNewCasePackOption,
    showAvailableStorageInventory,
    showProductCasePackErrors,
    maximumSelectableCaseQty,
  });
  return (
    <>
      {product.arePacksLoading ? (
        <Box width="16rem" marginLeft="S6">
          <LoadingSpinner size="large" />
        </Box>
      ) : (
        <>
          <NewProductCasePackModal
            product={product}
            onNewCasePackFormSubmit={onNewCasePackFormSubmit}
            onClose={hideModal}
            isIntlDtc={isIntlDtc}
          />
          <Box marginLeft={noMargin ? null : "S4"} marginRight={noMargin ? null : "S4"} width="16rem">
            {!isEmpty(selectionOptions) ? (
              <DetailedSelect
                placeholder="Select case pack template"
                id="product_case_pack_selection"
                options={selectionOptions}
                key={selected_option_key}
                onChange={onCasePackChange}
                value={selectedOption}
                isSearchable={false}
                isOptionDisabled={(option: DetailedSelectOption) => !!option.error}
              />
            ) : includeNewCasePackOption ? (
              <NewPackModalLink onClick={newTemplateOption.onLinkClick}>
                <Text bold>{newTemplateOption.label}</Text>
              </NewPackModalLink>
            ) : (
              <Text bold>
                {formatMessage({
                  id: "productCasePackSelect.noCasePacksAvailable",
                  defaultMessage: "No case configurations available for this product.",
                })}
              </Text>
            )}
          </Box>
        </>
      )}
    </>
  );
};
