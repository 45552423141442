import { Box, DefaultTheme, Text } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { FlexRadioTileGroup } from "../../../../fba/shipping/FlexRadioTileGroup";
import styled from "@emotion/styled";
import { useSelectLabelProvider } from "../hooks/useSelectLabelProvider";
import { CarrierAccountModal } from "./modals/CarrierAccountModal";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { CarrierAccountSelect } from "./CarrierAccountSelect/CarrierAccountSelect";
import { createReplenishmentCarrierAccount } from "../actions/createReplenishmentCarrierAccount";
import { updateReplenishmentCarrierAccount } from "../actions/updateReplenishmentCarrierAccount";
import { deleteReplenishmentCarrierAccount } from "../actions/deleteReplenishmentCarrierAccount";

const StyledDiv = styled.div<{ theme: DefaultTheme }>(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.S5};
  label {
    width: 100%;
    flex: 1;
  }
  label > div {
    width: 100%;
  }
`
);

interface SelectLabelProviderProps {
  isInternationalWholesaleOrder?: boolean;
}

export const SelectLabelProvider: FC<SelectLabelProviderProps> = ({ isInternationalWholesaleOrder }) => {
  const { formatMessage } = useIntl();
  const {
    isCarrierAccountModalVisible,
    carrierAccounts,
    targetCarrierAccount,
    selectedCarrierAccount,
    labelProvider,
    modalMode,
    onOpenModal,
    onCloseModal,
    onChangeLabelProvider,
  } = useSelectLabelProvider();

  const carrierAccountContent = isInternationalWholesaleOrder ? (
    <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.carrierAccountDisabledDescription} />
  ) : (
    <CarrierAccountSelect
      carrierAccounts={carrierAccounts}
      selectedCarrierAccount={selectedCarrierAccount}
      onOpenModal={onOpenModal}
    />
  );

  return (
    <Box width="95%" paddingBottom="S6">
      <Box paddingBottom="S5">
        <Text bold>
          <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.title} />
        </Text>
      </Box>
      <StyledDiv>
        <FlexRadioTileGroup
          block
          direction="HORIZONTAL"
          options={[
            {
              value: WholesaleShippingMethod.SELLER,
              label: formatMessage(wholesaleCreateLabels.steps.shipping.labelProvider.labelUpload.title),
              content: formatMessage(wholesaleCreateLabels.steps.shipping.labelProvider.labelUpload.description),
            },
            {
              value: WholesaleShippingMethod.COLLECT,
              label: formatMessage(wholesaleCreateLabels.steps.shipping.labelProvider.carrierAccount),
              content: carrierAccountContent,
              disabled: isInternationalWholesaleOrder,
            },
          ]}
          currentValue={labelProvider}
          onChange={(evt) => {
            onChangeLabelProvider(evt.currentTarget.value as WholesaleShippingMethod);
          }}
        />
      </StyledDiv>
      {isCarrierAccountModalVisible && (
        <CarrierAccountModal
          onClose={onCloseModal}
          onCreateCarrierAccount={createReplenishmentCarrierAccount}
          onUpdateCarrierAccount={updateReplenishmentCarrierAccount}
          onDeleteCarrierAccount={deleteReplenishmentCarrierAccount}
          modalMode={modalMode}
          carrierAccount={targetCarrierAccount}
        />
      )}
    </Box>
  );
};
