import { createActionCreator } from "common/ReduxUtils";
import { EntityError, OperationStatus } from "@deliverr/replenishment-client";
import { OrderConfirmationActionTypes } from "../store/OrderConfirmationActionTypes";

export const setOrderConfirmationSuccess = createActionCreator<OperationStatus, number | undefined>(
  OrderConfirmationActionTypes.ORDER_CONFIRMATION_SUCCESS,
  "operationStatus",
  "operationId"
);

export const setOrderConfirmationError = createActionCreator<EntityError[], OperationStatus, number | undefined>(
  OrderConfirmationActionTypes.ORDER_CONFIRMATION_ERROR,
  "errors",
  "operationStatus",
  "operationId"
);

export const resetOrderConfirmationState = createActionCreator(
  OrderConfirmationActionTypes.ORDER_CONFIRMATION_RESET_STATE
);
