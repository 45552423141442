import { PackagesListState, ReturnsPackageActionType } from "./PackagesListStore.types";
import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";

const ITEMS_PER_PAGE = 25;

export const packagesListInitialState: PackagesListState = {
  currentPageItems: [],
  pagingInfo: {
    currentPage: 0,
    itemsPerPage: ITEMS_PER_PAGE,
    totalPages: 0,
  },
  error: undefined,
};

const reducers: ReducerDictionary<PackagesListState> = {
  ...handleSimpleReducerUpdates([
    ReturnsPackageActionType.FETCH_RETURN_PACKAGE_SUCCESS,
    ReturnsPackageActionType.FETCH_RETURN_PACKAGE_FAIL,
    ReturnsPackageActionType.UPDATE_RETURN_PACKAGE,
  ]),
};

export const packagesListReducer = createReducer<PackagesListState>(packagesListInitialState, reducers);
