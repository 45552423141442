import { noop } from "lodash";
import { createContext } from "react";
import { SellerProblemOverview, ShippingPlan } from "@deliverr/inbound-client";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { UseStepMetadataSpec } from "inbounds/hooks/useStepMetadata";

export interface MissingBarcodeCaseContextData {
  missingBarcodeCase?: SellerProblemOverview;
  isCaseLoading: boolean;
  shippingPlan?: ShippingPlan;
  selectedProductBarcodes: string[];
  isSelectedProductBarcodesLoading: boolean;
  isRecognizedProduct: boolean;
  setIsRecognizedProduct: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  foundProduct?: ProductListItem;
  onProductSelect: (inventoryListItem?: ProductListItem) => void;
  selectedBarcode?: string;
  onBarcodeSelect: (barcode: { value: string }) => void;
  onDismiss: () => void;
  isOnDismissLoading: boolean;
  onSubmitProduct: () => void;
  onResolve: () => void;
  isOnResolveLoading: boolean;
  stepMetadata: UseStepMetadataSpec;
}

export const INITIAL_MISSING_BARCODE_CASE_CONTEXT: MissingBarcodeCaseContextData = {
  isCaseLoading: true,
  isSelectedProductBarcodesLoading: true,
  onProductSelect: noop,
  onBarcodeSelect: noop,
  onDismiss: noop,
  isOnDismissLoading: false,
  onSubmitProduct: noop,
  onResolve: noop,
  isOnResolveLoading: false,
  isRecognizedProduct: true,
  setIsRecognizedProduct: noop,
  selectedProductBarcodes: [],
  stepMetadata: {
    stepAccordions: [],
    setStepIsValid: noop,
    openSteps: {},
    toggleOpen: noop,
  },
};

export const MissingBarcodeCaseContext = createContext<MissingBarcodeCaseContextData>(
  INITIAL_MISSING_BARCODE_CASE_CONTEXT
);
