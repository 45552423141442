import React from "react";
import styled from "@emotion/styled";
import { ThemeProps, Title } from "common/components/ui";
import { DistributionChannel, NonComplianceResponse, OrderResponse } from "@deliverr/replenishment-client";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { ReplenishmentOrderStatusPill } from "./ReplenishmentOrderStatusPill";
import { ShipmentDetailActionsMenu } from "./ShipmentDetailActionsMenu";
import { transferCancelErrorLabels, transferHeaderLabels } from "transfers/detail/transferHeaderLabels";
import { CancelTransferModal } from "transfers/detail/CancelTransferModal";
import { useReplenishmentOrderDetailsHeader } from "./useReplenishmentOrderDetailsHeader";
import { ReplenishmentNonComplianceStatusPill } from "../non-compliance/ReplenishmentNonComplianceStatusPill";
import { DuplicateOrderButton } from "transfers/detail/DuplicateOrderButton";
import { FeatureName, useFeatureOn } from "../../../../../../common/Split";

const HeaderContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    justify-content: space-between;
    padding: ${theme.spacing.S6} ${theme.spacing.S7};
    display: flex;
    width:100%;
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  `
);

const HeaderActionsWrapper = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;
    gap: ${theme.spacing.S4};
  `
);

const HeaderTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
    margin-top: 0;
    margin-bottom: ${theme.spacing.S4};
  `
);

interface ReplenishmentOrderDetailsHeaderProps {
  replenishmentOrder: OrderResponse;
  nonCompliances?: NonComplianceResponse[];
}

const ecommDestination = {
  id: "replenishment.shipment.header.ecomm.title",
  defaultMessage: "Transfer shipment",
};

const fbaDestination = {
  id: "replenishment.shipment.header.fba.title",
  defaultMessage: "Transfer to Amazon",
};

const walmartDestination = {
  id: "replenishment.shipment.header.walmart.title",
  defaultMessage: "Transfer to Walmart",
};

const wholesaleDestination = {
  id: "replenishment.shipment.header.wholesale.title",
  defaultMessage: "Wholesale Order",
};

export const replenishmentOrderDestinationMap: Record<DistributionChannel, MessageDescriptor> = {
  [DistributionChannel.FBA]: fbaDestination,
  [DistributionChannel.FBA_V3]: fbaDestination,
  [DistributionChannel.WFS]: walmartDestination,
  [DistributionChannel.ECOM_VIA_CROSSDOCK]: ecommDestination,
  [DistributionChannel.ECOM_VIA_HUB]: ecommDestination,
  [DistributionChannel.ECOM_DIRECT_TO_FC]: ecommDestination,
  [DistributionChannel.FBA_MANUAL]: fbaDestination,
  [DistributionChannel.WHOLESALE]: wholesaleDestination,
};
const ShipmentDetailsActionMenu = ShipmentDetailActionsMenu(transferHeaderLabels);

export const ReplenishmentOrderDetailsHeader: React.FC<ReplenishmentOrderDetailsHeaderProps> = ({
  replenishmentOrder,
  nonCompliances,
}) => {
  const {
    isCancellable,
    shouldShowCancelModal,
    onCancelModalConfirm,
    onCancelSelect,
    onModalClose,
    isFBAIntegration,
    shipmentActionsMenuLabels,
    isWholesaleOrder,
    showDuplicateButton,
    nonComplianceStatusOverride,
    isPartiallyFulfillable,
    onPartialFulfilClick,
    onDuplicate,
  } = useReplenishmentOrderDetailsHeader({ replenishmentOrder, nonCompliances });
  const shouldShowASNAndPODownload = useFeatureOn(FeatureName.SpShowASNAndPODownload);

  return (
    <HeaderContainer>
      <div>
        <HeaderTitle>
          <FormattedMessage {...replenishmentOrderDestinationMap[replenishmentOrder.distributionChannel]} />
        </HeaderTitle>
        {nonComplianceStatusOverride ? (
          <ReplenishmentNonComplianceStatusPill status={nonComplianceStatusOverride} />
        ) : (
          <ReplenishmentOrderStatusPill status={replenishmentOrder.orderStatus} />
        )}
      </div>
      <HeaderActionsWrapper>
        {showDuplicateButton && <DuplicateOrderButton onDuplicate={onDuplicate} />}
        <ShipmentDetailsActionMenu
          isCancellable={isCancellable}
          onCancel={onCancelSelect}
          isWholesaleOrder={isWholesaleOrder}
          onPartialFill={onPartialFulfilClick}
          isPartiallyFulfillable={isPartiallyFulfillable}
          shouldShowASNAndPODownload={shouldShowASNAndPODownload}
        />
      </HeaderActionsWrapper>
      {replenishmentOrder?.orderId && (
        <CancelTransferModal
          showModal={shouldShowCancelModal}
          onClose={onModalClose}
          handleCancel={onCancelModalConfirm}
          shipmentActionsMenuLabels={shipmentActionsMenuLabels}
          errorModalLabels={transferCancelErrorLabels}
          transferId={replenishmentOrder?.orderId}
          isIntegratedFba={isFBAIntegration}
        />
      )}
    </HeaderContainer>
  );
};
