import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { ThemeProps, Text, BoxReset, Box } from "common/components/ui";
import { ViewShipmentCard } from "components/Shipment/ViewShipmentCard";
import { CardTable, createCellObject } from "inbounds/steps/ship/view/cards/CardTable";
import { useStorageShipmentContents } from "./useStorageShipmentContents";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { ProductDetailData } from "inbounds/steps/ship/view/cards/ProductDetailData";
import { mapStorageItemToDraftShippingPlanItem } from "storage/inbounds/utils/mapStorageItemToDraftShippingPlanItem";
import { useDemoMode } from "common/hooks/useDemoMode";
import { Dash } from "common/components/Dash";

const StyledHeader = styled(Text)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[500]};
  `
);

export const StorageShipmentContents: React.FC = () => {
  const { storageInboundOrder, storageItemDetails, title, shouldShowLotInformation, storageInboundOrderProblems } =
    useStorageShipmentContents();
  const { storageItems, status } = storageInboundOrder;
  const { isDemoMode, maskIfDemoMode } = useDemoMode();
  const headers = [
    createCellObject(
      <Box width="27rem">
        <StyledHeader bold size="caption">
          <FormattedMessage id={"storage.inbounds.details.contents.product"} defaultMessage={"Product"} />
        </StyledHeader>
      </Box>,
      "left",
      `storage-inbounds-contents-product`
    ),
    createCellObject(
      <StyledHeader bold size="caption">
        <FormattedMessage id={"storage.inbounds.details.contents.expected"} defaultMessage={"Expected units"} />
      </StyledHeader>,
      "right",
      `storage-inbounds-contents-expected`
    ),
    createCellObject(
      <StyledHeader bold size="caption">
        <FormattedMessage id={"storage.inbounds.details.contents.available"} defaultMessage={"Available units"} />
      </StyledHeader>,
      "right",
      `storage-inbounds-contents-received`
    ),
    createCellObject(
      <StyledHeader bold size="caption">
        <FormattedMessage id={"storage.inbounds.details.contents.damaged"} defaultMessage={"Damaged units"} />
      </StyledHeader>,
      "right",
      `storage-inbounds-contents-damaged`
    ),
    createCellObject(
      <StyledHeader bold size="caption">
        <FormattedMessage id={"storage.inbounds.details.contents.discrepancy"} defaultMessage={"Discrepant units"} />
      </StyledHeader>,
      "right",
      `storage-inbounds-contents-discrepant`
    ),
  ];

  const tableRows = React.useMemo(() => {
    const problemByDsku = storageInboundOrderProblems
      .filter((problem) => !!problem.dsku)
      .reduce((acc, currVal) => {
        acc[currVal.dsku!] = currVal;
        return acc;
      }, {});

    return storageItems.map((storageItem) => {
      const { dsku, expectedQty, receivedQty, damagedQty } = storageItem;
      const product = storageItemDetails[dsku];
      const problem = problemByDsku[dsku];
      const discrepancyQty = (receivedQty ?? 0) - (expectedQty ?? 0) + (damagedQty ?? 0);
      const isCompleted = status === InboundShipmentStatus.COMPLETED;

      const productSKUCell = !isDemoMode ? (
        <ProductDetailData
          product={product}
          problem={problem}
          planItem={mapStorageItemToDraftShippingPlanItem(storageItem)}
          shouldShowLotInformation={shouldShowLotInformation}
          shouldShowPackInformation
        />
      ) : (
        maskIfDemoMode(product?.name)
      );

      return [
        createCellObject(productSKUCell, "left", `storage-inbounds-contents-product-${dsku}`),
        createCellObject(expectedQty ?? <Dash />, "right", `storage-inbounds-contents-expected-${dsku}`),
        createCellObject(receivedQty ?? <Dash />, "right", `storage-inbounds-contents-received-${dsku}`),
        createCellObject(
          isCompleted ? damagedQty ?? 0 : <Dash />,
          "right",
          `storage-inbounds-contents-damaged-${dsku}`
        ),
        createCellObject(
          isCompleted ? discrepancyQty : <Dash />,
          "right",
          `storage-inbounds-contents-discrepant-${dsku}`
        ),
      ];
    });
  }, [storageItems, status, storageItemDetails, shouldShowLotInformation, storageInboundOrderProblems, isDemoMode]);

  return (
    <ViewShipmentCard title={title}>
      <BoxReset marginTop={"S4"}>
        <CardTable headerCells={headers} rows={tableRows} />
      </BoxReset>
    </ViewShipmentCard>
  );
};
