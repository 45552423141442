import { isEmpty } from "lodash";
/**
 * Merges multiple PDF files into a single PDF file.
 * @param files - Array of File objects representing PDFs.
 * @returns A Promise that resolves to a merged File object.
 */
export async function mergePdfFilesIfRequired(files: File[]): Promise<File> {
  if (isEmpty(files)) {
    throw new Error("No files provided for merging.");
  }

  // If there is only one file, return it as is
  if (files.length === 1) {
    return files[0];
  }

  // Sort files alphabetically by name
  files.sort((a, b) => a.name.localeCompare(b.name));

  const mergedPdfBlob = await mergeFilesToPdf(files);

  const fileNames = files.map((file) => {
    const lastDotIndex = file.name.lastIndexOf(".");
    return lastDotIndex !== -1 ? file.name.substring(0, lastDotIndex) : file.name;
  });

  const mergedFileName = `${fileNames.join("_")}_merged.pdf`;

  return new File([mergedPdfBlob], mergedFileName, { type: "application/pdf" });
}

/**
 * Private function to merge PDF files into a single Blob.
 * @param files - Array of File objects representing PDFs.
 * @returns A Promise that resolves to a Blob containing the merged PDF.
 */
async function mergeFilesToPdf(files: File[]): Promise<Blob> {
  const { default: PDFMerger } = await import("pdf-merger-js"); // Lazy load PDFMerger
  const merger = new PDFMerger();

  for (const file of files) {
    const arrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    await merger.add(uint8Array);
  }

  const mergedPdfBuffer = await merger.saveAsBuffer();
  return new Blob([mergedPdfBuffer], { type: "application/pdf" });
}
