import { Space } from "common/components/ui/shared";
import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { CSSProperties } from "react";

export interface FlexDivProps {
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  gap?: keyof typeof Space;
}

export const FlexDiv = styled.div<FlexDivProps, DefaultTheme>(
  ({ theme, justifyContent = "space-between", gap, alignItems = "center" }) => `
  display: flex;
  align-items: ${alignItems};
  justify-content: ${justifyContent};
  ${gap ? `gap: ${theme.spacing[gap]};` : ""}
`
);
