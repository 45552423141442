import React, { FC } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { ShippingPlan } from "@deliverr/inbound-client";
import { Text, ThemeProps } from "common/components/ui";

import RouteParser from "common/RouteParser";
import { Path } from "paths";

interface ShippingPlanSummaryProps {
  shippingPlan: ShippingPlan;
}

const SpacedText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S1};
  `
);

export const ShippingPlanSummary: FC<ShippingPlanSummaryProps> = ({ shippingPlan: { name, id } }) => (
  <>
    <SpacedText bold>
      <FormattedMessage id="inbounds.shippingPlan" defaultMessage="Shipping plan" />
    </SpacedText>
    <Link to={new RouteParser(Path.inboundsShippingPlanRedirect).parse({ shippingPlanId: id })}>
      <SpacedText bold>{name}</SpacedText>
    </Link>
  </>
);
