import styled from "@emotion/styled";
import { Box, Button, Stack, Text } from "common/components/ui";
import React, { FC } from "react";

import {
  InvalidOrderItemDto,
  InvalidOrderItemReason,
  InvalidOrderItemResolution,
} from "@deliverr/replenishment-client";
import { ProblemBaseBlade, ProblemBladeProps } from "common/components/ProblemBlade/ProblemBaseBlade";
import { NonComplianceRadioTileGroup } from "inbounds/non-compliance/components";
import { isEmpty } from "lodash";
import { invalidOrderItemLabel } from "./invalidOrderItemLabel";
import { UnknownItemResolution } from "./resolution-flows/UnknownItemResolution";
import { InvalidCaseConfigResolution } from "./resolution-flows/InvalidCaseConfigResolution";
import { useInvalidOrderItemBlade } from "./useInvalidOrderItemBlade";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { ReplenishmentInvalidItemDetails } from "transfers/detail/ReplenishmentSpsItemDetails";
import { ResolvedProductDetails } from "./ResolvedProductDetails";
import { useUserScopeMapping } from "common/user/useUserScopes";
import { TOOLTIP_MESSAGES } from "common/utils/tooltipMessages/tooltipMessages";

export interface InvalidOrderItemBladeProps extends ProblemBladeProps {
  invalidItem: InvalidOrderItemDto;
}

const StyledStack = styled(Stack)`
  width: 100%;
`;

export const InvalidOrderItemBlade: FC<InvalidOrderItemBladeProps> = ({
  id,
  onCloseClick,
  isBladeOpen,
  invalidItem,
}) => {
  const {
    resolution,
    selectedProduct,
    formatMessage,
    onChange,
    onContinueClick,
    setSelectedProduct,
    setResolution,
    isContinueLoading,
    isResolutionDataLoading,
  } = useInvalidOrderItemBlade({ id, onCloseClick, isBladeOpen, invalidItem });

  const userScopeMapping = useUserScopeMapping();
  const hasReplenishmentWriteAccess = userScopeMapping?.REPLENISHMENT.write;

  return (
    <ProblemBaseBlade
      id={id}
      title={formatMessage(invalidOrderItemLabel.reasons[invalidItem.reason])}
      onCloseClick={onCloseClick}
      isBladeOpen={isBladeOpen}
      width={"40%"}
    >
      <ReplenishmentInvalidItemDetails invalidItem={invalidItem} />

      {!isEmpty(invalidItem.requestedPackOf) && (
        <ResolvedProductDetails dsku={invalidItem.requestedPackOf} shouldShowPackInformation={false} />
      )}

      {isResolutionDataLoading ? (
        <LoadingSpinner />
      ) : (
        <StyledStack>
          <Box paddingTop={"S2"} paddingBottom={"S3"}>
            <Text size="bodyLarge" bold>
              {formatMessage(invalidOrderItemLabel.blade.removeProduct)}
            </Text>
          </Box>

          <NonComplianceRadioTileGroup
            currentValue={(resolution === InvalidOrderItemResolution.REMOVED).toString()}
            direction="HORIZONTAL"
            onChange={onChange}
            options={[
              {
                label: formatMessage(invalidOrderItemLabel.blade.yes),
                value: "true",
              },
              {
                label: formatMessage(invalidOrderItemLabel.blade.no),
                value: "false",
              },
            ]}
          />
          {resolution !== InvalidOrderItemResolution.REMOVED ? (
            <>
              <br />
              {invalidItem.reason === InvalidOrderItemReason.INVALID_ITEM && (
                <UnknownItemResolution
                  invalidItem={invalidItem}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  setResolution={setResolution}
                  formatMessage={formatMessage}
                />
              )}

              {invalidItem.reason === InvalidOrderItemReason.INVALID_CASE_CONFIG && (
                <InvalidCaseConfigResolution
                  invalidItem={invalidItem}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  setResolution={setResolution}
                />
              )}
            </>
          ) : null}
          <Box paddingTop={"S2"}>
            <Button
              data-testid="continue_button"
              secondary
              disabled={
                (resolution === InvalidOrderItemResolution.REMOVED ? false : isEmpty(selectedProduct)) ||
                !hasReplenishmentWriteAccess
              }
              onClick={onContinueClick}
              loading={isContinueLoading}
              tooltipMessage={!hasReplenishmentWriteAccess ? TOOLTIP_MESSAGES.insufficientAccess : undefined}
            >
              {formatMessage(invalidOrderItemLabel.blade.continue)}
            </Button>
          </Box>
        </StyledStack>
      )}
    </ProblemBaseBlade>
  );
};
