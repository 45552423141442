import React from "react";
import { ReplenishmentProductError } from "./ReplenishmentProductError";
import {
  LightDivider,
  ReplenishmentErrorContainer,
  ReplenishmentErrorTitle,
} from "./ReplenishmentOrderCreationErrorStyles";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { ReplenishmentOrderErrorMessages } from "./ReplenishmentErrorMessages";
import { ReplenishmentProductWithErrors } from "./ReplenishmentProductErrorTypes";
import { isEmpty } from "lodash";
import { Box } from "common/components/ui";

interface ProductErrorListProps {
  productsWithErrors: ReplenishmentProductWithErrors[];
  onRemoveProduct: (dsku: string) => void;
}

export const ReplenishmentProductErrorList: React.FC<ProductErrorListProps> = ({
  productsWithErrors,
  onRemoveProduct,
}) => {
  return !isEmpty(productsWithErrors) ? (
    <ReplenishmentErrorContainer>
      <ReplenishmentErrorTitle>
        <FormattedMessage {...ReplenishmentOrderErrorMessages.OrderItemErrorTitle} />
      </ReplenishmentErrorTitle>

      <LightDivider />

      <Box>
        {productsWithErrors.map((productWithErrors) => (
          <ReplenishmentProductError
            key={productWithErrors.product.dsku}
            productWithErrors={productWithErrors}
            removeProduct={onRemoveProduct}
          />
        ))}
      </Box>
    </ReplenishmentErrorContainer>
  ) : null;
};
