import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Address from "common/components/Address";
import { useShipmentAddressDisplay } from "./useShipmentAddressDisplay";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui/shared";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { FbaMultipleDestination } from "../../../../transfers/fbaIntegration/ui/FbaMultipleDestination";
import { useDemoMode } from "common/hooks/useDemoMode";

const StyledProvider = styled.p<ThemeProps>(
  ({ theme }) => `
  margin-bottom: 0;
  color: ${theme.colors.NEUTRAL[500]};
  `
);

const StyledShipmentAddressDisplay = styled.div<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S5} ${theme.spacing.S7};
    width: 100%;
    display: flex;
  `
);

const StyledAddress = styled.div<ThemeProps>(
  ({ theme }) => `
    flex-grow: 1;
    white-space: pre-line;
    height: fit-content;

    .address {
      color: ${theme.colors.NEUTRAL[500]};
    }
  `
);

const StyledAddressTitle = styled.p<ThemeProps>(
  ({ theme }) => `
    font-weight: ${theme.font.weight.BOLD};
    margin: 0;
  `
);

export const ShipmentAddressDisplay: FC = () => {
  const { fromAddress, toAddress, toProvider, isCrossdock } = useShipmentAddressDisplay();

  return (
    <UnconnectedShipmentAddressDisplay
      fromAddress={fromAddress}
      toAddress={toAddress}
      toProvider={toProvider}
      isCrossdock={isCrossdock}
    />
  );
};

interface ShipmentAddressDisplayProps {
  fromAddress?: DeliverrAddress;
  toAddress?: DeliverrAddress;
  toProvider?: string;
  hideDestinationName?: boolean;
  isFbaMultipleShipments?: boolean;
  isCrossdock?: boolean;
  onViewMoreClick?: (e) => void;
}

export const UnconnectedShipmentAddressDisplay: React.FC<ShipmentAddressDisplayProps> = ({
  fromAddress,
  toProvider,
  toAddress,
  hideDestinationName,
  isFbaMultipleShipments,
  isCrossdock,
  onViewMoreClick,
}) => {
  const { isDemoMode, maskIfDemoMode } = useDemoMode();
  return (
    <StyledShipmentAddressDisplay>
      <StyledAddress>
        <StyledAddressTitle>
          <FormattedMessage id={"shipFrom"} defaultMessage={"Ship from"} />
        </StyledAddressTitle>
        {fromAddress && <Address address={fromAddress} />}
      </StyledAddress>
      <StyledAddress>
        <StyledAddressTitle>
          <FormattedMessage id={"shipTo"} defaultMessage={"Ship to"} />
        </StyledAddressTitle>
        {!isDemoMode ? (
          <>
            {toProvider && !isCrossdock && (
              <StyledProvider>
                <FormattedMessage
                  id={"inbounds.viewShipment.provider"}
                  defaultMessage={"{provider} c/o Flexport"}
                  values={{ provider: toProvider }}
                />
              </StyledProvider>
            )}
            {isCrossdock && (
              <>
                <StyledProvider>
                  <FormattedMessage
                    id={"inbounds.viewShipment.attention"}
                    defaultMessage={"Attn: Crossdock"}
                    values={{ provider: toProvider }}
                  />
                </StyledProvider>
                <StyledProvider>
                  <FormattedMessage id={"inbounds.viewShipment.flexport"} defaultMessage={"Flexport"} />
                </StyledProvider>
              </>
            )}
            {!isFbaMultipleShipments && toAddress && (
              <Address address={toAddress} hideName={hideDestinationName ?? true} />
            )}
            {isFbaMultipleShipments && <FbaMultipleDestination onViewMoreClick={onViewMoreClick} />}
          </>
        ) : toAddress?.country ? (
          maskIfDemoMode(toAddress.country)
        ) : (
          maskIfDemoMode("Flexport")
        )}
      </StyledAddress>
    </StyledShipmentAddressDisplay>
  );
};
