import { SPThunkAction } from "common/ReduxUtils";
import { InboundBooking, InboundBookingRequest } from "@deliverr/inbound-client";
import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { selectBookingRequestByStep, selectCurrentStep } from "../selectors";

export const updateBooking =
  (shippingPlanId: number, shouldSubmit: boolean): SPThunkAction<Promise<InboundBooking>> =>
  async (dispatch, getState) => {
    const state = getState();
    const currentStep = selectCurrentStep(state);
    const bookingRequest: InboundBookingRequest = selectBookingRequestByStep(currentStep, shouldSubmit)(state);
    const ctx = logStart({ fn: SmbBookingCreationTimedEvent.UpdateBooking, shippingPlanId, bookingRequest });

    try {
      const response = await inboundClient.updateBooking(shippingPlanId, bookingRequest);
      logSuccess(ctx, "successfully updated booking");
      return response;
    } catch (error) {
      logError(ctx, error);
      throw error;
    }
  };
