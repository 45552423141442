import { Title, Stack, Input, Button } from "common/components/ui";
import styled from "@emotion/styled";
import { ElevioBorderlessButton, ElevioArticleId } from "common/elevio";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import WithLoader from "../WithLoader";
import { CreateProductSuccessHandler, NewProductFormValues, useNewProductForm } from "./useNewProductForm";
import { useUserScopeMapping } from "common/user/useUserScopes";
import { TOOLTIP_MESSAGES } from "common/utils/tooltipMessages/tooltipMessages";

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

const StyledForm = styled.form`
  max-width: 469px;
`;

export interface NewProductFormProps {
  defaultValues?: NewProductFormValues;
  onSuccess?: CreateProductSuccessHandler;
}

export const NewProductForm: FC<NewProductFormProps> = ({ defaultValues, onSuccess }) => {
  const { name, sku, onSubmit, isSubmitDisabled } = useNewProductForm(defaultValues, onSuccess);
  const userScopesMapping = useUserScopeMapping();
  const hasInventoryWriteAccess = userScopesMapping.INVENTORY?.write;

  return (
    <Stack>
      <StyledTitle as="h2" displayAs="h4">
        <FormattedMessage id="inventory.newProduct.title" defaultMessage="New Product" />
      </StyledTitle>
      <div>
        <FormattedMessage
          id="inventory.newProduct.description"
          defaultMessage="Manually create a product if it did not import from an integration or if you have not yet connected an integration. {learnMore}."
          values={{
            learnMore: (
              <ElevioBorderlessButton articleId={ElevioArticleId.NewProduct}>
                <FormattedMessage id="learnMore" defaultMessage="Learn more" />
              </ElevioBorderlessButton>
            ),
          }}
        />
      </div>
      <StyledForm onSubmit={onSubmit} autoComplete="off">
        <Stack>
          <Input
            {...sku.field}
            label={<FormattedMessage id="inventory.newProduct.labels.sku" defaultMessage="SKU" />}
            errorMessage={
              <FormattedMessage
                id="inventory.newProduct.errors.sku"
                defaultMessage="The SKU value cannot be empty and can only contain letters, numbers, dashes and underscores."
              />
            }
            hasError={sku.fieldState.invalid}
          />
          <Input
            {...name.field}
            label={<FormattedMessage id="inventory.newProduct.labels.name" defaultMessage="Name" />}
            errorMessage={<FormattedMessage id="inventory.newProduct.errors.name" defaultMessage="Name is required" />}
            hasError={name.fieldState.invalid}
          />
          <div>
            <Button
              type="submit"
              disabled={isSubmitDisabled || !hasInventoryWriteAccess}
              data-testid="new-product-submit"
              tooltipMessage={!hasInventoryWriteAccess ? TOOLTIP_MESSAGES.insufficientAccess : undefined}
            >
              <WithLoader name="newProduct" size="lg">
                <FormattedMessage id="inventory.newProduct.button" defaultMessage="Create Product" />
              </WithLoader>
            </Button>
          </div>
        </Stack>
      </StyledForm>
    </Stack>
  );
};
