import { RootState } from "RootReducer";

export const getPackingOptionsData = ({ fbaV3CreateState: { packingOptionsState } }: RootState) => packingOptionsState;

export const getPlacementOptionsData = ({ fbaV3CreateState: { placementOptionsState } }: RootState) =>
  placementOptionsState;

export const getTransportationOptionsData = ({ fbaV3CreateState: { transportationOptionsState } }: RootState) =>
  transportationOptionsState;

export const getOrderConfirmationData = ({ fbaV3CreateState: { orderConfirmationState } }: RootState) =>
  orderConfirmationState;
