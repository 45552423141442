import { sumBy, uniqBy } from "lodash/fp";
import React, { FC } from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { connect } from "react-redux";
import { format } from "date-fns-tz";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";
import { getShowAdminFeatures } from "common/user/UserSelectors";
import { ShippingMethod } from "inbounds/ShippingMethod";
import { getExternalShippingPlanId, getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getLoadedShipment } from "inbounds/store/selectors/shipments/getLoadedShipment";
import { RootState } from "RootReducer";
import { fromLooseDate } from "common/date/LooseDate";
import { FullSlashDate, TimeWithTz } from "common/date/DateFormat";
import { selectPrepRequest } from "prep/store";
import { AdminValue } from "components/AdminValue";
import { selectHasPrep } from "prep/store/selectors/selectHasPrep";
import { selectLoadedShipmentPoNumber } from "inbounds/store/selectors/shipments/selectLoadedShipmentPoNumber";
import { getPalletTotalDims } from "inbounds/store/selectors/freight";
import { LTL_MAX_PALLET_COUNT } from "freight/constants/cargoPalletConstants";

export interface ViewShipmentSummaryProps {
  numOfSkus: number;
  numOfUnits: number;
  poNumber?: number;
  externalShippingPlanId?: string;
  adminViewPONumbers: number[];
  createdAt: Date;
  loadedShipmentId: number;
  shippingMethod: ShippingMethod;
  shouldShowAdminFeatures: boolean;
  dpId?: string;
  palletsCount?: number;
  isLtlQuoteImprovementFeatureOn?: boolean;
}

const ViewShipmentSummaryContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S7};
  min-width: 335px;
  border-right: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
`
);

const AdminPONumber = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.ORANGE[400]};
  display: inline-block;
  margin-left: ${theme.spacing.S2};
`
);

const SHIPPING_METHOD_DESCRIPTIONS: Record<ShippingMethod, JSX.Element> = {
  [ShippingMethod.SPD_DELIVERR]: (
    <FormattedMessage
      id="inbounds.viewShipmentSummary.description.spdDeliverr"
      defaultMessage={"Inbounded on Flexport's carrier rates"}
    />
  ),
  [ShippingMethod.SPD_EXTERNAL]: (
    <FormattedMessage
      id="inbounds.viewShipmentSummary.description.spdExternal"
      defaultMessage={"Inbounded on your carrier rates"}
    />
  ),
  [ShippingMethod.LTL_EXTERNAL]: (
    <FormattedMessage
      id="inbounds.viewShipmentSummary.description.freightExternalLtl"
      defaultMessage={"Inbounded on your freight rates"}
    />
  ),
  [ShippingMethod.FTL_EXTERNAL]: (
    <FormattedMessage
      id="inbounds.viewShipmentSummary.description.freightExternalFtl"
      defaultMessage={"Inbounded on your freight rates"}
    />
  ),
  [ShippingMethod.LTL_DELIVERR]: (
    <FormattedMessage
      id="inbounds.viewShipmentSummary.description.freightDeliverrLtl"
      defaultMessage={"Shipping with Flexport Freight (LTL)"}
    />
  ),
  [ShippingMethod.FTL_DELIVERR]: (
    <FormattedMessage
      id="inbounds.viewShipmentSummary.description.freightDeliverrFtl"
      defaultMessage={"Shipping with Flexport Freight (FTL)"}
    />
  ),
  [ShippingMethod.METRO_DELIVERR]: (
    <FormattedMessage
      id="inbounds.viewShipmentSummary.description.freightDeliverr"
      defaultMessage={"Inbounded on Flexport's freight rates"}
    />
  ),
};

const getShippingMethodDescription = (shippingMethod: ShippingMethod, pallets?: number) => {
  if (shippingMethod === ShippingMethod.FTL_DELIVERR && pallets && pallets <= LTL_MAX_PALLET_COUNT) {
    return SHIPPING_METHOD_DESCRIPTIONS[ShippingMethod.LTL_DELIVERR];
  }
  return SHIPPING_METHOD_DESCRIPTIONS[shippingMethod];
};

export const UnconnectedViewShipmentSummary: FC<ViewShipmentSummaryProps> = ({
  numOfSkus,
  numOfUnits,
  poNumber,
  externalShippingPlanId,
  adminViewPONumbers,
  createdAt,
  loadedShipmentId,
  shippingMethod,
  shouldShowAdminFeatures,
  dpId,
  palletsCount,
  isLtlQuoteImprovementFeatureOn,
}) => (
  <ViewShipmentSummaryContainer>
    <div>
      <FormattedMessage
        id="shipmentId"
        defaultMessage={"Shipment ID {shipmentId}"}
        values={{ shipmentId: loadedShipmentId }}
      />
    </div>
    {externalShippingPlanId && (
      <div>
        <FormattedMessage
          id="ViewShipmentHeader.externalShippingPlanId"
          defaultMessage={"External Shipping Plan ID {externalShippingPlanId}"}
          values={{ externalShippingPlanId }}
        />
      </div>
    )}
    {poNumber && (
      <div>
        <FormattedMessage
          id="ViewShipmentHeader.poNumber"
          defaultMessage={"Flexport PO# {poNumber}"}
          values={{ poNumber }}
        />
        {shouldShowAdminFeatures &&
          adminViewPONumbers.map((number) => <AdminPONumber key={number}>{number}</AdminPONumber>)}
        {shouldShowAdminFeatures && dpId && <AdminValue>{dpId}</AdminValue>}
      </div>
    )}
    <div>
      {isLtlQuoteImprovementFeatureOn
        ? getShippingMethodDescription(shippingMethod, palletsCount)
        : SHIPPING_METHOD_DESCRIPTIONS[shippingMethod]}
    </div>
    <div>
      <FormattedMessage
        id="ViewShipmentSummary.summary"
        defaultMessage={"Contains {numOfSkus} {skus} and {numOfUnits} {units}"}
        values={{
          numOfSkus,
          skus: <FormattedPlural value={numOfSkus} one="SKU" other="SKUs" />,
          numOfUnits,
          units: <FormattedPlural value={numOfUnits} one="Unit" other="Units" />,
        }}
      />
    </div>
    <div>
      <FormattedMessage
        id={"ViewShipmentHeader.placedAt"}
        defaultMessage={"Placed on {date} at {time}"}
        values={{
          date: format(fromLooseDate(createdAt), FullSlashDate),
          time: format(fromLooseDate(createdAt), TimeWithTz),
        }}
      />
    </div>
  </ViewShipmentSummaryContainer>
);

export const ViewShipmentSummary = connect<ViewShipmentSummaryProps>(
  (state: RootState, isLtlQuoteImprovementFeatureOn: boolean): ViewShipmentSummaryProps => {
    const { shippingMethod } = getLoadedPlannedShipment(state);
    const [palletsCount] = getPalletTotalDims(state);
    const { createdAt, items, id: loadedShipmentId } = getLoadedShipment(state);
    const { poNumber, adminViewPONumbers } = selectLoadedShipmentPoNumber(state);
    const externalShippingPlanId = getExternalShippingPlanId(state);
    const prepRequest = selectPrepRequest(state);
    const hasPrep = selectHasPrep(state);

    return {
      numOfUnits: sumBy("qty", items),
      numOfSkus: uniqBy("dsku", items).length,
      poNumber,
      externalShippingPlanId,
      adminViewPONumbers,
      createdAt,
      loadedShipmentId,
      shippingMethod: shippingMethod!,
      shouldShowAdminFeatures: getShowAdminFeatures(state),
      dpId: hasPrep ? prepRequest?.dpId : undefined,
      palletsCount,
      isLtlQuoteImprovementFeatureOn,
    };
  }
)(UnconnectedViewShipmentSummary);
