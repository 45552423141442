import { InventoryListTab } from "./InventoryListReducer";
import { InventoryLocationFilterOptions } from "./InventoryListTableControls/InventoryListFilters/InventoryLocationFilter";
import { NumericFilter } from "common/list/AlgoliaService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { constructOpenSearchFilters } from "common/search/services/InventorySearchUtils";

interface InventoryListFilterParams {
  searchTerm?: string;
  isActive: boolean;
  inventoryLocation?: InventoryLocationFilterOptions;
  currentTab?: InventoryListTab;
}

interface InventoryListFilters {
  filters: string[];
  numericFilter?: NumericFilter;
  customizedOpenSearchFilters: QueryDslQueryContainer[];
}

export const getInventoryListFilters = (params: InventoryListFilterParams): InventoryListFilters => {
  return getInventoryListOpenSearchFilters(params);
};

function getNumericFilterByInventoryLocation(
  inventoryLocation: InventoryLocationFilterOptions | undefined
): NumericFilter | undefined {
  if (inventoryLocation === InventoryLocationFilterOptions.ECOM_FULFILLMENT) {
    return ["onHandQty", ">=", 1];
  } else if (inventoryLocation === InventoryLocationFilterOptions.AMAZON_FBA) {
    return ["fbaInventory.fulfillableQuantity", ">=", 1];
  } else if (inventoryLocation === InventoryLocationFilterOptions.WALMART_WFS) {
    return ["wfsInventory.fulfillableQuantity", ">=", 1];
  }

  return undefined;
}

function getInventoryListOpenSearchFilters({
  searchTerm,
  isActive,
  inventoryLocation,
  currentTab,
}: InventoryListFilterParams): InventoryListFilters {
  const customizedOpenSearchFilters = constructOpenSearchFilters({
    searchTerm,
    isHiddenOnSellerPortal: !isActive,
    inventoryLocation,
    currentTab,
  });

  const numericFilter = getNumericFilterByInventoryLocation(inventoryLocation);

  return { filters: [], numericFilter, customizedOpenSearchFilters };
}
