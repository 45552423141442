import { InboundBooking } from "@deliverr/inbound-client";
import {
  CreateShipmentFormState,
  createShipmentInitialFormState as defaults,
} from "inbounds/createShipment/store/CreateShipmentState";
type WarehouseSelectState = Pick<CreateShipmentFormState, "reserveStorage" | "crossdockWarehouse">;

export const getWarehouseSelectState = (booking: InboundBooking): WarehouseSelectState => {
  return {
    reserveStorage: {
      address: booking.to?.reserveStorageWarehouseAddress ?? defaults.reserveStorage.address,
      warehouseId: booking.to?.warehouseId ?? null,
    },
    crossdockWarehouse: {
      address: booking.to?.crossDockWarehouseAddress ?? defaults.crossdockWarehouse.address,
      warehouseId: booking.to?.warehouseId ?? null,
    },
  };
};
