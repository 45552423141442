import React from "react";
import { useIntl } from "react-intl";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { formatAddress } from "common/components/Address";
import { AddressInput } from "common/components/AddressInput";
import { StyledAddressSelect } from "./InboundAddressForm.styles";
import { ExternalShippingPlanIdInput } from "./ExternalShippingPlanIdInput";

interface InboundAddressFormProps {
  addresses: DeliverrAddress[];
  isSelectLoading: boolean;
  fromAddress: DeliverrAddress;
  externalShippingPlanId?: string;
  handleAddressSelectChange: ({ value }) => void;
  handleAddressInputChange: (address: DeliverrAddress) => void;
  handleExternalShippingPlanIdChange?: (value: string) => void;
  showName?: boolean;
  showPhone?: boolean;
  showCountry?: boolean;
  showExternalShippingPlanId?: boolean;
}

export const InboundAddressForm = ({
  addresses,
  isSelectLoading,
  fromAddress,
  externalShippingPlanId,
  handleAddressSelectChange,
  handleAddressInputChange,
  handleExternalShippingPlanIdChange,
  showName = true,
  showPhone,
  showCountry,
  showExternalShippingPlanId = true,
}: InboundAddressFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <StyledAddressSelect
        searchable={false}
        clearable={false}
        options={addresses.map((address) => ({
          value: address,
          label: formatAddress({ address }),
        }))}
        onChange={handleAddressSelectChange}
        placeholder={
          isSelectLoading
            ? formatMessage({ id: "inbounds.fromAddress.loading", defaultMessage: "Loading..." })
            : formatMessage({
                id: "inbounds.fromAddress.selectAddress",
                defaultMessage: "Select from saved addresses",
              })
        }
        isLoading={isSelectLoading}
        disabled={isSelectLoading}
      />
      <hr />
      <AddressInput
        address={fromAddress}
        onChange={handleAddressInputChange}
        showName={showName}
        showCountry={showCountry}
        showPhone={showPhone}
        countryAsSelect
      />
      {showExternalShippingPlanId && handleExternalShippingPlanIdChange && (
        <ExternalShippingPlanIdInput
          externalShippingPlanId={externalShippingPlanId}
          onChange={handleExternalShippingPlanIdChange}
        />
      )}
    </div>
  );
};
