/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { SkuPair } from "@deliverr/commons-objects";
import { ApiError } from "@deliverr/errors";
import { maxBy } from "lodash/fp";

import history from "BrowserHistory";
import { fulfillmentOrderClient, productClient } from "Clients";
import { claimClient } from "common/clients/instances";
import { createActionCreator, Thunk } from "common/ReduxUtils";
import log from "Logger";
import { getServiceLevelForOrder } from "order/OrderUtils";
import { Path } from "paths";
import { Claim } from "common/clients/claim/Claim/Claim";
import { TrackingDetailResponse } from "common/clients/transportation/TrackingDetails";
import { ShipmentFromFulfillment } from "./helpers/getFilteredFlattenedPackages";
import { formatDate, getTrackingDetailsWithTimeout } from "./helpers/getTrackingDetails";

const TRACKING_DETAIL_TIMEOUT_LIMIT_IN_MS = 5000;

export enum OrderDetailActionTypes {
  GET_ORDER = "GET_ORDER",
  GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS",
  GET_ORDER_ERROR = "GET_ORDER_ERROR",
  CANCEL_ORDER = "CANCEL_ORDER",
  GET_ITEM_DETAILS_SUCCESS = "GET_ITEM_DETAILS_SUCCESS",
  GET_ITEM_DETAILS_ERROR = "GET_ITEM_DETAILS_ERROR",
  RESET_ORDER = "RESET_ORDER",
  GET_PACKAGE_TRACKING_DETAILS = "GET_PACKAGE_TRACKING_DETAILS",
}

interface GetOrderOptions {
  includeClaim?: boolean;
}

export const getOrder: Thunk = (orderId: string, options: GetOrderOptions) => async (dispatch) => {
  const ctx = { fn: "getOrder", orderId };
  log.info(ctx, `getting order ${orderId}`);

  try {
    dispatch({ type: OrderDetailActionTypes.GET_ORDER });
    const order = await fulfillmentOrderClient.getOrder(orderId, undefined, undefined, undefined, true);

    if (!order) {
      const err = new Error("ORDER_NOT_FOUND");
      log.error({ ...ctx, err }, "order not found");
      throw err;
    }

    log.info({ ...ctx, order }, "order retrieved");

    let claim: Claim | undefined;
    if (options?.includeClaim) {
      log.info(ctx, `getting order claim ${orderId}`);
      try {
        const claimRes = await claimClient.getClaimsByFilter({
          externalRef: orderId.toString(),
          externalRefType: "orderId",
        });
        claim = claimRes[0];
      } catch (claimErr) {
        log.error({ ...ctx, error: claimErr, orderId }, "order claim error");
      }
    }

    const serviceLevel = await getServiceLevelForOrder(order);

    log.info({ ...ctx, orderId: order.id, serviceLevel }, "service level retrieved from order");

    const maxEstimatedShipTime =
      order.shipments.length > 0 && maxBy(({ estimatedShipTime }) => estimatedShipTime, order.shipments);
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const estimatedShipTime = (maxEstimatedShipTime && maxEstimatedShipTime.estimatedShipTime) || undefined;

    log.info({ ...ctx, orderId: order.id, estimatedShipTime }, "Estimated Ship time parsed");

    dispatch({
      type: OrderDetailActionTypes.GET_ORDER_SUCCESS,
      order,
      serviceLevel,
      estimatedShipTime,
      claim,
    });
    dispatch(getItemDetails(order.items));
    dispatch(getPackageTrackingDetails(order.shipments));
  } catch (err) {
    if (err.message !== ApiError.UNKNOWN_ERROR) {
      log.error({ ctx, err }, "error getting order");
    }
    dispatch({ err, type: OrderDetailActionTypes.GET_ORDER_ERROR });
    history.push(Path.orderList);
  }
};

export const getPackageTrackingDetails: Thunk = (shipments: ShipmentFromFulfillment[]) => async (dispatch) => {
  const ctx = { fn: "getPackageTrackingDetails" };
  log.info(ctx, "getting package tracking details");

  const trackingCodes = shipments
    .filter((shipment) => shipment.packages !== undefined)
    .map((shipment) => shipment.packages.map((pkg) => pkg.trackingCode))
    .flat();
  try {
    const trackingDetails = (await getTrackingDetailsWithTimeout(trackingCodes, TRACKING_DETAIL_TIMEOUT_LIMIT_IN_MS))
      .filter((result) => result.status === "fulfilled")
      .map((result) => (result as PromiseFulfilledResult<TrackingDetailResponse>).value);

    const packageTrackingDetails = trackingDetails.map((trackingDetail) => ({
      trackingCode: trackingDetail.trackingCode,
      trackingHistory: trackingDetail.trackingHistory
        .map(({ eventTime, location, message }) => ({
          date: formatDate(eventTime.toString()),
          time: eventTime.toString(),
          location:
            location.city && location.state && location.country
              ? `${location.city}, ${location.state}, ${location.country}`
              : undefined,
          description: message.toString(),
        }))
        ?.reverse(),
      deliveryImageUrl: trackingDetail.deliveryImageUrl,
    }));

    dispatch({
      packageTrackingDetails,
      type: OrderDetailActionTypes.GET_PACKAGE_TRACKING_DETAILS,
    });
  } catch (err) {
    log.error({ ...ctx, err }, "error getting tracking details");
    dispatch({
      packageTrackingDetails: [],
      type: OrderDetailActionTypes.GET_PACKAGE_TRACKING_DETAILS,
    });
  }
};

export const getItemDetails: Thunk = (orderItems: SkuPair[]) => async (dispatch) => {
  const ctx = { fn: "getItemDetails" };
  log.info({ ...ctx, orderItems }, "getting item details");

  try {
    const dskus = orderItems.map((orderItem) => orderItem.dsku);
    const itemDetails = await productClient.getProducts(dskus);
    log.info({ ...ctx, itemDetails }, "retrieved item details");
    dispatch({
      itemDetails,
      type: OrderDetailActionTypes.GET_ITEM_DETAILS_SUCCESS,
    });
  } catch (err) {
    log.error({ ...ctx, err }, "error getting item details");
    dispatch({ err, type: OrderDetailActionTypes.GET_ITEM_DETAILS_ERROR });
  }
};

export const resetOrder = createActionCreator(OrderDetailActionTypes.RESET_ORDER);
