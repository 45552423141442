import React from "react";
import { CarrierEmailInputScreen } from "./CarrierEmailInputScreen";
import { useSendAndTrackShipmentDetail } from "../SendAndTrackShipment/useSendAndTrackShipmentDetail";

export const AdvancedAppointmentSchedulingDetail: React.FC = () => {
  const {
    updateDeliveryAppointmentDetails: onSubmit,
    carrierEmail,
    submitDisabled,
    emailInputPlaceHolder,
    onCarrierEmailChange,
    handleBlur,
    isError,
    handleFocus,
    carrier,
    handleSelectionChange: handleCarrierSelectionChange,
  } = useSendAndTrackShipmentDetail();

  const carrierEmailInputProps = {
    onSubmit,
    carrierEmail,
    submitDisabled,
    emailInputPlaceHolder,
    onCarrierEmailChange,
    handleBlur,
    isError,
    handleFocus,
    carrier,
    handleCarrierSelectionChange,
  };

  return <CarrierEmailInputScreen {...carrierEmailInputProps} />;
};
