import { createSelector } from "reselect";
import { selectCreateShipmentForm } from "./selectCreateShipmentForm";
import { UnitPrepService } from "inbounds/createShipment/CreateShipmentTypes";
import { PrepCombo, PrepType } from "@deliverr/prep-service-client";
import { DtcPrepOption } from "@deliverr/inbound-client/lib/booking/v2/SubmitBookingRequest";

// Transfer DtcPreOption type (frontend) to PrepType | PrepCombo type (backend), we're not able to use backend type directly because it's not compatible with UI logic.
export const selectInboundDtcPrepOption = createSelector(
  selectCreateShipmentForm,
  ({ dtcPrepOption }): DtcPrepOption => {
    const prepTypes: PrepType[] = [];
    let prepCombo: PrepCombo | undefined;
    if (dtcPrepOption.boxLabelingUnitsSelected) {
      prepTypes.push(PrepType.BOX_CONTENT_LABELS);
    }
    if (dtcPrepOption.unitPrepService === UnitPrepService.UNIT_BARCODING) {
      prepTypes.push(PrepType.SKU_LABELS);
    } else {
      prepCombo = (dtcPrepOption.unitPrepService as PrepCombo) ?? undefined;
    }
    return {
      prepTypes,
      prepCombo,
    };
  }
);
