import { SHIPMENT_STATUSES_WITH_PACKAGE_DETAILS } from "inbounds/ShipmentStatus";
import { createSelector } from "reselect";
import { getLoadedShipment } from "./getLoadedShipment";
import { selectShouldReconfirmBoxes } from "./selectShouldReconfirmBoxes";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";

/**
 * Determine if the loaded shipment is in a status that requires having added box configurations
 * OR opted for Box Contents Labels Prep (which omits packages entirely).
 */
export const selectLoadedShipmentHasConfirmedBoxingChoice = createSelector(
  getLoadedShipment,
  selectShouldReconfirmBoxes,
  (loadedShipment, shouldReconfirmBoxes) => {
    return (
      loadedShipment &&
      SHIPMENT_STATUSES_WITH_PACKAGE_DETAILS.includes(loadedShipment.status) &&
      loadedShipment.status !== InboundShipmentStatus.NEED_PACKAGE_DETAILS &&
      !shouldReconfirmBoxes
    );
  }
);
