import { BoxStatus, QcShipmentStatus } from "@deliverr/returns-client";
import Pill, { PillColors } from "common/components/Pill";
import React, { FC } from "react";

interface BoxStatusPillProps {
  boxStatus: BoxStatus | QcShipmentStatus;
  widePill?: boolean;
}

const BoxStatusPillColors = {
  [BoxStatus.OPEN]: PillColors.GREEN,
  [BoxStatus.CLOSED]: PillColors.BLUE,
  [QcShipmentStatus.LABEL_ADDED]: PillColors.BLUE,
  [QcShipmentStatus.DISPATCHED]: PillColors.GREEN,
  [QcShipmentStatus.IN_TRANSIT]: PillColors.BLUE,
};

const getBoxStatusColor = (boxStatus: BoxStatus | QcShipmentStatus) =>
  BoxStatusPillColors[boxStatus] ?? PillColors.GRAY;

export const BoxStatusPill: FC<BoxStatusPillProps> = ({ boxStatus, widePill }) => {
  return (
    <Pill color={getBoxStatusColor(boxStatus)} widePill={widePill}>
      {boxStatus}
    </Pill>
  );
};
