import React from "react";
import { DefaultTheme } from "common/components/ui";
import { css } from "emotion";
import styled from "@emotion/styled";

import invalid from "assets/img/icons/invalid.svg";
import valid from "assets/img/icons/valid.svg";
import HtmlToReact from "../HtmlToReact";

interface CompliancePillProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  block?: boolean;
  bold?: boolean;
  centered?: boolean;
  icon?: React.ReactNode;
  nonCompliant?: boolean;
}

const icon = css`
  display: inline-block;
  height: 14px;
  margin-right: 5px;
  position: relative;
  top: 3px;

  svg {
    height: 14px;
  }
`;

export const validIcon = <HtmlToReact html={valid} className={icon} />;
export const invalidIcon = <HtmlToReact html={invalid} className={icon} />;

export const CompliancePill = styled.div<CompliancePillProps, DefaultTheme>`
  background-color: ${({ nonCompliant, theme }) => (nonCompliant ? theme.colors.RED[300] : theme.colors.GREEN[300])};
  border: none;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.NEUTRAL["00"]};
  cursor: default;
  display: ${({ block }) => (block ? "block" : "inline-block")};
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 800 : 700)};
  height: fit-content;
  letter-spacing: ${({ theme }) => theme.font.letterSpacing.LS5};
  line-height: 18px;
  ${({ centered }) => (centered ? "margin: 0 auto" : null)};
  padding: 2px 8px;
  text-transform: uppercase;
  width: fit-content;
`;
