import { useDispatch, useSelector } from "react-redux";
import { getOrderConfirmationData } from "../commons/FbaV3Selectors";
import { usePolling } from "common/hooks/usePolling";
import {
  FbaV3EntityName,
  FbaV3Loaders,
  ORDER_CONFIRMATION_FETCH_MAX_RETRIES,
  ORDER_CONFIRMATION_POLLING_INTERVAL_MS,
} from "../commons/FbaV3Constants";
import { useCallback, useEffect } from "react";
import { isNil } from "lodash";
import { retryFbaOperation } from "../commons/retryFbaOperation";
import { isOperationLoading } from "../commons/FbaV3Utils";
import { OperationStatus } from "@deliverr/replenishment-client";
import { useLoader } from "common/components/WithLoader/useLoader";
import { useStepManager } from "common/components/StepContainer";
import { getOrderConfirmationStatusAndSubmitFbaOrder } from "./actions/getOrderConfirmationStatusAndSubmitFbaOrder";
import { resetOrderConfirmationState } from "./actions/OrderConfirmationActions";
import { useUnmount } from "react-use";
import { resetFbaV3State } from "../state/FbaV3Actions";

export const useOrderConfirmation = () => {
  const dispatch = useDispatch();
  const { stepManager } = useStepManager();

  const isConfirmationInProgress = useLoader(FbaV3Loaders.GET_CONFIRM_ORDER_STATUS_AND_SUBMIT_ORDER);

  const { orderConfirmationOperationStatus, orderConfirmationOperationId, orderConfirmationErrors } =
    useSelector(getOrderConfirmationData);

  const isRetryOperationLoading = useLoader(FbaV3Loaders.RETRY_OPERATION);

  useEffect(() => {
    if (orderConfirmationOperationStatus === OperationStatus.SUCCESS) {
      stepManager.next();
    }
  }, [orderConfirmationOperationStatus, stepManager]);

  useUnmount(() => {
    dispatch(resetFbaV3State());
  });

  const shouldPollForOrderConfirmation = isOperationLoading(orderConfirmationOperationStatus);

  const { retries, resetStates } = usePolling(
    ORDER_CONFIRMATION_POLLING_INTERVAL_MS,
    shouldPollForOrderConfirmation,
    () => dispatch(getOrderConfirmationStatusAndSubmitFbaOrder(isConfirmationInProgress)),
    ORDER_CONFIRMATION_FETCH_MAX_RETRIES
  );

  const restartPolling = useCallback(() => {
    dispatch(resetOrderConfirmationState());
    resetStates();
  }, [dispatch, resetStates]);

  const hasRetryLimitReached = retries >= ORDER_CONFIRMATION_FETCH_MAX_RETRIES;
  const isOrderConfirmationLoading = !hasRetryLimitReached && shouldPollForOrderConfirmation;

  const onRetryAfterErrorClick = () => {
    if (!isNil(orderConfirmationOperationId)) {
      dispatch(
        retryFbaOperation({
          operationId: orderConfirmationOperationId,
          postRetryAction: restartPolling,
          entityName: FbaV3EntityName.ORDER_CONFIRMATION,
        })
      );
    } else {
      restartPolling();
    }
  };

  return {
    isNextDisabled: isOrderConfirmationLoading,
    isRetryOperationLoading,
    orderConfirmationErrors,
    hasRetryLimitReached,
    isOrderConfirmationLoading,
    onRetryAfterErrorClick,
    onRetryAfterRetryLimitReachedClick: restartPolling,
  };
};
