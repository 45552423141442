/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { TransferCreateState } from "./TransferCreateState";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { TransferCreateActionTypes } from "./TransferCreateActionTypes";
import produce from "immer";
import { TransferCreateInitialStep } from "../steps/createTransferSteps";
import { StorageProductData } from "@deliverr/storage-client";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { FulfillStrategy } from "@deliverr/replenishment-client";
import { isEmpty, map } from "lodash";

export const transferCreateInitialState: TransferCreateState = {
  stack: [TransferCreateInitialStep],
  selectedProducts: {},
  availableInventory: [],
  selectedProductsCaseInfo: {},
  selectedProductsLotNumbers: {},
  isTransferToEcomDraft: false,
  shipmentShippingMethodMap: {
    [WholesaleShippingMethod.DELIVERR]: {},
    [WholesaleShippingMethod.SELLER]: {},
    [WholesaleShippingMethod.COLLECT]: {},
  },
  isEachPickingOrder: false,
  carrierAccounts: [],
  prepOptions: [],
};

const reducers: ReducerDictionary<TransferCreateState> = {
  ...handleSimpleReducerUpdates([
    TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_DESTINATION_TYPE,
    TransferCreateActionTypes.TRANSFER_CREATE_SELECT_AMAZON_ADDRESS,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_FBA_SHIPMENT_ID,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_FBA_REFERENCE_ID,
    TransferCreateActionTypes.TRANSFER_SET_AVAILABLE_INVENTORY,
    TransferCreateActionTypes.TRANSFER_SET_STORAGE_TO_ECOM_RATES,
    TransferCreateActionTypes.TRANSFER_SET_ESTIMATED_NUMBER_OF_PALLETS,
    TransferCreateActionTypes.TRANSFER_CREATE_STORE_STEPS,
    TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_WHOLESALE_ORDER_OLD,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_WHOLESALE_ORDER,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_ERROR,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_DRAFT_FLAG,
    TransferCreateActionTypes.TRANSFER_CREATE_GET_UNIFIED_PRODUCT,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ORDER,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ORDER_QUOTES,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ERROR,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_PREP_TYPES,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_VALID_SHIP_BY,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_PO_NUMBER,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_CARRIER_ACCOUNTS,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_SELECTED_CARRIER_ACCOUNT,
    TransferCreateActionTypes.TRANSFER_CREATE_SET_NOTES,
  ]),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_LABEL_FILENAME]: (state, { label, file }) =>
    produce(state, (draft) => {
      draft.labelFiles = { ...draft.labelFiles, [label]: file };
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_STORAGE_TRANSFER_ORDER]: (
    state,
    { storageTransferOrder, selectedProducts, selectedProductsCaseInfo }
  ) => {
    return produce(state, (draft) => {
      draft.storageTransferOrder = storageTransferOrder;
      draft.selectedProducts = selectedProducts;
      draft.selectedProductsCaseInfo = selectedProductsCaseInfo;
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_RESET]: () => transferCreateInitialState,
  [TransferCreateActionTypes.TRANSFER_CREATE_ADD_PRODUCT]: (state, { dsku, product }) => {
    return produce(state, (draft) => {
      draft.selectedProducts[dsku] = product;
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_RESET_PRODUCT_SELECTION]: (state) => {
    return produce(state, (draft) => {
      draft.selectedProducts = {};
      draft.availableInventory = [];
      draft.selectedProductsCaseInfo = {};
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_BULK_ADD_PRODUCT]: (state, { selectedProducts }) => {
    return produce(state, (draft) => {
      draft.selectedProducts = selectedProducts;
    });
  },
  [TransferCreateActionTypes.TRANSFER_SET_SELECTED_PRODUCTS_CASE_INFO]: (state, { selectedProductsCaseInfo }) => {
    return produce(state, (draft) => {
      Object.keys(selectedProductsCaseInfo).forEach((dsku) => {
        if (selectedProductsCaseInfo[dsku]) {
          const selectedProductCaseInfo: StorageProductData = selectedProductsCaseInfo[dsku];
          const selectedProduct = draft.selectedProducts[dsku];
          draft.selectedProductsCaseInfo[dsku] = {
            ...draft.selectedProductsCaseInfo[dsku],
            caseHeight: selectedProductCaseInfo.caseHeight,
            caseLength: selectedProductCaseInfo.caseLength,
            caseWidth: selectedProductCaseInfo.caseWidth,
            caseWeight: selectedProductCaseInfo.caseWeight,
            weightUnit: selectedProductCaseInfo.weightUnit,
            lengthUnit: selectedProductCaseInfo.lengthUnit,
          };
          selectedProduct.caseQty = state.isEachPickingOrder
            ? 1
            : selectedProductCaseInfo.caseQty || selectedProduct.caseQty || 1; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          selectedProduct.numberOfCases = Math.floor((selectedProduct.qty ?? 0) / (selectedProduct.caseQty || 1));
          selectedProduct.qty = state.isEachPickingOrder
            ? selectedProduct.qty
            : selectedProduct?.caseQty * selectedProduct.numberOfCases;
        }
      });
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_LOT_NUMBER]: (state, { dsku, atpDetails }) => {
    return produce(state, (draft) => {
      draft.selectedProductsLotNumbers[dsku] = atpDetails;
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_REMOVE_PRODUCT]: (state, { dsku }) => {
    return produce(state, (draft) => {
      delete draft.selectedProducts[dsku];
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_NUMBER_OF_CASES]: (state, { dsku, numberOfCases }) => {
    return produce(state, (draft) => {
      const product = draft.selectedProducts[dsku];
      product.numberOfCases = numberOfCases;
      product.qty = numberOfCases * (product.caseQty ?? 1);
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_TOTAL_QTY]: (state, { dsku, totalQty }) => {
    return produce(state, (draft) => {
      const product = draft.selectedProducts[dsku];
      // Setting to zero to throw error if user goes back and disables each picking
      product.numberOfCases = 0;
      product.qty = totalQty;
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_IS_EACH_PICKING_ORDER]: (state, { isEachPickingOrder }) => {
    return produce(state, (draft) => {
      draft.isEachPickingOrder = isEachPickingOrder;
      if (!isEachPickingOrder) {
        Object.keys(draft.selectedProducts).forEach((dsku) => {
          draft.selectedProducts[dsku].numberOfCases = 0;
          draft.selectedProducts[dsku].qty = 0;
        });
      }
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_STATE_FROM_WHOLESALE_ORDER]: (state, data) => {
    return { ...state, ...data.payload };
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_CASE_QUANTITY]: (state, { dsku, caseQty }) => {
    return produce(state, (draft) => {
      const product = draft.selectedProducts[dsku];
      product.caseQty = caseQty;
      product.qty = (product.numberOfCases ?? 0) * (caseQty ?? 1);
      draft.selectedProductsCaseInfo[dsku].caseQty = caseQty;
    });
  },
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_UPLOADING]: (state, { attachmentType, value }) =>
    produce(state, (draft) => {
      if (!draft.isUploadingLabel) {
        draft.isUploadingLabel = {};
      }
      Object.assign(draft.isUploadingLabel, { [attachmentType]: value });
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_SHIPPING_CONFIG]: (
    state,
    { shippingConfig: { shippingMethod, shipmentType } }
  ) =>
    produce(state, (draft) => {
      if (shippingMethod) {
        draft.shippingMethod = shippingMethod;
      }
      draft.cargoType = shipmentType;
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_SHIPMENT_SHIPPING_CONFIG]: (
    state,
    { shipmentId, shippingMethod, shipmentType }
  ) =>
    produce(state, (draft) => {
      draft.shipmentShippingMethodMap[shippingMethod][shipmentId] = shipmentType;
    }),

  [TransferCreateActionTypes.TRANSFER_CREATE_SET_ATTACHMENT]: (state, { attachmentData }) =>
    produce(state, (draft) => {
      map(attachmentData, (attachmentDetails, attachmentType) => {
        draft.attachments = draft.attachments ?? {};
        draft.attachments[attachmentType] = attachmentDetails;
      });
    }),

  [TransferCreateActionTypes.TRANSFER_CREATE_SET_SHIPMENT_VALIDATION_ERROR]: (
    state,
    { shippingPartner, shippingType, shipmentValidationError }
  ) =>
    produce(state, (draft) => {
      if (!draft.shipmentValidationError) {
        draft.shipmentValidationError = {};
      }
      if (!draft.shipmentValidationError[shippingPartner]) {
        draft.shipmentValidationError[shippingPartner] = {};
      }
      draft.shipmentValidationError[shippingPartner][shippingType] = shipmentValidationError;
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_MUTATE_INVALID_ORDER_ITEM]: (state, payload) =>
    produce(state, (draft) => {
      const invalidOrderItems = draft.replenishmentOrder?.invalidOrderItems;
      if (invalidOrderItems) {
        for (let i = 0; i < invalidOrderItems.length; i++) {
          const invalidOrderItem = invalidOrderItems[i];
          if (invalidOrderItem.id === payload.invalidOrderItem.id) {
            invalidOrderItems[i] = payload.invalidOrderItem;
            break;
          }
        }
      }
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_INVALID_ORDER_ITEMS]: (state, payload) =>
    produce(state, (draft) => {
      draft.replenishmentOrder!.invalidOrderItems = payload.invalidOrderItems;
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_PARTIAL_FILL]: (state, { partialFill }) =>
    produce(state, (draft) => {
      draft.replenishmentOrder!.fulfillStrategy = partialFill ? FulfillStrategy.PARTIAL : FulfillStrategy.DEFAULT;
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_ADD_CARRIER_ACCOUNT]: (state, { carrierAccount }) =>
    produce(state, (draft) => {
      draft.carrierAccounts.push(carrierAccount);
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_CARRIER_ACCOUNT]: (state, { carrierAccount }) =>
    produce(state, (draft) => {
      const index = draft.carrierAccounts.findIndex((account) => account.id === carrierAccount.id);
      if (index !== -1) {
        draft.carrierAccounts[index] = carrierAccount;
      }
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_DELETE_CARRIER_ACCOUNT]: (state, { carrierAccountId }) =>
    produce(state, (draft) => {
      draft.carrierAccounts = draft.carrierAccounts.filter((account) => account.id !== carrierAccountId);
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_KIT_COMPONENT_ORDER_ITEMS]: (state, { kitComponentOrderItem }) =>
    produce(state, (draft) => {
      if (!draft.kitComponentOrderItems) {
        draft.kitComponentOrderItems = [];
      }
      if (!draft.kitComponentOrderItems.find((orderItem) => orderItem.dsku === kitComponentOrderItem.dsku)) {
        draft.kitComponentOrderItems.push(kitComponentOrderItem);
      }
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_REMOVE_KIT_COMPONENT_ORDER_ITEMS]: (state, { dsku }) =>
    produce(state, (draft) => {
      draft.kitComponentOrderItems = draft.kitComponentOrderItems?.filter((item) => item.dsku !== dsku);
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_KIT_COMPONENT_ORDER_ITEM_UNIT]: (
    state,
    { dsku, kitComponentQuantity, selectedKitUnits }
  ) =>
    produce(state, (draft) => {
      const item = draft.kitComponentOrderItems?.find((orderItem) => orderItem.dsku === dsku);
      if (item) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        item.totalUnits += selectedKitUnits * kitComponentQuantity;
      }
    }),
  [TransferCreateActionTypes.TRANSFER_RESET_KIT_COMPONENT_ORDER_ITEM_UNITS]: (state) =>
    produce(state, (draft) => {
      draft.kitComponentOrderItems?.forEach((item) => {
        item.totalUnits = 0;
      });
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_PREP_TYPE]: (state, { prepOption }) =>
    produce(state, (draft) => {
      draft.prepOption = prepOption;
      draft.prepOptions = prepOption ? [prepOption] : [];
    }),
  [TransferCreateActionTypes.TRANSFER_CREATE_SET_SELECTED_PRODUCT_ERROR_MESSAGE]: (state, { dsku, errorMessage }) =>
    produce(state, (draft) => {
      if (!isEmpty(draft.selectedProducts[dsku])) {
        draft.selectedProducts[dsku].errorMessage = errorMessage;
      }
    }),
};

export const transferCreateReducer = createReducer<TransferCreateState>(transferCreateInitialState, reducers);
