import React from "react";
import { useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { useTransferSelectPlacementPlanStep } from "./hooks/useTransferSelectPlacementPlanStep";
import { SelectDestinationWarehouse } from "./SelectDestinationWarehouse";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { ManagedStep } from "common/components/StepContainer";
import { RadioTileGroup } from "common/components/ui";
import { Direction, ThemeProps } from "common/components/ui/shared";
import styled from "@emotion/styled";

const StyledText = styled.p<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S4};
`
);

export const TransferSelectPlacementPlanStep = () => {
  const { formatMessage } = useIntl();
  const {
    currentPlan,
    warehouseOptions,
    selectedWarehouseOption,
    isWarehouseDetailsLoading,
    isNextDisabled,
    isLoading,
    shouldShowForwardingPlan,
    handlePlanChange,
    handleDestinationChange,
  } = useTransferSelectPlacementPlanStep();
  return (
    <ManagedStep
      data-testid="transfer-select-placement-plan"
      title={formatMessage(transfersShipmentCreateLabels.steps.placement.title)}
      subtitle={formatMessage(transfersShipmentCreateLabels.steps.placement.subtitle)}
      isNextDisabled={isNextDisabled}
      isLoading={isLoading}
    >
      <RadioTileGroup
        direction={Direction.VERTICAL}
        currentValue={currentPlan}
        onChange={handlePlanChange}
        options={[
          ...(shouldShowForwardingPlan
            ? [
                {
                  value: DistributionChannel.ECOM_VIA_CROSSDOCK,
                  label: formatMessage(transfersShipmentCreateLabels.steps.placement.forwarding.label),
                  content: formatMessage(transfersShipmentCreateLabels.steps.placement.forwarding.content),
                },
              ]
            : []),
          {
            value: DistributionChannel.ECOM_DIRECT_TO_FC,
            label: formatMessage(transfersShipmentCreateLabels.steps.placement.direct.label),
            content: (
              <>
                <StyledText>
                  <FormattedMessage {...transfersShipmentCreateLabels.steps.placement.direct.content} />
                </StyledText>
                <SelectDestinationWarehouse
                  options={warehouseOptions}
                  selectedOption={selectedWarehouseOption}
                  isLoading={isWarehouseDetailsLoading}
                  handleChange={handleDestinationChange}
                />
              </>
            ),
          },
        ]}
      />
    </ManagedStep>
  );
};
