import { SelectedProductData } from "common/components/ProductChooser";
import { useIntl } from "react-intl";
import { FbaV3ProductErrorLabels } from "./FbaV3ProductErrorLabels";
import {
  FBA_V3_MAX_CASE_DIMENSION_IN,
  FBA_V3_MAX_CASE_WEIGHT_LB,
  FBA_V3_MAX_QUANTITY_IN_CASE,
} from "../commons/FbaV3Constants";
import { FeatureName, isFeatureOn } from "common/Split";

export const getFbaV3ProductError = (
  selectedProduct: SelectedProductData,
  formatMessage: ReturnType<typeof useIntl>["formatMessage"]
): string | undefined => {
  const shouldSkipValidationsForSeller = isFeatureOn(FeatureName.OmitFbaV3ChecksForSeller);

  if (shouldSkipValidationsForSeller) {
    return;
  }

  const { qty, length, width, height, weight } = selectedProduct;

  if (!!qty && qty > FBA_V3_MAX_QUANTITY_IN_CASE) {
    return formatMessage(FbaV3ProductErrorLabels.skuQuantityExceeded, {
      maxUnitsOfSku: FBA_V3_MAX_QUANTITY_IN_CASE,
    });
  } else if (
    (!!length && length > FBA_V3_MAX_CASE_DIMENSION_IN) ||
    (!!width && width > FBA_V3_MAX_CASE_DIMENSION_IN) ||
    (!!height && height > FBA_V3_MAX_CASE_DIMENSION_IN)
  ) {
    return formatMessage(FbaV3ProductErrorLabels.caseDimensionsExceeded, {
      maxDimensionInInches: FBA_V3_MAX_CASE_DIMENSION_IN,
    });
  } else if (!!weight && weight > FBA_V3_MAX_CASE_WEIGHT_LB) {
    return formatMessage(FbaV3ProductErrorLabels.caseWeightExceeded, {
      maxWeightInLbs: FBA_V3_MAX_CASE_WEIGHT_LB,
    });
  }

  return undefined;
};
