import { InboundPrepRequestData } from "@deliverr/inbound-client";
import { PrepType } from "@deliverr/prep-service-client";

/**
 * Determine if the PrepRequest exists and has *all* the specified PrepTypes
 */
export const checkIfPrepRequestHasAllSpecifiedPrepTypes = (
  prepRequest: InboundPrepRequestData | undefined,
  ...prepTypes: PrepType[]
) => Boolean(prepRequest && prepTypes.every((prepType) => prepRequest.prepTypes.includes(prepType)));
