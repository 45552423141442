import { useHistory, useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { useProblemsTableColumns } from "common/components/ProblemsTable/useProblemsTableColumns";
import log from "Logger";
import { useSelector, useDispatch } from "react-redux";
import { getSellerId } from "common/user/UserSelectors";
import { sellerProblemsViewClient } from "Clients";
import * as spvUtils from "./spvUtils";
import { compact, isNil } from "lodash/fp";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { getNonComplianceFlowRoute } from "./spvUtils/getNonComplianceFlowRoute";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";
import { nonComplianceDynamicRoutes } from "../NonComplianceRoutes";
import { SellerProblemsDetailView, SellerProblemStatus, SellerProblemType } from "@deliverr/inbound-client";
import { ProductCollection } from "common/models";
import { ProblemType } from "common/problems/ProblemType";
import { DynamicRoutes } from "paths";
import { useSPSelector } from "common/ReduxUtils";
import { searchNonCompliance } from "./actions/searchNonCompliance";
import { setCurrentPage } from "./actions/setCurrentPage";

export interface NonComplianceSpvListRouteParams {
  nonCompliantId?: string;
  nonComplianceType?: SellerProblemType;
}

const getSellerProblemRow = (issue: SellerProblemsDetailView, productCollection?: ProductCollection) => {
  if (!issue.isLegacyCase) {
    return spvUtils.getNonComplianceRowsFromGenericIssueCases(issue, productCollection);
  }
  switch (issue.type) {
    case SellerProblemType.DAMAGED_PRODUCT:
      return spvUtils.getNonComplianceRowsFromDamagedProductCases(issue, productCollection);
    case SellerProblemType.MISSING_BARCODE:
      return spvUtils.getNonComplianceRowsFromMissingBarcodeCases(issue, productCollection);
    case SellerProblemType.UNEXPECTED_SKU:
      return spvUtils.getNonComplianceRowsFromUnexpectedBarcodeCases(issue, productCollection);
    case SellerProblemType.UNKNOWN_BARCODE:
      return spvUtils.getNonComplianceRowsFromNonComplianceProducts(issue, productCollection);
    case SellerProblemType.FLEXPORT_EXCEPTION:
      return spvUtils.getNonComplianceRowsFromOperationalException(issue, productCollection);
    default:
      log.warn(issue, `Unsupported SellerProblemType in useNonComplianceSpvList`);
      return undefined;
  }
};

export const useNonComplianceSpvList = (pageSize: number) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigate = usePreventScrollToTopNavigation();
  const { columns } = useProblemsTableColumns();
  const sellerId = useSelector(getSellerId);
  const { nonCompliantId, nonComplianceType } = useParams<NonComplianceSpvListRouteParams>();
  const [activeCaseFallback, setActiveCaseFallback] = useState<SellerProblemsDetailView | undefined>();
  const [productCollection] = useState<ProductCollection>();
  const { problemTypes: selectedProblemTypes, problemStatuses: selectedProblemStatuses } = useSPSelector(
    (state) => state.nonComplianceList.searchFilters
  );
  const { currentPageItems, searchInfo, searchLoading } = useSPSelector((state) => state.nonComplianceList);

  const currentPage = searchInfo?.currentPage ?? 0;
  useEffect(() => {
    dispatch(searchNonCompliance());
  }, [sellerId, pageSize, currentPage, selectedProblemTypes, selectedProblemStatuses, dispatch]);

  const rows = compact(currentPageItems.map((issue) => getSellerProblemRow(issue, productCollection)));

  const activeCase = useMemo(() => {
    if (nonCompliantId === undefined) {
      return undefined;
    }
    if (
      activeCaseFallback &&
      (nonCompliantId === activeCaseFallback?.sellerProblemId?.toString() ||
        nonCompliantId === activeCaseFallback?.issueId)
    ) {
      return getSellerProblemRow(activeCaseFallback, productCollection);
    }
    return undefined;
  }, [rows, nonCompliantId, activeCaseFallback]);

  useEffect(() => {}, [selectedProblemTypes]);

  useEffect(() => {
    if (!activeCase && nonCompliantId !== undefined) {
      const fetchActiveCaseFallback = async () => {
        const activeCaseView = await sellerProblemsViewClient
          .getSellerProblemsDetailView(nonCompliantId, nonComplianceType as SellerProblemType)
          .then((res) => res.data);
        setActiveCaseFallback(activeCaseView);
      };

      fetchActiveCaseFallback().catch((err) => {
        log.warn({ nonCompliantId, err }, `Unable to find detailed view for problem id`);
      });
    }
  }, [activeCase, nonCompliantId]);

  const onRowClick = async (row: ProblemRowData) => {
    if (row.problemType === ProblemType.FLEXPORT_SHIP) {
      const path = DynamicRoutes.inboundsSmbShipmentProblem.parse({
        shippingPlanId: row.shippingPlanId,
        problemId: row.id,
      });
      history.push(path);
    } else if (row.status === SellerProblemStatus.ACTION_NEEDED && !isNil(row.id)) {
      const route = getNonComplianceFlowRoute(row.problemType);
      if (route !== null) {
        history.push(
          route.parse({
            nonCompliantId: row.id,
          })
        );
      }
    } else {
      navigate(
        nonComplianceDynamicRoutes.viewCompletedCase.parse({
          nonCompliantId: row.id,
          nonComplianceType: row.problemType,
        })
      );
    }
  };

  const savePage = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(searchNonCompliance());
  };

  return {
    columns,
    rows,
    totalPages: searchInfo?.totalPages,
    onRowClick,
    isLoading: searchLoading,
    activeCase,
    currentPage,
    savePage,
  };
};
