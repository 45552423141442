import { fromLooseDate } from "common/date/LooseDate";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { SellerProblemStatus } from "@deliverr/inbound-client";

// map NC status to a value for determining sort order:
// the smaller the value, the the higher its priority in an ascending sort
const NC_STATUS_SORT_ORDER: Record<SellerProblemStatus, number> = {
  [SellerProblemStatus.CREATED]: 0,
  [SellerProblemStatus.ACTION_NEEDED]: 0,
  [SellerProblemStatus.SUPPORT_CONTACTED]: 1,
  [SellerProblemStatus.RESOLVED]: 2,
};

const compareAcknowledgedAt = (acknowledgedAtA?: Date, acknowledgedAtB?: Date): number => {
  return !!acknowledgedAtA && !!acknowledgedAtB
    ? 0 // consider two acknowledge problems as equal to fallback to other logic
    : (acknowledgedAtA ? fromLooseDate(acknowledgedAtA).getTime() : 0) -
        (acknowledgedAtB ? fromLooseDate(acknowledgedAtB).getTime() : 0);
};

/**
 * Sort function for comparing two Shipment Problems rows
 * @param a
 * @param b
 */
export const compareShipmentProblemRows = (a: ProblemRowData, b: ProblemRowData): number => {
  const statusCompareResult = NC_STATUS_SORT_ORDER[a.status] - NC_STATUS_SORT_ORDER[b.status];

  // if statuses are not equal, use that as primary determinant for order
  if (statusCompareResult !== 0) {
    return statusCompareResult;
  }

  const acknowledgedCompareResult = compareAcknowledgedAt(a.acknowledgedAt, b.acknowledgedAt);

  // If one is acknowledged and the other isn't, return that comparison
  if (acknowledgedCompareResult !== 0) {
    return acknowledgedCompareResult;
  }

  // otherwise, use updatedAt as comparison
  return (
    (a.updatedAt ? fromLooseDate(a.updatedAt).getTime() : 0) - (b.updatedAt ? fromLooseDate(b.updatedAt).getTime() : 0)
  );
};
