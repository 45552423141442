import { useSelector, useDispatch } from "react-redux";
import { useMount, useUnmount } from "react-use";
import { useParams } from "react-router-dom";
import { getStorageInboundOrder, resetStorage } from "./store/actions";
import { getSellerId } from "common/user/UserSelectors";
import { getStorageInboundDetail } from "./store";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";

export const useStorageDetail = () => {
  const dispatch = useDispatch();
  const { shippingPlanId } = useParams<{ shippingPlanId: string }>();
  const sellerId = useSelector(getSellerId);
  const { storageInboundOrder, storageItemDetails, isLoading } = useSelector(getStorageInboundDetail);

  const shouldShowShipmentInstructions = [InboundShipmentStatus.READY_FOR_WAREHOUSE].includes(
    storageInboundOrder.status!
  );

  useMount(() => {
    dispatch(getStorageInboundOrder(Number(shippingPlanId), sellerId));
  });

  useUnmount(() => {
    dispatch(resetStorage());
  });

  return {
    shouldShowShipmentInstructions,
    storageInboundOrder,
    storageItemDetails,
    isLoading,
  };
};
