import { getSellerId } from "common/user/UserSelectors";
import { isEmpty, isEqual, trim, escape } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import {
  Carrier,
  CarrierAccount,
  CreateCarrierAccountRequest,
  UpdateCarrierAccountRequest,
  CarrierPaymentType,
} from "@deliverr/replenishment-client";
import { useEffect, useState, useRef } from "react";
import { useLoader } from "common/components/WithLoader/useLoader";
import { ReplenishmentCarrierAccountLoader } from "../ReplenishmentCarrierAccountLoader";
import { SelectOptionType, toast } from "common/components/ui";
import { SPThunkAction } from "common/ReduxUtils";
import { CreateCarrierAccountSchema, UpdateCarrierAccountSchema } from "../schema/CarrierAccountSchema";

export interface ReplenishmentCarrierOption {
  value: Carrier;
  label: string;
}

export interface CarrierPaymentTypeOption {
  value: CarrierPaymentType;
  label: string;
}

export interface ReplenishmentCarrierAccountFormData {
  displayName: string;
  carrier: ReplenishmentCarrierOption;
  paymentType: CarrierPaymentTypeOption;
  accountNumber: string;
  postalCode: string;
}

export interface ReplenishmentCarrierAccountFormError
  extends Omit<ReplenishmentCarrierAccountFormData, "carrier" | "paymentType"> {}

export const getCarrierLabel = (carrier: Carrier) => {
  switch (carrier) {
    case Carrier.FEDEX:
      return "FedEx";
    case Carrier.UPS:
      return "UPS";
    default:
      return "Unknown Carrier";
  }
};

export const getPaymentTypeLabel = (paymentType: CarrierPaymentType) => {
  switch (paymentType) {
    case CarrierPaymentType.THIRD_PARTY:
      return "Third Party";
    case CarrierPaymentType.RECEIVER:
      return "Receiver";
    default:
      return "Unknown Payment Type";
  }
};

const carrierOptions = Object.values(Carrier).map((value) => ({
  value,
  label: getCarrierLabel(value),
}));

const paymentTypeOptions = Object.values(CarrierPaymentType).map((value) => ({
  value,
  label: getPaymentTypeLabel(value),
}));

const defaultCarrier = carrierOptions[0];

const getFormData = (carrierAccount?: CarrierAccount): ReplenishmentCarrierAccountFormData => ({
  displayName: trim(carrierAccount?.displayName),
  carrier: {
    value: carrierAccount?.carrier ?? defaultCarrier.value,
    label: carrierAccount?.carrier ?? defaultCarrier.label,
  },
  accountNumber: trim(carrierAccount?.accountNumber),
  postalCode: trim(carrierAccount?.postalCode),
  paymentType: {
    value: carrierAccount?.paymentType ?? CarrierPaymentType.THIRD_PARTY,
    label: carrierAccount?.paymentType
      ? getPaymentTypeLabel(carrierAccount.paymentType)
      : getPaymentTypeLabel(CarrierPaymentType.THIRD_PARTY),
  },
});

export const useReplenishmentCarrierAccountForm = (
  createCarrierAccount: (createCarrierAccountRequest: CreateCarrierAccountRequest) => SPThunkAction<Promise<void>>,
  updateCarrierAccount: (
    carrierAccountId: number,
    updateCarrierAccountRequest: UpdateCarrierAccountRequest
  ) => SPThunkAction<Promise<void>>,
  onClose: () => void,
  isUpdateMode: boolean,
  carrierAccount?: CarrierAccount
) => {
  const dispatch = useDispatch();
  const { destinationAddress } = useSelector(selectTransferCreate);
  const sellerId = useSelector(getSellerId);

  const [formData, setFormData] = useState(getFormData(carrierAccount));
  const [errors, setErrors] = useState<ReplenishmentCarrierAccountFormError>({
    displayName: "",
    accountNumber: "",
    postalCode: "",
  });

  const isLoading = useLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader);

  const prevCarrierAccountRef = useRef<CarrierAccount | undefined>(carrierAccount);

  useEffect(() => {
    if (!isEqual(prevCarrierAccountRef.current, carrierAccount)) {
      setFormData(getFormData(carrierAccount));
      prevCarrierAccountRef.current = carrierAccount;
    }
  }, [carrierAccount]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleCarrierChange = (option: SelectOptionType) => {
    setFormData((prevData) => ({
      ...prevData,
      carrier: {
        value: option.value as Carrier,
        label: option.label,
      },
    }));
  };

  const handlePaymentTypeChange = (option: SelectOptionType) => {
    setFormData((prevData) => ({
      ...prevData,
      paymentType: {
        value: option.value as CarrierPaymentType,
        label: option.label,
      },
    }));
  };

  const isSaveDisabled =
    isEmpty(trim(formData.displayName)) || isEmpty(trim(formData.accountNumber)) || isEmpty(trim(formData.postalCode));

  const validateUpdateCarrierAccount = () => {
    if (!carrierAccount?.id) {
      throw new Error("Carrier account id is required");
    }
    if (
      formData.displayName === carrierAccount.displayName &&
      formData.postalCode === carrierAccount.postalCode &&
      formData.paymentType.value === carrierAccount.paymentType
    ) {
      throw new Error("No changes detected");
    }
  };

  const handleSave = async (): Promise<void> => {
    try {
      if (isUpdateMode) {
        validateUpdateCarrierAccount();
        const updateCarrierAccountRequest = {
          displayName: escape(trim(formData.displayName)),
          postalCode: trim(formData.postalCode),
          paymentType: formData.paymentType.value,
        };

        await UpdateCarrierAccountSchema.validate(updateCarrierAccountRequest, { abortEarly: false });
        await dispatch(updateCarrierAccount(carrierAccount!.id, updateCarrierAccountRequest));
      } else {
        const createCarrierAccountRequest = {
          displayName: escape(trim(formData.displayName)),
          carrier: formData.carrier.value,
          accountNumber: trim(formData.accountNumber),
          postalCode: trim(formData.postalCode),
          companyName: destinationAddress?.name ?? destinationAddress?.company ?? formData.displayName,
          sellerId,
          paymentType: formData.paymentType.value,
        };

        await CreateCarrierAccountSchema.validate(createCarrierAccountRequest, { abortEarly: false });
        await dispatch(createCarrierAccount(createCarrierAccountRequest));
      }
      onClose();
    } catch (err) {
      if (err.name === "ValidationError") {
        const validationErrors = {
          displayName: "",
          accountNumber: "",
          postalCode: "",
          carrier: "",
        };
        err.inner.forEach((error: any) => {
          if (error.path && Object.prototype.hasOwnProperty.call(validationErrors, error.path)) {
            validationErrors[error.path] = error.message;
          }
        });
        setErrors(validationErrors);
      } else {
        toast.error(err.message ?? "An error occurred", {
          toastId: "replenishmentCarrierAccountFormError",
        });
      }
    }
  };

  return {
    formData,
    carrierOptions,
    paymentTypeOptions,
    isSaveDisabled,
    isLoading,
    errors,
    handleInputChange,
    handleCarrierChange,
    handlePaymentTypeChange,
    handleSave,
  };
};
