/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/dedupe";
import closest from "closest";
import React, { PropsWithChildren } from "react";
import ReactPaginate from "react-paginate";

import Checkbox from "common/components/Checkbox";
import ColumnHeader from "common/components/lists/Table/ColumnHeader";
import { ResultsPerPageSelector } from "common/components/lists/Table/ResultsPerPageSelector";
import WithLoader from "common/components/WithLoader";
import { HorizontalAlignment, Sort, SortDir } from "common/models";
import { isUndefined } from "lodash";
import { TableNoResult } from "./TableNoResult";

import cls from "./Table.less";
import { defaultTheme, IconV2, ThemeProps } from "../../ui";

const HorizontallyScrollingContainer = styled.div`
  overflow-x: auto;
`;

const ExpandedRow = styled.tr<ThemeProps>(
  ({ theme }) => `
  background: ${theme.colors.NEUTRAL[20]};
  td {
    // Needed to use important to override the default vertical-align: middle;
    vertical-align: top !important;
  }
`
);

export interface TableColumn<ItemT> {
  header: React.ReactNode;
  helpText?: JSX.Element;
  id?: string;
  className?: string;
  sortField?: string;
  ellipsis?: boolean;
  breakWord?: boolean;
  width?: string;
  align?: HorizontalAlignment;
  renderCell?: (item: ItemT, i: number) => React.ReactNode;
  renderCellHtml?: (item: ItemT, i: number) => string;
  renderExpandedCell?: (item: ItemT, i: number) => React.ReactNode;
  getCellRowSpan?: (item: ItemT) => number;
}

export interface PagingInfo {
  totalPages: number;
  currentPage: number;
}

export interface TableProps<ItemT> {
  columns: Array<TableColumn<ItemT>>;
  items: ItemT[];
  page: string;
  maxSize?: 10 | 25 | 50 | 100 | 150 | 200;
  className?: string;
  anyRowsSelected?: boolean;
  pageChecked?: boolean;
  selectedRows?: { [index: number]: boolean };
  expandedRows?: { [index: number]: boolean };
  currentSort?: Sort;
  pagingInfo?: PagingInfo;
  allowRowSelection?: boolean;
  allowRowExpansion?: boolean;
  showAllSelectionCheckbox?: boolean;
  hidePaginate?: boolean;
  hideHover?: boolean;
  hideBoxShadow?: boolean;
  noResultsMessage?: string | JSX.Element;
  noResults?: React.ReactNode;
  listLoaderType?: string;
  isHeaderSticky?: boolean;
  shouldCheckRowOnClick?: boolean;
  // This prop is for a temporary solution to allow partial row selection on specific pages and is planned to be removed. Please do not use it in new code.
  allowPartialRowCheck?: boolean;

  /**
   * Min width for table. If minWidth is specified, table will scroll horizontally if needed.
   */
  minWidth?: string;

  onPageChange: (page: number) => void;
  onPageCheck?: (isChecked: boolean) => void;
  onRowClick?: (item: ItemT) => void;
  onRowCheck?: (rowIndex: number, isSelected: boolean) => void;
  onRowExpand?: (rowIndex: number, isExpanded: boolean) => void;
  onSortChange?: (sort: Sort) => void;
  getRowClass?: (item: ItemT) => string;
  showCheckboxCondition?: (item: ItemT) => boolean;
  getRowTestId?: (item: ItemT) => string;
  keyFunction?: (item: ItemT) => string;
}

const Table = <T,>({
  columns,
  items,
  page,
  maxSize = 100,
  className,
  anyRowsSelected,
  pageChecked,
  selectedRows,
  expandedRows,
  currentSort,
  pagingInfo,
  allowRowSelection = true,
  allowRowExpansion = false,
  hidePaginate,
  hideHover,
  hideBoxShadow,
  noResultsMessage,
  noResults,
  listLoaderType,
  isHeaderSticky = false,
  showAllSelectionCheckbox = true,
  minWidth,
  shouldCheckRowOnClick = true,
  allowPartialRowCheck = false,
  onPageChange,
  onPageCheck,
  onRowCheck,
  onRowExpand,
  onRowClick,
  onSortChange,
  getRowClass,
  showCheckboxCondition,
  getRowTestId,
  keyFunction,
}: PropsWithChildren<TableProps<T>>) => {
  const getRowId = (target: EventTarget): number | undefined => {
    const tr = closest(target, "tr");
    return tr ? Number(closest(target, "tr").dataset.row_ix) : undefined;
  };

  const handleMasterCheckboxCheck = (_, checked: boolean) => {
    if (onPageCheck) {
      onPageCheck(checked);
    }
  };

  const handleRowCheckboxCheck = (e: React.MouseEvent<HTMLInputElement>, checked: boolean) => {
    const rowIx = getRowId(e.target);
    if (!isUndefined(rowIx) && onRowCheck) {
      onRowCheck(rowIx, checked);
    }
  };

  const handleRowExpanderClick = (e: React.MouseEvent<HTMLTableCellElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const rowIx = getRowId(e.target);

    if (!isUndefined(rowIx) && onRowExpand && expandedRows) {
      onRowExpand(rowIx, !expandedRows[rowIx]);
    }
  };

  const handlePageChange = ({ selected }: any) => {
    if (!pagingInfo || selected !== pagingInfo.currentPage) {
      onPageChange(selected);
    }
  };

  const handleColumnHeaderClick = (e: React.MouseEvent<HTMLTableHeaderCellElement>) => {
    const sortField = closest(e.target, "th", true).dataset.sortfield;

    if (currentSort && onSortChange) {
      onSortChange({
        fieldName: sortField,
        direction:
          currentSort.fieldName === sortField && currentSort.direction === SortDir.ASC ? SortDir.DESC : SortDir.ASC,
      });
    }
  };

  const handleItemClick = async (e: React.MouseEvent<HTMLTableRowElement>) => {
    const rowIx = getRowId(e.target);
    if (onRowClick && !isUndefined(rowIx)) {
      await onRowClick(items[rowIx]);
    }
  };

  const handleItemClickWithSelection = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const rowIx = getRowId(e.target);
    if (shouldCheckRowOnClick && onRowCheck && selectedRows && !isUndefined(rowIx)) {
      onRowCheck(rowIx, !selectedRows[rowIx]);
    }
  };

  const handleRowCheckboxCellClick = (e: React.MouseEvent<HTMLTableDataCellElement>) => e.stopPropagation();

  // In pages where only certain rows are allowed to be selected, we show the master checkbox only if there are rows that can be selected.
  const showMasterCheckboxCondition =
    showAllSelectionCheckbox && (!allowPartialRowCheck || items.some((item) => !(item as any).disableRowSelection));

  const selectionCount = selectedRows ? Object.keys(selectedRows).filter((i) => selectedRows[i]).length : 0;
  const rowClickHandler = selectionCount === 0 ? handleItemClick : handleItemClickWithSelection;

  const columnCount = columns.length + (allowRowSelection ? 1 : 0);

  const table = (
    <WithLoader name={listLoaderType!} size={"lg"}>
      {items.length > 0 ? (
        <table
          className={classNames(`table ${cls.table}`, className, {
            [cls.selecting]: selectionCount > 0,
            [cls.showHover]: !hideHover,
            [cls.hideBoxShadow]: hideBoxShadow,
          })}
          style={{ minWidth }}
        >
          <thead className={classNames(isHeaderSticky && cls.sticky)}>
            <tr>
              {allowRowSelection && (
                <ColumnHeader
                  className={cls.selectionCol}
                  key="selColHdr"
                  content={
                    showMasterCheckboxCondition ? (
                      <Checkbox
                        onCheck={handleMasterCheckboxCheck}
                        checked={pageChecked || anyRowsSelected}
                        indeterminate={anyRowsSelected || undefined}
                      />
                    ) : null
                  }
                />
              )}

              {/* Empty column for expander */}
              {allowRowExpansion && <ColumnHeader className={cls.expansionCol} key="expandColHdr" content={""} />}

              {columns.map((col, ix) => (
                <ColumnHeader
                  className={col.className || ""}
                  key={ix}
                  content={col.header}
                  helpText={col.helpText}
                  sortField={col.sortField}
                  onSort={col.sortField ? handleColumnHeaderClick : undefined}
                  currentSort={currentSort}
                  width={col.width}
                  align={col.align}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((item, i: number) => {
              // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
              const rowClass = getRowClass?.(item);
              const rowTestId = getRowTestId?.(item);
              return (
                <>
                  <tr
                    data-testid={rowTestId}
                    key={keyFunction?.(item) ?? i}
                    data-row_ix={i}
                    className={classNames(rowClass, {
                      // We mark a given row as selected in two cases: when the master checkbox of the page is checked or when the row itself is selected.
                      // However for the first case there is a caveat, on specific pages, only certain rows are allowed to be selected.
                      // Therefore, if a user checks the master checkbox on those pages, it shouldn't select all rows automatically.
                      [cls.selectedRow]: (!allowPartialRowCheck && pageChecked) || (selectedRows && selectedRows[i]),
                    })}
                    onClick={selectionCount > 0 && (item as any).disableRowSelection ? undefined : rowClickHandler}
                  >
                    {allowRowSelection && (
                      <td className={cls.selectionCol} onClick={handleRowCheckboxCellClick}>
                        {(!showCheckboxCondition || showCheckboxCondition(item)) && (
                          <Checkbox
                            style={{ color: "rgb(205, 207, 212)" }}
                            checked={(!allowPartialRowCheck && pageChecked) || (selectedRows && selectedRows[i])}
                            onCheck={handleRowCheckboxCheck}
                          />
                        )}
                      </td>
                    )}

                    {allowRowExpansion && (
                      <td className={cls.expansionCol} onClick={handleRowExpanderClick}>
                        {/* TODO: Fix styling */}
                        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                          <IconV2
                            type={expandedRows && expandedRows[i] ? "angle-down" : "angle-right"}
                            size="lg"
                            color={defaultTheme.colors.NEUTRAL[500]}
                          />
                        </div>
                      </td>
                    )}

                    {columns.map((col, ix) => {
                      const rowSpan = col.getCellRowSpan ? col.getCellRowSpan(item) : undefined;
                      if (rowSpan === 0) {
                        return null;
                      }
                      return (
                        <td
                          className={classNames(col.className, {
                            [cls.ellipsis]: col.ellipsis,
                            [cls.breakWord]: col.breakWord,
                            [cls.alignCenter]: col.align === HorizontalAlignment.CENTER,
                            [cls.alignRight]: col.align === HorizontalAlignment.RIGHT,
                          })}
                          title={(col.ellipsis && col.renderCellHtml && col.renderCellHtml(item, i)) || ""}
                          key={col.id || `cell-${i}-${ix}`}
                          style={{ width: col.width || "" }}
                          rowSpan={rowSpan}
                          dangerouslySetInnerHTML={
                            col.renderCellHtml && {
                              __html: col.renderCellHtml(item, i),
                            }
                          }
                        >
                          {col.renderCell && col.renderCell(item, i)}
                        </td>
                      );
                    })}
                  </tr>

                  {/* Expanded row if row is toggled */}
                  {expandedRows && expandedRows[i] && (
                    <ExpandedRow>
                      {/* empty cell for check and expander if allowRowSelection or allowRowExpansion is true */}
                      {allowRowSelection && <td className={cls.selectionCol} />}
                      {allowRowExpansion && <td className={cls.expansionCol} />}

                      {/* Iterate over columns and render the expanded row cell */}
                      {columns.map((col, ix) => {
                        const rowSpan = col.getCellRowSpan ? col.getCellRowSpan(item) : undefined;
                        if (rowSpan === 0) {
                          return null;
                        }

                        return (
                          <td
                            className={classNames(col.className, {
                              [cls.ellipsis]: col.ellipsis,
                              [cls.breakWord]: col.breakWord,
                              [cls.alignCenter]: col.align === HorizontalAlignment.CENTER,
                              [cls.alignRight]: col.align === HorizontalAlignment.RIGHT,
                            })}
                            title={(col.ellipsis && col.renderCellHtml && col.renderCellHtml(item, i)) || ""}
                            key={col.id || `cell-${i}-${ix}`}
                            style={{ width: col.width || "" }}
                            rowSpan={rowSpan}
                          >
                            {col.renderExpandedCell && col.renderExpandedCell(item, i)}
                          </td>
                        );
                      })}
                    </ExpandedRow>
                  )}
                </>
              );
            })}
          </tbody>
          {!hidePaginate && (
            <tfoot>
              <tr>
                <td colSpan={columnCount} className={cls.pagingFooter}>
                  <div className={cls.pager}>
                    <div className={cls.resultsPerPageSelector} />
                    {pagingInfo && pagingInfo.totalPages > 0 && (
                      <ReactPaginate
                        key={`${pagingInfo.totalPages}-${pagingInfo.currentPage}`}
                        previousLabel={<FontAwesomeIcon icon="chevron-left" />}
                        nextLabel={<FontAwesomeIcon icon="chevron-right" />}
                        breakLabel="..."
                        breakClassName={cls.pagerBreak}
                        pageCount={pagingInfo.totalPages}
                        initialPage={pagingInfo.currentPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={cls.pagerContainer}
                        subContainerClassName={cls.pagerPages}
                        activeClassName={cls.pagerActive}
                      />
                    )}
                    <ResultsPerPageSelector page={page} maxSize={maxSize} className={cls.resultsPerPageSelector} />
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        noResults || <TableNoResult message={noResultsMessage} hideBoxShadow={hideBoxShadow} />
      )}
    </WithLoader>
  );

  return minWidth ? <HorizontallyScrollingContainer>{table}</HorizontallyScrollingContainer> : table;
};

export default Table;
