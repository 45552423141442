import { Product, ProductCasePack } from "@deliverr/inbound-client/lib/legacy-copy-pasta/product";

export const getCasePackConfiguration = (productDetails?: {
  siblingCasePacks: ProductCasePack[];
  parentProduct: Product;
  product: Product;
}) => {
  const product = productDetails?.siblingCasePacks?.[0]
    ? productDetails.siblingCasePacks[0]
    : productDetails?.parentProduct;
  return {
    caseQty: productDetails?.siblingCasePacks?.[0]
      ? productDetails.siblingCasePacks[0].quantity
      : productDetails?.parentProduct.caseQty,
    length: product?.length,
    width: product?.width,
    height: product?.height,
    weight: product?.weight,
  };
};
