import { InboundBookingProduct } from "@deliverr/inbound-client";
import { productClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { logError, logStart } from "Logger";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { selectBookingProducts } from "../selectors";
import { setBookingProducts } from "./simpleUpdateActions";
import { ProductPrepCategory } from "@deliverr/commons-clients";

export const getBookingProducts =
  (isDtcNetworkBooking: boolean, mixedSkuProductDskus?: string[]): SPThunkAction<Promise<InboundBookingProduct[]>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { selectedProducts, selectedProductsCaseDimensions, selectedProductsCasePacks } =
      getStorageInboundCreate(state);
    const savedBookingProducts = selectBookingProducts(state);

    const dskus = Object.keys(selectedProducts);
    // need to check if the value was set by the AirCargoModal, which collects box dims without products;
    const hasAirCargoInfo = savedBookingProducts.length && savedBookingProducts[0].dsku === undefined;

    if (!dskus.length) {
      if (!hasAirCargoInfo) {
        dispatch(setBookingProducts([]));
      }
      return [];
    }

    const ctx = logStart({ fn: "useFetchProducts", dskus });
    try {
      const productData = await productClient.getProducts(dskus, {
        includeCustomsInformation: true,
        includeProductPreparation: true,
      });
      const bookingProducts = Object.values(selectedProducts)
        .filter(({ dsku }) => productData?.[dsku])
        .map(({ dsku, qty, caseQty }) => {
          const casePackDsku = selectedProductsCasePacks?.[dsku]?.dsku;
          const { customsInformation, brandedPackaging, productPreparation } = productData![dsku];
          const caseDimensions = selectedProductsCaseDimensions[dsku];
          return {
            dsku: isDtcNetworkBooking ? dsku : casePackDsku,
            categoryName: brandedPackaging ? ProductPrepCategory.OTHER : productPreparation?.packagingType,
            countryOfOrigin: customsInformation?.originCountry,
            sixDigitHsCode: customsInformation?.tariffCode,
            qty,
            caseHeight: caseDimensions?.height,
            caseWidth: caseDimensions?.width,
            caseLength: caseDimensions?.length,
            caseWeight: caseDimensions?.weight,
            caseQty,
          };
        });
      if (mixedSkuProductDskus) {
        const filteredBookingProducts = bookingProducts.filter((bookingProduct) =>
          mixedSkuProductDskus.includes(bookingProduct.dsku)
        );
        dispatch(setBookingProducts(filteredBookingProducts));
      } else {
        dispatch(setBookingProducts(bookingProducts));
      }
      return bookingProducts;
    } catch (err) {
      logError(ctx, err, "error fetching products");
      throw err;
    }
  };
