import { OpenSearchService } from "common/search/services/OpenSearchService";
import { SearchOptions } from "@deliverr/logistics-search-client";

export class JobRunsSearchService extends OpenSearchService {
  protected buildSearchOptions(): SearchOptions {
    return {
      query: {},
    };
  }
}
