import { defineMessages } from "react-intl";

export const carrierInputLabels = defineMessages({
  emailDescription: {
    id: "inbounds.shipmentInstructionStep.freight.DeliveryAppointment.title",
    defaultMessage:
      "To receive an email with your <a>Conduit</a> link for scheduling a warehouse delivery appointment, please provide your carrier information. This helps ensure your delivery is accepted smoothly and minimizes the risk of delays.",
  },
  selectCarrier: {
    id: "inbounds.shipmentInstructionStep.freight.DeliveryAppointment.carrier",
    defaultMessage: "Carrier",
  },
  carrierEmail: {
    id: "inbounds.shipmentInstructionStep.freight.DeliveryAppointment.carrierEmail",
    defaultMessage: `Carrier Email`,
  },
  confirmButton: {
    id: "inbounds.shipmentInstructionStep.freight.DeliveryAppointment.confirmButton",
    defaultMessage: "Confirmed",
  },
  invalidEmail: {
    id: "inbounds.shipmentInstructionStep.freight.DeliveryAppointment.emailInput.invalidMessage",
    defaultMessage: `Invalid email`,
  },
  carrierEmailMandatory: {
    id: "inbounds.shipmentInstructionStep.freight.DeliveryAppointment.emailInput.carrierEmailMandatory",
    defaultMessage: `Carrier email is required and without it, your inbound will incur delays`,
  },
  carrierEmailPlaceholder: {
    id: "inbounds.shipmentInstructionStep.freight.DeliveryAppointment.emailInput.placeholder",
    defaultMessage: "Enter your carrier email",
  },
});
