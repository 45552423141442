import history from "BrowserHistory";
import { generatePath } from "react-router-dom";
import { Path } from "paths";
import { DistributionChannel, OrderResponse, OrderStatus } from "@deliverr/replenishment-client";
import { getReplenishmentOrder } from "transfers/create/actions/replenishmentOrder/getReplenishmentOrder";
import { transfersListLabels } from "transfers/listV2/transferShipmentsList.labels";
import { toast } from "react-toastify";
import { redirectToInbounds } from "transfers/detail/store/redirectToInbounds";
import { ReplenishmentIndexRow } from "./ReplenishmentIndexRow";
import { getTransferToEcomDetails } from "transfers/detail/store/getTransferToEcomDetails";
import { isEmpty } from "lodash";

const isShippedFlexportEcomTransfer = (order: OrderResponse): boolean =>
  !isEmpty(order.distributionChannel) &&
  [
    DistributionChannel.ECOM_VIA_CROSSDOCK,
    DistributionChannel.ECOM_DIRECT_TO_FC,
    DistributionChannel.ECOM_VIA_HUB,
  ].includes(order.distributionChannel) &&
  [
    OrderStatus.SHIPPED,
    OrderStatus.DELIVERED,
    OrderStatus.ARRIVED,
    OrderStatus.COMPLETED,
    OrderStatus.RECEIVING,
  ].includes(order.orderStatus);

export const replenishmentListPageOnRowClick = async (item: ReplenishmentIndexRow, formatMessage, dispatch) => {
  // v2 orders are not supported by the replenishment details page
  if (item.version === "v2") {
    await replenishmentDraftOrDetailsRedirect(item.orderId, formatMessage, dispatch);
    return;
  }

  if (item.distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK) {
    if (item.status === OrderStatus.SHIPPED) {
      dispatch(redirectToInbounds(item.distributionChannelOrderId, item.shipments?.[0]?.distributionChannelShipmentId));
      return;
    }
    // storage service orders
    await dispatch(getTransferToEcomDetails(item.orderId));
    return;
  }

  // fallback to wholesale details page
  history.push(generatePath(Path.transferDetails, { orderId: item.orderId }));
};

const replenishmentDraftOrDetailsRedirect = async (orderId: string, formatMessage, dispatch) => {
  try {
    // Can't rely on opensearch index to be up to date, so we need to fetch the order to determine the status
    const replenishmentOrderResponse: OrderResponse = await getReplenishmentOrder(orderId, { includeShipments: true });

    if (
      replenishmentOrderResponse.orderStatus === OrderStatus.CREATED ||
      replenishmentOrderResponse.orderStatus === OrderStatus.DRAFT
    ) {
      history.push(`${generatePath(Path.transfersNew)}?replenishmentOrderId=${orderId}&selectProvider=true`);
      return;
    } else if (isShippedFlexportEcomTransfer(replenishmentOrderResponse)) {
      dispatch(
        redirectToInbounds(
          replenishmentOrderResponse.distributionChannelOrderId,
          replenishmentOrderResponse.shipments?.[0]?.distributionChannelShipmentId
        )
      );
      return;
    } else {
      history.push(generatePath(Path.replenishmentDetails, { orderId }));
      return;
    }
  } catch (err) {
    const message = formatMessage({ ...transfersListLabels.loadingError });

    toast.error(message, {
      autoClose: 5000,
      toastId: "getReplenishmentOrderError",
    });
  }
};
