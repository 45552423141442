import classNames from "classnames/dedupe";
import cls from "./ProductChooser.less";
import React from "react";
import { removeBarcodesDraft } from "barcodes/BarcodeDraft";
import SelectedProduct from "common/components/ProductChooser/SelectedProduct";
import { InfoIcon } from "../icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FormattedMessage } from "react-intl";
import { ProductChooserProps } from "./ProductChooserProps";
import { SelectedProductData } from "./SelectedProductData";
import { ActionCreator } from "common/ReduxUtils";
import styled from "@emotion/styled";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";

const TRANSITION_DURATION_MS = 400;
const SlidingRow = ({ children, ...props }) => (
  <CSSTransition
    {...props}
    timeout={TRANSITION_DURATION_MS}
    classNames={{
      enter: cls.slidingRowEnter,
      enterActive: cls.slidingRowEnterActive,
      exit: cls.slidingRowExit,
      exitActive: cls.slidingRowExitActive,
    }}
  >
    {children}
  </CSSTransition>
);

const parseQty = (rawQty: string): number => {
  const parsedQty = Number(rawQty);
  return isNaN(parsedQty) || parsedQty >= 0 ? parsedQty : 0;
};

export const determineVisibility = (isPromotional: boolean | undefined) => {
  // promotional inbounds should not allow item removal, item search, or enforce 0 qty restriction
  const canRemove = !isPromotional;
  const shouldHideSearchBar = isPromotional;
  const minUnits = isPromotional ? 0 : 1;
  const shouldShowTooltip = !isPromotional;

  return { canRemove, shouldHideSearchBar, shouldShowTooltip, minUnits };
};

export type SelectedProductsListProps = {
  canRemoveProduct?: boolean;
  updateQty: ActionCreator | ((dsku: string, quantity: number) => void);
  showStorageRecommendationAlert?: () => void;
  selectedProductsLotNumbersList?: { [dsku: string]: ATPDetails[] | undefined };
  selectedProductsLotNumbers?: { [dsku: string]: ATPDetails | undefined };
  updateLotNumber?: ActionCreator | ((dsku: string, lotNumber?: string) => void);
} & Pick<
  ProductChooserProps,
  | "selectedProducts"
  | "unapprovedBatteries"
  | "useCasePack"
  | "isCaseQtyReadonly"
  | "hideUnitsColumn"
  | "showEstimatedCosts"
  | "isPromotional"
  | "disabled"
  | "hasMaxValue"
  | "invalidDskus"
  | "isInbound"
  | "shouldShowAvailability"
  | "updateProductDims"
  | "isReserveStorage"
  | "toggleIsUnapprovedBattery"
  | "updateNumberOfCases"
  | "removeProduct"
  | "checkForInvalidProducts"
  | "isOrder"
  | "updateCaseQty"
  | "shouldHideBatteryColumn"
  | "showCasePackSelectionDropdown"
  | "onNewCasePackFormSubmit"
  | "onChangeSelectedProductCasePack"
  | "selectedProductCasePacks"
  | "showOnlyPacks"
  | "shouldHideCasepackSingleUnitWarning"
  | "shouldShowBrandedPackagingAlert"
  | "ShowWarningForProductFn"
  | "showStorageRecommendation"
  | "shouldShowEcomDetailsForStorageProducts"
  | "shouldProductsListScroll"
  | "shouldShowLotNumberDropdown"
  | "shouldShowSIOCWarning"
  | "shouldShowKitHint"
  | "shouldShowSkuQtyLimitWarning"
>;

const StyledScrollArea = styled.div`
  // Since scroll feature now is only applied to removal feature, see the height of the scroll area directly here.
  // If we found we can improve this component to be more generic, we can move this to the props.
  max-height: 22rem;
  overflow-y: auto;
`;

export const SelectedProductsList: React.FC<SelectedProductsListProps> = ({
  selectedProducts,
  unapprovedBatteries,
  useCasePack,
  isCaseQtyReadonly,
  hideUnitsColumn,
  showEstimatedCosts,
  isPromotional,
  disabled,
  hasMaxValue,
  invalidDskus,
  isInbound,
  shouldShowAvailability,
  updateProductDims,
  isReserveStorage,
  toggleIsUnapprovedBattery,
  updateNumberOfCases,
  removeProduct,
  checkForInvalidProducts,
  isOrder,
  updateQty,
  updateCaseQty,
  canRemoveProduct = true,
  shouldHideBatteryColumn,
  showCasePackSelectionDropdown,
  onNewCasePackFormSubmit,
  onChangeSelectedProductCasePack,
  selectedProductCasePacks,
  showOnlyPacks,
  shouldHideCasepackSingleUnitWarning,
  shouldShowBrandedPackagingAlert,
  showStorageRecommendation,
  showStorageRecommendationAlert,
  ShowWarningForProductFn,
  shouldShowEcomDetailsForStorageProducts,
  shouldProductsListScroll,
  shouldShowLotNumberDropdown,
  selectedProductsLotNumbersList,
  updateLotNumber,
  selectedProductsLotNumbers,
  shouldShowSIOCWarning,
  shouldShowKitHint,
  shouldShowSkuQtyLimitWarning,
}) => {
  const { canRemove, shouldShowTooltip, minUnits } = determineVisibility(isPromotional);
  const onRemoveItemClick = async ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
    const sku = currentTarget.dataset.sku;

    if (!sku) {
      return;
    }

    if (!disabled) {
      removeBarcodesDraft(sku);
      removeProduct(sku);
    }
    if (isOrder) {
      await checkForInvalidProducts!(sku);
    }
  };

  const onQtyChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { sku },
      max,
    } = currentTarget;
    const qty = parseQty(value);
    const maxQty = parseQty(max);
    const isAboveMax = hasMaxValue && maxQty && qty > maxQty;

    if (!isNaN(qty) && sku) {
      // eslint-disable-next-line no-extra-boolean-cast
      updateQty(sku, Boolean(isAboveMax) ? maxQty : qty);
    }
  };

  const onCaseQtyChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    const {
      dataset: { sku },
    } = currentTarget;
    const qty = parseQty(currentTarget.value);

    if (updateCaseQty && !isNaN(qty)) {
      updateCaseQty(sku, qty);
    }
  };

  const onNumberOfCasesChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { sku },
      max,
    } = currentTarget;
    const qty = parseQty(value);
    const maxQty = parseQty(max);
    const isAboveMax = hasMaxValue && maxQty && qty > maxQty;

    if (updateNumberOfCases && !isNaN(qty) && sku) {
      updateNumberOfCases(sku, isAboveMax ? maxQty : qty);
    }
  };

  const transitionGroupWithSelectedProducts: JSX.Element = (
    <TransitionGroup>
      {selectedProducts.map((product: SelectedProductData, index) => {
        return (
          <SlidingRow key={product.dsku}>
            <SelectedProduct
              productIndex={index}
              product={product}
              canRemove={canRemove && canRemoveProduct}
              disabled={disabled}
              hasMaxValue={hasMaxValue}
              invalidDskus={invalidDskus}
              isInbound={isInbound}
              isPromotional={isPromotional}
              isUnapprovedBattery={unapprovedBatteries?.[product.dsku]}
              minUnits={minUnits}
              onCaseQtyChange={onCaseQtyChange}
              onNumberOfCasesChange={onNumberOfCasesChange}
              onQtyChange={onQtyChange}
              onRemoveItemClick={onRemoveItemClick}
              showTooltip={shouldShowTooltip}
              toggleIsUnapprovedBattery={unapprovedBatteries ? toggleIsUnapprovedBattery : undefined}
              useCasePack={Boolean(useCasePack)}
              isCaseQtyReadonly={isCaseQtyReadonly}
              showEstimatedCosts={showEstimatedCosts}
              shouldShowAvailableQty={shouldShowAvailability}
              updateProductDims={updateProductDims}
              isReserveStorage={isReserveStorage}
              hideUnitsColumn={hideUnitsColumn}
              shouldHideBatteryColumn={shouldHideBatteryColumn}
              showCasePackSelectionDropdown={showCasePackSelectionDropdown}
              onNewCasePackFormSubmit={onNewCasePackFormSubmit}
              selectedProductCasePack={selectedProductCasePacks?.[product.dsku]}
              onChangeSelectedProductCasePack={onChangeSelectedProductCasePack}
              showOnlyPacks={showOnlyPacks}
              ShowWarningForProductFn={ShowWarningForProductFn}
              shouldHideCasepackSingleUnitWarning={shouldHideCasepackSingleUnitWarning}
              shouldShowBrandedPackagingAlert={shouldShowBrandedPackagingAlert}
              showStorageRecommendationAlert={showStorageRecommendationAlert}
              showStorageRecommendation={showStorageRecommendation}
              shouldShowEcomDetailsForStorageProducts={shouldShowEcomDetailsForStorageProducts}
              shouldShowLotNumberDropdown={shouldShowLotNumberDropdown}
              selectedProductLotNumberList={selectedProductsLotNumbersList?.[product.dsku]}
              onLotNumberChange={updateLotNumber}
              selectedProductLotNumber={selectedProductsLotNumbers?.[product.dsku]}
              shouldShowSIOCWarning={shouldShowSIOCWarning}
              shouldShowKitHint={shouldShowKitHint}
              shouldShowSkuQtyLimitWarning={shouldShowSkuQtyLimitWarning}
            />
          </SlidingRow>
        );
      })}
    </TransitionGroup>
  );

  return (
    <div className={classNames(cls.selectedProducts, cls.form)}>
      {selectedProducts.length > 0 && (
        <div key={"selectedProductsHeader"} className={cls.selectedProductsHeader}>
          <div className={cls.productColumn}>
            <FormattedMessage id="skus" defaultMessage="SKUs" />
          </div>
          {!shouldHideBatteryColumn && unapprovedBatteries && (
            <div className={cls.batteryColumn}>
              <FormattedMessage id="inbounds.productChooser.battery" defaultMessage="Battery" />
              <InfoIcon
                size="small"
                placement="top"
                tooltipProps={{
                  overlayClassName: cls.batteryTooltip__overlay,
                }}
                message={
                  <FormattedMessage
                    id="inbounds.productChooser.batteryTooltipText"
                    defaultMessage="Approval is required for lithium batteries. Alkaline batteries do not require approval."
                  />
                }
              />
            </div>
          )}
          {shouldShowLotNumberDropdown && (
            <div className={cls.lotNumberDropdownColumn}>
              <FormattedMessage id="inbounds.productChooser.lotNumber" defaultMessage="Lot Number" />
            </div>
          )}
          {useCasePack && (
            <div className={showCasePackSelectionDropdown ? cls.casePackDropdownColumn : cls.caseQtyColumn}>
              {showCasePackSelectionDropdown ? (
                <FormattedMessage id="inbounds.productChooser.casePackDetails" defaultMessage="Packing details" />
              ) : (
                <>
                  <FormattedMessage id="inbounds.productChooser.unitsPerCase" defaultMessage="Units per Box" />
                  <InfoIcon
                    size="small"
                    wrap
                    placement="top"
                    message={
                      <FormattedMessage
                        id="inbounds.productChooser.unitsPerCase.info"
                        defaultMessage="This is the number of sellable units in each case."
                      />
                    }
                  />
                </>
              )}
            </div>
          )}
          {useCasePack ? (
            <div className={cls.numOfCasesColumn}>
              <FormattedMessage id="inbounds.productChooser.numOfCases" defaultMessage="Boxes" />
            </div>
          ) : (
            !hideUnitsColumn && (
              <div className={cls.qtyColumn}>
                <FormattedMessage id="units" defaultMessage="Units" />
              </div>
            )
          )}
          {useCasePack && (
            <div className={cls.totalQtyColumn}>
              {showCasePackSelectionDropdown ? (
                <FormattedMessage id="inbounds.productChooser.totalUnits" defaultMessage="Units" />
              ) : (
                <FormattedMessage id="inbounds.productChooser.total" defaultMessage="Total" />
              )}
            </div>
          )}
          {showEstimatedCosts && (
            <div className={cls.costEstimateColumn}>
              <FormattedMessage id="inbounds.productChooser.costEstimate" defaultMessage="Fulfillment Fee" />
            </div>
          )}
          <div className={cls.removeItemColumn} />
        </div>
      )}

      {shouldProductsListScroll ? (
        <StyledScrollArea>{transitionGroupWithSelectedProducts}</StyledScrollArea>
      ) : (
        transitionGroupWithSelectedProducts
      )}
    </div>
  );
};
