import { SellerProblemStatus, SellerProblemsDetailView } from "@deliverr/inbound-client";

import { ProblemType } from "common/problems/ProblemType";
import { UnexpectedSkuProblemRow } from "common/components/ProblemsTable/types";
import { UnexpectedSkuCaseSubtype } from "@deliverr/commons-clients/lib/non-compliance/UnexpectedSkuCaseV2";

export const getShipmentProblemsFromUnexpectedSkuCases = (
  cases?: SellerProblemsDetailView[]
): UnexpectedSkuProblemRow[] => {
  const sellerProblemsDetailViewMap = new Map<string, SellerProblemsDetailView>();

  (cases ?? []).forEach((sellerProblemsDetailView) => {
    const key = `${sellerProblemsDetailView.dsku}-${sellerProblemsDetailView.sellerAsnLabel}-${sellerProblemsDetailView.subType}`;
    const existingSellerProblemsDetailView = sellerProblemsDetailViewMap.get(key);

    if (
      existingSellerProblemsDetailView &&
      sellerProblemsDetailView.receivedUnits &&
      existingSellerProblemsDetailView.receivedUnits
    ) {
      existingSellerProblemsDetailView.receivedUnits += sellerProblemsDetailView.receivedUnits;
    } else {
      sellerProblemsDetailViewMap.set(key, { ...sellerProblemsDetailView });
    }
  });

  const deduplicatedCases = Array.from(sellerProblemsDetailViewMap.values());

  return deduplicatedCases.map((sellerProblemsDetailView) => {
    return {
      id: sellerProblemsDetailView.sellerProblemId?.toString() ?? sellerProblemsDetailView.issueId!,
      isLegacyCase: sellerProblemsDetailView.isLegacyCase,
      problemType:
        sellerProblemsDetailView.subType === UnexpectedSkuCaseSubtype.UNEXPECTED_SKU
          ? ProblemType.UNEXPECTED_SKU
          : ProblemType.MISMATCH_CASE_QTY,
      shippingPlanId: sellerProblemsDetailView.shippingPlanId,
      shippingPlanName: sellerProblemsDetailView.shippingPlanName,
      status: SellerProblemStatus.RESOLVED,
      createdAt: sellerProblemsDetailView.createdAt,
      updatedAt: sellerProblemsDetailView.updatedAt,
      barcode: sellerProblemsDetailView.barcode ?? "",
      dsku: sellerProblemsDetailView.dsku,
      cdsku: "",
      units: sellerProblemsDetailView.receivedUnits,
      mappedSkus: [{ dsku: sellerProblemsDetailView.dsku ?? "", msku: "", productName: "" }],
      shipmentId: sellerProblemsDetailView.shipmentId!,
      acknowledgedAt: new Date(),
    };
  });
};
