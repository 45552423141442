import { defineMessages, MessageDescriptor } from "react-intl";
import { ReplenishmentDestinationType } from "./store/ReplenishmentDestinationType";
import { ReplenishmentOrderListTabFilter } from "./store/ReplenishmentListState";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { UNKNOWN_DISTRIBUTION_CHANNEL } from "./ReplenishmentListUtils";

export const ReplenishmentListDestinationFilterLabels: Record<ReplenishmentDestinationType, MessageDescriptor> =
  defineMessages({
    [ReplenishmentDestinationType.ALL]: {
      id: "replenishment.list.destinationFilter.all",
      defaultMessage: "All Destinations",
    },
    [ReplenishmentDestinationType.FBA]: {
      id: "replenishment.list.destinationFilter.fba",
      defaultMessage: "Amazon (FBA)",
    },
    [ReplenishmentDestinationType.FLEXPORT_ECOM]: {
      id: "replenishment.list.destinationFilter.flexportEcom",
      defaultMessage: "Flexport Ecom",
    },
    [ReplenishmentDestinationType.WFS]: {
      id: "replenishment.list.destinationFilter.wfs",
      defaultMessage: "Walmart (WFS)",
    },
  });

export const ReplenishmentBannerMessages: Record<string, MessageDescriptor> = defineMessages({
  amazonTitle: {
    id: "replenishment.banner.title.amazon",
    defaultMessage: "Replenish inventory to Amazon's fulfillment network (FBA)",
  },
  new: {
    id: "replenishment.banner.pill",
    defaultMessage: "New",
  },
  getStarted: {
    id: "replenishment.banner.subText",
    defaultMessage: "Add the integration to get started.",
  },
  addIntegration: {
    id: "replenishment.banner.add",
    defaultMessage: "Add integration",
  },
});

export const ReplenishmentCancelOrderModalMessages: Record<string, MessageDescriptor> = defineMessages({
  modalDeleteReplenishmentOrderTitle: {
    id: "replenishment.deleteOrderModal.title",
    defaultMessage: "Delete Transfer",
  },
  modalDeleteReplenishmentOrderText: {
    id: "replenishment.deleteOrderModal.body",
    defaultMessage:
      "Are you sure you want to delete {length, plural, one {transfer <bold>{orderIds}</bold>} other {<bold>{length}</bold> selected transfers}}? This action cannot be undone.",
  },
  modalDeleteReplenishmentOrderConfirmAction: {
    id: "replenishment.deleteOrderModal.confirm",
    defaultMessage: "Delete Transfer",
  },
  modalDeleteReplenishmentOrderDenyAction: {
    id: "replenishment.deleteOrderModal.deny",
    defaultMessage: "Keep Transfer",
  },
});

export const ReplenishmentListLabels = defineMessages({
  searchPlaceholder: {
    id: "replenishment.list.searchPlaceholder",
    defaultMessage: "Search Transfers",
  },
  emptyStateTitle: {
    id: "replenishment.emptyList.title",
    defaultMessage: "Put restocking on autopilot",
  },
  emptyStateMessage: {
    id: "replenishment.emptyList.message",
    defaultMessage:
      "Store your inventory at a fraction of the cost, and get smart, timely product replenishment from Reserve Storage to Flexport, Amazon, and Walmart fulfillment centers.",
  },
  emptyStateTransferButton: {
    id: "replenishment.emptyList.emptyStateTransferButton",
    defaultMessage: "Create Transfer",
  },
  emptyCreateInboundButton: {
    id: "replenishment.emptyList.emptyStateTransferButton",
    defaultMessage: "Send inventory to reserve storage",
  },
  createTransferButton: {
    id: "replenishment.emptyList.createTransfer",
    defaultMessage: "Create Transfer",
  },
  manageRecommendationSettingsButton: {
    id: "replenishment.list.manageRecommendationSettingsButton",
    defaultMessage: "Manage recommendation settings",
  },
  recommendationTooltip: {
    id: "replenishment.list.recommendationTooltip",
    defaultMessage: "This is recommended based on your replenishment preferences.<a>Manage preferences</a>",
  },
  recommendedTransferNotificationTitle: {
    id: "replenishment.list.recommendedTransferNotificationTitle",
    defaultMessage: "You have a recommended transfer",
  },
  recommendedTransferNotificationDesc: {
    id: "replenishment.list.recommendedTransferNotificationDesc",
    defaultMessage: "Review and confirm this transfer to avoid stocking out.",
  },
  tableCellShipmentId: {
    id: "replenishment.list.cell.orderId",
    defaultMessage: "Transfer {orderId}",
  },
  tableCellCreationDate: {
    id: "replenishment.list.cell.creationDate",
    defaultMessage: "Created {date}",
  },
  tableHeader: {
    shipment: {
      id: "replenishment.list.header.shipment",
      defaultMessage: "Shipment",
    },
    poNumber: {
      id: "replenishment.list.header.poNumber",
      defaultMessage: "PO#",
    },
    origin: {
      id: "replenishment.list.header.origin",
      defaultMessage: "Origin",
    },
    destination: {
      id: "replenishment.list.header.destination",
      defaultMessage: "Destination",
    },
    status: {
      id: "replenishment.list.header.status",
      defaultMessage: "Status",
    },
    skus: {
      id: "replenishment.list.header.skus",
      defaultMessage: "SKUs",
    },
    expectedUnits: {
      id: "replenishment.list.header.expectedUnits",
      defaultMessage: "Expected units",
    },
  },
  loadingError: {
    id: "replenishment.list.loadingError",
    defaultMessage: "An error occurred while loading the data.",
  },
  cancelError: {
    id: "replenishment.list.cancelError",
    defaultMessage: "The order could not be cancelled. Please contact support",
  },
  originFlexportReserve: {
    id: "replenishment.list.origin.deliverrReserve",
    defaultMessage: "Flexport Reserve",
  },
  cancelOrderButton: {
    id: "replenishment.list.order.cancel",
    defaultMessage: "Cancel Transfer",
  },
  deleteOrderButton: {
    id: "replenishment.list.order.delete",
    defaultMessage: "Delete Transfer",
  },
  multiple: {
    id: "replenishment.list.po.multiple",
    defaultMessage: "Multiple",
  },
  multipleDestination: {
    id: "replenishment.list.destination.multiple",
    defaultMessage: "Multiple Locations",
  },
});

export const ReplenishmentListDestinationLabels = defineMessages({
  [DistributionChannel.ECOM_DIRECT_TO_FC]: {
    id: "replenishment.list.destinationLabel.ECOM_DIRECT_TO_FC",
    defaultMessage: "{destinationWarehouseId}",
  },
  [DistributionChannel.ECOM_VIA_CROSSDOCK]: {
    id: "replenishment.list.destinationLabel.ECOM_VIA_CROSSDOCK",
    defaultMessage: "Flexport Ecom",
  },
  [DistributionChannel.ECOM_VIA_HUB]: {
    id: "replenishment.list.destinationLabel.ECOM_VIA_HUB",
    defaultMessage: "Flexport Ecom",
  },
  [DistributionChannel.FBA]: {
    id: "replenishment.list.destinationLabel.FBA",
    defaultMessage: "Amazon (FBA)",
  },
  [DistributionChannel.FBA_MANUAL]: {
    id: "replenishment.list.destinationLabel.FBA_MANUAL",
    defaultMessage: "Amazon (FBA)",
  },
  [DistributionChannel.FBA_V3]: {
    id: "replenishment.list.destinationLabel.FBA_MANUAL",
    defaultMessage: "Amazon (FBA)",
  },
  [DistributionChannel.WHOLESALE]: {
    id: "replenishment.list.destinationLabel.WHOLESALE",
    defaultMessage: "Wholesale",
  },
  [DistributionChannel.WFS]: {
    id: "replenishment.list.destinationLabel.wfs",
    defaultMessage: "Walmart (WFS)",
  },
  [UNKNOWN_DISTRIBUTION_CHANNEL]: {
    id: "replenishment.list.destinationLabel.unknown",
    defaultMessage: "Unknown",
  },
});

export const ReplenishmentListOrderNames = defineMessages({
  replenishmentToFlexportEcom: {
    id: "replenishment.list.orderName.replenishmentToEcom",
    defaultMessage: "Transfer to Flexport Ecom ({ecomOrderType}) {orderId}",
  },
  replenishmentToAmazon: {
    id: "replenishment.list.orderName.replenishmentToAmazon",
    defaultMessage: "Transfer to Amazon {orderId}",
  },
  replenishmentToWalmart: {
    id: "replenishment.list.destinationFilter.replenishmentToWalmart",
    defaultMessage: "Transfer to Walmart {orderId}",
  },
  unknownDestination: {
    id: "replenishment.list.orderName.unknown",
    defaultMessage: "Transfer {orderId}",
  },
});

export const ReplenishmentTabFilterMessages: Record<ReplenishmentOrderListTabFilter, MessageDescriptor> =
  defineMessages({
    [ReplenishmentOrderListTabFilter.ALL_ORDERS]: {
      id: `ReplenishmentList.tabFilter.ALL_ORDERS`,
      defaultMessage: "All orders",
    },
    [ReplenishmentOrderListTabFilter.HAS_PROBLEM]: {
      id: `ReplenishmentList.tabFilter.HAS_PROBLEM`,
      defaultMessage: "Has problem",
    },
  });
