import { Domain, UserScopeMapping } from "./UserScope";
import { FeatureName, useFeatureOn } from "common/Split";

import { Role } from "common/clients/seller/User/Role";
import { RootState } from "RootReducer";
import { UserScope } from "@deliverr/business-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useShouldHideIfSellerIsSuspended } from "common/organization/useShouldHideIfSellerIsSuspended";

export const useUserScopeMapping = () => {
  const currentUser = useSelector((state: RootState) => state.user);

  const currentUserInOrg = useSelector((state: RootState) =>
    state.organization.users.find((user) => user.email === currentUser.email)
  );

  const isUserScopesFeatureFlagOn = useFeatureOn(FeatureName.UserScopes);
  const { shouldHideIfSellerIsSuspended } = useShouldHideIfSellerIsSuspended();

  const userScopeMapping = useMemo(
    () =>
      getUserScopesMappingFromUserScopeArray(
        currentUserInOrg?.userScopes,
        isUserScopesFeatureFlagOn,
        currentUser.isAdmin || currentUserInOrg?.role === Role.ADMIN,
        shouldHideIfSellerIsSuspended
      ),
    [currentUserInOrg, currentUser, isUserScopesFeatureFlagOn]
  );
  return userScopeMapping;
};

export const getUserScopesMappingFromUserScopeArray = (
  scopes: UserScope[] | undefined,
  isUserScopesFeatureFlagOn: boolean,
  isAdmin: boolean,
  shouldHideIfSellerIsSuspended: boolean
) => {
  const userScopesMapping: UserScopeMapping = getDefaultUserScopeMapping(isUserScopesFeatureFlagOn, isAdmin);

  if (shouldHideIfSellerIsSuspended) {
    return getSuspendedUserScopeMapping(userScopesMapping);
  }

  if (!scopes) {
    return userScopesMapping;
  }

  scopes.forEach((scope) => {
    const lastUnderscoreIndex = scope.lastIndexOf("_");
    const domain = scope.substring(0, lastUnderscoreIndex) as Domain;
    const permission = scope.substring(lastUnderscoreIndex + 1);

    switch (permission) {
      case "READ":
        userScopesMapping[domain].read = true;
        break;
      case "WRITE":
        userScopesMapping[domain].write = true;
        break;
      case "MAIL":
        userScopesMapping[domain].mail = true;
        break;
      default:
        break;
    }
  });

  return userScopesMapping;
};

export const getDefaultUserScopeMapping = (isUserScopesFeatureFlagOn: boolean, isAdmin: boolean) => {
  const userScopesMapping: UserScopeMapping = {} as UserScopeMapping;
  Object.values(Domain).forEach((domain) => {
    // okay to set mail to false as it's won't be accessed by code for any contact areas from this mapping, unless the feature flag is on.
    userScopesMapping[domain] = {
      read: !isUserScopesFeatureFlagOn || isAdmin,
      write: !isUserScopesFeatureFlagOn || isAdmin,
      mail: false,
    };
  });

  if (!isAdmin) {
    userScopesMapping.BILLING.read = false;
    userScopesMapping.BILLING.write = false;
  }
  return userScopesMapping;
};

export const getSuspendedUserScopeMapping = (userScopesMapping: UserScopeMapping) => {
  const domainsToSetFalse: Domain[] = [
    Domain.INBOUNDS,
    Domain.NON_COMPLIANCE,
    Domain.CLAIMS,
    Domain.ORDERS,
    Domain.INVENTORY,
    Domain.REPLENISHMENT,
    Domain.WHOLESALE,
    Domain.RETURNS,
    Domain.PREP,
    Domain.PARCEL,
    Domain.FREIGHT,
    Domain.FAST_TAGS,
    Domain.INTEGRATIONS,
  ];
  domainsToSetFalse.forEach((domain) => {
    userScopesMapping[domain].read = false;
    userScopesMapping[domain].write = false;
  });
  return userScopesMapping;
};
