import React from "react";
import { StorageShipmentDetail } from "./StorageShipmentDetail/StorageShipmentDetail";
import Spinner from "common/components/Spinner";
import { useStorageDetail } from "./useStorageDetail";
import { Container } from "common/components/Container";
import styled from "@emotion/styled";
import { StorageShipmentConfirmation } from "./StorageShipmentConfirmation/StorageShipmentConfirmation";
import { Box, ThemeProps } from "common/components/ui";
import { StorageShipmentContents } from "./StorageShipmentContent/StorageShipmentContents";
import { StorageShipmentInstructions } from "./StorageShipmentInstructions";
import { StorageInboundStatusHistory } from "./StorageInboundStatusHistory";
import { ShipmentProblemsCard } from "./StorageShipmentProblems/ShipmentProblemsCard";

const SpinnerContainer = styled.div<ThemeProps>(
  ({ theme }) => `
margin: 45vh auto 0 auto;
width: ${theme.spacing.S6};
`
);

const StorageInboundDetail: React.FC = () => {
  const { isLoading, shouldShowShipmentInstructions, storageInboundOrder } = useStorageDetail();
  const minHeight = "800px";
  return (
    <Container minHeight={minHeight}>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <div>
          <Box marginLeft="S3" marginRight="S3" marginTop="S7" marginBottom={null}>
            <StorageShipmentDetail />
          </Box>
          <Box marginLeft="S3" marginRight="S3" marginTop={null} marginBottom={null}>
            <StorageInboundStatusHistory storageInboundOrder={storageInboundOrder} />
          </Box>
          <Box marginLeft="S3" marginRight="S3" marginTop="S5" marginBottom={null}>
            <StorageShipmentConfirmation />
          </Box>
          {shouldShowShipmentInstructions && (
            <Box marginLeft="S3" marginRight="S3" marginTop="S5" marginBottom={null}>
              <StorageShipmentInstructions />
            </Box>
          )}
          <Box marginLeft="S3" marginRight="S3" marginTop="S5" marginBottom={null}>
            <ShipmentProblemsCard />
          </Box>
          <Box marginLeft="S3" marginRight="S3" marginTop="S5" marginBottom="S9">
            <StorageShipmentContents />
          </Box>
        </div>
      )}
    </Container>
  );
};

export default StorageInboundDetail;
