import { AddReceivingInfoFormRenderer } from "inbounds/steps/ship/view/cards/confirmation/LtlExternalConfirmation/ReceivingInfo/AddReceivingInfoForm";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSendAndTrackShipmentDetail } from "./useSendAndTrackShipmentDetail";
import styled from "@emotion/styled";
import { Box, ThemeProps, Text, BoxReset, Input } from "common/components/ui";
import { DatePicker } from "common/components/DatePicker";
import { DayOfWeekAndMonth } from "common/date/DateFormat";

const SpacedText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

const SpacedLabel = styled.label<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
  `
);

const WideInput = styled(Input)`
  width: 100%;
`;

const StyledDatePicker = styled(DatePicker)<ThemeProps>(
  ({ theme }) => `
  &.overrideDatePicker {
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: ${theme.border.radius.R2};
  height: ${theme.spacing.S7};
  margin-bottom: ${theme.spacing.S2};
}

> span {
  color: ${theme.colors.NEUTRAL[300]};
  font-size: ${theme.font.size.F2};
}
`
);

export const SendAndTrackShipment: FC = () => {
  const {
    bolId,
    setBolId,
    proId,
    setProId,
    bolIdPlaceholder,
    proIdPlaceholder,
    containerIdPlaceholder,
    submitDisabled,
    onSubmit,
    date,
    onDateChange,
    minDate,
    isFlexport,
    submitButtonMessage,
    containerId,
    updateContainerId,
  } = useSendAndTrackShipmentDetail();

  const containerIdInputMarkup = (
    <div>
      <SpacedLabel>
        <FormattedMessage
          id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.containerIdMarkup"
          defaultMessage={"Container ID"}
        />
      </SpacedLabel>
      <WideInput
        name="containerId"
        value={containerId}
        placeholder={containerIdPlaceholder}
        onChange={(e) => updateContainerId(e)}
      />
    </div>
  );

  const shipDateInputMarkUp = (
    <Box marginBottom={"S3"}>
      <BoxReset marginBottom="S2">
        <Text size="label">
          <FormattedMessage
            id="storage.inbounds.details.sendAndTrackShipment.inputs.shipDate"
            defaultMessage="Ship Date"
          />
        </Text>
      </BoxReset>
      <StyledDatePicker
        hideRemoveButton={true}
        format={DayOfWeekAndMonth}
        date={date}
        minDate={minDate}
        onDateChange={onDateChange}
        className={"overrideDatePicker"}
        nextYearButton={null}
        prevYearButton={null}
      />
    </Box>
  );

  const titleOverride = (
    <SpacedText>
      <FormattedMessage
        id="inbounds.shipmentInstructionStep.freight.trackShipment.title"
        defaultMessage={`Providing tracking information helps ensure your shipment is received on time. If you do not have this information, you can provide it during the warehouse appointment process.`}
      />
    </SpacedText>
  );

  const props = {
    isFlexport,
    bolId,
    setBolId,
    proId,
    setProId,
    bolIdPlaceholder,
    proIdPlaceholder,
    submitDisabled,
    onSubmit,
    shipDateInputMarkUp,
    containerIdInputMarkup,
    shouldHideHeader: true,
    titleOverride,
    submitButtonMessage,
  };

  return <AddReceivingInfoFormRenderer {...props} />;
};
