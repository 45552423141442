import { Checkbox, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import HtmlToReact from "common/components/HtmlToReact";
import React from "react";
import { FormattedMessage } from "react-intl";
import { packingInstructionsLabels } from "./packingInstructions.labels";
import LabelledCaseSvg from "assets/img/inbounds/packing-instructions-flc.svg";

const StyledFigure = styled.figure<ThemeProps>(
  ({ theme }) => `
    position: relative;
    margin: ${theme.spacing.S6} auto 0 auto;
    height: 280px;
    width: 830px;
  `
);

const StyledHtmlToReact = styled(HtmlToReact)<ThemeProps>(
  ({ theme }) => `
    margin: auto ${theme.spacing.S9} auto ;
    width: 75%;
  `
);

const LabelsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    width: 830px;
    height: 292.53px;
    figcaption {
      font-size: ${theme.font.size.F2};
      line-height: ${theme.font.lineHeight.LH2};
      position: absolute;
    };
  `
);

const FigCaptionCasePack = styled.figcaption`
  width: 154px;
  left: 0;
  top: 4px;
`;

const CheckboxContainer = styled.div`
  padding: 16px;
  background-color: #fafbfc;
  border: 1px solid #dae1e8;
`;

const Description = styled.div`
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
`;
export interface CasePackingInstructionsProps {
  instructionOverride?: JSX.Element;
}

export const CasePackingInstructions: React.FC<CasePackingInstructionsProps> = ({ instructionOverride }) => {
  return (
    <>
      <Description>
        <FormattedMessage {...packingInstructionsLabels.description} />
      </Description>
      <StyledFigure>
        <StyledHtmlToReact key="storage.inbounds.detail.casePackingInstructions" html={LabelledCaseSvg} />
        <LabelsContainer>
          <FigCaptionCasePack>
            {instructionOverride ?? <FormattedMessage {...packingInstructionsLabels.casePackLabel} />}
          </FigCaptionCasePack>
        </LabelsContainer>
      </StyledFigure>
      <CheckboxContainer>
        <Checkbox
          defaultChecked={true}
          disabled={true}
          label={
            <FormattedMessage
              id="inbounds.shipmentInstructions.palletPackingInstructions.checkboxLabel"
              defaultMessage="I understand that my shipment will be turned away if it does not comply with the above guidelines."
            />
          }
          labelStyles={`color: #45505B`}
        />
      </CheckboxContainer>
    </>
  );
};
