import { useDispatch, useSelector } from "react-redux";
import { transferCreateUpdateProductLotNumber } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const useFbaLotExpiryPicker = (dsku: string) => {
  const dispatch = useDispatch();

  const { selectedProductsLotNumbers } = useSelector(selectTransferCreate);
  const expirationDate = selectedProductsLotNumbers?.[dsku]?.expirationDate ?? undefined;

  const onExpiryChange = (date: Date) => {
    return dispatch(
      transferCreateUpdateProductLotNumber(dsku, {
        expirationDate: date,
      })
    );
  };

  return {
    onExpiryChange,
    expirationDate,
  };
};
