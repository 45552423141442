import React, { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { defaultTheme, Stack, Text, useMobile } from "common/components/ui";
import isNil from "lodash/isNil";
import { Redirect } from "react-router";

import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { UnknownBarcodeCaseContext } from "../context";
import { ShippingPlanSummary } from "inbounds/components/ShippingPlanSummary";
import {
  NonComplianceCard,
  NonComplianceCaseSummary,
  NonComplianceSection,
  NonComplianceTitle,
  TextWithBottomMargin,
  NonComplianceRadioTileGroup,
} from "inbounds/non-compliance/components/NonComplianceStyles";
import { NonComplianceProductPhotoSection } from "inbounds/non-compliance/components/NonComplianceProductPhotoSection";
import { NonComplianceFooter } from "inbounds/non-compliance/components/NonComplianceFooter";
import { NonComplianceFlowSteps } from "inbounds/non-compliance/components/NonComplianceFlowSteps";
import { NonComplianceDismissSection } from "inbounds/non-compliance/components/NonComplianceDismissSection";
import { nonComplianceDynamicRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { ProblemType } from "common/problems/ProblemType";
import { SellerProblemStatus } from "@deliverr/inbound-client";
import { useDemoMode } from "common/hooks/useDemoMode";

export const UnknownBarcodeCase: FC = () => {
  const { isDemoMode } = useDemoMode();

  const {
    isRecognizedProduct,
    setIsRecognizedProduct,
    onDismiss,
    isOnDismissLoading,
    stepMetadata,
    ncProduct,
    isNCProductLoading,
    shippingPlan,
  } = useContext(UnknownBarcodeCaseContext);

  const isMobile = useMobile();

  if (isNCProductLoading || isNil(ncProduct)) {
    return <SimpleLoader />;
  }

  // redirect if case was already resolved
  if (ncProduct.status !== SellerProblemStatus.CREATED && ncProduct.status !== SellerProblemStatus.ACTION_NEEDED) {
    return (
      <Redirect
        to={nonComplianceDynamicRoutes.viewCompletedCase.parse({
          nonComplianceType: ProblemType.UNKNOWN_BARCODE,
          nonCompliantId: ncProduct.sellerProblemId,
        })}
      />
    );
  }

  const { rawPhotoKeys, barcode } = ncProduct;

  return (
    <NonComplianceCard>
      <NonComplianceCaseSummary>
        <NonComplianceSection>
          <NonComplianceTitle displayAs="h4">
            <FormattedMessage id="nonCompliance.unknownBarcode.title" defaultMessage="Identify Unknown Barcode" />
          </NonComplianceTitle>
        </NonComplianceSection>
        <NonComplianceSection>
          <Text>
            <FormattedMessage
              id="nonCompliance.unknownBarcode.description"
              defaultMessage="Our fulfillment centers were unable to receive this product because the barcode is not on the shipping plan."
            />
          </Text>
        </NonComplianceSection>
        {shippingPlan && (
          <NonComplianceSection>
            <ShippingPlanSummary shippingPlan={shippingPlan} />
          </NonComplianceSection>
        )}
        {!isDemoMode && rawPhotoKeys.length > 0 && (
          <NonComplianceSection>
            <TextWithBottomMargin bold margin={defaultTheme.spacing.S4}>
              <FormattedMessage id="nonCompliance.productPhotosTitle" defaultMessage="Product photos" />
            </TextWithBottomMargin>
            <Stack gap="S2">
              <NonComplianceProductPhotoSection photos={rawPhotoKeys} isSmall={isMobile} />
            </Stack>
          </NonComplianceSection>
        )}
        <NonComplianceSection>
          <TextWithBottomMargin bold>
            <FormattedMessage id="nonCompliance.unknownBarcodeTitle" defaultMessage="Unknown barcode" />
          </TextWithBottomMargin>
          <Text>{barcode}</Text>
        </NonComplianceSection>
        <NonComplianceSection>
          <TextWithBottomMargin bold margin={defaultTheme.spacing.S2}>
            <FormattedMessage id="nonCompliance.identifyProductTitle" defaultMessage="Is this your product?" />
          </TextWithBottomMargin>
          <NonComplianceRadioTileGroup
            currentValue={isRecognizedProduct as any}
            direction="HORIZONTAL"
            onChange={setIsRecognizedProduct}
            options={
              [
                {
                  label: <FormattedMessage id="yes" defaultMessage="Yes" />,
                  value: true,
                },
                {
                  label: <FormattedMessage id="no" defaultMessage="No" />,
                  value: false,
                },
              ] as any
            }
          />
        </NonComplianceSection>
      </NonComplianceCaseSummary>
      {isRecognizedProduct ? (
        <NonComplianceFlowSteps {...stepMetadata} />
      ) : (
        <NonComplianceDismissSection onDismiss={onDismiss} isOnDismissLoading={isOnDismissLoading} />
      )}
      <NonComplianceFooter />
    </NonComplianceCard>
  );
};
