import { removeAllEmTags } from "common/StringUtils";
import { Pack } from "common/clients/storage/Packs/Pack";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { extractPacksDims } from "storage/packUtils/extractPacksDims";
import { getNumberOrZeroFromString } from "common/utils/string/getNumberOrZeroFromString";

interface PackInfoProps {
  product: Pick<Pack, "caseQty" | "weight" | "width" | "height" | "length" | "packName">;
}

export const PackInfo: FC<PackInfoProps> = ({ product }) => {
  const packDims = extractPacksDims(product);

  return (
    <>
      {product.packName ? (
        <FormattedMessage
          id="productSearchOption.packWithName.caseQty"
          defaultMessage="{packName} · {height} x {width} x {length} in · {weight, plural, one {# lb} other {# lbs}}"
          values={{
            ...packDims,
            packName: removeAllEmTags(product.packName),
          }}
        />
      ) : (
        <FormattedMessage
          id="productSearchOption.packWithoutName.caseQty"
          defaultMessage="{caseQty, plural, one {# unit} other {# units}} per box · {height} x {width} x {length} in · {weight, plural, one {# lb} other {# lbs}}"
          values={{
            ...packDims,
            caseQty: getNumberOrZeroFromString(product.caseQty?.toString()),
          }}
        />
      )}
    </>
  );
};
