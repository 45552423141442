import { PriceItem, ServiceLevel } from "@deliverr/billing-pricer-client";
import { productClient } from "Clients";
import { onboardingClientV2 } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { createListActions, ListType } from "common/list";
import { Sort } from "common/models";
import { createActionCreator, SPThunkAction } from "common/ReduxUtils";
import { logError, logStart } from "Logger";
import { toast } from "common/components/ui";

import { LocalStorageKey, setItemInLocalStorage } from "common/LocalStorage";
import { batch } from "react-redux";
import { InventoryListActionTypes } from "./InventoryListActionTypes";
import { CountryCode } from "@deliverr/commons-objects";
import { getApprovedHazmatInfo } from "common/utils/product/getApprovedHazmatInfo";
import { getMultipleBundleEstimatedCosts, getMultipleEstimatedCostsPerUnitDetail } from "common/ProductUtils";
import { defineMessages, FormattedMessage } from "react-intl";
import React from "react";

import { BundleListItem, InventoryListTab, ProductListItem } from "./InventoryListReducer";
import { getProductDimensions } from "common/components/EstimatedCosts/EstimatedCost";
import { fetchBskuToAvailableQty } from "inventory/fetchBskuToAvailableQty";
import { JobStatusName } from "common/clients/onboarding/JobStatus/JobStatusName";
import { InventoryLocationFilterOptions } from "./InventoryListTableControls/InventoryListFilters/InventoryLocationFilter";
import { FeatureName, isFeatureOn } from "common/Split";
import { updateResultsPerPage } from "../../common/components/lists/Table/ResultsPerPageActions";
import { getInventoryListFilters } from "./InventoryListActionsSearchFilters";
import { sumBy, zipObject } from "lodash";

export const SYNC_PRODUCTS_INTERVAL = 1000 * 10; // 10 seconds

export const {
  getSearchService: getInventorySearchService,
  searchIndex: searchProductIndex,
  setHitChecked: setProductChecked,
  setRowExpand: setProductListRowExpand,
  setPageChecked: setProductListPageChecked,
  setRemovalPagePartiallyChecked,
  setSort: setProductListSort,
  setPage: setProductListPage,
  clearAllChecked: clearAllCheckedProducts,
  clearAllExpanded: clearAllExpandedProducts,
  clearSearchCache: clearProductSearchCache,
} = createListActions(ListType.Inventory);

export const {
  getSearchService: getBundleSearchService,
  searchIndex: searchBundleIndex,
  clearSearchCache: clearBundleSearchCache,
  setPage: setBundleListPage,
  setSort: setBundleListSort,
} = createListActions(ListType.Bundle);

export const { searchIndex: searchJobRunsIndex, clearSearchCache: clearJobRunsSearchCache } = createListActions(
  ListType.JobRuns
);

export const BRANDED_PACKAGING_TAG = "BRANDED_PACKAGING_DSKU";
export const MULTI_CASE_PACK_TAG = "PACK";
export const BRANDED_PACKAGING_FILTER = `NOT _tags:${BRANDED_PACKAGING_TAG}`;
export const MULTI_CASE_PACK_FILTER = `NOT _tags:${MULTI_CASE_PACK_TAG}`;

export const searchProducts =
  (searchTerm?: string): SPThunkAction =>
  async (dispatch, getState) => {
    const {
      inventoryList: {
        isActive,
        searchTerm: currentSearchTerm,
        productList: { page, sort },
        inventoryLocation,
        currentTab,
      },
    } = getState();
    batch(() => {
      dispatch(addLoader(InventoryListActionTypes.INVENTORY_LIST_LOADER));
      dispatch(clearProductSearchCache());
      dispatch(clearAllExpandedProducts());
      dispatch(clearJobRunsSearchCache());
    });

    const { filters, numericFilter, customizedOpenSearchFilters } = getInventoryListFilters({
      searchTerm: searchTerm ?? currentSearchTerm,
      isActive,
      inventoryLocation,
      currentTab,
    });
    await dispatch(
      searchProductIndex({
        searchTerm: searchTerm ?? currentSearchTerm,
        filters,
        numericFilter,
        customizedOpenSearchFilters,
        clearCacheOnSearch: true,
        page,
        sort,
      })
    );

    await dispatch(searchJobRunsIndex({}));

    dispatch(estimateProductListCosts());
    if (isFeatureOn(FeatureName.StorageMultiCasePack)) {
      dispatch(calculateMultiCasePackStorageInventory());
    }
    dispatch(clearLoader(InventoryListActionTypes.INVENTORY_LIST_LOADER));
  };

export const searchBundles =
  (searchTerm?: string): SPThunkAction =>
  async (dispatch, getState) => {
    const {
      inventoryList: {
        isActive,
        searchTerm: currentSearchTerm,
        bundleList: { page, sort },
        currentTab,
      },
    } = getState();
    batch(() => {
      dispatch(addLoader(InventoryListActionTypes.INVENTORY_LIST_LOADER));
      dispatch(clearBundleSearchCache());
    });

    const inventoryListFilters = getInventoryListFilters({
      searchTerm: searchTerm ?? currentSearchTerm,
      isActive,
      currentTab,
    });
    await dispatch(
      searchBundleIndex({
        ...inventoryListFilters,
        searchTerm: searchTerm ?? currentSearchTerm,
        clearCacheOnSearch: true,
        page,
        sort,
      })
    );
    await dispatch(fetchBundleListAvailableQtys());
    dispatch(clearLoader(InventoryListActionTypes.INVENTORY_LIST_LOADER));
    dispatch(estimateBundleListCosts());
  };

const getUniqueProductIds = (bundles: BundleListItem[]) => {
  const set = bundles.reduce((acc, bundle) => {
    for (const { dsku } of bundle.bundleItems) {
      acc.add(dsku);
    }
    return acc;
  }, new Set<string>());

  return Array.from(set);
};

export const resetResultsPerPage = (): SPThunkAction => async (dispatch, getState) => {
  return dispatch(updateResultsPerPage("inventoryList", 25));
};

export const fetchBundleListAvailableQtys = (): SPThunkAction => async (dispatch, getState) => {
  const {
    inventoryList: {
      bundleList: { listItems },
    },
  } = getState();

  const bskuToAvailableQty = await fetchBskuToAvailableQty(listItems);

  return dispatch({
    type: InventoryListActionTypes.SET_BUNDLE_LIST_AVAILABLE_QTY,
    bskuToAvailableQty,
  });
};

export const estimateCurrentListCosts = (): SPThunkAction => async (dispatch, getState) => {
  const {
    inventoryList: { currentTab },
  } = getState();

  return currentTab === "PRODUCTS" ? dispatch(estimateProductListCosts()) : dispatch(estimateBundleListCosts());
};

export const estimateBundleListCosts = (): SPThunkAction => async (dispatch, getState) => {
  const {
    user: { sellerId },
    costEstimatorModal: { showNewPricing },
    inventoryList: {
      bundleList: { listItems },
      estimatedCostsServiceLevel,
      countryCode,
    },
  } = getState();

  if (!listItems.length) {
    return;
  }

  dispatch({
    type: InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS_LOADING,
    isPricesLoading: true,
    bskuToFulfillmentFee: {},
  });

  const productIds = getUniqueProductIds(listItems);
  const products = await productClient.getProducts(productIds);

  const priceItemBatches: PriceItem[][] = listItems.map((bundle) =>
    bundle.bundleItems.map(({ dsku, qty }) => ({
      dsku,
      qty,
      ...(getProductDimensions(products[dsku]) as any),
      hazmatIds: getApprovedHazmatInfo(products[dsku]),
      minimumPackagingType: products[dsku].shippingSpecifications?.seller?.minimumPackagingType,
    }))
  );

  const prices = await getMultipleBundleEstimatedCosts({
    items: priceItemBatches,
    serviceLevel: estimatedCostsServiceLevel,
    showNewPrices: showNewPricing,
    countryCode,
    sellerId,
  });

  if (prices?.length) {
    const bskuToFulfillmentFee = listItems.reduce((acc, bundle, i) => {
      const totalPrice = sumBy(prices[i]?.orderItems, "totalPrice");
      acc[bundle.bsku] = totalPrice;
      return acc;
    }, {});

    dispatch({
      type: InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS,
      bskuToFulfillmentFee,
    });
  }

  dispatch({
    type: InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS_COMPLETED,
    isPricesLoading: false,
  });
};

export const calculateMultiCasePackStorageInventory = (): SPThunkAction => async (dispatch, getState) => {
  const {
    inventoryList: {
      productList: { listItems },
    },
  } = getState();

  if (!listItems.length) {
    return;
  }

  dispatch({
    type: InventoryListActionTypes.CALCULATE_MULTI_PACK_STORAGE_INVENTORY_LOADING,
    isStorageInventoryLoading: true,
  });
  const dskuList = listItems.map((item) => item.objectID);
  const service = getInventorySearchService(ListType.Inventory);
  const casePackResult = await service.searchByIds(dskuList, "", "packOf", 1000);
  if (!casePackResult?.hits?.length) {
    dispatch({
      type: InventoryListActionTypes.CALCULATE_MULTI_PACK_STORAGE_INVENTORY,
      isStorageInventoryLoading: false,
    });
    return;
  }
  const multiCasePackInventory = casePackResult.hits.reduce<
    Record<
      string,
      Pick<
        ProductListItem,
        | "storageOnHandQty"
        | "storageInTransferQty"
        | "inTransferQty"
        | "storageInboundUnits"
        | "storageNonPickableQty"
        | "storagePlannedQty"
        | "storageUnavailableQty"
        | "storageUnfilledOrderQty"
      >
    >
  >((acc, productListItem: ProductListItem) => {
    const packOf = productListItem.packOf ?? "";
    const {
      storageOnHandQty = 0,
      storageInboundUnits = 0,
      storageNonPickableQty = 0,
      storagePlannedQty = 0,
      storageUnavailableQty = 0,
      storageUnfilledOrderQty = 0,
      inTransferQty = 0,
    } = productListItem;
    const storageInventory = {
      storageOnHandQty: (acc[packOf]?.storageOnHandQty ?? 0) + storageOnHandQty,
      storageInTransferQty: (acc[packOf]?.storageInTransferQty ?? 0) + inTransferQty, // Note: in transfer qtys only exist in the default pool, thus when accounting for in transfer qty from pack dskus, we can't look at the storage in transit (which is always 0)
      storageInboundUnits: (acc[packOf]?.storageInboundUnits ?? 0) + storageInboundUnits,
      storageNonPickableQty: (acc[packOf]?.storageNonPickableQty ?? 0) + storageNonPickableQty,
      storagePlannedQty: (acc[packOf]?.storagePlannedQty ?? 0) + storagePlannedQty,
      storageUnavailableQty: (acc[packOf]?.storageUnavailableQty ?? 0) + storageUnavailableQty,
      storageUnfilledOrderQty: (acc[packOf]?.storageUnfilledOrderQty ?? 0) + storageUnfilledOrderQty,
    };
    acc[packOf] = storageInventory;
    return acc;
  }, {});
  const newItems = [...listItems];
  newItems.forEach((newItem) => {
    if (multiCasePackInventory[newItem.objectID]) {
      const {
        storageOnHandQty = 0,
        storageInTransferQty = 0,
        storageInboundUnits = 0,
        storageNonPickableQty = 0,
        storagePlannedQty = 0,
        storageUnavailableQty = 0,
        storageUnfilledOrderQty = 0,
      } = multiCasePackInventory[newItem.objectID];
      newItem.storageOnHandQty = (newItem.storageOnHandQty ?? 0) + storageOnHandQty;
      newItem.storageInTransferQty = (newItem.storageInTransferQty ?? 0) + storageInTransferQty;
      newItem.storageInboundUnits = (newItem.storageInboundUnits ?? 0) + storageInboundUnits;
      newItem.storageNonPickableQty = (newItem.storageNonPickableQty ?? 0) + storageNonPickableQty;
      newItem.storagePlannedQty = (newItem.storagePlannedQty ?? 0) + storagePlannedQty;
      newItem.storageUnavailableQty = (newItem.storageUnavailableQty ?? 0) + storageUnavailableQty;
      newItem.storageUnfilledOrderQty = (newItem.storageUnfilledOrderQty ?? 0) + storageUnfilledOrderQty;
      newItem.raw = {
        ...newItem.raw,
        storageOnHandQty: newItem.storageOnHandQty,
        storageInTransferQty: newItem.storageInTransferQty,
        storageInboundUnits: newItem.storageInboundUnits,
        storageNonPickableQty: newItem.storageNonPickableQty,
        storagePlannedQty: newItem.storagePlannedQty,
        storageUnavailableQty: newItem.storageUnavailableQty,
        storageUnfilledOrderQty: newItem.storageUnfilledOrderQty,
      };
    }
  });
  dispatch({
    type: InventoryListActionTypes.CALCULATE_MULTI_PACK_STORAGE_INVENTORY,
    listItems: newItems,
    isStorageInventoryLoading: false,
  });
};

export const estimateProductListCosts = (): SPThunkAction => async (dispatch, getState) => {
  const {
    user: { sellerId },
    costEstimatorModal: { showNewPricing },
    inventoryList: {
      searchTerm,
      productList: { listItems },
      estimatedCostsServiceLevel,
      countryCode,
    },
  } = getState();

  if (!listItems.length) {
    return;
  }

  dispatch({
    type: InventoryListActionTypes.ESTIMATE_PRODUCT_LIST_COSTS_LOADING,
    isPricesLoading: true,
  });

  const priceItems: PriceItem[] = listItems.map((item) => ({
    dsku: item.dsku,
    ...(getProductDimensions(item) as any),
    hazmatIds: getApprovedHazmatInfo(item),
    minimumPackagingType: item.sellerShippingSpecification?.minimumPackagingType,
  }));

  const prices = await getMultipleEstimatedCostsPerUnitDetail({
    items: priceItems,
    showNewPrices: showNewPricing,
    serviceLevel: estimatedCostsServiceLevel,
    countryCode,
    sellerId,
  });

  const hasSearchTermChanged = getState().inventoryList.searchTerm !== searchTerm;
  if (prices?.length && !hasSearchTermChanged) {
    const pricesMap = zipObject(
      listItems.map((item) => item.dsku),
      prices
    );

    dispatch({
      type: InventoryListActionTypes.SET_ESTIMATED_COSTS,
      pricesMap,
    });

    dispatch({
      type: InventoryListActionTypes.ESTIMATE_PRODUCT_LIST_COSTS,
      isPricesLoading: false,
    });
  }
};

export const setEstimatedCostsServiceLevel =
  (serviceLevel: ServiceLevel): SPThunkAction =>
  (dispatch) => {
    dispatch({
      type: InventoryListActionTypes.SET_ESTIMATED_COSTS_SERVICE_LEVEL,
      estimatedCostsServiceLevel: serviceLevel,
    });
  };

export const setCountryCode =
  (countryCode: CountryCode): SPThunkAction =>
  (dispatch) => {
    dispatch({
      type: InventoryListActionTypes.SET_INVENTORY_LIST_COUNTRY_CODE,
      countryCode,
    });
  };

const syncProductsMessages = defineMessages({
  started: {
    id: "inventory.list.sync.started",
    defaultMessage: "We're importing any new products now. This will take a few minutes to complete.",
  },
  error: {
    id: "inventory.list.sync.error",
    defaultMessage: "Oops. There was a problem importing your new products. Please try again later.",
  },
  success: {
    id: "inventory.list.sync.success",
    defaultMessage: "Catalog synced successfully.",
  },
});

export const syncProducts = (): SPThunkAction => async (dispatch) => {
  const ctx = logStart({ fn: "syncProducts" });

  try {
    toast.info(<FormattedMessage {...syncProductsMessages.started} />, {
      toastId: "productSyncStarted",
    });

    dispatch({ type: InventoryListActionTypes.PRODUCT_SYNC, productSyncInProgress: true });

    await onboardingClientV2.startCatalogUpdate(false);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const intervalId = setInterval(async () => {
      const jobStatus = await onboardingClientV2.getCatalogUpdateStatus();
      const isError = jobStatus && jobStatus.status === JobStatusName.ERROR;
      const isComplete = jobStatus && jobStatus.status === JobStatusName.COMPLETE;

      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (isError || isComplete) {
        dispatch({ type: InventoryListActionTypes.PRODUCT_SYNC, productSyncInProgress: false });
        dispatch(searchProducts());
        clearInterval(intervalId);
        toast.dismiss("productSyncStarted");
      }

      if (isError) {
        toast.error(<FormattedMessage {...syncProductsMessages.error} />, {
          toastId: "productSyncError",
        });
      }

      if (isComplete) {
        toast.success(<FormattedMessage {...syncProductsMessages.success} />, {
          toastId: "productSyncSuccess",
        });
      }
    }, SYNC_PRODUCTS_INTERVAL);
  } catch (err) {
    dispatch({ type: InventoryListActionTypes.PRODUCT_SYNC, productSyncInProgress: false });
    logError(ctx, err);
    toast.dismiss("productSyncStarted");
    toast.error(<FormattedMessage {...syncProductsMessages.error} />, {
      toastId: "productSyncError",
    });
  }
};

export const setSortProductList =
  (sort: Sort): SPThunkAction =>
  async (dispatch) => {
    await dispatch(setProductListSort(sort));
    setItemInLocalStorage(LocalStorageKey.FulfillmentFeeInventoryListSort, sort);
    dispatch(searchProducts());
  };

export const setTab = (currentTab: InventoryListTab) => ({
  type: InventoryListActionTypes.SET_TAB,
  currentTab,
});

export const setSearchTerm = (searchTerm: string) => ({
  type: InventoryListActionTypes.SET_SEARCH_TERM,
  searchTerm,
});

export const searchCurrentInventoryList = (searchTerm?: string) => async (dispatch, getState) => {
  const {
    inventoryList: { currentTab },
  } = getState();

  if (currentTab === "PRODUCTS" || currentTab === "KITS" || currentTab === "ELIGIBLE_FOR_REMOVAL") {
    dispatch(searchProducts(searchTerm));
  } else {
    dispatch(searchBundles(searchTerm));
  }
};

export const setInventoryLocationFilter = createActionCreator<InventoryLocationFilterOptions>(
  InventoryListActionTypes.SET_INVENTORY_LOCATION_FILTER,
  "inventoryLocation"
);
