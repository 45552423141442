import { SmbBookingPortGroupingInput } from "@deliverr/commons-clients";
import { BookingAddress } from "@deliverr/inbound-client";
import { InternationalRateCountryList } from "common/components/CountrySelect/Countries";
import { PlaceType, Port } from "inbounds/createShipment/CreateShipmentTypes";
import { createShipmentInitialFormState } from "inbounds/createShipment/store/CreateShipmentState";

export const _parseBookingAddress = (
  address: BookingAddress | undefined,
  portGrouping?: readonly SmbBookingPortGroupingInput[]
): PlaceType => {
  if (address) {
    Object.keys(address).forEach((key) => {
      if (address[key] === "N/A") {
        address[key] = "";
      }
    });
  }
  return {
    value: {
      address: address
        ? {
            ...address,
            country:
              Object.keys(InternationalRateCountryList).find(
                (k) => InternationalRateCountryList[k] === address.country
              ) ??
              address.country ??
              "",
          }
        : createShipmentInitialFormState.origin.value.address,
      ports: (portGrouping as Port[]) ?? [],
      distanceKm: undefined, // only used in options list
    },
    label: address?.optionLabel ?? address?.name ?? "",
  };
};
