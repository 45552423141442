import { BoxReset, ThemeProps, Title } from "common/components/ui";
import styled from "@emotion/styled";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { ShipmentStatusPill } from "inbounds/steps/ship/ShipmentStatusPill";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StorageAdminBarcodeDownload } from "../StorageAdminBarcodeDownload";
import { NCShipmentStatusPill } from "inbounds/steps/ship/NCShipmentStatusPill";
import { NonComplianceShipmentStatus } from "@deliverr/commons-clients";
interface ShipmentHeaderProps {
  shipmentId?: number;
  status: InboundShipmentStatus;
  isActionNeeded: boolean;
}

const HeaderContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing.S6} ${theme.spacing.S7};
  `
);

export const StorageShipmentHeader: React.FC<ShipmentHeaderProps> = ({ shipmentId, status, isActionNeeded }) => {
  const { formatMessage } = useIntl();
  const title = formatMessage(
    {
      id: "storage.inbounds.shipmentHeader.title",
      defaultMessage: "Reserve Storage shipment",
    },
    { shipmentId }
  );

  return (
    <HeaderContainer>
      <div>
        <BoxReset marginTop={null} marginBottom="S3">
          <Title data-testid="storage.inbound.detail.shipment.header" displayAs="h2" as="h2">
            <FormattedMessage defaultMessage={title} id={"storage.inbounds.shipmentHeader.title"} />
          </Title>
        </BoxReset>
        {isActionNeeded ? (
          <NCShipmentStatusPill status={NonComplianceShipmentStatus.ACTION_NEEDED} />
        ) : (
          <ShipmentStatusPill shipmentStatus={status} />
        )}
      </div>
      <StorageAdminBarcodeDownload />
    </HeaderContainer>
  );
};
