import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";

import { ListType } from "common/list";
import { OrderListActionTypes } from "../OrderListActionTypes";
import { OrderSearchService } from "common/search/services/OrderSearchService";
import { RootState } from "RootReducer";
import { Thunk } from "common/ReduxUtils";
import { batch } from "react-redux";
import { getCustomizedOpenSearchFilters } from "../helpers/getOpenSearchFilters";
import log from "Logger";
import { searchOrdersSuccess } from "./searchOrdersSuccess";
import { searchServiceFor } from "common/search/Factory";

const orderSearchService: OrderSearchService = searchServiceFor(ListType.Orders) as OrderSearchService;

export interface SearchOrdersProps {
  page?: number;
  isOrderIngestErrorsLogisticsSearchOn?: boolean;
  useOrdersLogisticsSearchV2?: boolean;
}

export const searchOrders: Thunk =
  ({ page, useOrdersLogisticsSearchV2 }: SearchOrdersProps = {}) =>
  async (dispatch, getState: () => RootState) => {
    const ctx = { fn: "searchOrders" };
    log.info({ ...ctx }, "searching orders");
    dispatch(addLoader(OrderListActionTypes.LOAD_ORDER_LIST));
    const {
      orderListV2: {
        currentSearchTerm,
        sort,
        searchInfo,
        filters: { status, serviceLevel, date },
      },
      user: { resultsPerPage, isAdmin },
    } = getState();

    const customizedOrdersFilters = getCustomizedOpenSearchFilters({
      status,
      serviceLevel,
      date,
      isAdmin,
    });

    try {
      // OV-1403: Make the OpenSearch request regardless of feature-flag to track performance
      //          When feature-flag is off, the request is async to minimize latency in the webapp
      const openSearchRequest = {
        searchTerm: currentSearchTerm,
        page: searchInfo?.currentPage ?? page ?? 0,
        sort,
        customizedOpenSearchFilters: customizedOrdersFilters.length > 0 ? customizedOrdersFilters : undefined,
        pageSize: resultsPerPage.orderList,
        highlightMatches: true,
      };
      const openSearchResult = useOrdersLogisticsSearchV2
        ? orderSearchService.executeSearch(openSearchRequest)
        : orderSearchService.execute(openSearchRequest);
      const searchResult = await openSearchResult;
      log.info(ctx, "successfully retrieved orders");

      const { response, hits } = searchResult;

      batch(() => {
        dispatch(searchOrdersSuccess(response, hits));
        dispatch(clearLoader(OrderListActionTypes.LOAD_ORDER_LIST));
      });
    } catch (err) {
      log.error({ ...ctx, err }, "error searching for products");
      return dispatch({ type: OrderListActionTypes.SEARCH_ORDERS_ERROR });
    }
  };
