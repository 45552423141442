enum InboundLoaderId {
  boxLabelsButton = "inboundsBoxLabelsButton",
  confirmEstimate = "inboundsConfirmEstimate",
  fetchingQuotes = "fetchingQuotes",
  finalizingShipment = "finalizingShipment",
  fromAddressSelect = "inboundsFromAddressSelect",
  loadingInbound = "loadingInbound",
  printBarcodesButton = "inboundsPrintBarcodesButton",
  rowButton = "inboundRowButton",
  shipmentProblemsTable = "inboundsShipmentProblemsTable",
  transition = "inboundsTransition",
  updateShipmentFreightAppointmentInfoButton = "inboundsUpdateShipmentFreightAppointmentInfoButton",
  updateShipmentFreightDeliveryInfoButton = "inboundsUpdateShipmentFreightDeliveryInfoButton",
  updateTrackingButton = "updateTrackingButton",
  updateShippingOption = "inboundUpdateShippingOption",
  saveBoxPackingConfiguration = "inboundSaveBoxPackingConfiguration",
  updateDeliveryAppointment = "inboundUpdateDeliveryAppointment",
}

export default InboundLoaderId;
