import { SPThunkAction } from "common/ReduxUtils";
import { SearchResultsResponse } from "common/search/SearchService";
import { NonComplianceListActionTypes } from "./NonComplianceListActionTypes";

export const searchNonComplianceSuccess =
  ({ nbPages, page, nbHits, hitsPerPage }: SearchResultsResponse, hits): SPThunkAction =>
  (dispatch) =>
    dispatch({
      type: NonComplianceListActionTypes.SEARCH_NON_COMPLIANCE_SUCCESS,
      searchInfo: {
        totalPages: nbPages,
        currentPage: page,
        totalHits: nbHits,
        itemsPerPage: hitsPerPage,
      },
      searchLoading: false,
      currentPageItems: hits,
    });
