import { createSelector } from "reselect";
import { InboundShipment, InboundShipmentItem } from "@deliverr/inbound-client";
import { compact, pipe, reduce, flatten } from "lodash/fp";

import log, { logError, logStart } from "Logger";
import { ProductCollection } from "common/models/Product";
import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
import { RootState } from "RootReducer";

export const getLoadedShipment = createSelector(
  (state: RootState) => state.inbound.loadedShipmentId,
  (state: RootState) => state.inbound.shipments.byId,
  (loadedShipmentId: number, shipmentsById: { [shipmentId: number]: InboundShipment }) => {
    const ctx = logStart({ fn: "getLoadedShipment", msg: `Retrieving loaded shipment with id "${loadedShipmentId}."` });
    if (loadedShipmentId === undefined) {
      const msg = "Cannot retrieve loaded shipment with undefined loadedShipmentId.";
      logError(ctx, new Error(msg));
      throw new Error(msg);
    }
    if (!shipmentsById?.[loadedShipmentId]) {
      const msg = "Cannot retrieve shipment with the specified shipment Id.";
      logError(ctx, new Error(`${msg} ${loadedShipmentId}`));
      throw new Error(msg);
    }

    return shipmentsById[loadedShipmentId];
  }
);

export const getLoadedShipmentBarcodes = createSelector(
  getLoadedShipment,
  getProductDetailsCache,
  (shipment: InboundShipment, productDetailsCache: ProductCollection) => {
    log.info({ fn: "getLoadedShipmentBarcodes", shipmentId: shipment.id }, "Retrieving shipment barcodes");

    return productDetailsCache
      ? pipe<InboundShipmentItem[], Array<Array<string | undefined> | undefined>, Array<string | undefined>, string[]>(
          reduce<InboundShipmentItem, Array<Array<string | undefined> | undefined>>((acc, current) => {
            if (current?.dsku && productDetailsCache[current.dsku]?.barcodes?.length) {
              return acc.concat(productDetailsCache[current.dsku].barcodes);
            }

            return acc;
          }, []),
          flatten,
          compact
        )(shipment.items)
      : [];
  }
);
