import { InboundBooking } from "@deliverr/inbound-client";
import { OriginType } from "inbounds/createShipment/CreateShipmentTypes";
import { getIsPlaceAddress } from "inbounds/createShipment/steps/OriginSearchStep/utils";
import { CreateShipmentFormState } from "../../../CreateShipmentState";
import { _parseBookingAddress } from "./_parseBookingAddress";

type OriginState = Pick<
  CreateShipmentFormState,
  "originType" | "origin" | "originFacilityName" | "isOriginPort" | "createdBy"
>;

export const getOriginState = (booking: InboundBooking): OriginState => {
  const origin = _parseBookingAddress(booking.from ?? undefined, booking.originPortGrouping);

  const isOriginAddress = getIsPlaceAddress(origin);
  const originFacilityName = isOriginAddress ? booking.from?.name ?? null : null;

  return {
    originType: OriginType.INTERNATIONAL,
    origin,
    originFacilityName,
    isOriginPort: !isOriginAddress,
    createdBy: booking.createdBy,
  };
};
