import { SPThunkAction } from "common/ReduxUtils";
import { StorageInboundDetailActionTypes } from "../StorageInboundDetailStore.types";

export const setStorageCarrierEmailAddress =
  (carrierEmailAddress: string): SPThunkAction =>
  async (dispatch) => {
    dispatch({
      type: StorageInboundDetailActionTypes.SET_STORAGE_CARRIER_EMAIL,
      carrierEmailAddress,
    });
  };
