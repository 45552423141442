import { InboundPrepRequestData } from "@deliverr/inbound-client";
import { PrepType } from "@deliverr/prep-service-client";
import { SPThunkAction } from "common/ReduxUtils";
import { setBclSelection } from "prep/store";
import { checkIfPrepRequestHasAllSpecifiedPrepTypes } from "prep/util/checkIfPrepRequestHasAllSpecifiedPrepTypes";
import { selectIsBclPrepEnabledForFlow } from "inbounds/store/selectors/boxLabels/selectIsBclPrepEnabledForFlow";
import { getLoadedShipmentId } from "inbounds/store/selectors/shipments";
import { selectLoadedShipmentHasConfirmedBoxingChoice } from "inbounds/store/selectors/shipments/selectLoadedShipmentHasConfirmedBoxingChoice";
import { BoxContentLabelingOption } from "inbounds/types/BoxContentLabelingOption";
import { RootState } from "RootReducer";

/**
 * Given a PrepRequest, update the related Prep data.
 * Note: must be called after loading shipment info.
 */
export const initializeBclPrepContext =
  (prepRequest?: InboundPrepRequestData): SPThunkAction =>
  (dispatch, getState) => {
    const state: RootState = getState();

    const isBclPrepEnabledForFlow = selectIsBclPrepEnabledForFlow(state);
    const hasBclPrepRequest =
      checkIfPrepRequestHasAllSpecifiedPrepTypes(prepRequest, PrepType.BOX_CONTENT_LABELS) ||
      checkIfPrepRequestHasAllSpecifiedPrepTypes(prepRequest, PrepType.KITTING);
    const loadedShipmentId = getLoadedShipmentId(state);

    const hasConfirmedBoxingChoice = selectLoadedShipmentHasConfirmedBoxingChoice(state);

    // use combination of states to determine which Box Contents Labeling option should currently be selected
    let prepBclOption: BoxContentLabelingOption | undefined;
    if (!isBclPrepEnabledForFlow) {
      prepBclOption = BoxContentLabelingOption.DIY;
    } else if (hasBclPrepRequest) {
      prepBclOption = BoxContentLabelingOption.DELIVERR_PREP;
    } else if (hasConfirmedBoxingChoice) {
      prepBclOption = BoxContentLabelingOption.DIY;
    } else {
      prepBclOption = BoxContentLabelingOption.DELIVERR_PREP;
    }

    dispatch(setBclSelection(prepBclOption, loadedShipmentId!));
  };
