import styled from "@emotion/styled";
import { Select as DeliverrSelect, SelectProps, ThemeProps } from "common/components/ui";

interface DeliverrSelectProps extends SelectProps {
  isInDownloadModal: boolean;
}

interface SelectContainerProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  isInDownloadModal: boolean;
}

export const ButtonsContainer = styled.div<ThemeProps>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => `${theme.spacing.S5}`};

  > button {
    flex-basis: 45%;
  }
`;

export const SelectContainer = styled.div<ThemeProps<SelectContainerProps>>(
  ({ isInDownloadModal, theme }) => `
    display: flex;
    flex-direction: ${isInDownloadModal ? "column" : "row"};

    label {
      margin-bottom: ${isInDownloadModal ? 0 : theme.spacing.S5};
      span {
        color: ${theme.colors.NEUTRAL[500]};
      }
    }
  `
);

export const SelectWidth = styled(DeliverrSelect)<ThemeProps<DeliverrSelectProps>>(
  ({ isInDownloadModal, theme }) => `
  width: ${isInDownloadModal ? "260px" : "365px"};
  [class*="-menu"] {
    z-index: ${theme.zindex.Z1};
  }

  &:first-of-type {
    margin-right: ${isInDownloadModal ? 0 : theme.spacing.S5};
  }
`
);
