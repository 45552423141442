import styled from "@emotion/styled";
import { ManagedStep } from "common/components/StepContainer";
import { Box, Input, ThemeProps } from "common/components/ui";
import { values } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { useReplenishmentShippingDetailsStep } from "./useReplenishmentShippingDetailsStep";
import { ReplenishmentShippingDetailsLabels } from "./ReplenishmentShippingDetailsLabels";

const Space = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.S4};
`
);

export const ReplenishmentShippingDetailsStep = () => {
  const { formatMessage } = useIntl();
  const {
    currentFbaShipmentId,
    currentFbaReferenceId,
    isUploadingLabel,
    setIsUploadingLabel,
    isLoading,
    shouldShowFbaShipmentHint,
    shouldShowFbaRefHint,
    isShipmentTypeParcel,
    validate,
    onShipmentIdChange,
    onShipmentIdBlur,
    onReferenceIdChange,
    onReferenceIdBlur,
    attachments,
    shouldShowBoxLabelHint,
    shouldShowCommercialInvoiceHint,
  } = useReplenishmentShippingDetailsStep();

  const transfersLabels = transfersShipmentCreateLabels.steps;

  return (
    <ManagedStep
      title={formatMessage(transfersLabels.shipmentDetails.title)}
      subtitle={formatMessage(transfersLabels.shipmentDetails.subtitle)}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      isLoading={isLoading}
      nextData={async () => ({
        attachmentTypes: values(attachments)
          .filter((attachmentType) => attachmentType.isUploadedToS3)
          .map((attachmentType) => attachmentType.attachmentType),
      })}
      validate={validate}
      isBackDisabled={isLoading}
    >
      <Box width="43%" maxWidth="27rem">
        <Input
          required
          data-testid="fba-shipment-id"
          label={formatMessage(transfersLabels.shipmentDetails.shipmentId.label)}
          placeholder={formatMessage(transfersLabels.shipmentDetails.shipmentId.placeholder)}
          value={currentFbaShipmentId}
          onChange={onShipmentIdChange}
          onBlur={onShipmentIdBlur}
          hasError={shouldShowFbaShipmentHint}
          errorMessage={formatMessage(transfersLabels.shipmentDetails.shipmentId.validateAttribute)}
        />
        <Space />
        {!isShipmentTypeParcel && (
          <Input
            required
            data-testid="fba-reference-id"
            label={formatMessage(transfersLabels.shipmentDetails.referenceId.label)}
            placeholder={formatMessage(transfersLabels.shipmentDetails.referenceId.placeholder)}
            value={currentFbaReferenceId}
            onChange={onReferenceIdChange}
            onBlur={onReferenceIdBlur}
            hasError={shouldShowFbaRefHint}
            errorMessage={formatMessage(transfersLabels.shipmentDetails.referenceId.validateAttribute)}
          />
        )}
        <Space />
        <ReplenishmentShippingDetailsLabels
          shouldShowBoxLabelHint={shouldShowBoxLabelHint}
          shouldShowCommercialInvoiceHint={shouldShowCommercialInvoiceHint}
          setIsUploadingLabel={setIsUploadingLabel}
          isUploadingLabel={isUploadingLabel}
        />
      </Box>
    </ManagedStep>
  );
};
