import { ManagedStep } from "common/components/StepContainer";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "../../../transfersShipmentCreate.labels";
import { useReplenishmentOrderShippingStep } from "./useReplenishmentOrderShippingStep";
import { ShipmentPartnerTiles } from "./ShipmentPartnerTiles";
import { ShipmentTypeTiles } from "./ShipmentTypeTiles";
import { SelectShipmentShipByDate } from "./SelectShipmentShipByDate";
import { useSelectShipmentShipByDate } from "./useSelectShipmentShipByDate";
import { ShippingMethod } from "wholesale/create/steps/shipping";
import { SelectLabelProvider } from "./carrier-account/components/SelectLabelProvider";
import { WholesaleDestinationAddress } from "./WholesaleDestinationAddress";
import { isEmpty } from "lodash";

export const ReplenishmentOrderShippingStep: FC = () => {
  const { formatMessage } = useIntl();
  const {
    destinationAddress,
    shippingMethod,
    cargoType,
    shippingEstimation,
    retryShippingEstimation,
    isNextDisabled,
    isDestinationWholesale,
    isInternationalDestinationCountry,
    onChangeShippingMethod,
    shouldShowLabelProvider,
  } = useReplenishmentOrderShippingStep();
  const { onShipByChange, selectedShipByDate, hasShipByDateChanged, validShipBy, shipBy } =
    useSelectShipmentShipByDate();

  return (
    <ManagedStep
      data-testid="replenishment-order-shipping-step"
      title={formatMessage(transfersShipmentCreateLabels.steps.shipping.title)}
      isNextDisabled={isNextDisabled}
      nextData={async () => ({
        selectedShipByDate: hasShipByDateChanged ? selectedShipByDate : undefined,
      })}
    >
      {isDestinationWholesale ? (
        <>
          {destinationAddress && <WholesaleDestinationAddress address={destinationAddress} />}
          <ShippingMethod
            isV2
            value={shippingMethod}
            onChange={onChangeShippingMethod}
            isInternationalWholesaleOrder={isInternationalDestinationCountry}
          />
        </>
      ) : (
        <ShipmentPartnerTiles shippingEstimation={shippingEstimation} shippingMethod={shippingMethod} />
      )}

      {shippingMethod && (
        <ShipmentTypeTiles
          shippingEstimation={shippingEstimation}
          shippingMethod={shippingMethod}
          cargoType={cargoType}
          retryShippingEstimation={retryShippingEstimation}
        />
      )}
      {shouldShowLabelProvider && (
        <SelectLabelProvider isInternationalWholesaleOrder={isInternationalDestinationCountry} />
      )}
      {isDestinationWholesale && !isEmpty(cargoType) && (
        <SelectShipmentShipByDate
          cargoType={cargoType}
          hasShipByDateChanged={hasShipByDateChanged}
          onShipByChange={onShipByChange}
          selectedShipByDate={selectedShipByDate}
          shipBy={shipBy}
          validShipBy={validShipBy}
        />
      )}
    </ManagedStep>
  );
};
