import { SPThunkAction } from "common/ReduxUtils";
import { BookingDestinationRequest, BookingDestinationResponse } from "@deliverr/inbound-client";
import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { getSellerId } from "common/user/UserSelectors";
import { selectBookingDestinationRequest } from "../selectors/selectBookingDestinationRequest";
import { setCrossdockWarehouse, setDestinationAddressFid } from "./simpleUpdateActions";

export const updateBookingDestination =
  (shippingPlanId: number): SPThunkAction<Promise<BookingDestinationResponse>> =>
  async (dispatch, getState) => {
    const state = getState();
    const sellerId = getSellerId(state);
    const bookingRequest: BookingDestinationRequest = selectBookingDestinationRequest()(state);
    const ctx = logStart({ fn: SmbBookingCreationTimedEvent.UpdateBooking, shippingPlanId, bookingRequest });

    try {
      const response = await inboundClient.bookingSetDestination(sellerId, shippingPlanId, bookingRequest);
      const { destinationAddressFid, to } = response;
      dispatch(setDestinationAddressFid(destinationAddressFid ?? null));
      dispatch(
        setCrossdockWarehouse({
          address: to?.crossDockWarehouseAddress ?? null,
          warehouseId: to?.warehouseId ?? null,
        })
      );
      logSuccess(ctx, "successfully set destination");
      return response;
    } catch (error) {
      logError(ctx, error);
      throw error;
    }
  };
