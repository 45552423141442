import React, { FC } from "react";
import { DefaultTheme, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { FbaPrepDetailsLabels } from "./FbaPrepDetailsLabels";
import { useModal } from "common/hooks/useModal";
import { SetFbaPrepDetailsModal } from "./SetFbaPrepDetailsModal";
import { BorderlessButton } from "common/components/BorderlessButton";
import { getSetPrepDetailsModalIdForSku } from "../commons/FbaV3Constants";

const StyledButton = styled(BorderlessButton)<{}, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors[theme.config.colorByAppearance.PRIMARY][300]};

    &:hover {
      color: #11bfe3;
    }
  `
);

interface SetPrepDetailsModalProps {
  dsku: string;
}

export const SetPrepDetails: FC<SetPrepDetailsModalProps> = ({ dsku }) => {
  const { showModal, hideModal } = useModal(getSetPrepDetailsModalIdForSku(dsku));

  return (
    <>
      <SetFbaPrepDetailsModal dsku={dsku} onCloseModal={hideModal} />
      <StyledButton onClick={showModal}>
        <Text bold>
          <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsButtonText} />
        </Text>
      </StyledButton>
    </>
  );
};
