import styled from "@emotion/styled";
import { DefaultTheme, Stack } from "common/components/ui";
import Modal from "common/components/Modal/Modal";

const breakdownPanelWidth = "225px";
const breakdownPanelHeight = "12rem";
const inputHeight = "38px";

export const FulfillmentBreakdownSection = styled.section`
  display: grid;
  grid-template-columns: 1fr ${breakdownPanelWidth};
  grid-auto-rows: auto;
  grid-gap: 1rem;
`;

export const BreakdownPanel = styled.div<{ flexHeight: boolean; theme: DefaultTheme }>(
  ({ flexHeight, theme }) => `
  background-color: ${theme.colors.NEUTRAL[40]};
  padding: ${theme.spacing.S4};
  border-radius: 10px;
  width: 100%;
  ${flexHeight ? "" : `min-height: ${breakdownPanelHeight}`};
  ${!flexHeight ? "position: relative;" : ""}
  p {
    margin-bottom: 0;
  }
`
);

export const HeaderText = styled.div<{ theme: DefaultTheme }>(
  ({ theme }) => `
  font-size: ${theme.font.size.F3};
  font-weight: ${theme.font.weight.BOLD};
`
);

export const Divider = styled.hr<{ theme: DefaultTheme }>(
  ({ theme }) => `
  border-top: 1px solid ${theme.colors.NEUTRAL[200]};
  width: 100%;
`
);

export const CostContainer = styled(Stack)<{ theme: DefaultTheme }>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
  justify-content: space-between;
  align-items: center;
  svg {
    margin-left: ${theme.spacing.S1};
  }
`
);

export const TotalCost = styled.p<{ theme: DefaultTheme }>(
  ({ theme }) => `
  margin-top: 0;
  font-size: ${theme.font.size.F6};
  font-weight: ${theme.font.weight.BOLD};
  font-family: ${theme.font.family.TITLE};
`
);

export const EstimateContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LabelText = styled.p<{ theme: DefaultTheme }>(
  ({ theme }) => `
  margin: ${theme.spacing.S2} 0 ${theme.spacing.S2} 0;
`
);

export const PackagingSelectButton = styled.button<{ theme: DefaultTheme }>(
  ({ theme }) => `
  display: block;
  width: 100%;
  height: ${inputHeight};
  background-color: ${theme.colors.NEUTRAL["00"]};
  border: 1px solid ${theme.colors.NEUTRAL[80]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${theme.spacing.S4};
  padding-right: ${theme.spacing.S4};
`
);

export const StyledModal = styled(Modal)<{ theme: DefaultTheme }>(
  ({ theme }) => `
  .modal-content {
    padding: ${theme.spacing.S8};
  }
  h2 {
    text-align: center;
  }
`
);

export const SelectedOptionContainer = styled.div<{ theme: DefaultTheme }>(
  ({ theme }) => `
  svg {
    margin-left: ${theme.spacing.S1};
  }
`
);
