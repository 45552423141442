import { ProblemType } from "common/problems/ProblemType";
import { UnexpectedBarcodeNonComplianceRow } from "../types";
import { SellerProblemsDetailView } from "@deliverr/inbound-client";
import { ProductCollection } from "common/models";
import { getMappedSkuPairs } from "inbounds/steps/ship/view/cards/ShipmentProblems/spvUtils/getMappedSkuPairs";

export const getNonComplianceRowsFromUnexpectedBarcodeCases = (
  unexpectedBarcodeCase: SellerProblemsDetailView,
  productCollection?: ProductCollection
): UnexpectedBarcodeNonComplianceRow => {
  const {
    sellerProblemId,
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    barcode,
    dsku,
    reportedUnits,
    receivedUnits,
    shipmentId,
    cdsku,
    issueId,
    isLegacyCase,
    status,
    affectedCdskus,
    rawImages,
  } = unexpectedBarcodeCase;

  return {
    id: sellerProblemId?.toString() ?? issueId!,
    isLegacyCase,
    problemType: ProblemType.UNEXPECTED_BARCODE,
    shippingPlanId,
    shippingPlanName,
    status,
    updatedAt,
    createdAt,
    barcode: barcode!,
    cdsku: cdsku!,
    units: reportedUnits ?? receivedUnits,
    mappedSkus: productCollection
      ? getMappedSkuPairs(unexpectedBarcodeCase, productCollection)
      : [{ dsku: dsku!, msku: "", productName: "" }],
    shipmentId: shipmentId!,
    acknowledgedAt: new Date(),
    affectedCdskus,
    photos: rawImages ?? [],
  };
};
