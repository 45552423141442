import { CountryCode } from "@deliverr/commons-objects";
import { ATP } from "common/clients/inventory/ATP/ATP";
import { ServiceLevel } from "@deliverr/billing-pricer-client";
import { productClient } from "Clients";
import { inventoryClient } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { loadWarehouses } from "common/deliverr/DeliverrActions";
import { notifyUserOfError } from "common/ErrorToast";
import { loadGoogleMaps } from "common/LoadGoogleMaps";
import { getEstimatedCosts } from "common/ProductUtils";
import { raceTimeout } from "common/PromiseUtils";
import { createActionCreator, Thunk } from "common/ReduxUtils";
import { getApprovedHazmatInfo } from "common/utils/product/getApprovedHazmatInfo";
import { InventoryLoaderId } from "inventory/InventoryLoaderId";
import log, { logError, logStart } from "Logger";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { batch } from "react-redux";
import { toast } from "common/components/ui";

import { InventoryDetailActionTypes } from "./InventoryDetailActionTypes";
import { FeatureName, isFeatureOn } from "common/Split";
import { InventoryDetailState } from "./InventoryDetailReducer";

export const getProductDetail: Thunk = (dsku: string) => {
  return async (dispatch) => {
    const ctx = { fn: "getProductDetails", dsku };
    log.info(ctx, "getting product detail");

    try {
      const dskuToProduct = await productClient.getProduct(dsku, {
        includeCustomsInformation: true,
        includeProductAliases: true,
        includeHazmatInformation: true,
        includeShippingSpecifications: true,
        includeProductPreparation: true,
        includeBundleMembership: true,
        includeProductCasePacks: !!isFeatureOn(FeatureName.StorageMultiCasePack),
        includeKitComponents: true,
        includeKitMembership: true,
      });

      if (!dskuToProduct) {
        const err = new Error(`Product ${dsku} not found`);
        throw err;
      }

      log.info({ ...ctx, dskuToProduct }, "retrieved product detail");

      dispatch({
        product: dskuToProduct,
        type: InventoryDetailActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
      });
    } catch (err) {
      log.error({ ...ctx, err }, "error getting product detail");
      dispatch({
        err,
        type: InventoryDetailActionTypes.GET_PRODUCT_DETAIL_ERROR,
      });
    }
  };
};

export const setServiceLevel = createActionCreator<ServiceLevel>(
  InventoryDetailActionTypes.SET_SELECTED_SERVICE_LEVEL,
  "serviceLevel"
);

export const setCountryCode = createActionCreator<CountryCode>(
  InventoryDetailActionTypes.SET_SELECTED_COUNTRY_CODE,
  "countryCode"
);

export const updateEstimatedCosts: Thunk = () => async (dispatch, getState) => {
  const {
    user: { sellerId },
    costEstimatorModal: { showNewPricing },
    inventoryDetail: { product, selectedQty, serviceLevel, countryCode },
  } = getState();
  const ctx = { fn: "updateEstimatedCosts" };
  log.info({ ...ctx, product, showNewPricing }, "updating estimated costs");

  if (!product) {
    log.error(ctx, "no product loaded");
    return;
  }

  const { dsku, length, width, height, weight } = product;
  const hazmatIds = getApprovedHazmatInfo(product);
  const minimumPackagingType = product.shippingSpecifications?.seller?.minimumPackagingType;

  dispatch({
    type: InventoryDetailActionTypes.UPDATE_ESTIMATED_COSTS,
    estimatedCosts: await getEstimatedCosts({
      items: [{ dsku, length, width, height, weight, qty: selectedQty, hazmatIds, minimumPackagingType }],
      showNewPrices: showNewPricing,
      serviceLevel,
      countryCode,
      sellerId,
    }),
  });
};

export const getInventoryByWarehouse: Thunk = (dsku: string) => {
  return async (dispatch, getState) => {
    const ctx = { fn: "getInventoryByWarehouse", dsku };
    log.info(ctx, "getting inventory by warehouse");
    dispatch({
      type: InventoryDetailActionTypes.GET_INVENTORY_BY_WAREHOUSE_START,
    });

    try {
      const warehouseAtp = await raceTimeout(inventoryClient.getWarehouseATP([dsku]), 5000, {});

      log.info({ ...ctx, warehouseAtp }, "retrieved warehouse atp");
      const warehouseIdToAtp: { [warehouseId: string]: ATP } | {} = warehouseAtp[dsku] || {};
      const warehouseIds = Object.keys(warehouseIdToAtp);
      await dispatch(loadWarehouses(warehouseIds));
      const {
        deliverr: { warehouses },
      } = getState();

      const inventoryByWarehouse = Object.entries(warehouseIdToAtp)
        .filter(([_, { onHandQty }]) => onHandQty > 0)
        .map(([warehouseId, { onHandQty }]) => {
          const { address, lat, lng } = warehouses[warehouseId];
          return {
            address,
            onHandQty,
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            name: `${address.city}, ${address.state}`,
            lat,
            lng,
          };
        });

      dispatch({
        inventoryByWarehouse,
        type: InventoryDetailActionTypes.GET_INVENTORY_BY_WAREHOUSE_SUCCESS,
      });
    } catch (err) {
      log.error({ ...ctx, err }, "error getting inventory by warehouse");
      dispatch({
        err,
        type: InventoryDetailActionTypes.GET_INVENTORY_BY_WAREHOUSE_ERROR,
      });
    }
  };
};

export const createWarehouseMarkers: Thunk = () => async (dispatch, getState) => {
  const {
    inventoryDetail: { inventoryByWarehouse },
  } = getState();
  const ctx = { fn: "createWarehouseMarkers", inventoryByWarehouse, currentLocation: undefined };
  log.info(ctx, "creating warehouse markers");

  try {
    const warehouseMarkerRequests = inventoryByWarehouse.map(async ({ name, onHandQty, lng, lat }) => {
      let coordinates;
      if (lng && lat) {
        coordinates = [lng, lat];
      } else {
        // see when geocoding fails
        ctx.currentLocation = name;
        const address = await geocodeByAddress(name);
        const addressCoords = await getLatLng(address[0]);
        coordinates = [addressCoords.lng, addressCoords.lat];
      }
      return { units: onHandQty, coordinates };
    });
    dispatch({
      type: InventoryDetailActionTypes.CREATE_WAREHOUSE_MARKERS,
      warehouseMarkers: await Promise.all(warehouseMarkerRequests),
    });
  } catch (err) {
    log.error({ ...ctx, err }, "error creating warehouse markers");
  }
};

export const loadInventoryDetail: Thunk = (dsku: string) => async (dispatch) => {
  const ctx = logStart({ fn: "loadInventoryDetail", dsku });

  try {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    batch(async () => {
      dispatch(addLoader(InventoryLoaderId.DetailPage));
      dispatch(addLoader(InventoryLoaderId.CurrentLocations));
    });

    const loadInventoryDetails = Promise.all([dispatch(getProductDetail(dsku))]).then(() =>
      dispatch(clearLoader(InventoryLoaderId.DetailPage))
    );

    const loadCurrentLocations = Promise.all([loadGoogleMaps(), dispatch(getInventoryByWarehouse(dsku))])
      .then(() => dispatch(createWarehouseMarkers()))
      .then(() => dispatch(clearLoader(InventoryLoaderId.CurrentLocations)));

    await Promise.all([loadInventoryDetails, loadCurrentLocations]);
  } catch (err) {
    logError(ctx, err);
    batch(() => {
      dispatch(clearLoader(InventoryLoaderId.DetailPage));
      dispatch(clearLoader(InventoryLoaderId.CurrentLocations));
    });
  }
};

export const clearInventoryDetailState: Thunk = () => (dispatch) =>
  dispatch({ type: InventoryDetailActionTypes.RESET_INVENTORY_DETAIL });

export const updateMsku: Thunk = (msku: string) => async (dispatch, getState) => {
  const ctx = { fn: "updateMsku", msku };
  log.info(ctx, "renaming inventory product msku");
  const {
    inventoryDetail: { product },
  } = getState();

  try {
    dispatch(addLoader(InventoryLoaderId.DetailPage));
    await productClient.update({ dsku: product.dsku, msku });
    batch(() => {
      dispatch({ type: InventoryDetailActionTypes.UPDATE_MSKU_SUCCESS });
      dispatch(getProductDetail(product.dsku));
    });
    toast.success("SKU successfully changed", {
      autoClose: 5000,
      toastId: "renameInventoryProductMskuSuccess",
    });
  } catch (err) {
    notifyUserOfError({ err, toastId: "renameInventoryProductMskuError" });
    log.error({ ...ctx, err }, "error renaming msku");
  } finally {
    dispatch(clearLoader(InventoryLoaderId.DetailPage));
  }
};

export const setKitInstructions = createActionCreator<InventoryDetailState["kitInstructions"]>(
  InventoryDetailActionTypes.SET_KIT_INSTRUCTIONS,
  "kitInstructions"
);

export const setKitTextInstructions = createActionCreator<string>(
  InventoryDetailActionTypes.SET_KIT_TEXT_INSTRUCTIONS,
  "kitTextInstructions"
);

export const setKitInstructionsLoading = createActionCreator<InventoryDetailState["kitInstructionsLoading"]>(
  InventoryDetailActionTypes.KIT_INSTRUCTIONS_LOADING,
  "kitInstructionsLoading"
);
