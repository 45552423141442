import React, { FC } from "react";
import { useReturnsPackageDetail } from "./useReturnsPackageDetail";
import { Container } from "../../common/components/Container";
import { ReturnsPackageHeader } from "./header/ReturnsPackageHeader";
import { ReturnsRtmDetail } from "./ReturnsRtmDetail";
import { ReturnsPackageItemDetails } from "./ReturnsPackageItemDetails";
import WithLoader from "common/components/WithLoader";
import { ReturnLoader } from "../ReturnsLoader";
import styled from "@emotion/styled";

const StyledDiv = styled.div`
  background: white;
  box-shadow: 0 1px 2px rgba(0, 1, 0, 0.13);
  margin-bottom: 50px;
`;

const ReturnsPackageDetail: FC = () => {
  const { returnsPackageQcItemDetails, packageDetail } = useReturnsPackageDetail();
  return (
    <Container>
      <WithLoader name={ReturnLoader.PackagesDetailLoader}>
        <StyledDiv>
          <ReturnsPackageHeader />
          <ReturnsRtmDetail />
          <ReturnsPackageItemDetails
            returnsPackageQcItems={packageDetail?.returnsPackageQcItems ?? []}
            returnsPackageQcItemDetails={returnsPackageQcItemDetails}
          />
        </StyledDiv>
      </WithLoader>
    </Container>
  );
};

export default ReturnsPackageDetail;
