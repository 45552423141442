import { Button } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { transferHeaderLabels } from "./transferHeaderLabels";
import { useUserScopeMapping } from "common/user/useUserScopes";
import { TOOLTIP_MESSAGES } from "common/utils/tooltipMessages/tooltipMessages";

interface DuplicateOrderButtonProps {
  onDuplicate: () => void;
}

export const DuplicateOrderButton: FC<DuplicateOrderButtonProps> = ({ onDuplicate }) => {
  const userScopesMapping = useUserScopeMapping();
  const hasOrderWriteAccess = userScopesMapping.ORDERS?.write;
  return (
    <Button
      onClick={onDuplicate}
      data-testid="duplicate-order-button"
      secondary
      disabled={!hasOrderWriteAccess}
      tooltipMessage={!hasOrderWriteAccess ? TOOLTIP_MESSAGES.insufficientAccess : undefined}
    >
      <FormattedMessage {...transferHeaderLabels.duplicateOrder} />
    </Button>
  );
};
