import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { NonComplianceListProblemTypeFilter } from "./NonComplianceListProblemTypeFilter";
import { NonComplianceListStatusFilter } from "./NonComplianceListStatusFilter";

const StyledFilters = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;

    > div {
      margin-right: ${theme.spacing.S2};
    }

    label {
      margin-bottom: 0;
    }
  `
);

export const NonComplianceListFilters: FC = () => {
  return (
    <StyledFilters>
      <NonComplianceListProblemTypeFilter />
      <NonComplianceListStatusFilter />
    </StyledFilters>
  );
};
