import { SPThunkAction } from "common/ReduxUtils";
import { BookingItemRequest, BookingItemResponse } from "@deliverr/inbound-client";
import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { getSellerId } from "common/user/UserSelectors";
import { selectBookingItemRequest } from "../selectors/selectBookingItemRequest";

export const updateBookingItem =
  (shippingPlanId: number): SPThunkAction<Promise<BookingItemResponse>> =>
  async (dispatch, getState) => {
    const state = getState();
    const sellerId = getSellerId(state);
    const bookingRequest: BookingItemRequest = selectBookingItemRequest()(state);
    const ctx = logStart({ fn: SmbBookingCreationTimedEvent.UpdateBooking, shippingPlanId, bookingRequest });

    try {
      const response = await inboundClient.bookingUpdateItem(sellerId, shippingPlanId, bookingRequest);
      logSuccess(ctx, "successfully update item");
      return response;
    } catch (error) {
      logError(ctx, error);
      throw error;
    }
  };
