import React, { FC } from "react";
import { Anchor, DefaultTheme, Icon, IconsProps } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { AnyAction } from "redux";

import { FreightPalletRow } from "./FreightPalletRow/FreightPalletRow";
import { useFreightPalletTable } from "./useFreightPalletTable";
import { InboundFreightPalletSetup } from "@deliverr/inbound-client";

const AddPallet = styled(Anchor)<{}, DefaultTheme>`
  cursor: pointer;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.S5} 0;
`;

const Circle = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  font-size: ${theme.font.size.F1};
  display: inline-block;
  margin-right: ${theme.spacing.S1};
`
);

const Table = styled.table<{}, DefaultTheme>(({ theme }) => {
  return `
  margin-bottom: ${theme.spacing.S6};
  width: 100%;

  th {
    color: ${theme.colors.NEUTRAL[300]};
    font-weight: ${theme.font.weight.BOLD};
    font-size: ${theme.font.size.F1};
  }

  thead {
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]};
  }

  tfoot {
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]};
    border-top: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]};

    td {
      color: ${theme.colors.NEUTRAL[300]};
      font-size: ${theme.font.size.F1};
      line-height: ${theme.font.lineHeight.LH1};
      font-weight: ${theme.font.weight.BOLD};
    }
  }

  th, tfoot td {
    display: inline-block;
    min-width: 120px;
    padding: ${theme.spacing.S4} ${theme.spacing.S2};

    &:first-of-type {
      width: 270px;
      padding-left: 0;
    }
  }
`;
});

export interface FreightPalletTableProps {
  addPalletConfig: () => AnyAction;
  removePalletConfig: (index: number) => AnyAction;
  updatePalletConfig: (index: number, name: string, value: number) => AnyAction;
  hasMaxConfig: boolean;
  hasChangesLocked: boolean;
  hasPalletsLocked?: boolean;
  palletSum: number;
  weightSum: number;
  palletConfigurations: Partial<InboundFreightPalletSetup>[];
}

export const FreightPalletTable: FC<FreightPalletTableProps> = (props) => {
  const {
    addRow,
    dimensions,
    handlePalletConfigChange,
    hasMaxConfig,
    numberOfPallets,
    palletSum,
    palletConfigurations,
    hasChangesLocked,
    removeRow,
    total,
    totalWeight,
    weight,
    weightSum,
  } = useFreightPalletTable(props);

  const palletRows = palletConfigurations.map((pallet, index) => {
    return (
      <FreightPalletRow
        handleInputChange={handlePalletConfigChange(index)}
        // dont allow removal of the only pallet config
        hideRemoveButton={palletConfigurations.length === 1}
        key={`pallet-config-${String(index)}`}
        index={index}
        pallet={pallet as InboundFreightPalletSetup}
        hasChangesLocked={hasChangesLocked}
        removeRow={removeRow}
        hasPalletsLocked={props.hasPalletsLocked}
      />
    );
  });

  return (
    <Table>
      <thead>
        <tr>
          <th>{dimensions}</th>
          <th>{weight}</th>
          <th>{numberOfPallets}</th>
          <th>{totalWeight}</th>
        </tr>
      </thead>
      <tbody>
        {palletRows}
        {!hasMaxConfig && !hasChangesLocked && (
          <tr>
            <td>
              <AddPallet data-testid="addPallet" onClick={addRow}>
                <Circle type="plus-circle" size="xs" />
                <FormattedMessage id="inbounds.ltl.addAnotherPallet" defaultMessage="Add another pallet" />
              </AddPallet>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td>{total}</td>
          <td></td>
          <td>
            {palletSum} <FormattedPlural value={palletSum} one="pallet" other="pallets" />
          </td>
          <td>
            {weightSum} <FormattedPlural value={weightSum} one="lb" other="lbs" />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};
