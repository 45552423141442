import { defineMessages } from "react-intl";

export const packingInstructionsLabels = defineMessages({
  casePackLabel: {
    id: "storage.inbounds.detail.casePackingInstructions.casePack",
    defaultMessage: "Case packs must have product information or a scannable barcode on the outside of the case.",
  },

  palletLabel: {
    id: "storage.inbounds.detail.casePackingInstructions.palletLabel",
    defaultMessage: "1 pallet label must be applied to each side of the pallet.",
  },

  woodenPalletsLabel: {
    id: "inbounds.palletPackingInstructions.woodenPallets",
    defaultMessage: "Four-way pallets must be used. Pallets must be in good condition.",
  },

  maxWeightLabel: {
    id: "inbounds.palletPackingInstructions.maxWeight",
    defaultMessage: `Each pallet must weigh less than {maxWeight}lbs.`,
  },

  maxHeightLabel: {
    id: "inbounds.palletPackingInstructions.maxHeight",
    defaultMessage: `Pallet height, including the pallet, must be shorter than {maxHeight}". Pallets taller than {maxHeight}" are re-palletized at an additional cost.`,
  },
  description: {
    id: "inbounds.palletPackingInstructions.description",
    defaultMessage: `Follow these instructions carefully to ensure your shipment meets all requirements. All floor loaded containers (20' & 40') must remain at the fulfillment center for 2 business days to complete unloading. Live unloads are not offered for floor-loaded containers. Please monitor your Conduit appointment for a "completed" status to signal the empty is ready for pick up.`,
  },
});
