import React from "react";
import { FormattedMessage } from "react-intl";
import { noop } from "lodash/fp";

import { BulkUploadModal } from "components/BulkUpload/BulkUploadModal";
import ProductChooser, { SelectedProductData } from "common/components/ProductChooser";
import { useStorageInboundProductChooser } from "./useStorageInboundProductChooser";
import { FeatureName, useFeatureOn } from "common/Split";
import { BULK_UPLOAD_CONTENT_MESSAGES } from "components/BulkUpload/BulkUploadContentMessages";
import { Box, Button } from "common/components/ui";
import { FixUnitsPerCasePackWarning } from "components/alerts/FixUnitsPerCasePackWarning";
import { BulkUploadTemplateType } from "components/BulkUpload/BulkUploadTemplateType";
import { SingleSkuPackageCollection } from "inbounds/createShipment/CreateShipmentTypes";
import { FieldArrayWithId } from "react-hook-form";
import { CreateShipmentFormState } from "inbounds/createShipment/store";

export const UnconnectedStorageInboundProductChooser = (
  props: ReturnType<typeof useStorageInboundProductChooser> & {
    isDtcSingleSkuBooking?: boolean;
    isDtcMixedSkuBooking?: boolean;
    singleSkuPackages?: SingleSkuPackageCollection;
    isIntlRs?: boolean;
    isBulkUploadButtonEnabled?: boolean;
    index?: number;
    isIntlDtc?: boolean;
    mixedSkuPackage?: FieldArrayWithId<CreateShipmentFormState, `mixedSkuPackages.${number}.items`, "id">[];
  }
) => {
  const shouldIncludeBrandedPackaging = useFeatureOn(FeatureName.BrandedPackagingInboundCreation);

  const {
    isDtcSingleSkuBooking,
    isDtcMixedSkuBooking,
    isIntlRs,
    addProduct,
    selectedProducts,
    shouldShowFixCasePackWarning,
    removeProduct,
    updateCaseQty,
    updateNumberOfCases,
    shouldShowModal,
    setShowModal,
    handleStorageBulkUploadSuccess,
    showCasePackSelectionDropdown,
    selectedProductCasePacks,
    onChangeSelectedProductCasePack,
    onNewCasePackFormSubmit,
    shouldHideCasepackSingleUnitWarning,
    onCreateProductSuccess,
    singleSkuPackages,
    isBulkUploadButtonEnabled,
    index,
    mixedSkuPackage,
    isIntlDtc,
  } = props;

  const notificationComponent = shouldShowFixCasePackWarning ? (
    <Box marginBottom="S4" marginTop="S4">
      <FixUnitsPerCasePackWarning />
    </Box>
  ) : null;

  const bulkUploadButton = (
    <Button secondary onClick={() => setShowModal(true)}>
      <FormattedMessage {...BULK_UPLOAD_CONTENT_MESSAGES.UPLOAD_FILE} />
    </Button>
  );

  return (
    <>
      {!isIntlDtc && (
        <BulkUploadModal
          show={shouldShowModal}
          closeModal={() => setShowModal(false)}
          casePacked
          templateType={isIntlRs ? BulkUploadTemplateType.STORAGE_INTL : BulkUploadTemplateType.STORAGE}
          handleBulkUploadSuccess={handleStorageBulkUploadSuccess}
        />
      )}
      <ProductChooser
        singleSkuPackages={singleSkuPackages}
        mixedSkuPackage={mixedSkuPackage}
        isDtcSingleSkuBooking={isDtcSingleSkuBooking}
        isDtcMixedSkuBooking={isDtcMixedSkuBooking}
        addProduct={addProduct}
        removeProduct={removeProduct}
        selectedProducts={selectedProducts as unknown as SelectedProductData[]}
        useCasePack={!isDtcMixedSkuBooking}
        isReserveStorage
        updateQty={noop as any}
        allowZeroInventorySelection
        shouldShowAvailability={false}
        updateCaseQty={updateCaseQty}
        updateNumberOfCases={updateNumberOfCases}
        shouldHideFastTagAlert
        shouldHideBatteryColumn
        shouldIncludeBrandedPackaging={shouldIncludeBrandedPackaging}
        hideHiddenSKUs
        bulkUploadButton={isBulkUploadButtonEnabled ? bulkUploadButton : undefined}
        notificationComponent={notificationComponent}
        showCasePackSelectionDropdown={showCasePackSelectionDropdown}
        shouldHideCasepackSingleUnitWarning={shouldHideCasepackSingleUnitWarning}
        selectedProductCasePacks={selectedProductCasePacks}
        onChangeSelectedProductCasePack={onChangeSelectedProductCasePack}
        onNewCasePackFormSubmit={onNewCasePackFormSubmit}
        onCreateProductSuccess={onCreateProductSuccess}
        index={index}
        shouldShowSkuQtyLimitWarning={true}
      />
    </>
  );
};

export const StorageInboundProductChooser = ({
  isIntlRs,
  isBulkUploadButtonEnabled = true,
}: {
  isIntlRs?: boolean;
  isBulkUploadButtonEnabled?: boolean;
}) => {
  const connectedProps = useStorageInboundProductChooser();

  return (
    <UnconnectedStorageInboundProductChooser
      {...connectedProps}
      isIntlRs={isIntlRs}
      isBulkUploadButtonEnabled={isBulkUploadButtonEnabled}
    />
  );
};
