import { FeatureName } from "common/Split";
import { InventoryListTab } from "inventory/list/InventoryListReducer";
import { InventoryLocationFilterOptions } from "inventory/list/InventoryListTableControls/InventoryListFilters/InventoryLocationFilter/InventoryLocationFilterOptions";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { isFeatureOn } from "common/Split/isFeatureOn";

interface InventorySearchInput {
  currentTab?: InventoryListTab;
  searchTerm?: string;
  searchFilters?: InventoryOpenSearchFilters;
  isHiddenOnSellerPortal?: boolean;
  inventoryLocation?: InventoryLocationFilterOptions;
}

export interface InventoryOpenSearchFilters {
  hiddenFilter?: boolean;
  excludedDskuFilter?: boolean;
  shouldIncludeBrandedPackaging?: boolean;
  productPackFilter?: boolean;
}

type InventorySearchFilterFactory = (input: InventorySearchInput) => QueryDslQueryContainer[];

export const constructOpenSearchFilters = (input: InventorySearchInput): QueryDslQueryContainer[] => {
  const searchTermFilters = getSearchTermFilters(input);
  const productBooleanFilters = getProductBooleanFilters(input);
  const kitFilters = getKitFilters(input);
  const removalFilters = getRemovalFilters(input);
  const reserveStorageFilters = getReserveStorageFilters(input);

  return [...searchTermFilters, ...productBooleanFilters, ...kitFilters, ...removalFilters, ...reserveStorageFilters];
};

export const getSearchTermFilters: InventorySearchFilterFactory = ({ currentTab, searchTerm }) => {
  if (!searchTerm) {
    return [];
  }

  const skuKey = currentTab === "BUNDLES" ? "bsku" : "dsku";
  return [
    {
      bool: {
        must: {
          dis_max: {
            queries: [
              {
                match_phrase: {
                  name: {
                    query: searchTerm,
                    slop: 2,
                    analyzer: "autocomplete_search",
                  },
                },
              },
              {
                match: {
                  msku: {
                    query: searchTerm,
                    fuzziness: 0,
                    operator: "and",
                    analyzer: "autocomplete_search",
                  },
                },
              },
              {
                match: {
                  "productAliases.msku": {
                    query: searchTerm,
                    fuzziness: 0,
                    operator: "and",
                  },
                },
              },
              {
                prefix: {
                  [skuKey]: {
                    value: searchTerm,
                    case_insensitive: true,
                  },
                },
              },
              {
                prefix: {
                  asin: {
                    value: searchTerm,
                    case_insensitive: true,
                  },
                },
              },
            ],
          },
        },
      },
    },
  ];
};

const getProductBooleanFilters: InventorySearchFilterFactory = ({
  currentTab,
  isHiddenOnSellerPortal,
  searchFilters,
}) => {
  if (currentTab === "BUNDLES") {
    return [{ bool: { must: [{ term: { isHiddenOnSellerPortal: isHiddenOnSellerPortal ? 1 : 0 } }] } }];
  }

  const filters: QueryDslQueryContainer[] = [];
  if (searchFilters?.shouldIncludeBrandedPackaging !== true) {
    filters.push({ bool: { must_not: [{ term: { _tags: "BRANDED_PACKAGING_DSKU" } }] } });
  }
  filters.push({ bool: { must_not: [{ term: { _tags: "PACK" } }] } });
  filters.push({ bool: { must: [{ term: { isHiddenOnSellerPortal: isHiddenOnSellerPortal ? 1 : 0 } }] } });

  return filters;
};

const getKitFilters: InventorySearchFilterFactory = ({ currentTab }: InventorySearchInput) => {
  if (currentTab !== "PRODUCTS" && currentTab !== "KITS") {
    return [];
  }

  if (!isFeatureOn(FeatureName.PrepKittingEnabled)) {
    return [];
  }

  return [{ bool: { must: { term: { isKit: currentTab === "KITS" } } } }];
};

const getRemovalFilters: InventorySearchFilterFactory = ({ currentTab }) => {
  if (currentTab !== "ELIGIBLE_FOR_REMOVAL") {
    return [];
  }

  const customizedOpenSearchFilters: QueryDslQueryContainer[] = [];

  // Check products that are
  // * NOT InvalidDimensions
  // * NOT OutOfStock : onHandQty > 0
  // * OutOfStock : onHandQty - unfilledOrderQty - unavailableQty > 0
  customizedOpenSearchFilters.push({
    bool: {
      must: [
        {
          exists: {
            field: "dimSourceType",
          },
        },
        {
          terms: {
            dimSourceType: ["warehouse", "manual", "seller", "averageregression", "crossdock", "amazonscraped"],
          },
        },
        {
          script: {
            script: {
              source: "doc['onHandQty'].value - doc['unfilledOrderQty'].value - doc['unavailableQty'].value > 0",
              lang: "painless",
            },
          },
        },
        {
          range: {
            onHandQty: {
              gt: 0,
            },
          },
        },
      ],
    },
  });
  // Check Hazmat : hazmatInformation / hazmatInformation.status field is not exist or hazmatInformation.status is APPROVED
  customizedOpenSearchFilters.push({
    bool: {
      should: [
        {
          bool: {
            must_not: [
              {
                exists: {
                  field: "hazmatInformation",
                },
              },
              {
                exists: {
                  field: "hazmatInformation.status",
                },
              },
            ],
          },
        },
        {
          term: {
            "hazmatInformation.status": "APPROVED",
          },
        },
      ],
      minimum_should_match: 1,
    },
  });
  return customizedOpenSearchFilters;
};

const getReserveStorageFilters: InventorySearchFilterFactory = ({ inventoryLocation }) => {
  if (inventoryLocation !== InventoryLocationFilterOptions.RESERVE_STORAGE) {
    return [];
  }

  return [{ bool: { must: { term: { hasInventoryOnPacks: true } } } }];
};
