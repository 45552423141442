import React, { FC } from "react";
import Modal from "common/components/Modal";
import styled from "@emotion/styled";
import { ThemeProps, Title, Button, Stack, Box, Notification } from "common/components/ui";
import { DatePicker } from "common/components/DatePicker";
import { isDate } from "lodash";
import {
  FBA_V3_MIN_DAYS_FOR_PRODUCT_EXPIRATION,
  getFbaLotAndExpiryPickerModalIdForSku,
} from "../commons/FbaV3Constants";
import { addDays } from "date-fns";
import { FbaLotAndExpiryPickerLabels } from "./FbaLotAndExpiryPickerLabels";
import { FormattedMessage } from "react-intl";

const StyledModal = styled(Modal)<ThemeProps>(
  ({ theme }) => `
  .modal-dialog {
    width: 480px;
  }
  .modal-content {
    display: flex;
    justify-content: center;
    padding: ${theme.spacing.S7};
  }
`
);

const FormTitle = styled(Title)(
  ({ theme }: ThemeProps) => `
  align-self: center;
  padding-top: ${theme.spacing.S3};
  padding-bottom: ${theme.spacing.S3};
`
);

const ButtonContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  gap: ${theme.spacing.S2};
`
);

export interface FbaLotAndExpiryPickerModalProps {
  dsku: string;
  onCloseModal: () => void;
  onExpiryChange: (date?: Date) => void;
  expirationDate?: Date;
}

export const FbaLotAndExpiryPickerModal: FC<FbaLotAndExpiryPickerModalProps> = ({
  dsku,
  onCloseModal,
  onExpiryChange,
  expirationDate,
}) => {
  return (
    <StyledModal
      id={getFbaLotAndExpiryPickerModalIdForSku(dsku)}
      size="md"
      hasCloseButton={true}
      onClose={onCloseModal}
    >
      <Stack>
        <Box width={"100%"} paddingLeft={"S2"} paddingRight={"S2"}>
          <FormTitle displayAs="h4">
            <FormattedMessage {...FbaLotAndExpiryPickerLabels.setExpiryDateLabel} />
          </FormTitle>

          <Box width={"100%"} paddingBottom={"S3"}>
            <Notification
              appearance="DEFAULT"
              content={{
                description: (
                  <FormattedMessage
                    {...FbaLotAndExpiryPickerLabels.expiryDatePickerNotification}
                    values={{ br: () => <br /> }}
                  />
                ),
              }}
            />
          </Box>

          <Box width={"100%"} paddingBottom={"S3"}>
            <Notification
              appearance="WARNING"
              content={{
                description: <FormattedMessage {...FbaLotAndExpiryPickerLabels.expiryDatePickerMinDateNotification} />,
              }}
            />
          </Box>

          <Box paddingTop={"S3"} paddingBottom={"S5"}>
            <DatePicker
              onDateChange={onExpiryChange}
              date={expirationDate}
              minDate={addDays(new Date(), FBA_V3_MIN_DAYS_FOR_PRODUCT_EXPIRATION)}
            />
          </Box>

          <ButtonContainer>
            <Button block secondary onClick={onCloseModal}>
              <FormattedMessage {...FbaLotAndExpiryPickerLabels.cancelButtonText} />
            </Button>

            <Button block onClick={onCloseModal} disabled={!isDate(expirationDate)}>
              <FormattedMessage {...FbaLotAndExpiryPickerLabels.submitButtonText} />
            </Button>
          </ButtonContainer>
        </Box>
      </Stack>
    </StyledModal>
  );
};
