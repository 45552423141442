import { SellerProblemsDetailView } from "@deliverr/inbound-client";
import { ProductCollection } from "common/models";
import { getMappedSkuPairs } from "inbounds/steps/ship/view/cards/ShipmentProblems/spvUtils/getMappedSkuPairs";
import { OperationalException } from "common/components/ProblemsTable/types";
import { ProblemType } from "common/problems/ProblemType";
export const getNonComplianceRowsFromOperationalException = (
  genericIssue: SellerProblemsDetailView,
  productCollection?: ProductCollection
): OperationalException => {
  const {
    sellerProblemId,
    status,
    updatedAt,
    dsku,
    shippingPlanId,
    shippingPlanName,
    reportedUnits,
    receivedUnits,
    issueId,
    isLegacyCase,
    affectedCdskus,
    createdAt,
    exceptionTypeName,
    externalMessage,
    dueAt,
  } = genericIssue;

  return {
    id: sellerProblemId?.toString() ?? issueId!,
    isLegacyCase,
    problemType: ProblemType.FLEXPORT_SHIP,
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    status,
    units: reportedUnits ?? receivedUnits,
    mappedSkus: productCollection
      ? getMappedSkuPairs(genericIssue, productCollection)
      : dsku
      ? [{ dsku, msku: "", productName: "" }]
      : [],
    affectedCdskus,
    acknowledgedAt: new Date(),
    externalMessage,
    exceptionTypeName,
    dueAt,
  };
};
