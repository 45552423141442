import { Report } from "./Report";
import { SellerReportType } from "common/clients/reporting/Report/SellerReportType";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { ReportsActionTypes } from "report/ReportsActions";
import { SelectedProductData } from "common/components/ProductChooser";
import { ReportDateRangeType } from "common/clients/reporting/Report/ReportDateRangeType";

export interface ReportsState {
  dateEnd?: Date;
  dateStart?: Date;
  logisticsSku?: string;
  pendingReports: Report[];
  reports: Report[];
  reportType?: SellerReportType;
  reportTypes: SellerReportType[];
  selectedProduct?: SelectedProductData;
  dateRangeType?: ReportDateRangeType;
}

export const reportsInitialState: ReportsState = {
  reportTypes: [],
  reports: [],
  pendingReports: [],
};

const reducers: ReducerDictionary<ReportsState> = {
  ...handleSimpleReducerUpdates([
    ReportsActionTypes.UPDATE_DATE_RANGE,
    ReportsActionTypes.UPDATE_LOGISTICS_SKU,
    ReportsActionTypes.UPDATE_REPORT_TYPE,
    ReportsActionTypes.UPDATE_SELECTED_PRODUCT,
    ReportsActionTypes.UPDATE_DATE_RANGE_TYPE,
  ]),
  [ReportsActionTypes.CLEAR_EMPTY_REPORT]: (state) => ({
    ...state,
    pendingReports: state.pendingReports?.slice(0, -1),
  }),
  [ReportsActionTypes.CLEAR_REPORT_FIELDS]: (state) => ({
    ...reportsInitialState,
    reportTypes: state.reportTypes,
    reports: state.reports,
    pendingReports: state.pendingReports,
  }),
  [ReportsActionTypes.GENERATE_REPORT]: (state, { report }) => ({
    ...state,
    pendingReports: [report, ...state.pendingReports],
  }),
  [ReportsActionTypes.GET_REPORTS]: (state, { reports, reportTypes, newReportIds }) => ({
    ...state,
    reports,
    reportTypes,
    pendingReports: state.pendingReports?.slice(0, -1 * newReportIds.length),
  }),
};

export const reportsReducer = createReducer<ReportsState>(reportsInitialState, reducers);
