import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { EntityError, OrderStatus } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { logError } from "Logger";
import { FbaV3EntityName, FbaV3Loaders } from "../../commons/FbaV3Constants";
import { FormattedMessage } from "react-intl";
import React from "react";
import { toast } from "common/components/ui";
import { transferCreateSetReplenishmentError } from "transfers/create/actions";
import { getFlattenedEntityErrors } from "../../commons/FbaV3Utils";
import { buildReplenishmentUpdateOrderRequest } from "transfers/create/actions/replenishmentOrder/updateReplenishmentOrder";
import { getPlacementOptionsData } from "../../commons/FbaV3Selectors";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { FBAConfirmOrderRequest } from "@deliverr/replenishment-client/lib/dtos/fba-v3/order-confirmation/FBAConfirmOrderRequest";
import { FbaV3Labels } from "../../commons/FbaV3Labels";

export const confirmFbaV3Order =
  (): SPThunkAction =>
  async (dispatch, getState): Promise<boolean> => {
    dispatch(addLoader(FbaV3Loaders.CONFIRM_ORDER));

    try {
      const { replenishmentOrder } = selectTransferCreate(getState());

      if (!replenishmentOrder) {
        throw new Error("Replenishment order not found in state");
      }

      const { value: order } = await replenishmentClient.getOrder(replenishmentOrder.orderId);

      if (order.orderStatus === OrderStatus.DRAFT) {
        const updateOrderRequest = await buildFbaV3OrderRequest(dispatch, getState);
        await replenishmentClient.confirmFBAOrder(replenishmentOrder.orderId, updateOrderRequest);
      }

      return true;
    } catch (err) {
      logError({ fn: "confirmFbaV3Order" }, err, "Failed to confirm FBA order");
      const error = err?.response?.data?.error ?? err;

      if (error instanceof EntityError) {
        dispatch(
          transferCreateSetReplenishmentError({
            errors: getFlattenedEntityErrors([error]),
            hasError: true,
          })
        );
      } else {
        dispatch(
          transferCreateSetReplenishmentError({
            errors: [
              new EntityError({
                message: error?.message,
                code: error?.code,
              }),
            ],
            hasError: true,
          })
        );
      }

      toast.error(<FormattedMessage {...FbaV3Labels[FbaV3EntityName.ORDER_CONFIRMATION].fetchFailureTitle} />, {
        autoClose: false,
        toastId: "confirmFbaV3OrderError",
      });

      return false;
    } finally {
      dispatch(clearLoader(FbaV3Loaders.CONFIRM_ORDER));
    }
  };

const buildFbaV3OrderRequest = async (dispatch, getState): Promise<FBAConfirmOrderRequest> => {
  const { selectedPlacementOptionId } = getPlacementOptionsData(getState());
  const updateOrderRequest = await buildReplenishmentUpdateOrderRequest(dispatch, getState, true, false);

  return {
    ...updateOrderRequest,
    confirmedPlacementOptionId: selectedPlacementOptionId!,
  };
};
