import { replenishmentClient } from "Clients";
import { OrderFetchOptions, OrderResponse } from "@deliverr/replenishment-client";

export const getReplenishmentOrder = async (
  orderId: string,
  fetchOptions?: OrderFetchOptions
): Promise<OrderResponse> => {
  const response = (await replenishmentClient.getOrder(orderId, fetchOptions)).value;
  return response;
};
