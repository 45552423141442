import { SkuQuantity } from "@deliverr/inbound-client";
import { ATPMap } from "common/clients/inventory/ATP/ATPMap";
import { SelectedProductData } from "common/components/ProductChooser";
import { ProductCollection } from "common/models";
import { ProductListItem } from "inventory/list/InventoryListReducer";

export const mapSkuCaseQtiesToSelectedProducts = (
  skuCaseQties?: PartialSkuCaseQuantity[],
  productDetails?: ProductCollection,
  storageProductsInventory?: ATPMap,
  productEcomInventory?: { [dsku: string]: ProductListItem },
  isEachPicking?: boolean
): { [dsku: string]: SelectedProductData } => {
  return (skuCaseQties ?? []).reduce((map, { dsku, qty, caseQty }) => {
    const safeCaseQty: number = isEachPicking ? 1 : caseQty ?? productDetails?.[dsku]?.caseQty ?? 1;
    const packOf = productDetails?.[dsku]?.packOf ?? dsku;

    map[dsku] = {
      ...productDetails?.[dsku],
      qty: qty || 0,
      caseQty: safeCaseQty,
      numberOfCases: Number(qty || 0) / safeCaseQty,
      storageOnHandQty: storageProductsInventory?.[dsku]?.onHandQty,
      storageNonPickableQty: storageProductsInventory?.[dsku]?.nonPickableQty,
      storageUnfilledOrderQty: storageProductsInventory?.[dsku]?.unfilledOrderQty,
      storageInTransferQty: storageProductsInventory?.[dsku]?.inTransferQty,
      storageUnavailableQty: storageProductsInventory?.[dsku]?.unavailableQty,

      ...(!!packOf && {
        onHandQty: productEcomInventory?.[packOf]?.onHandQty,
        nonPickableQty: productEcomInventory?.[packOf]?.nonPickableQty,
        unfilledOrderQty: productEcomInventory?.[packOf]?.unfilledOrderQty,
        inTransferQty: productEcomInventory?.[packOf]?.inTransferQty,
        unavailableQty: productEcomInventory?.[packOf]?.unavailableQty,
        unitsSoldLast30Days: productEcomInventory?.[packOf]?.unitsSoldLast30Days,
      }),
    };
    return map;
  }, {});
};

export type PartialSkuCaseQuantity = SkuQuantity & { caseQty?: number };
