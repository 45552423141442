import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../RootReducer";
import { useMount, useUnmount } from "react-use";
import { getReturnsPackage, resetPackageDetails } from "./store/ReturnsPackageDetailsActions";
import { useIntl } from "react-intl";
import { toast } from "../../common/components/ui";
import history from "BrowserHistory";
import { Path } from "../../paths";
import { returnsPackageDetailsMessages } from "./ReturnsPackageDetailsMessages";

export const useReturnsPackageDetail = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { packageId } = useParams<{ packageId: string }>();
  const { packageDetail, returnsPackageQcItemDetails } = useSelector((state: RootState) => state.returnsPackageDetail);

  enum ReturnsErrorCodes {
    NOT_FOUND = "NOT_FOUND",
  }

  useMount(async () => {
    try {
      await dispatch(getReturnsPackage(packageId));
    } catch (err) {
      history.push(Path.returnsPackages);
      if (err?.code && err.code === ReturnsErrorCodes.NOT_FOUND) {
        toast.error(formatMessage(returnsPackageDetailsMessages.getPackageError.notFound), {
          autoClose: 3000,
          toastId: "returnsPackageDetailNotFound",
        });
      }
      toast.error(formatMessage(returnsPackageDetailsMessages.getPackageError.unknownError), {
        autoClose: 3000,
        toastId: "returnsPackageDetailFetchError",
      });
    }
  });

  useUnmount(() => {
    dispatch(resetPackageDetails());
  });

  return {
    returnsPackageQcItemDetails,
    packageDetail,
  };
};
