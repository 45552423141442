import { defineMessages } from "react-intl";

export const ReturnsDetailInformationLabel = defineMessages({
  customer: {
    id: "returns.returnsDetail.returnsDetailInformation.customer",
    defaultMessage: "Customer: {customer}",
  },
  totalCostHeading: {
    id: "returns.returnsDetail.returnsDetailInformation.totalCostHeading",
    defaultMessage: "Total Order Cost",
  },
  returnOutcome: {
    id: "returns.returnsDetail.returnsDetailInformation.returnOutcome",
    defaultMessage: "Return Outcome : {returnOutcome}",
  },
  providerOrderNumber: {
    id: "returns.returnsDetail.returnsDetailInformation.providerOrderNumber",
    defaultMessage: "Provider Order Number : {providerOrderNumber}",
  },
  packageReference: {
    id: "returns.returnsDetail.returnsDetailInformation.packageReference",
    defaultMessage: "Package Reference : {packageReference}",
  },
  channelCreationDate: {
    id: "returns.returnsDetail.returnsDetailInformation.creationDate",
    defaultMessage: "Return Creation Date : {channelCreationDate}",
  },
  channelProcessedAt: {
    id: "returns.returnsDetail.returnsDetailInformation.channelProcessedDate",
    defaultMessage: "Channel Processed Date : {channelProcessedDate}",
  },
  detailHeading: {
    id: "returns.returnsDetail.returnsDetailInformation.detailHeading",
    defaultMessage: "Detail",
  },
});
