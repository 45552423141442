import React from "react";
import { Box, Card, DefaultTheme, Stack, Title } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { useReturnsPackageDetailHeader } from "./useReturnsPackageDetailHeader";
import { BoxStatusPill } from "../../package/BoxStatusPill";
import { returnsPackageDetailsMessages } from "../ReturnsPackageDetailsMessages";

const returnPadding = "40px";
const returnPackageIdSize = "28px";
const returnBarcodeSize = "26px";

const Container = styled(Card)<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${returnPadding};
  display: flex;
  justify-content: space-between;
  border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
`
);

const ReturnsPackageTitle = styled(Title)`
  margin: 0;
  font-size: ${returnPackageIdSize};
`;

const ReturnsBarcodeTitle = styled(Title)`
  margin: 0;
  font-size: ${returnBarcodeSize};
`;

export const ReturnsPackageHeader = () => {
  const { packageId, boxBarcode, boxStatus } = useReturnsPackageDetailHeader();
  return (
    <Container>
      <Stack>
        <ReturnsPackageTitle as="h3" displayAs="h2">
          <FormattedMessage {...returnsPackageDetailsMessages.titlePackageId} values={{ packageId }}></FormattedMessage>
        </ReturnsPackageTitle>
        <ReturnsBarcodeTitle as="h3" displayAs="h3">
          <FormattedMessage {...returnsPackageDetailsMessages.titleBarcode} values={{ boxBarcode }}></FormattedMessage>
        </ReturnsBarcodeTitle>
      </Stack>
      {boxStatus && (
        <Box>
          <BoxStatusPill boxStatus={boxStatus} widePill />
        </Box>
      )}
    </Container>
  );
};
