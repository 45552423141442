import { SellerProblemsDetailView } from "@deliverr/inbound-client";
import { SUPPORTED_SELLER_PROBLEM_STATUSES } from "common/problems/SellerProblemStatus";
import { SUPPORTED_SELLER_PROBLEM_TYPES } from "common/problems/SellerProblemType";

import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import produce from "immer";
import { NonComplianceListActionTypes } from "./actions/NonComplianceListActionTypes";
import { NonComplianceListSearchFilters } from "./NonComplianceListFilters/NonComplianceFilterListTypes";
import {
  NonComplianceProductWithPlanName,
  MissingBarcodeCaseWithPlanName,
  UnexpectedBarcodeCaseWithPlanName,
} from "./types";

export interface NonComplianceListState {
  currentPageItems: SellerProblemsDetailView[];
  searchInfo?: {
    totalHits: number;
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
  };
  searchLoading: boolean;
  searchTerm?: string;
  searchFilters: NonComplianceListSearchFilters;
  ncProducts: NonComplianceProductWithPlanName[];
  missingBarcodeCases: MissingBarcodeCaseWithPlanName[];
  unexpectedBarcodeCases: UnexpectedBarcodeCaseWithPlanName[];
  unexpectedSkuCases: SellerProblemsDetailView[];
}

export const nonComplianceListInitialState: NonComplianceListState = {
  currentPageItems: [],
  searchLoading: true,
  searchFilters: {
    problemTypes: SUPPORTED_SELLER_PROBLEM_TYPES,
    problemStatuses: SUPPORTED_SELLER_PROBLEM_STATUSES,
  },
  searchInfo: {
    totalHits: 0,
    currentPage: 0,
    itemsPerPage: 10,
    totalPages: 0,
  },
  ncProducts: [],
  missingBarcodeCases: [],
  unexpectedBarcodeCases: [],
  unexpectedSkuCases: [],
};

const reducers: ReducerDictionary<NonComplianceListState> = {
  ...handleSimpleReducerUpdates([
    NonComplianceListActionTypes.SET_UNKNOWN_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_MISSING_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_UNEXPECTED_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_UNEXPECTED_SKU_NC_CASES,
    NonComplianceListActionTypes.SET_CURRENT_NON_COMPLIANCE_SEARCH_TERM,
    NonComplianceListActionTypes.SET_SEARCH_FILTERS,
    NonComplianceListActionTypes.SEARCH_NON_COMPLIANCE_SUCCESS,
  ]),
  [NonComplianceListActionTypes.SET_CURRENT_NON_COMPLIANCE_PAGE]: (state, { page }) =>
    produce(state, (draft) => {
      if (draft.searchInfo) {
        draft.searchInfo.currentPage = page;
      }
    }),
};

export const nonComplianceListReducer = createReducer(nonComplianceListInitialState, reducers);
