import { NavBar, linkWithState } from "common/components/NavBar";
import {
  ReturnsPackagesPage,
  ReturnsCreate,
  ReturnsDetail,
  ReturnsLabel,
  ReturnsListPage,
  ReturnsPolicyPage,
  ReturnsPackageDetail,
} from "chunks";
import { Route, generatePath, useParams } from "react-router-dom";

import { Path } from "paths";
import React from "react";
import { ReturnsRmtContainer } from "./rmt/ReturnsRmtContainer";
import { RmtId } from "@deliverr/returns-client";
import { RootState } from "RootReducer";
import { rmtConfigProp } from "./rmt/RmtUtils";
import { useSelector } from "react-redux";

function NavComponent(params) {
  const { sellerInfo } = useSelector((state: RootState) => state?.returnsSeller);
  const breadcrumbs = sellerInfo?.enableReturn
    ? [
        {
          pageName: "Returns",
          path: linkWithState(Path.returnsList, { preserveState: true }),
        },
      ]
    : [
        {
          pageName: "Returns",
          path: linkWithState(Path.returnsList, { preserveState: true }),
        },
        { pageName: "Get started" },
      ];
  return <NavBar breadcrumbs={breadcrumbs} />;
}

function RmtNavComponent() {
  const { rmtId } = useParams<{ rmtId: string }>() as { rmtId: RmtId };
  const rmtName = rmtConfigProp(rmtId, "name");

  const breadcrumbs = [
    {
      pageName: "Returns",
      path: linkWithState(Path.returns, { preserveState: true }),
    },
    { pageName: "Settings", path: linkWithState(Path.returnsPolicy, { preserveState: true }) },
    {
      pageName: `${rmtName}`,
      path: linkWithState(generatePath(Path.returnsRmtPolicy, { rmtId }), { preserveState: true }),
    },
  ];
  return <NavBar breadcrumbs={breadcrumbs} />;
}

export const returnsHeaderRoutes = [
  <Route
    key={"returnsCreateHeader"}
    path={Path.returnsCreate}
    exact={true}
    render={() => (
      <NavBar
        breadcrumbs={[
          {
            pageName: "Returns",
            path: linkWithState(Path.returnsList, { preserveState: true }),
          },
          { pageName: "Create return" },
        ]}
      />
    )}
  />,
  <Route
    key={"returnsLabelHeader"}
    path={Path.returnsLabel}
    exact={true}
    render={() => (
      <NavBar
        breadcrumbs={[
          {
            pageName: "Returns",
            path: linkWithState(Path.returnsList, { preserveState: true }),
          },
          { pageName: "Return Label" },
        ]}
      />
    )}
  />,
  <Route
    key={"returnsListHeader"}
    path={Path.returnsList}
    exact={true}
    render={() => {
      return <NavComponent />;
    }}
  />,
  <Route
    key={"returnPolicyHeader"}
    path={Path.returnsPolicy}
    exact={true}
    render={() => (
      <NavBar
        breadcrumbs={[
          {
            pageName: "Return Settings",
          },
        ]}
      />
    )}
  />,
  <Route
    key="orderReturnsDetailHeader"
    path={Path.returnsDetail}
    exact={true}
    render={() => (
      <NavBar
        breadcrumbs={[
          {
            pageName: "Returns",
            path: linkWithState(Path.returnsList, { preserveState: true }),
          },
          { pageName: "Return Detail" },
        ]}
      />
    )}
  />,
  <Route
    key={"returnsRmtPolicyHeader"}
    path={Path.returnsRmtPolicy}
    exact={true}
    render={() => {
      return <RmtNavComponent />;
    }}
  />,
  <Route
    key={"returnsPackageHeader"}
    path={Path.returnsPackages}
    exact={true}
    render={() => (
      <NavBar
        breadcrumbs={[
          {
            pageName: "Returns Packages",
          },
        ]}
      />
    )}
  />,
  <Route
    key={"returnPackageDetailsHeader"}
    path={Path.returnsPackageDetail}
    exact={true}
    render={() => (
      <NavBar
        breadcrumbs={[
          {
            pageName: "Returns Packages",
            path: linkWithState(Path.returnsPackages, { preserveState: true }),
          },
          {
            pageName: "Returns Package Detail",
          },
        ]}
      />
    )}
  />,
];

export const returnsRoutes = [
  <Route key={"returnsCreate"} path={Path.returnsCreate} exact={true} component={ReturnsCreate} />,
  <Route key={"returnsLabel"} path={Path.returnsLabel} exact={true} component={ReturnsLabel} />,
  <Route
    key={"returnsDetail"}
    path={[Path.returnsDetail, Path.returnsDetailWithId]}
    exact={true}
    component={ReturnsDetail}
  />,
  <Route key={"returnsList"} path={Path.returnsList} exact={true} component={ReturnsListPage} />,
  <Route key={"returnsPolicy"} path={Path.returnsPolicy} exact={true} component={ReturnsPolicyPage} />,
  <Route key={"returnsPackages"} path={Path.returnsPackages} exact={true} component={ReturnsPackagesPage} />,
  <Route key={"returnsRmt"} path={Path.returnsRmtPolicy} exact={true} component={ReturnsRmtContainer} />,
  <Route key={"returnsRmt"} path={Path.returnsRmtPolicyWithToken} exact={true} component={ReturnsRmtContainer} />,
  <Route key={"returnsPackageDetail"} path={Path.returnsPackageDetail} exact={true} component={ReturnsPackageDetail} />,
];
