import { DeliverrAddress, SkuPair } from "@deliverr/commons-objects";
import { produce } from "immer";
import { isEmpty, omit, pickBy, some } from "lodash/fp";

import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { SearchProduct, NewOrderActionTypes } from "order/new/NewOrderActions";
import { NewOrderStep } from "order/new/NewOrderStep";
import { isInternationalAddress } from "common/AddressUtils";
import { ItemizedPrice } from "@deliverr/billing-pricer-client";
import { calculateAvailableQuantity } from "../../common/utils/product/calculateAvailableQuantity";
import { checkForAddressErrors } from "common/components/AddressInput/utils/addressValidationUtils";

interface ProductDictionary {
  [dsku: string]: SearchProduct;
}

export interface NewOrderState {
  items: SkuPair[];
  productDetails: ProductDictionary;
  address?: DeliverrAddress;
  step: NewOrderStep;
  shippingMethod?: string;
  shippingCosts?: { [key: string]: ItemizedPrice };
  isValid: boolean;
  savingOrder: boolean;
  invalidDskus: string[];
  isRemoval: boolean;
  isLotRemoval: boolean;
  isMethodsVisible: boolean;
  validateAddressInput: {
    street1?: boolean;
    street2?: boolean;
    city?: boolean;
    state?: boolean;
    country?: boolean;
    zip?: boolean;
    phone?: boolean;
    name?: boolean;
    company?: boolean;
  };
}

export const newOrderInitialState: NewOrderState = {
  items: [],
  /**
   * Currently we're caching some catalog info about the product
   * to reduce requests since we don't have that info elsewhere
   */
  productDetails: {},
  step: NewOrderStep.SELECT_PRODUCTS,
  isValid: false,
  savingOrder: false,
  invalidDskus: [],
  isRemoval: false,
  isLotRemoval: false,
  isMethodsVisible: false,
  validateAddressInput: {
    name: true,
    company: true,
    street1: true,
    street2: true,
    city: true,
    state: true,
    country: true,
    zip: true,
    phone: true,
  },
};

const reducers: ReducerDictionary<NewOrderState> = {
  [NewOrderActionTypes.ADD_PRODUCT]: (state, { sku, product }) => {
    const { isRemoval } = state;
    const defaultQty = isRemoval ? calculateAvailableQuantity(product) : 1;
    return validateOrder({
      ...state,
      items: [{ dsku: sku, qty: defaultQty }, ...state.items],
      productDetails: {
        ...state.productDetails,
        [sku]: product,
      },
    });
  },
  [NewOrderActionTypes.ADD_PRODUCTS]: (state, { products }) => {
    const { isRemoval } = state;
    const newState = produce(state, (draft) => {
      const productItems = products.map((product) => {
        const defaultQty = isRemoval ? calculateAvailableQuantity(product) : 1;
        return {
          dsku: product.dsku,
          qty: defaultQty,
        };
      });
      draft.items.push(...productItems);
      draft.productDetails = products.reduce((acc, product) => {
        acc[product.dsku] = product;
        return acc;
      }, draft.productDetails);
    });
    return validateOrder(newState);
  },
  [NewOrderActionTypes.REMOVE_PRODUCT]: (state, { sku }) =>
    validateOrder({
      ...state,
      items: state.items.filter((item) => item.dsku !== sku),
      productDetails: pickBy(Boolean, state.productDetails) as ProductDictionary,
    }),
  [NewOrderActionTypes.UPDATE_QTY]: (state, { sku, qty }) =>
    validateOrder({
      ...state,
      items: state.items.map((item) => (item.dsku === sku ? { ...item, qty } : item)),
    }),
  [NewOrderActionTypes.SET_ADDRESS]: (state, action) => validateOrder({ ...state, address: action.address }),
  [NewOrderActionTypes.SET_ORDER_SHIPPING_METHOD]: (state, action) =>
    validateOrder({
      ...state,
      shippingMethod: action.shippingMethod,
    }),
  [NewOrderActionTypes.SET_ORDER_SHIPPING_COSTS]: (state, action) =>
    validateOrder({
      ...state,
      shippingCosts: action.shippingCosts,
    }),
  [NewOrderActionTypes.REVIEW_ORDER]: (state) => ({
    ...state,
    step: NewOrderStep.REVIEW,
  }),
  [NewOrderActionTypes.MODIFY_ORDER]: (state) => ({
    ...state,
    step: NewOrderStep.SELECT_PRODUCTS,
  }),
  [NewOrderActionTypes.CHECK_PRODUCT]: (state, action) => ({
    ...state,
    invalidDskus: action.newInvalidDskus,
  }),
  [NewOrderActionTypes.CLEAR_NEW_ORDER]: () => ({ ...newOrderInitialState }),
  [NewOrderActionTypes.SET_REMOVAL]: (state) => ({
    ...state,
    isRemoval: true,
  }),
  [NewOrderActionTypes.SET_LOT_REMOVAL]: (state) => ({
    ...state,
    isLotRemoval: true,
  }),
};

const removalOmitAddressProps = ["email", "street2", "isResidential", "company"];
const omitAddressProps = ["phone", ...removalOmitAddressProps];

const validateOrder = (state: NewOrderState): NewOrderState => {
  const { address, shippingMethod, items, invalidDskus, isRemoval } = state;
  const omitPropsFromAddress = isRemoval ? removalOmitAddressProps : omitAddressProps;
  const isValidAddress =
    address &&
    Object.values(checkForAddressErrors(address!, state.validateAddressInput)).every((err) => err === undefined) &&
    !some(isEmpty, omit(omitPropsFromAddress, address));
  const isItemsValid = items.length > 0 && items.some((item) => item.qty > 0) && invalidDskus.length === 0;

  const isMethodsVisible = isRemoval
    ? Boolean(isValidAddress && isItemsValid) && !isInternationalAddress(address!)
    : Boolean(isValidAddress && isItemsValid);
  const isValid = Boolean(isValidAddress && shippingMethod && isItemsValid);

  return {
    ...state,
    isValid,
    isMethodsVisible,
  };
};

export const newOrderReducer = createReducer<NewOrderState>(newOrderInitialState, reducers);
