import { batch } from "react-redux";
import { NonComplianceListActionTypes } from "./NonComplianceListActionTypes";
import { setCurrentPage } from "./setCurrentPage";

export const setCurrentSearchTerm = (currentSearchTerm: string) => async (dispatch) =>
  batch(() => {
    dispatch({
      type: NonComplianceListActionTypes.SET_CURRENT_NON_COMPLIANCE_SEARCH_TERM,
      searchTerm: currentSearchTerm,
    });
    dispatch(setCurrentPage(0));
  });
