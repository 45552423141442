import { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import produce from "immer";
import { LocalizedMessage } from "common/components/ui";
import { AmazonBarcodeEntry } from "./AmazonBarcodeEntry";
import { SelectedProductData } from "common/components/ProductChooser";
import { BarcodeFormat } from "@deliverr/barcode-utils";
import { isFNSKUOrASINBarcode } from "transfers/utils/isFNSKUBarcode";

const AMAZON_BARCODE_ERROR_MESSAGES = defineMessages({
  emptyBarcode: {
    id: "transfers.fba.addAmazonBarcode.error.emptyBarcode",
    defaultMessage: "Please enter a barcode.",
  },
  unknownError: {
    id: "transfers.fba.addAmazonBarcode.error.unknownError",
    defaultMessage: "An error occurred. Please try again later.",
  },
  fnskuValidationError: {
    id: "transfers.fba.addAmazonBarcode.error.fnskuValidationError",
    defaultMessage:
      "FNSKUs must either begin with 'X00' and contain exactly 10 characters, or begin with 'B' and contain exactly 10 characters.",
  },
});

export const useAddNewAmazonBarcode = (products?: SelectedProductData[]) => {
  const [barcodeMap, setBarcodeMap] = useState<Record<string, AmazonBarcodeEntry>>(
    (products ?? []).reduce((acc, { dsku }) => {
      acc[dsku] = {
        barcodeType: BarcodeFormat.FNSKU,
        barcodeValue: "",
      };
      return acc;
    }, {})
  );
  const { formatMessage } = useIntl();

  const setError = (dsku: string, error?: LocalizedMessage) => {
    setBarcodeMap((prev) =>
      produce(prev, (draft) => {
        draft[dsku].error = error;
      })
    );
  };

  const onBarcodeValueChange = useCallback(
    (dsku: string, barcodeValue: string) => {
      const isValid = isFNSKUOrASINBarcode(barcodeValue);
      if (!barcodeValue) {
        setError(dsku, formatMessage(AMAZON_BARCODE_ERROR_MESSAGES.emptyBarcode));
      } else if (!isValid) {
        setError(dsku, formatMessage(AMAZON_BARCODE_ERROR_MESSAGES.fnskuValidationError));
      } else {
        setError(dsku, "");
      }

      setBarcodeMap((prev) =>
        produce(prev, (draft) => {
          draft[dsku].barcodeValue = barcodeValue;
        })
      );
    },
    [isFNSKUOrASINBarcode, formatMessage, setBarcodeMap, setError]
  );

  return {
    setError,
    barcodeMap,
    onBarcodeValueChange,
  };
};
