import React, { FC } from "react";
import { Card, DefaultTheme, Text } from "common/components/ui";
import { FormattedDate, FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { ReturnOrderMinimal } from "@deliverr/returns-client";
import { ReturnsDetailInformationLabel } from "./ReturnDetailInformationLabel";
import { Currency, CurrencyFormat } from "common/components/Currency";

interface ReturnDetailInformationProps {
  returnsDetail: ReturnOrderMinimal;
}

const Container = styled(Card)<{}, DefaultTheme>(
  ({ theme }) => `
    padding: ${theme.spacing.S7};
    border-top: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    display: grid;
    grid-template-columns: repeat(2, auto);
  `
);

const InfoLabel = styled.dt<{}, DefaultTheme>(
  ({ theme }) => `
    display: block;
    font-size: ${theme.font.size.F1};
    font-weight: ${theme.font.weight.BOLD};
    color: ${theme.colors.NEUTRAL[200]};
    margin-bottom: ${theme.spacing.S1};
  `
);

export const ReturnDetailInformation: FC<ReturnDetailInformationProps> = ({ returnsDetail }) => {
  return (
    <Container>
      <div>
        <InfoLabel>
          <FormattedMessage {...ReturnsDetailInformationLabel.detailHeading} />
        </InfoLabel>
        {returnsDetail.customer && (
          <Text>
            <FormattedMessage
              {...ReturnsDetailInformationLabel.customer}
              values={{ customer: returnsDetail.customer }}
            />
          </Text>
        )}
        {returnsDetail.details?.returnOutcome && (
          <Text>
            <FormattedMessage
              {...ReturnsDetailInformationLabel.returnOutcome}
              values={{ returnOutcome: returnsDetail.details.returnOutcome }}
            />
          </Text>
        )}
        {returnsDetail.details?.providerOrderNumber && (
          <Text>
            <FormattedMessage
              {...ReturnsDetailInformationLabel.providerOrderNumber}
              values={{ providerOrderNumber: returnsDetail.details.providerOrderNumber }}
            />
          </Text>
        )}
        {returnsDetail.details?.packageReference && (
          <Text>
            <FormattedMessage
              {...ReturnsDetailInformationLabel.packageReference}
              values={{ packageReference: returnsDetail.details.packageReference }}
            />
          </Text>
        )}
        <Text>
          <FormattedMessage
            {...ReturnsDetailInformationLabel.channelCreationDate}
            values={{
              channelCreationDate: (
                <FormattedDate
                  value={returnsDetail.channelCreationDate ?? returnsDetail.createdAt}
                  year="numeric"
                  month="numeric"
                  day="numeric"
                />
              ),
            }}
          />
        </Text>
        {returnsDetail.details?.channelProcessedAt && (
          <Text>
            <FormattedMessage
              {...ReturnsDetailInformationLabel.channelProcessedAt}
              values={{
                channelProcessedDate: (
                  <FormattedDate
                    value={returnsDetail.details?.channelProcessedAt}
                    year="numeric"
                    month="numeric"
                    day="numeric"
                  />
                ),
              }}
            />
          </Text>
        )}
      </div>
      {returnsDetail.details?.totalCost && (
        <div>
          <InfoLabel>
            <FormattedMessage {...ReturnsDetailInformationLabel.totalCostHeading} />
          </InfoLabel>
          <Currency currency={CurrencyFormat.USD} value={returnsDetail.details.totalCost} />
        </div>
      )}
    </Container>
  );
};
