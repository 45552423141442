export enum BulkUploadTemplateType {
  DEFAULT = "DEFAULT",
  STORAGE = "STORAGE",
  STORAGE_INTL = "STORAGE_INTL",
  REPLENISHMENT = "REPLENISHMENT",
  REPLENISHMENT_EACHES = "REPLENISHMENT_EACHES",
  INDIVIDUAL = "INDIVIDUAL",
  INDIVIDUAL_ENHANCED = "INDIVIDUAL_ENHANCED",
  INDIVIDUAL_ENHANCED_WITH_SELLER_LPNS = "INDIVIDUAL_ENHANCED_WITH_SELLER_LPNS",
}
