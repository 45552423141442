import React, { FC } from "react";
import { Accordion } from "common/components/ui/Accordion";
import { StepsContainer, StyledTitle, Content, AccordionContainer } from "./Styles";
import { AccordionFlowProps, useAccordionFlow } from "./useAccordionFlow";
import { FlowStep } from "./AccordionFlowTypes";

export const AccordionFlow: FC<AccordionFlowProps> = (props) => {
  const { keepAllStepsOpen = false } = props;
  const { stepsValidation, firstInvalidIndex, openSteps, toggleOpen } = useAccordionFlow(props);

  return (
    <StepsContainer>
      {props.steps.map((step: FlowStep, i: number) => {
        // a card should be disabled if any of the steps before it is not valid.
        // we only care about the cards before the current step.
        const isDisabled =
          stepsValidation &&
          !Object.values(stepsValidation)
            .filter((stepMetadata) => stepMetadata && stepMetadata.index < i)
            .every((stepMetadata) => stepMetadata?.isValid);

        const isDefaultOpen: boolean = !isDisabled && (i === firstInvalidIndex || keepAllStepsOpen);

        const title = (
          <StyledTitle as="h4" displayAs="h4">
            {step.title}
          </StyledTitle>
        );
        return (
          <AccordionContainer key={step.stepType}>
            <Accordion
              isOpen={openSteps[i] ?? isDefaultOpen}
              disabled={isDisabled}
              headerContent={title}
              indicator
              counter={i + 1}
              toggleOpen={() => toggleOpen(i)}
            >
              <Content>{step.content}</Content>
            </Accordion>
          </AccordionContainer>
        );
      })}
    </StepsContainer>
  );
};
