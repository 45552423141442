import { populateReplenishmentOrderWithPacks } from "storage/packUtils/populateReplenishmentOrderWithPacks";
import { OrderFetchOptions, OrderResponse } from "@deliverr/replenishment-client";
import { getReplenishmentOrder } from "./getReplenishmentOrder";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { loadWarehouses } from "common/deliverr/DeliverrActions";
import {
  replenishmentOrderDeatilsSetProducts,
  replenishmentOrderDeatilsSetReplenishmentOrder,
  setReplenishmentOrderNonCompliances,
} from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsActions";
import { chain } from "lodash";

export const getAndSetReplenishmentOrderState = async (
  orderId: string,
  fetchOptions: OrderFetchOptions,
  dispatch
): Promise<OrderResponse> => {
  const replenishmentOrderResponse: OrderResponse = await getReplenishmentOrder(orderId, fetchOptions);
  const replenishmentOrderWithPacks: OrderResponse = await dispatch(
    populateReplenishmentOrderWithPacks(replenishmentOrderResponse)
  );
  const dskus: string[] = replenishmentOrderWithPacks.orderItems?.map((item) => item.dsku) ?? [];
  const kitComponentDskus: string[] =
    replenishmentOrderWithPacks.kitComponentOrderItems?.map((item) => item.dsku) ?? [];
  if (replenishmentOrderWithPacks.originWarehouseId) {
    dispatch(loadWarehouses([replenishmentOrderWithPacks.originWarehouseId]));
  }
  const products = await fetchMultiPackProductsByDskus([...dskus, ...kitComponentDskus], dispatch); // why this is needed
  await dispatch(replenishmentOrderDeatilsSetReplenishmentOrder(replenishmentOrderWithPacks));
  await dispatch(replenishmentOrderDeatilsSetProducts(products));

  const nonCompliances = chain(replenishmentOrderResponse.shipments)
    .map((shipment) => shipment.nonCompliances ?? [])
    .flatten()
    .compact()
    .value();

  await dispatch(setReplenishmentOrderNonCompliances(nonCompliances));

  return replenishmentOrderWithPacks;
};
