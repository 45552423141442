import { DamageFault } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { SellerProblemStatus, SellerProblemsDetailView } from "@deliverr/inbound-client";

export const getDamagedProductStatus = ({
  inDispute,
  selfServeEnabled,
  selfServeCompletedAt,
  damageFault,
}: SellerProblemsDetailView): SellerProblemStatus => {
  return inDispute! || !selfServeEnabled || (!selfServeCompletedAt && damageFault === DamageFault.DELIVERR)
    ? SellerProblemStatus.SUPPORT_CONTACTED
    : SellerProblemStatus.RESOLVED;
};
