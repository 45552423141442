import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { useTransferSelectProductQuantityStep } from "./useTransferSelectProductQuantityStep";
import { noop } from "lodash/fp";
import { Box, DefaultTheme } from "common/components/ui";
import { getWarehouseAddressLabel } from "transfers/utils/getWarehouseAddressLabel";
import { SelectedProductsList } from "common/components/ProductChooser/SelectedProductList";
import { useSelector } from "react-redux";
import { CreateTransferLoader } from "../../CreateTransferLoader.types";
import { NetworkErrorNotification } from "../../../ui/NetworkErrorNotification";
import { TransferCreationTypes } from "../../transferCreationTypes";
import { DeliverrAddress } from "@deliverr/wholesale-client";
import { FixUnitsPerCasePackWarning } from "components/alerts/FixUnitsPerCasePackWarning";
import { WholesaleErrorType } from "../../../../common/wholesale-common/utils/WholesaleErrorTypes";
import { Text, TextProps } from "common/components/ui/Text";
import styled from "@emotion/styled";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
import { FeatureName, useFeatureOn } from "common/Split";

const StyledText = styled(Text)<TextProps, DefaultTheme>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S7};
  `
);
const Address: React.FC<{ address: DeliverrAddress }> = ({ address }) => (
  <>
    {address.name}
    <br />
    {address.street1}
    <br />
    {address.city}, {address.state} {address.zip}
  </>
);

export const TransferSelectProductQuantityStep = () => {
  const { formatMessage } = useIntl();
  const {
    isEcomTransfer,
    selectedProductsQty,
    originStorageWarehouse,
    updateNumberOfCases,
    isNextDisabled,
    isLoading,
    errorType,
    updateCaseQty,
    removeProduct,
    destinationType,
    isMultiCasePackEnabled,
    replenishmentError,
    currentPalletCount,
    selectedProductsLotNumbers,
    updateLotNumber,
    selectedProductsLotNumbersList,
    updateQty,
    isKittingEnabled,
    shouldUseCasePack,
  } = useTransferSelectProductQuantityStep();

  const isLoadingOrder = useSelector(selectAnyIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);
  const isReplenishmentsLotBasedOutboundsOn = useFeatureOn(FeatureName.ReplenishmentsLotBasedOutbounds);
  const isDefaultPoolB2BMerchant = useFeatureOn(FeatureName.DefaultPoolB2BMerchant);
  const shouldShowLotNumberDropdown = isReplenishmentsLotBasedOutboundsOn && !isKittingEnabled;

  const makeSubtitleWithName = () =>
    formatMessage(transfersShipmentCreateLabels.steps.productQuantity.subtitle, {
      storageWarehouseLocation: getWarehouseAddressLabel(originStorageWarehouse?.warehouse),
    });

  const makeSubtitleWithAddress = () => (
    <FormattedMessage
      {...transfersShipmentCreateLabels.steps.productQuantity.subtitleWithAddress}
      values={{
        br: <br />,
        fullAddress: originStorageWarehouse?.warehouse.address && (
          <Address address={originStorageWarehouse.warehouse.address} />
        ),
      }}
    />
  );

  const shouldShowEcomDetailsForStorageProducts = destinationType === TransferCreationTypes.Ecom;

  const subtitle = destinationType === TransferCreationTypes.Fba ? makeSubtitleWithAddress() : makeSubtitleWithName();
  const showErrorNotification =
    destinationType !== TransferCreationTypes.FbaIntegration && destinationType !== TransferCreationTypes.Fba;

  return (
    <ManagedStep
      title={formatMessage(transfersShipmentCreateLabels.steps.productQuantity.title)}
      subtitle={subtitle}
      isNextDisabled={isNextDisabled}
      isLoading={isLoading || isLoadingOrder}
      data-testid="transfers-select-product-case-quantity"
    >
      <Box width="85%">
        {showErrorNotification && errorType === WholesaleErrorType.UNTYPED && (
          <NetworkErrorNotification
            errorMessage={replenishmentError?.errors?.[0]?.message ?? "An unknown error occurred"}
          />
        )}
        {isEcomTransfer && selectedProductsQty.length > 0 && <FixUnitsPerCasePackWarning />}
        <SelectedProductsList
          selectedProducts={selectedProductsQty}
          removeProduct={removeProduct}
          updateQty={!shouldUseCasePack ? updateQty : noop}
          updateCaseQty={updateCaseQty}
          updateNumberOfCases={updateNumberOfCases}
          canRemoveProduct
          useCasePack={shouldUseCasePack}
          showOnlyPacks={isMultiCasePackEnabled && !isDefaultPoolB2BMerchant}
          isCaseQtyReadonly={isMultiCasePackEnabled || !isEcomTransfer}
          isReserveStorage={!isDefaultPoolB2BMerchant}
          hasMaxValue
          shouldHideBatteryColumn
          shouldShowEcomDetailsForStorageProducts={shouldShowEcomDetailsForStorageProducts}
          shouldShowLotNumberDropdown={shouldShowLotNumberDropdown}
          selectedProductsLotNumbersList={selectedProductsLotNumbersList}
          selectedProductsLotNumbers={selectedProductsLotNumbers}
          updateLotNumber={updateLotNumber}
          shouldShowKitHint={isKittingEnabled && destinationType === TransferCreationTypes.Ecom}
        />

        {isEcomTransfer && (
          <StyledText bold data-testId="estimated-pallet-count-display">
            <FormattedMessage
              id="replenishment.pallet.count"
              defaultMessage="Estimated Pallet Count: {currentPalletCount}"
              values={{ currentPalletCount }}
            />
          </StyledText>
        )}
      </Box>
    </ManagedStep>
  );
};
