import { ShippingPlanItem } from "@deliverr/inbound-client";
import { ProductLotInformationFields } from "../types/LotInformationFormValues";
import { trimExpirationDate } from "./trimExpirationDate";

export const getLotInformationDefaultValues = (planItem?: ShippingPlanItem): ProductLotInformationFields => {
  const defaultFieldValues: ProductLotInformationFields = {
    lotNumber: planItem?.lotNumber ?? undefined,
    hasFullExpirationDate: { value: true },
  };

  const existingExpirationDate = planItem?.expirationDate;

  const hasFullExpirationDate = Boolean(planItem?.expirationDateHasDay);

  if (existingExpirationDate) {
    defaultFieldValues.hasFullExpirationDate.value = hasFullExpirationDate;
    defaultFieldValues.expirationDate = trimExpirationDate(existingExpirationDate.toString(), hasFullExpirationDate);
  }

  return defaultFieldValues;
};
