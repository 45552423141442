import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";
import { ProductChooserKittedProducts } from "common/components/ProductChooser/ProductChooserReducer";
import { isNil } from "lodash";

export const calculateReserveStorageAvailableQuantity = (
  product: {
    raw?: {
      storageOnHandQty?: number;
      storageUnfilledOrderQty?: number;
      storageUnavailableQty?: number;
    };
    storageOnHandQty?: number;
    storageUnfilledOrderQty?: number;
    storageUnavailableQty?: number;
    isKit?: boolean;
    dsku: string;
  },
  selectedProductLotNumber?: ATPDetails,
  kittedProductsInventory?: ProductChooserKittedProducts
): number => {
  if (product.isKit && !isNil(kittedProductsInventory?.[product.dsku])) {
    // Handle Kitted Product Inventory Calculation here on the basis of Finished Kit Inventory Check in Product (Future Prospect)
    return kittedProductsInventory?.[product.dsku]?.availableToKit ?? 0;
  }
  const { storageOnHandQty = 0, storageUnfilledOrderQty = 0, storageUnavailableQty = 0 } = product.raw ?? product ?? {};

  const result = storageOnHandQty - storageUnfilledOrderQty - storageUnavailableQty;
  return selectedProductLotNumber ? Math.max(selectedProductLotNumber.totalPickableQty ?? 0, 0) : Math.max(result, 0);
};
