const ID_PREFIX = "inbounds.lotInformationHeader";

const FEFO_CONTENT = {
  TITLE: {
    id: `${ID_PREFIX}.fefoTitle`,
    defaultMessage: "Add Expiration Date",
  },
  DESCRIPTION: {
    id: `${ID_PREFIX}.fefoDescription`,
    defaultMessage:
      "Fulfill oldest expiration date first. Incorrect expiration dates will result in delays and additional fees. {learnMoreLink}",
  },
};

const LOT_TRACKING_CONTENT = {
  TITLE: {
    id: `${ID_PREFIX}.lotTrackingTitle`,
    defaultMessage: "Add Lot Tracking Details",
  },
  DESCRIPTION: {
    id: `${ID_PREFIX}.lotTrackingDescription`,
    defaultMessage: "Incorrect lot numbers will result in delays and additional fees. {learnMoreLink}",
  },
};

const GENERAL_CONTENT = {
  TITLE: {
    id: `${ID_PREFIX}.generalTitle`,
    defaultMessage: "Add Lot Tracking Details",
  },
  DESCRIPTION: {
    id: `${ID_PREFIX}.generalDescription`,
    defaultMessage:
      "Fulfill oldest expiration date first. Incorrect information will result in delays and additional fees. {learnMoreLink}",
  },
};

const INVALID_KIT_CONTENT = {
  TITLE: {
    id: `${ID_PREFIX}.generalTitle`,
    defaultMessage: "Add Lot Tracking Details",
  },
  DESCRIPTION: {
    id: `${ID_PREFIX}.generalDescription`,
    defaultMessage:
      "The following kits have lot tracking/FEFO enabled but their components do not. Please enable lot tracking/FEFO for at least one of the components of each kit to proceed further.",
  },
};

export { FEFO_CONTENT, LOT_TRACKING_CONTENT, GENERAL_CONTENT, INVALID_KIT_CONTENT };
