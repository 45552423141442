import { InboundBookingType } from "@deliverr/inbound-client";

export const getIsInternationalFreightInbound = (
  bookingType: InboundBookingType | null | undefined,
  shippingPlanSkuCount: number
) => {
  // we initiate draft international bookings before submitting their booking type, and before collecting product details
  // as a workaround, we check if the row does not contain SKUs, as this is only possible for international bookings
  return !!bookingType || shippingPlanSkuCount === 0;
};
