import { OrderListActionTypes } from "../OrderListActionTypes";
import { Thunk } from "common/ReduxUtils";
import log from "Logger";
import { billingClientV2 } from "Clients";
import { BulkOrderActualCostRequest, BulkOrderActualCostResponse } from "@deliverr/billing-types";

interface getBulkOrdersCostProps {
  orderIds: string[];
}

export const getBulkOrdersCost: Thunk =
  ({ orderIds }: getBulkOrdersCostProps) =>
  async (dispatch) => {
    const ctx = { fn: "getBulkOrdersCost" };
    log.info({ ...ctx }, "Getting bulk orders cost");

    try {
      dispatch({ type: OrderListActionTypes.LOAD_ACTUAL_COST, loadActualCost: true });
      const request: BulkOrderActualCostRequest = { orderIds };
      const bulkOrderActualCost: BulkOrderActualCostResponse = (await billingClientV2.getBulkOrderActualCosts(request))
        .value;
      dispatch({ type: OrderListActionTypes.SET_BULK_ORDERS_COST, bulkOrderActualCost });
    } catch (err) {
      log.error({ ...ctx, err }, "error getting bulk orders cost");
    } finally {
      dispatch({ type: OrderListActionTypes.LOAD_ACTUAL_COST, loadActualCost: false });
    }
  };
