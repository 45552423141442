import { SellerProblemsDetailView } from "@deliverr/inbound-client";
import { MappedSkuPair } from "common/components/ProblemsTable/types";
import { ProductCollection } from "common/models";

export const getMappedSkuPairs = (
  detailedSellerProblem: SellerProblemsDetailView,
  productCollection: ProductCollection
): MappedSkuPair[] => {
  const dsku = detailedSellerProblem.dsku;
  const product = dsku ? productCollection[dsku] : null;
  return dsku && product ? [{ dsku, msku: product.msku, productName: product.name }] : [];
};
