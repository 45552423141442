export enum InboundActionTypes {
  ADD_PRODUCTS = "ADD_PRODUCTS",
  REMOVE_PRODUCT = "REMOVE_PRODUCT",
  UPDATE_QTY = "UPDATE_QTY",
  UPDATE_QTY_PER_CASE = "UPDATE_QTY_PER_CASE",
  CREATE_SHIPPING_PLAN = "CREATE_SHIPPING_PLAN",
  SAVE_NEW_SHIPPING_PLAN_SUCCESS = "SAVE_NEW_SHIPPING_PLAN_SUCCESS",
  ADD_BOX_SIZE = "ADD_BOX_SIZE",
  REMOVE_BOX_SIZE = "REMOVE_BOX_SIZE",
  ADD_PACKAGES = "ADD_PACKAGES",
  UPDATE_BARCODES = "UPDATE_BARCODES",
  SET_IS_UPLOADING_PACKAGES = "SET_IS_UPLOADING_PACKAGES",
  SET_PACKAGE_COUNT = "SET_PACKAGE_COUNT",
  SET_BOX_QTY = "SET_BOX_QTY",
  SET_BOX_SIZE = "SET_BOX_SIZE",
  SET_BOX_DIMENSIONS = "SET_BOX_DIMENSIONS",
  SET_BOX_WEIGHT = "SET_BOX_WEIGHT",
  SET_NUM_BOXES = "SET_NUM_BOXES",
  ACCEPT_CHARGES_START = "ACCEPT_CHARGES_START",
  ACCEPT_CHARGES_SUCCESS = "ACCEPT_CHARGES_SUCCESS",
  CONFIRM_PRICING_AND_FEES = "CONFIRM_PRICING_AND_FEES",
  SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD",
  CREATE_SHIPMENTS_SUCCESS = "CREATE_SHIPMENTS_SUCCESS",
  COMPLETE_SHIPMENT = "COMPLETE_SHIPMENT",
  SET_USE_CASE_PACKS = "SET_USE_CASE_PACKS",
  UPDATE_NUMBER_OF_CASES = "UPDATE_NUMBER_OF_CASES",
  ADD_ONE_SKU_BOX_CONFIG = "ADD_ONE_SKU_BOX_CONFIG",
  REMOVE_PACKAGE = "REMOVE_PACKAGE",
  SET_NUMBER_OF_BOXES = "SET_NUMBER_OF_BOXES",
  DUPLICATE_PACKAGE = "DUPLICATE_PACKAGE",
  UPDATE_PRODUCT_CASE_QTY_SUCCESS = "UPDATE_PRODUCT_CASE_QTY_SUCCESS",
  UPDATE_PRODUCT_CASE_QTY_ERROR = "UPDATE_PRODUCT_CASE_QTY_ERROR",
  UPDATE_TRACKING_NUMBER = "UPDATE_TRACKING_NUMBER",
  SET_IS_FIRST_INBOUND = "SET_IS_FIRST_INBOUND",
  SAVE_OLD_INBOUND_STATE = "SAVE_OLD_INBOUND_STATE",
  SET_IS_REDISTRIBUTIONS = "SET_IS_REDISTRIBUTIONS",
  SET_DISPERSAL_METHOD = "SET_DISPERSAL_METHOD",
  UPDATE_PLAN = "UPDATE_PLAN",
  UPDATE_PRODUCT_CACHE = "UPDATE_PRODUCT_CACHE",
  LOAD_SHIPMENTS = "LOAD_SHIPMENTS",
  LOAD_RECEIVING_INFO = "LOAD_RECEIVING_INFO",
  SHOW_MAX_UNITS_EXCEEDED = "SHOW_MAX_UNITS_EXCEEDED",
  SHOW_BLACKLISTED = "SHOW_BLACKLISTED",
  TOGGLE_BATTERY_COMPLIANCE = "TOGGLE_BATTERY_COMPLIANCE",
  TOGGLE_PACKING_LIST_DOWNLOADED = "TOGGLE_PACKING_LIST_DOWNLOADED",
  SET_DELIVERR_PREP_SELECTED = "SET_DELIVERR_PREP_SELECTED",
  SET_CASE_PACK_DEFAULTS = "SET_CASE_PACK_DEFAULTS",
  SET_BULK_UPLOAD_SESSION_ID = "SET_BULK_UPLOAD_SESSION_ID",
  SET_CURRENT_STEP = "SET_CURRENT_STEP",

  SET_BOX_LABEL_URL = "SET_BOX_LABEL_URL",
  SET_HAS_DOWNLOADED_LABELS = "SET_HAS_DOWNLOADED_LABELS",
  SET_HAS_DOWNLOADED_SHIPMENT_BOX_LABELS = "SET_HAS_DOWNLOADED_SHIPMENT_BOX_LABELS",

  SET_SHIPMENT_FREIGHT_INFO = "SET_SHIPMENT_FREIGHT_INFO",

  UPDATE_SHIPMENT_QUANTITY = "UPDATE_SHIPMENT_QUANTITY",

  TOGGLE_INBOUND_SHIPMENT_METADATA = "TOGGLE_INBOUND_SHIPMENT_METADATA",

  SET_IS_GENERATING_SHIPMENTS = "SET_IS_GENERATING_SHIPMENTS",
  SET_HAS_INACTIVE_LABELS = "SET_HAS_INACTIVE_LABELS",
  SET_CARGO_TYPE = "SET_CARGO_TYPE",
  UPDATE_CARRIER_EMAIL = "UPDATE_CARRIER_EMAIL",
  SET_EXTERNAL_SHIPPING_PLAN_ID = "SET_EXTERNAL_SHIPPING_PLAN_ID",
}
