import { DeliverrAddress } from "@deliverr/commons-objects";
import { ShippingPlan } from "@deliverr/inbound-client";
import {
  DestinationType,
  PrepJobResponse,
  PrepJobResponseStatus,
  PrepJobSourceType,
  PrepType,
} from "@deliverr/prep-service-client";
import { OrderResponse } from "@deliverr/replenishment-client";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { PrepDetailsActionTypes } from "prep/types/details/PrepDetailsActionTypes";

export interface PrepDetailsState {
  details: PrepJobResponse & { wholesaleOrderId?: string };
  sourcePlanDetails?: ShippingPlan;
  sourceOrderDetails?: OrderResponse;
  destinationAddress?: DeliverrAddress;
  isArchivedJob?: boolean;
  hasPricingError?: boolean;
}

export const prepDetailsInitialState: PrepDetailsState = {
  details: {
    dpId: "",
    status: PrepJobResponseStatus.INITIALIZED,
    sellerId: "",
    sourceType: PrepJobSourceType.EXTERNAL_TASK,
    sourceId: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    prepTypes: [PrepType.BOX_CONTENT_LABELS],
    tasks: [],
    destinationType: DestinationType.SL_NETWORK,
  },
};

export const reducers: ReducerDictionary<PrepDetailsState> = {
  ...handleSimpleReducerUpdates([
    PrepDetailsActionTypes.SET_PREP_DETAILS,
    PrepDetailsActionTypes.SET_SOURCE_PLAN_DETAILS,
    PrepDetailsActionTypes.SET_SOURCE_ORDER_DETAILS,
    PrepDetailsActionTypes.SET_SELECTED_JOB_DETAILS,
    PrepDetailsActionTypes.SET_PRICING_ERROR,
  ]),
  [PrepDetailsActionTypes.RESET_DETAILS]: () => prepDetailsInitialState,
  [PrepDetailsActionTypes.RESET_DETAILS_WITH_ARCHIVED]: () => ({
    ...prepDetailsInitialState,
    isArchivedJob: true,
  }),
};

export const prepDetailsReducer = createReducer<PrepDetailsState>(prepDetailsInitialState, reducers);
