import * as Yup from "yup";
import { Carrier, CarrierAccountStatus, CarrierPaymentType } from "@deliverr/replenishment-client";

export const CreateCarrierAccountSchema = Yup.object().shape({
  carrier: Yup.string().required("Carrier is required").oneOf(Object.values(Carrier), "Invalid carrier"),
  accountNumber: Yup.string()
    .required("Account number is required")
    .min(1, "Account number must be at least 1 character")
    .max(50, "Account number must be at most 50 characters"),
  postalCode: Yup.string()
    .required("Postal code is required")
    .min(1, "Postal code must be at least 1 character")
    .max(10, "Postal code must be at most 10 characters"),
  displayName: Yup.string()
    .required("Display name is required")
    .min(1, "Display name must be at least 1 character")
    .max(100, "Display name must be at most 100 characters"),
  companyName: Yup.string()
    .required("Company name is required")
    .min(1, "Company name must be at least 1 character")
    .max(255, "Company name must be at most 255 characters"),
  sellerId: Yup.string()
    .required("Seller ID is required")
    .min(1, "Seller ID must be at least 1 character")
    .max(255, "Seller ID must be at most 255 characters"),
  paymentType: Yup.string()
    .required("Payment type is required")
    .oneOf(Object.values(CarrierPaymentType), "Invalid payment type"),
});
export const UpdateCarrierAccountSchema = Yup.object().shape({
  postalCode: Yup.string()
    .optional()
    .min(1, "Postal code must be at least 1 character")
    .max(10, "Postal code must be at most 10 characters"),
  displayName: Yup.string()
    .optional()
    .min(1, "Display name must be at least 1 character")
    .max(100, "Display name must be at most 100 characters"),
  accountStatus: Yup.string().optional().oneOf(Object.values(CarrierAccountStatus), "Invalid account status"),
  paymentType: Yup.string().optional().oneOf(Object.values(CarrierPaymentType), "Invalid payment type"),
});
