import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { BoxLabelsFormat } from "common/clients/inbound/BoxLabelsFormat";
import { LabelLanguage } from "common/clients/inbound/LabelLanguage";
import { LabelType } from "common/clients/storage/StorageLabelRequest/LabelType";
import { initiateDownloadFromUrl } from "common/InitiateDownload";
import { toast } from "common/components/ui";
import { getStorageInboundDetail } from "../../../store";
import { useState } from "react";
import { storageClient } from "common/clients/instances";
import { printSizeLabels } from "./printSize.labels";
import { LanguageOption } from "inbounds/steps/ship/labels/PalletLabelsSelect/LanguageOption";
import { LANGUAGE_DOWNLOAD_OPTIONS_TITLES } from "inbounds/steps/ship/view/LanguageDownloadOptions.labels";

export const useDownloadPalletLabel = () => {
  const { formatMessage } = useIntl();
  const { storageInboundOrder } = useSelector(getStorageInboundDetail);
  const [labelSize, setLabelSize] = useState<BoxLabelsFormat>();
  const [languageOption, setLanguageOption] = useState<LanguageOption>({
    value: LabelLanguage.ENGLISH,
    label: formatMessage(LANGUAGE_DOWNLOAD_OPTIONS_TITLES[LabelLanguage.ENGLISH]),
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleOptionSelect = (label) => {
    setLabelSize(label.value);
  };

  const handleLanguageChange = (language) => {
    setLanguageOption(language);
  };

  const onDownloadClick = async () => {
    setIsLoading(true);
    const storageLabelsResponse = await storageClient.generateLabels({
      storageInboundOrderId: storageInboundOrder.id,
      size: labelSize ?? BoxLabelsFormat.A4_PAPER,
      labelType: LabelType.PALLET_DYNAMIC,
      language: languageOption.value ?? LabelLanguage.ENGLISH,
      asnId: storageInboundOrder.asnId,
    });
    const storageLabels = storageLabelsResponse.value?.labels;
    if (storageLabels) {
      const labelUrl = storageLabels[0].link;
      initiateDownloadFromUrl(labelUrl, `FlexportPalletLabels-${storageInboundOrder.shipmentId}.pdf`);
      toast.success(
        formatMessage({
          id: "storage.inbounds.details.label.download.success",
          defaultMessage: "Pallet label downloaded",
        })
      );
    } else {
      toast.error(
        formatMessage({
          id: "storage.inbounds.details.label.download.error",
          defaultMessage: "Pallet label download failed",
        })
      );
    }
    setIsLoading(false);
  };

  const printSizeOptions = Object.values(BoxLabelsFormat).map((boxLabelsValue) => ({
    label: formatMessage(printSizeLabels[boxLabelsValue]),
    value: boxLabelsValue,
  }));

  return {
    printSizeOptions,
    onDownloadClick,
    handleOptionSelect,
    labelSize,
    languageOption,
    handleLanguageChange,
    isLoading,
  };
};
