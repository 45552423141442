import { ShippingPlan } from "@deliverr/inbound-client";
import { addAllToById } from "common/ById";

export const getNormalizedShippingPlanItems = (plan: ShippingPlan) => {
  const dskus: any = plan.items.map((item) => item.dsku);
  const normalizedItems = plan.items.map((item) => {
    return {
      ...item,
      numberOfCases: item.qty / item.caseQty,
    };
  });

  return addAllToById(dskus, normalizedItems);
};
