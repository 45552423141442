import { createActionCreator, SPThunkAction } from "common/ReduxUtils";
import { productClient, returnsClient } from "../../../Clients";
import { QcPackageDetails } from "@deliverr/returns-client";
import log from "../../../Logger";
import { ReturnsPackageDetailTypes } from "./ReturnsPackageDetailTypes";
import { ReturnsPackageQcItem } from "./ReturnsPackageDetailState";
import { ReturnLoader } from "returns/ReturnsLoader";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { isEmpty } from "lodash";

export const getReturnsPackage =
  (packageId: string): SPThunkAction =>
  async (dispatch) => {
    try {
      dispatch(addLoader(ReturnLoader.PackagesDetailLoader));
      const response = await returnsClient.getPackageDetails(packageId);
      const qcPackage = response?.value as QcPackageDetails;
      const returnsPackageQcItems = (qcPackage?.inspectedItems ?? []).reduce<Record<string, ReturnsPackageQcItem>>(
        (acc, { dsku, quantity, returnId }) => {
          if (!acc[dsku]) {
            acc[dsku] = { dsku, quantity: 0, returnIds: [] };
          }
          acc[dsku].quantity += quantity;
          if (!acc[dsku].returnIds.includes(returnId)) {
            acc[dsku].returnIds.push(returnId);
          }
          return acc;
        },
        {}
      );
      dispatch({
        type: ReturnsPackageDetailTypes.RETURNS_GET_PACKAGE_DETAILS_SUCCESS,
        packageDetail: { ...qcPackage, returnsPackageQcItems: Object.values(returnsPackageQcItems) },
      });
      const packageDskus = Object.keys(returnsPackageQcItems);
      if (!isEmpty(packageDskus)) {
        dispatch(getPackageQcItemDetails(Object.keys(returnsPackageQcItems)));
      }
    } catch (err) {
      log.error({ err, packageId }, "Error getting return package details for packageId");
      throw err;
    } finally {
      dispatch(clearLoader(ReturnLoader.PackagesDetailLoader));
    }
  };

export const getPackageQcItemDetails =
  (dskus: string[]): SPThunkAction =>
  async (dispatch) => {
    try {
      const details = await productClient.getUnifiedProducts(dskus);
      dispatch({
        type: ReturnsPackageDetailTypes.RETURN_GET_PACKAGE_ITEM_DETAILS_SUCCESS,
        returnsPackageQcItemDetails: details,
      });
    } catch (err) {
      log.error({ err }, "Error getting package item details");
    }
  };

export const resetPackageDetails = createActionCreator(ReturnsPackageDetailTypes.RESET_RETURN_PACKAGE_DETAILS);
