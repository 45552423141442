import { defineMessages } from "react-intl";

export const manageRmtMessages = defineMessages({
  invalidCredentials: {
    id: "rmt.manage.invalid.credentials",
    defaultMessage: "Invalid Credentials",
  },
  disconnect: {
    id: "rmt.manage.disconnect",
    defaultMessage: "Disconnect",
  },
  connect: {
    id: "rmt.manage.connect",
    defaultMessage: "Connect",
  },
  updateCredentials: {
    id: "rmt.manage.updateCredentials",
    defaultMessage: "Update Credentials",
  },
});
