import React, { FC } from "react";
import EmptyListMessage from "../../common/components/EmptyListMessage";
import { FormattedMessage } from "react-intl";
import { Text } from "../../common/components/ui";
import emptyOrderImg from "assets/img/emptyOrderListIcon.png";
import { returnsPackageDetailsMessages } from "./ReturnsPackageDetailsMessages";

export const ReturnsPackageDetailsEmptyBox: FC = () => {
  return (
    <EmptyListMessage
      title={<FormattedMessage {...returnsPackageDetailsMessages.emptyPackage} />}
      content={
        <Text>
          <FormattedMessage {...returnsPackageDetailsMessages.emptyPackageDescription} />
        </Text>
      }
      imageUrl={emptyOrderImg}
    />
  );
};
