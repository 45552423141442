import history from "BrowserHistory";
import { getSalesChannels } from "channels/ChannelsActions";
import { createActionCreator, Thunk } from "common/ReduxUtils";
import { destroySplit, FeatureName, initSplitClient, isFeatureOn } from "common/Split";
import { forceRefreshSession } from "common/user/TokenCheck";

import { getSellerSettings, setSplitLoaded, updateLocalUser } from "common/user/UserActions";
import { FederatedUsernamePrefix } from "common/user/UserSelectors";
import { getUser } from "common/utils/SSO";
import { searchInbounds } from "inbounds/list/InboundListActions";
import { searchProducts } from "inventory/list/InventoryListActions";
import { isEmpty } from "lodash/fp";
import log, { setLogUser } from "Logger";
import { updateOrganization } from "organization/OrganizationActions";
import { searchOrders } from "order/listV2/actions/searchOrders";
import { Path } from "paths";
import { batch } from "react-redux";
import { toast } from "common/components/ui";

import { RootState } from "RootReducer";
import { clearInternationalShippingAdmin } from "settings/InternationalShipping/InternationalShippingActions";

import { addLoader, clearLoader } from "../WithLoader/LoadingActions";
import { resetBilling } from "billing/BillingActions";
import { resetNotifications } from "notifications/NotificationsActions";
import { UserReducerState } from "common/user/UserReducer";
import { updateDataDogIdentity } from "common/utils/thirdParty/DataDog/DataDog";
import { adminClient, onboardingClientV2, sfnClient, userClient } from "common/clients/instances";
import { AnyAction } from "redux";

export enum AdminActionTypes {
  SEARCH_SELLER_SUCCESS = "SEARCH_SELLER_SUCCESS",
  SET_USER_PROPS = "SET_USER_PROPS",
  RESET_USER_PROPS = "RESET_USER_PROPS",
  SET_SHOW_ADMIN_TEXT = "SET_SHOW_ADMIN_TEXT",
  SET_SHOW_ARCHIVED_SHIPPING_PLANS = "SET_SHOW_ARCHIVED_SHIPPING_PLANS",
  SET_SHOW_HOURLY_INVENTORY_VALUES = "SET_SHOW_HOURLY_INVENTORY_VALUES",
  TOGGLE_DEMO_MODE = "TOGGLE_DEMO_MODE",
}

export const adminChangeLoadingId = "adminChangeSellerId";

let currentSellerId;
export const adminChangeSellerId: Thunk = (sellerId: string, email?: string) => async (dispatch, getState) => {
  log.info({ sellerId }, "updating admin seller ID");

  await destroySplit();
  dispatch(setSplitLoaded(false));

  // saves cognito calls
  if (sellerId !== currentSellerId) {
    currentSellerId = sellerId;

    dispatch(addLoader(adminChangeLoadingId));

    const state = getState();

    try {
      await adminClient.impersonateOrganization(sellerId);
      await getUser({ bypassCache: true });
      await dispatch(updateOrganization(sellerId));
      await dispatch({ type: AdminActionTypes.RESET_USER_PROPS });
      await dispatch(updateLocalUser({ sellerId }));

      await Promise.all([
        initSplitClient(sellerId),
        dispatch(getSalesChannels()),
        dispatch(getSellerSettings(sellerId)),
      ]);

      batch(() => {
        dispatch(getCognitoAttrs(email));
        dispatch(setSplitLoaded(true));
        dispatch(refreshSearch());
        if (!isEmpty(state.settings.internationalShipping.products)) {
          dispatch(clearInternationalShippingAdmin());
        }
        if (isFeatureOn(FeatureName.Notifications)) {
          dispatch(resetNotifications());
        }
      });

      const user: UserReducerState = getState().user;
      updateDataDogIdentity({ user: { ...user, isTestSeller: true } });

      await forceRefreshSession();

      dispatch(resetBilling());

      setLogUser({ sellerId: user.sellerId, email: user.email });

      history.push(Path.root);
      window.location.reload();
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(`Coundn't switch seller ID. ${err}`, {
        autoClose: 5000,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toastId: `SwitchSellerIDError-${err}`,
      });
    } finally {
      dispatch(clearLoader(adminChangeLoadingId));
    }
  }
};

export const searchSeller: Thunk = (searchTerm: string) => async (dispatch, getState) => {
  const ctx = { fn: "searchSeller", searchTerm };
  const sellerResultSize = 20;

  const fetchUsers = async () => {
    try {
      const users = await userClient.getUsersFromPartialMatch(searchTerm);
      log.info(ctx, "retrieved seller results successfully");
      return users?.data.slice(0, sellerResultSize) ?? [];
    } catch (err) {
      log.warn({ ...ctx, err }, "error searching for sellers");
      return [];
    }
  };

  const fetchOrganizations = async () => {
    try {
      const organizations = await sfnClient.searchSfnAccounts(searchTerm);
      log.info(ctx, "retrieved seller SFN accounts successfully");
      return organizations?.data.slice(0, sellerResultSize) ?? [];
    } catch (err) {
      log.warn({ ...ctx, err }, "error searching for SFN accounts");
      return [];
    }
  };

  const usersRequest = fetchUsers();
  const organizationsRequest = fetchOrganizations();

  const topUsers = await usersRequest;
  const topOrganizations = await organizationsRequest;

  dispatch({ type: AdminActionTypes.SEARCH_SELLER_SUCCESS, topUsers, topOrganizations });
};

const refreshSearch: Thunk = () => (dispatch, getState) => {
  const ctx = { fn: "refreshSearch" };
  log.info(ctx, "refreshing search");

  const inbound = getState().inboundList;

  dispatch(searchProducts());
  // OV-717: Have OpenSearch active for orders page by default for admin-users, feature-flag is properly followed when the page is interacted with
  dispatch(searchOrders({ isOrdersLogisticsSearchOn: true, useOrdersLogisticsSearchV2: true }));
  dispatch(searchInbounds(inbound.searchTerm));
};

const getFederatedType = (createdBy: string): string | undefined => {
  return createdBy.split("_")[0].replace(FederatedUsernamePrefix, "") ?? null;
};

const getSellerEmail = async (state: RootState): Promise<string | undefined> => {
  const ctx = { fn: "getSellerEmail" };
  const {
    user: { sellerId },
  } = state;
  try {
    const sellerInfo = await onboardingClientV2.getSeller(sellerId);
    return sellerInfo.email;
  } catch (err) {
    log.warn({ ...ctx, err }, `error getting email for sellerId ${sellerId}`);
    return;
  }
};

export const getCognitoAttrs: Thunk = (email: string) => async (dispatch, getState) => {
  const state = getState() as RootState;
  const sellerEmail = email ?? (await getSellerEmail(state));
  if (!sellerEmail) {
    dispatch({ type: AdminActionTypes.RESET_USER_PROPS });
    return;
  }

  dispatch({
    type: AdminActionTypes.SET_USER_PROPS,
    dealStage: state.organization?.dealStage,
    onboardingStage: state.organization?.onboardingStage,
    email: sellerEmail,
    federatedType: state?.organization?.createdBy?.includes("|") && getFederatedType(state.organization.createdBy),
    listingTool: state?.channels?.salesChannels[0]?.salesChannelId || "",
  });
};

export const setShowAdminFeatures = createActionCreator<boolean>(
  AdminActionTypes.SET_SHOW_ADMIN_TEXT,
  "showAdminFeatures"
);

export const setShowArchivedShippingPlans: Thunk = (showArchivedShippingPlans) => async (dispatch, getState) => {
  batch(() => {
    dispatch({
      type: AdminActionTypes.SET_SHOW_ADMIN_TEXT,
      showArchivedShippingPlans,
    });
    dispatch(refreshSearch());
  });
};

export const setShowHourlyInventoryValues: Thunk = (showHourlyInventoryValues) => async (dispatch, getState) => {
  batch(() => {
    dispatch({
      type: AdminActionTypes.SET_SHOW_HOURLY_INVENTORY_VALUES,
      showHourlyInventoryValues,
    });
  });
};
export const toggleDemoMode = (): AnyAction => ({
  type: AdminActionTypes.TOGGLE_DEMO_MODE,
});
