import { FBAPrepCategory } from "@deliverr/replenishment-client";
import { Box, Select, SelectComponentOption, SelectOptionType, Text, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { FbaPrepDetailsLabels } from "./FbaPrepDetailsLabels";
import styled from "@emotion/styled";

const LightText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  overflow-wrap: anywhere;
  `
);

const PrepCategoryOption: FC<{ data: { label: string; value: FBAPrepCategory } }> = (props) => {
  const prepCategory = props.data;

  return (
    <SelectComponentOption {...props}>
      <Box width={"22rem"}>
        <Text bold>{prepCategory.value}</Text>

        <Box paddingTop={"S1"} paddingBottom={"S1"}>
          <LightText>{prepCategory.label}</LightText>
        </Box>
      </Box>
    </SelectComponentOption>
  );
};

export const PrepCategorySelect: FC<{
  prepCategoryOptions: { value: FBAPrepCategory; label: string }[];
  onChangePrepCategory: (fbaPrepCategory: SelectOptionType) => void;
  selectedPrepCategoryOption?: {
    value: FBAPrepCategory;
    label: string;
  };
}> = ({ prepCategoryOptions, selectedPrepCategoryOption, onChangePrepCategory }) => {
  return (
    <Box paddingTop={"S3"} padding-bottom={"S3"}>
      <Text bold>
        <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsCategoryTitle} />
      </Text>

      <Box paddingTop={"S2"} paddingBottom={"S2"}>
        <LightText>
          <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsCategoryDescription} />
        </LightText>
      </Box>

      <Select
        placeholder={<FormattedMessage {...FbaPrepDetailsLabels.prepCategorySelectPlaceholder} />}
        options={prepCategoryOptions}
        value={selectedPrepCategoryOption}
        onChange={onChangePrepCategory}
        customComponents={{
          Option: PrepCategoryOption,
        }}
        formatOptionLabel={(option) => <Text bold>{option.value}</Text>}
      />
    </Box>
  );
};
