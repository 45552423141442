import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useMobile } from "common/components/ui";

import { UnknownBarcodeCaseContext } from "../context";
import { nonComplianceDynamicRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { ProblemType } from "common/problems/ProblemType";
import { SellerProblemStatus } from "@deliverr/inbound-client";

export const useUnknownBarcodeSuccess = () => {
  const history = useHistory();
  const { ncProduct, isNCProductLoading, shippingPlan, foundProduct, isRecognizedProduct } =
    useContext(UnknownBarcodeCaseContext);
  const isMobile = useMobile();

  useEffect(() => {
    // redirect if case was already resolved
    if (
      ncProduct !== undefined &&
      ncProduct.status !== SellerProblemStatus.CREATED &&
      ncProduct.status !== SellerProblemStatus.ACTION_NEEDED
    ) {
      history.replace(
        nonComplianceDynamicRoutes.viewCompletedCase.parse({
          nonComplianceType: ProblemType.UNKNOWN_BARCODE,
          nonCompliantId: ncProduct.sellerProblemId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ncProduct]);

  return {
    ncProduct,
    isNCProductLoading,
    shippingPlan,
    isRecognizedProduct,
    foundProduct,
    isMobile,
  };
};
