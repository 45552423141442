import { toast } from "common/components/ui";
import { SaveNewInboundError, DuplicateExternalShippingPlanIdError } from "./SaveNewInboundError";
import { DeliverrError } from "@deliverr/errors";

export const handleSaveNewInboundError = (err: DeliverrError) => {
  // Check for duplicate external shipping plan ID error
  if (err.payload?.data?.error?.code === "DUPLICATE_SHIPPING_PLAN_EXTERNAL_ID") {
    toast.error(DuplicateExternalShippingPlanIdError(), {
      toastId: "duplicateExternalShippingPlanIdError",
      autoClose: 6000,
    });
  } else {
    toast.error(SaveNewInboundError, { toastId: "saveNewInboundError" });
  }
  throw err;
};
