import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { Grid, Input, ThemeProps } from "common/components/ui";

import { InfoIcon } from "common/components/icons/InfoIcon";
import { useAddReceivingInfoForm } from "./useAddReceivingInfoForm";
import { SpacedText } from "../../sharedComponents";
import { ButtonType, LoaderButton } from "common/components/WithLoader/LoaderButton";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { FLEXPORT_TRACKING_PREFIX } from "inbounds/steps/ship/view/FlexportAutomatedTracking/constants";
import { FlexportAutomatedTrackingInstructions } from "inbounds/steps/ship/view/FlexportAutomatedTracking/FlexportAutomatedTrackingInstructions";
import { useUserScopeMapping } from "common/user/useUserScopes";
import { TOOLTIP_MESSAGES } from "common/utils/tooltipMessages/tooltipMessages";

const SpacedForm = styled.form<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

const SpacedLabel = styled.label<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
  `
);

const SpacedInfoIcon = styled.span<ThemeProps>(
  ({ theme }) => `
    margin-left: ${theme.spacing.S2};
  `
);

const WideInput = styled(Input)`
  width: 100%;
`;

export interface AddReceivingInfoFormProps {
  isFlexport?: boolean;
}

export interface AddReceivingInfoFormRendererProps extends AddReceivingInfoFormProps {
  bolId?: string;
  setBolId: (val: string) => void;
  proId?: string;
  setProId: (val: string) => void;
  bolIdPlaceholder: string;
  proIdPlaceholder: string;
  submitDisabled: boolean;
  onSubmit: () => void;
  carrierInputMarkup?: JSX.Element;
  shipDateInputMarkUp?: JSX.Element;
  containerIdInputMarkup?: JSX.Element;
  shouldHideHeader?: boolean;
  titleOverride?: JSX.Element;
  submitButtonMessage?: string;
}

export const AddReceivingInfoFormRenderer: FC<AddReceivingInfoFormRendererProps> = ({
  isFlexport = false,
  bolId,
  setBolId,
  proId,
  setProId,
  bolIdPlaceholder,
  proIdPlaceholder,
  submitDisabled,
  onSubmit,
  carrierInputMarkup,
  shipDateInputMarkUp,
  containerIdInputMarkup,
  shouldHideHeader,
  titleOverride,
  submitButtonMessage,
}) => {
  const shouldShowFlexportTracking = isFlexport;
  const userScopesMapping = useUserScopeMapping();
  const hasInboundsWriteAccess = userScopesMapping?.INBOUNDS?.write;

  const bolInputMarkup = (
    <div>
      <SpacedLabel>
        <FormattedMessage id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.bolIdLabel" defaultMessage="BOL" />
      </SpacedLabel>
      <WideInput
        name="bolId"
        value={bolId ?? ""}
        placeholder={bolIdPlaceholder}
        onChange={(e) => setBolId(e.target.value)}
      />
    </div>
  );

  const flexIdInputMarkup = (
    <div>
      <SpacedLabel>
        <FormattedMessage
          id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.proIdLabel"
          defaultMessage={"Flex ID"}
        />
      </SpacedLabel>
      <WideInput
        name="proId"
        value={proId ?? FLEXPORT_TRACKING_PREFIX}
        placeholder=""
        onChange={(e) => setProId(e.target.value)}
      />
    </div>
  );

  const proIdInputMarkup = (
    <div>
      <SpacedLabel>
        <FormattedMessage
          id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.proIdLabel"
          defaultMessage={"PRO"}
        />
        <SpacedInfoIcon>
          <InfoIcon
            message={
              <FormattedMessage
                id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.proIdHelpText"
                defaultMessage={
                  "A PRO # is a tracking code that is provided by your freight carrier at the time that the pickup occurs"
                }
              />
            }
            wrap
            placement="top"
          />
        </SpacedInfoIcon>
      </SpacedLabel>
      <WideInput
        name="proId"
        value={proId ?? ""}
        placeholder={proIdPlaceholder}
        onChange={(e) => setProId(e.target.value)}
      />
    </div>
  );

  return (
    <>
      {shouldHideHeader ? null : (
        <SpacedText bold size="bodyLarge">
          <FormattedMessage
            id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.heading"
            defaultMessage="Freight Delivery Information"
          />
        </SpacedText>
      )}

      {titleOverride ??
        (shouldShowFlexportTracking ? (
          <SpacedText>
            <FormattedMessage
              id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.flexport.description"
              defaultMessage={"Please provide your BOL # and Flex ID for better tracking and visibility"}
            />
          </SpacedText>
        ) : (
          <SpacedText>
            <FormattedMessage
              id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.noFlexport.description"
              defaultMessage={
                "Please provide your BOL # and PRO # to have your inventory received up to 3 days earlier."
              }
            />
          </SpacedText>
        ))}

      <SpacedForm autoComplete="off">
        <Grid gap="S4" columns="1fr 1fr">
          {carrierInputMarkup ?? null}
          {shipDateInputMarkUp ?? null}
          {shouldShowFlexportTracking && flexIdInputMarkup}
          {bolInputMarkup}
          {!shouldShowFlexportTracking && proIdInputMarkup}
          {containerIdInputMarkup ?? null}
        </Grid>
      </SpacedForm>

      {shouldShowFlexportTracking && (
        <SpacedText>
          <FlexportAutomatedTrackingInstructions />
        </SpacedText>
      )}

      <LoaderButton
        name={InboundLoaderId.updateShipmentFreightAppointmentInfoButton}
        buttonType={ButtonType.PrimaryReversed}
        disabled={submitDisabled || !hasInboundsWriteAccess}
        onClick={onSubmit}
        tooltipMessage={!hasInboundsWriteAccess ? TOOLTIP_MESSAGES.insufficientAccess : undefined}
      >
        {submitButtonMessage ?? (
          <FormattedMessage
            id="inbounds.ltlExternalConfirmation.addReceivingInfoForm.submitButtonLabel"
            defaultMessage={"Update delivery information"}
          />
        )}
      </LoaderButton>
    </>
  );
};

export const AddReceivingInfoForm: FC<AddReceivingInfoFormProps> = ({ isFlexport = false }) => {
  const props = {
    isFlexport,
    ...useAddReceivingInfoForm(isFlexport),
  };

  return <AddReceivingInfoFormRenderer {...props} />;
};
