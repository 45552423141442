import React, { FC } from "react";
import { DamageFault, DamageType } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { Box } from "common/components/ui";
import { ProblemBaseBlade, ProblemBladeProps } from "../ProblemBaseBlade";
import { DamagedProductBladeHeader } from "./DamagedProductBladeHeader";
import { DamagedProductCostTable } from "./DamagedProductCostTable";
import { DamagedProductDetails } from "./DamagedProductDetails";
import { DamagedProductBladeFooter } from "./DamagedProductBladeFooter";
import { DamagedProductProblemRow, MappedSkuPair } from "common/components/ProblemsTable/types";
import { ProblemTypeLabel } from "common/components/ProblemsTable/ProblemTypeCell/ProblemTypeLabel";
import { SellerProblemStatus } from "@deliverr/inbound-client";

export interface DamagedProductBladeProps extends ProblemBladeProps {
  shipmentProblem: DamagedProductProblemRow;
  onShippingPlanClick?: () => void;
  product?: MappedSkuPair;
  onDisputeClick: () => Promise<any>;
  shippingPlanId?: number;
  shippingPlanName?: string;
}

export const DamagedProductBlade: FC<DamagedProductBladeProps> = ({
  id,
  shipmentProblem,
  onShippingPlanClick,
  onCloseClick,
  isBladeOpen,
  product,
  onDisputeClick,
  shippingPlanId,
  shippingPlanName,
}) => {
  const {
    status,
    updatedAt,
    retailUnitPrice,
    reimbursementUnitCost,
    damageFault,
    damageType,
    units = 0,
    disputeTicketCount,
    isDisposed,
    disputeOpenedAt,
    disputeClosedAt,
    disputeAutoResolvedAt,
  } = shipmentProblem;

  const shouldDisplayCostTable = damageFault === DamageFault.DELIVERR && !!reimbursementUnitCost;
  const hasDisputes = disputeTicketCount > 0;
  const shouldDisplayDisputeFooter = status !== SellerProblemStatus.SUPPORT_CONTACTED;
  const isExpired = damageType === DamageType.EXPIRED;

  return (
    <ProblemBaseBlade
      id={id}
      title={<ProblemTypeLabel problemRow={shipmentProblem} />}
      onCloseClick={onCloseClick}
      isBladeOpen={isBladeOpen}
      updatedAt={updatedAt}
      footer={
        shouldDisplayDisputeFooter ? (
          <DamagedProductBladeFooter onDisputeClick={onDisputeClick} hasDisputes={hasDisputes} isExpired={isExpired} />
        ) : undefined
      }
    >
      <Box marginBottom="S5">
        <DamagedProductBladeHeader
          status={status}
          damageFault={damageFault}
          isExpired={isExpired}
          hasDisputes={hasDisputes}
          hasReimbursementCost={!!reimbursementUnitCost}
          isDisposed={isDisposed}
          disputeOpenedAt={disputeOpenedAt}
          disputeClosedAt={disputeClosedAt}
          disputeAutoResolvedAt={disputeAutoResolvedAt}
        />
      </Box>
      {shouldDisplayCostTable && (
        <Box marginBottom="S4">
          <DamagedProductCostTable
            units={units}
            retailUnitPrice={retailUnitPrice}
            reimbursementUnitCost={reimbursementUnitCost!}
          />
        </Box>
      )}
      <DamagedProductDetails
        shipmentProblem={shipmentProblem}
        product={product}
        onShippingPlanClick={onShippingPlanClick}
        shippingPlanId={shippingPlanId}
        shippingPlanName={shippingPlanName}
        isExpired={isExpired}
      />
    </ProblemBaseBlade>
  );
};
