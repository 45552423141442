import { Select, SelectOptionType, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { useSPDispatch, useSPSelector } from "common/ReduxUtils";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/sellerProblemTypeLabelMap";
import { SUPPORTED_SELLER_PROBLEM_TYPES } from "common/problems/SellerProblemType";
import { NonComplianceListActionTypes } from "../actions/NonComplianceListActionTypes";

const StyledFilters = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;

  > div {
    margin-right: ${theme.spacing.S2};
  }

  label {
    margin-bottom: 0;
  }
`
);

const StyledSelectWrapper = styled.div`
  min-width: 100px;

  & > label {
    min-width: 100%;
  }
`;

const StyledProblemTypeSelect = styled(Select)`
  min-width: 200px;
`;

export const NonComplianceListProblemTypeFilter: FC = () => {
  const dispatch = useSPDispatch();
  const { searchFilters } = useSPSelector((state) => state.nonComplianceList);
  const selectedProblemTypes = searchFilters.problemTypes;

  const options: SelectOptionType[] = SUPPORTED_SELLER_PROBLEM_TYPES.map((problemType) => {
    return {
      value: problemType,
      label: PROBLEM_TYPE_LABEL_MAP[problemType].defaultMessage,
    };
  });

  const formattedProblemTypes = selectedProblemTypes.map((problemType) => ({
    value: problemType,
    label: PROBLEM_TYPE_LABEL_MAP[problemType].defaultMessage,
  }));

  return (
    <StyledFilters>
      <StyledSelectWrapper>

        <StyledProblemTypeSelect
          multiple
          flexWidth
          pluralEntityName="problem types"
          onChange={(problemTypes) => {
            dispatch({
              type: NonComplianceListActionTypes.SET_SEARCH_FILTERS,
              searchFilters: {
                ...searchFilters,
                problemTypes: problemTypes.map((problemType) => problemType.value),
              },
            });
          }}
          value={formattedProblemTypes}
          options={options}
        />
      </StyledSelectWrapper>
    </StyledFilters>
  );
};
