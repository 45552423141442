import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { ReturnsPackageDetailState } from "./ReturnsPackageDetailState";
import { ReturnsPackageDetailTypes } from "./ReturnsPackageDetailTypes";

export const returnsPackageDetailInitialState: ReturnsPackageDetailState = {
  returnsPackageQcItemDetails: {},
  packageDetail: undefined,
};

const reducers: ReducerDictionary<ReturnsPackageDetailState> = {
  ...handleSimpleReducerUpdates([ReturnsPackageDetailTypes.RETURN_GET_PACKAGE_ITEM_DETAILS_SUCCESS]),
  [ReturnsPackageDetailTypes.RETURNS_GET_PACKAGE_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    packageDetail: { ...state.packageDetail, ...action.packageDetail },
  }),
  [ReturnsPackageDetailTypes.RESET_RETURN_PACKAGE_DETAILS]: () => returnsPackageDetailInitialState,
};

export const returnsPackageDetailReducer = createReducer<ReturnsPackageDetailState>(
  returnsPackageDetailInitialState,
  reducers
);
