import React, { FC } from "react";
import cx from "classnames";
import { FormattedMessage } from "react-intl";
import { CollapsibleSection } from "common/components/CollapsibleSection";
import { CompliancePill, validIcon } from "common/components/CompliancePill";
import HtmlToReact from "common/components/HtmlToReact";
import { List } from "common/components/lists/List";
import { ElevioBorderlessButton, ElevioArticleId } from "common/elevio";
import { ExternalLink } from "common/components/ExternalLink";
import { getHelpCenterNewRequestUrl } from "common/support/helpCenter";
import Flashlight from "assets/img/inventory/flashlight.svg";
import PhoneWithAirpods from "assets/img/inventory/phone.svg";
import cls from "./BatteryRequirements.less";

interface BatteryRequirementsProps {
  isDefaultOpen?: boolean;
}

export const supportLink = (text: string) => <ExternalLink href={getHelpCenterNewRequestUrl()}>{text}</ExternalLink>;

const batteriesInsideDeviceMessage = (
  <div className={cls.beneathIcon}>
    <CompliancePill className={cls.pill} block>
      {validIcon}
      <FormattedMessage id="BatteryRequirements.UN3481UN3091Products" defaultMessage="UN3481 / UN3091" />
    </CompliancePill>
    <FormattedMessage id="BatteryRequirements.DeviceBatteries" defaultMessage="Battery installed inside the device" />
  </div>
);

const batteriesOutsideDeviceMessage = (
  <div className={cls.beneathIcon}>
    <CompliancePill className={cls.pill} block nonCompliant={false}>
      {validIcon} <FormattedMessage id="BatteryRequirements.UN3480UN3090Products" defaultMessage="UN3480 / UN3090" />
    </CompliancePill>
    <FormattedMessage id="BatteryRequirements.LooseBatteries" defaultMessage="Loose batteries outside of device" />
  </div>
);

const hazmatCategoriesAllowed = (
  <FormattedMessage
    key="req-1"
    id="BatteryRequirements.Specification.3480And3090Expansion"
    defaultMessage="must fall under either <strong>UN 3481</strong>, <strong>UN 3091</strong>, <strong>UN 3480</strong> or <strong>UN 3090</strong>"
    values={{
      strong: (chunks) => <strong>{chunks}</strong>,
    }}
  />
);

const hazmatChargeMessage = (
  <FormattedMessage
    id="BatteryRequirements.ChargePerUnit.3480And3090Expansion"
    defaultMessage="Please <a>contact support</a> for pricing information for hazmat products."
    values={{
      a: supportLink,
    }}
  />
);

export const BatteryRequirements: FC<BatteryRequirementsProps> = ({ isDefaultOpen }) => {
  return (
    <CollapsibleSection
      isBolded
      isDefaultOpen={isDefaultOpen}
      collapsedContent={
        <FormattedMessage id="BatteryRequirements.ProductsAccepted" defaultMessage="Products accepted by Flexport" />
      }
      classNameExpanded={cls.expandedSection}
      noFill
    >
      <div className={cls.batteriesContainer}>
        <div className={cx(cls.imageContainer, cls.acceptable)}>
          <HtmlToReact className={cls.svgContainer} html={PhoneWithAirpods} />
          {batteriesInsideDeviceMessage}
        </div>
        <div className={cx(cls.imageContainer, cls.notAcceptable)}>
          <HtmlToReact className={cx(cls.svgContainer, cls.flashlight)} html={Flashlight} />
          {batteriesOutsideDeviceMessage}
        </div>
      </div>
      <div className={cls.textContainer}>
        <div className={cls.listTitle}>
          <FormattedMessage id="BatteryRequirements.AdditionalText" defaultMessage="Additionally, the product" />
        </div>
        <List
          className={cls.list}
          narrow
          items={[
            hazmatCategoriesAllowed,
            <FormattedMessage
              key="req-2"
              id="BatteryRequirements.AirRequirement"
              defaultMessage="can be shipped by air"
            />,
            <FormattedMessage
              key="req-3"
              id="BatteryRequirements.TempRequirement"
              defaultMessage="can be stored at room temperature"
            />,
            <FormattedMessage
              key="req-4"
              id="BatteryRequirements.HandlingRequirement"
              defaultMessage="<strong>does not</strong> require special handling equipment"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />,
            <FormattedMessage
              key="req-5"
              id="BatteryRequirements.UNRequirement"
              defaultMessage="has a valid UN Test Summary document"
            />,
            <FormattedMessage
              key="req-6"
              id="BatteryRequirements.MSDSRequirement"
              defaultMessage="has a valid MSDS that <strong>has not</strong> expired"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />,
          ]}
        />
        <p>{hazmatChargeMessage}</p>
        <p>
          <FormattedMessage
            id="BatteryRequirements.AddtlRequirements"
            defaultMessage="Learn more about <elevio>battery requirements</elevio>."
            values={{
              elevio: (chunks) => (
                <ElevioBorderlessButton articleId={ElevioArticleId.BatteryRequirements}>
                  {chunks}
                </ElevioBorderlessButton>
              ),
            }}
          />
        </p>
      </div>
    </CollapsibleSection>
  );
};
