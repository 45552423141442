import { selectAmazonDestinationType } from "./selectAmazonDestinationType";
import { selectIsByAmazon } from "./selectIsByAmazon";
import { selectIsByFlexport } from "./selectIsByFlexport";
import { selectIsDTCNetwork } from "./selectIsDTCNetwork";
import { selectIsP2P } from "./selectIsP2P";
import { InboundBookingType } from "@deliverr/inbound-client";
import { selectDistributionType } from "./selectDistributionType";
import { createSelector } from "reselect";
import { getBookingTypeFromSelections } from "../utils/getBookingTypeFromSelections";

export const selectDerivedBookingType = createSelector(
  selectIsP2P,
  selectIsByAmazon,
  selectIsByFlexport,
  selectIsDTCNetwork,
  selectDistributionType,
  selectAmazonDestinationType,
  (isP2P, isByAmazon, isByFlexport, isDTCNetwork, distributionType, amazonDestinationType): InboundBookingType => {
    return getBookingTypeFromSelections({
      isP2P,
      isByAmazon,
      isByFlexport,
      isDTCNetwork,
      distributionType,
      amazonDestinationType,
    });
  }
);
