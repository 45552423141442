import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";
import { BookingItemRequest } from "@deliverr/inbound-client";

export const selectBookingItemRequest = () =>
  createSelector(selectBookingRequest(false), (bookingRequest) => {
    const {
      bookingProducts,
      cargoReadyDate,
      cargoTotalBoxes,
      cargoTotalItems,
      cargoTotalVolume,
      cargoTotalWeight,
      cargoUnitSystem,
      dangerousGoods,
      lithiumBatteries,
      lithiumBatteryPackaging,
      nonLithiumBatteries,
      otherDangerousGoods,
      packages,
      palletCount,
    } = bookingRequest;
    const bookingItemRequest: BookingItemRequest = {
      bookingProducts,
      cargoReadyDate,
      cargoTotalBoxes,
      cargoTotalItems,
      cargoTotalVolume,
      cargoTotalWeight,
      cargoUnitSystem,
      dangerousGoods,
      lithiumBatteries,
      lithiumBatteryPackaging,
      nonLithiumBatteries,
      otherDangerousGoods,
      packages: packages ?? [],
      palletCount,
    };
    return bookingItemRequest;
  });
