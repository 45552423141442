import { spsV2Steps } from "./../spsV2Steps";
import { Step } from "common/components/StepContainer";
import { TransferPickDestinationStep } from "./TransferPickDestinationStep";
import {
  transferCreateChooseDestinationType,
  transferCreateReset,
  transferCreateSetReplenishmentError,
} from "../actions";
import { TransferCreationTypes } from "../transferCreationTypes";
import { WfsSteps, wfsSteps } from "../wfsSteps";
import { selectSalesChannels } from "transfers/wfs/store/WfsIntegrationSelectors";
import { FbaV2Steps, fbaV2Steps } from "../fbaV2Steps";
import { FbaIntegrationV2Steps, fbaIntegrationV2Steps } from "../fbaIntegrationV2Steps";
import { SalesChannelConnectionStatus } from "common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
import { EcomV2Step, ecomV2Steps } from "../ecomV2Steps";
import { fbaV3IntegrationSteps } from "./fbaV3/FbaV3IntegrationSteps";
import { batch } from "react-redux";

export const TransferCreateInitialStep = "destinationType";

export const createTransferSteps: (
  dispatch,
  getState,
  isFbaConnected: boolean,
  featureFlags: Record<string, boolean>
) => Record<string, Step> = (dispatch, getState, isFbaConnected, featureFlags: Record<string, boolean>) => ({
  destinationType: {
    component: TransferPickDestinationStep,
    next: async (destinationType: TransferCreationTypes) => {
      const state = getState();
      const { destinationType: prevDestinationType } = state.transferCreate;

      if (prevDestinationType !== destinationType) {
        dispatch(transferCreateReset());
      }

      batch(() => {
        dispatch(transferCreateChooseDestinationType(destinationType));
        dispatch(transferCreateSetReplenishmentError({ errors: [] }));
      });

      switch (destinationType) {
        case TransferCreationTypes.Fba:
          return FbaV2Steps.SelectAmazonFacility;
        case TransferCreationTypes.FbaIntegration: {
          if (isFbaConnected) {
            return FbaV2Steps.SelectProduct;
          } else {
            return FbaIntegrationV2Steps.Authorize;
          }
        }
        case TransferCreationTypes.Wfs: {
          const isAuthorized =
            selectSalesChannels(getState()).find((salesChannel) => salesChannel.channelName === "Walmart")
              ?.connectionStatus === SalesChannelConnectionStatus.CONNECTED;
          if (isAuthorized) {
            return WfsSteps.SelectProduct;
          } else {
            return WfsSteps.Authorize;
          }
        }
        case TransferCreationTypes.Ecom: {
          return EcomV2Step.SelectProduct;
        }
        default: {
          return TransferCreateInitialStep;
        }
      }
    },
  },
  ...ecomV2Steps(dispatch, getState),
  ...wfsSteps(dispatch, getState),
  ...spsV2Steps(dispatch, getState),
  ...fbaV2Steps(dispatch, getState, featureFlags.isFbaUnitPrepOn, featureFlags.isFbaV3On),
  ...fbaIntegrationV2Steps(dispatch, getState),
  ...fbaV3IntegrationSteps(dispatch, getState, featureFlags.isFbaUnitPrepOn),
});
