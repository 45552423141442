import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Stack, Text } from "common/components/ui";
import { useDownloadPalletLabel } from "./useDownloadPalletLabel";
import {
  LANGUAGE_DOWNLOAD_OPTIONS_SUBTITLES,
  LANGUAGE_DOWNLOAD_OPTIONS_TITLES,
} from "inbounds/steps/ship/view/LanguageDownloadOptions.labels";
import { DropdownDescription, DropdownTitle } from "inbounds/steps/ship/view/ViewShipmentHeaderDownloadsDropdownOption";
import { SelectContainer, SelectWidth } from "inbounds/steps/ship/labels/LabelsSelect";

export const DownloadPalletLabel: React.FC = () => {
  const {
    handleOptionSelect,
    printSizeOptions,
    onDownloadClick,
    labelSize,
    languageOption,
    handleLanguageChange,
    isLoading,
  } = useDownloadPalletLabel();

  return (
    <Stack direction="VERTICAL" data-testid="storage.inbound.palletLabel.download.section">
      <div>
        <Text size="body" style={{ fontWeight: "bold" }}>
          <FormattedMessage
            id="storage.inbounds.detail.shipment.instruction.palletLabel.title"
            defaultMessage="Pallet labels"
          />
        </Text>
        <Text size="body">
          <FormattedMessage
            id="storage.inbounds.detail.shipment.instruction.palletLabel.text"
            defaultMessage="Please print pallet labels here and affix to each pallet. Failure to do so will result in an inbound penalty fee."
          />
        </Text>
      </div>

      <Box marginTop={"S2"}>
        <SelectContainer isInDownloadModal={false}>
          <SelectWidth
            label={
              <FormattedMessage
                id="storage.inbounds.detail.shipment.instruction.palletLabel.selectPrintSize"
                defaultMessage="Print size"
              />
            }
            onChange={handleOptionSelect}
            options={printSizeOptions}
            placeholder={
              <FormattedMessage
                id="storage.inbounds.detail.shipment.instruction.palletLabel.select"
                defaultMessage="Select pallet labels"
              />
            }
            searchable={false}
          />
          <SelectWidth
            label={
              <FormattedMessage
                id="storage.inbounds.detail.shipment.instruction.palletLabel.selectLanguage"
                defaultMessage="Instruction language"
              />
            }
            options={Object.keys(LANGUAGE_DOWNLOAD_OPTIONS_TITLES).map((option) => ({
              label: (
                <>
                  <DropdownTitle>
                    <FormattedMessage {...LANGUAGE_DOWNLOAD_OPTIONS_TITLES[option]} />
                  </DropdownTitle>
                  <DropdownDescription>
                    <FormattedMessage {...LANGUAGE_DOWNLOAD_OPTIONS_SUBTITLES[option]} />
                  </DropdownDescription>
                </>
              ),
              value: option,
            }))}
            placeholder={
              <FormattedMessage
                id="inbounds.palletLabelLanguageSelect.placeholder"
                defaultMessage="Select a language"
              />
            }
            onChange={handleLanguageChange}
            value={
              languageOption
                ? {
                    value: languageOption,
                    label: <FormattedMessage {...LANGUAGE_DOWNLOAD_OPTIONS_TITLES[languageOption.value]} />,
                  }
                : null
            }
            searchable={false}
          />
        </SelectContainer>

        <Button
          data-testid="storage.inbound.palletLabel.download.button"
          secondary
          onClick={onDownloadClick}
          disabled={!labelSize}
          loading={isLoading}
        >
          <FormattedMessage
            id="storage.inbounds.detail.shipment.instruction.palletLabel.download"
            defaultMessage={"Download pallet labels"}
          />
        </Button>
      </Box>
    </Stack>
  );
};
