import { ListType } from "common/list";
import { LogisticsSearchConfig } from "./LogisticsSearchConfig";
import { getBearerToken } from "common/clients/core/getBearerToken";
import { getEnvString } from "@deliverr/commons-utils";

/**
 * Sets up all search configs required for listing pages.
 * The goal is to consolidate config creation by "list type".
 *
 * Search configs are pulled from Algolia by default and a
 * optional logisticsSearch flag can be passed to search from
 * the new Logistics Search platform.
 *
 * More info here
 * https://docs.google.com/document/d/11WgAJu_LuHfP5Dbo-sTEP-nnkDS3gy3IyI_nVNcoTFo/edit
 */

const DefaultLogisticsSearchConfig: Omit<LogisticsSearchConfig, "indexName"> = {
  searchConfig: {
    hitsPerPage: 25,
    highlightMatches: true,
  },
  auth: {
    token: getBearerToken,
  },
};

/**
 *
 * @param listType
 * @returns
 */
export const configFor = (listType: ListType): LogisticsSearchConfig => {
  switch (listType) {
    case ListType.InventoryV2:
      return { ...DefaultLogisticsSearchConfig, indexName: getEnvString("OPENSEARCH_INDEX_INVENTORY", "products") };
    case ListType.Parcel:
      return { ...DefaultLogisticsSearchConfig, indexName: process.env.OPENSEARCH_INDEX_PARCEL! };
    case ListType.BundleV2:
      return { ...DefaultLogisticsSearchConfig, indexName: getEnvString("OPENSEARCH_INDEX_BUNDLE", "bundles") };
    case ListType.Prep:
      return { ...DefaultLogisticsSearchConfig, indexName: process.env.OPENSEARCH_INDEX_PREP! };
    case ListType.ClaimUploads:
      return { ...DefaultLogisticsSearchConfig, indexName: process.env.OPENSEARCH_INDEX_CLAIM! };
    case ListType.ReserveStorageOutbounds:
      return { ...DefaultLogisticsSearchConfig, indexName: process.env.OPENSEARCH_INDEX_RESERVE_STORAGE_OUTBOUNDS! };
    case ListType.InboundListV2:
      return { ...DefaultLogisticsSearchConfig, indexName: process.env.OPENSEARCH_INDEX_INBOUNDS! };
    case ListType.Orders:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: getEnvString("OPENSEARCH_INDEX_ORDER", "fulfillment_deliverrorder"),
      };
    case ListType.Carriers:
      return { ...DefaultLogisticsSearchConfig, indexName: process.env.OPENSEARCH_INDEX_CARRIERS! };
    case ListType.OrderIngestErrors:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: getEnvString("OPENSEARCH_INDEX_ORDER_INGEST_ERRORS", "channels_order_ingest_errors"),
      };
    case ListType.NonCompliance:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: 'seller_problems_view',
    };
    case ListType.JobRuns:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: getEnvString("OPENSEARCH_INDEX_JOB_RUNS", "job_runs"),
      };
    default:
      throw new Error("Not implemented");
  }
};
