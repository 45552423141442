import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { EntityError, FBAPrepCategory, FBAPrepType } from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { FbaV3Loaders } from "../../commons/FbaV3Constants";
import { replenishmentClient } from "Clients";
import { getSellerId } from "common/user/UserSelectors";
import { toast } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import React from "react";
import { FbaPrepDetailsLabels } from "../FbaPrepDetailsLabels";
import { getFlattenedEntityErrors } from "../../commons/FbaV3Utils";

export const setFbaPrepDetailsAction =
  (
    dsku: string,
    prepCategory: FBAPrepCategory,
    prepTypes: FBAPrepType[],
    setErrors: (errors: EntityError[]) => void,
    onSuccess?: () => void
  ): SPThunkAction =>
  async (dispatch, getState) => {
    dispatch(addLoader(FbaV3Loaders.SET_PREP_DETAILS));

    try {
      const state = getState();
      const sellerId = getSellerId(state);

      await replenishmentClient.setItemPrepDetails(sellerId, [
        {
          dsku,
          prepCategory,
          prepTypes,
        },
      ]);

      onSuccess?.();

      toast.success(<FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsSubmitSuccess} />, {
        autoClose: 5000,
        toastId: "setFbaPrepDetailsActionSuccess",
      });
    } catch (err) {
      const error = err?.response?.data?.error ?? err;
      logError({ fn: "setFbaPrepDetailsAction" }, error, "Failed to set prep details");

      setErrors(getFlattenedEntityErrors([error]));

      toast.error(<FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsSubmitError} />, {
        autoClose: 5000,
        toastId: "setFbaPrepDetailsActionError",
      });
    } finally {
      dispatch(clearLoader(FbaV3Loaders.SET_PREP_DETAILS));
    }
  };
