import { CrossdockInboundQuote } from "@deliverr/crossdock-service-client/lib/packages/client/src/legacy-crossdock-client";
import {
  InboundPackage,
  InboundPackageCreator,
  InboundPackageData,
  InboundPackageItem,
  InboundPackageSummary,
  InboundShipment,
  ShippingPlan,
  CasePackDefault,
} from "@deliverr/inbound-client";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { countBy, difference, fromPairs, isEmpty, isEqual, pick, sortBy, uniq, uniqWith } from "lodash/fp";
import { BoxSize, PlannedShipment } from "inbounds/InboundTypes";
import { isConfirmedShipmentStatus } from "inbounds/ShipmentStatus";
import BoxArrangement from "inbounds/steps/ship/BoxArrangement";
import { isShipToOnePlan } from "./ship/InboundUtils";
import { isCrossdockChargeablePlanAndShipment } from "inbounds/crossdock/util";
import { getIsLtlDeliverr, getIsSpdDeliverr, getShippingMethodFromOption } from "inbounds/utils/shippingMethodUtils";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { CargoType } from "./ship/freight/FreightContainerDetailsStep/types";

const EMPTY_FROM_ADDRESS = {
  name: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
};
const EMPTY_BOX_SIZE = { width: 0, length: 0, height: 0 };

export const newPackage = (
  shipment: InboundShipment,
  fromAddress: DeliverrAddress,
  casePackDefaults?: Partial<CasePackDefault>
): InboundPackageData => ({
  createdBy: InboundPackageCreator.SELLER,
  sellerId: shipment.sellerId,
  shippingPlanId: shipment.shippingPlanId,
  warehouseId: shipment.warehouseId,
  shippingOption: "",
  shipmentId: shipment.id,
  fromAddress,
  width: casePackDefaults?.width ?? 0,
  length: casePackDefaults?.length ?? 0,
  height: casePackDefaults?.height ?? 0,
  weight: casePackDefaults?.weight ?? 0,
  weightUnit: "lb",
  dimensionUnit: "in",
  items: [],
});

const packageSpecificProperties = [
  "sellerId",
  "shippingPlanId",
  "warehouseId",
  "shippingOption",
  "shipmentId",
  "width",
  "length",
  "height",
  "weight",
  "weightUnit",
  "dimensionUnit",
];

const convertPackageToPackageData = (pkg: InboundPackage): InboundPackageData => ({
  ...(pick(packageSpecificProperties, pkg) as InboundPackageData),
  items: pkg.items.map(pick(["dsku", "qty"])),
});

const getPackageItemKey = ({ items }: InboundPackage) => `${items[0].dsku}-${items[0].qty}`;

const getIdenticalPackageCounts = (
  isOneSkuPerBox: boolean,
  packages: InboundPackage[],
  plannedShipmentPackages: InboundPackage[]
): ReadonlyArray<number> => {
  const dskuToPackageCounts = countBy(getPackageItemKey, packages);
  return isOneSkuPerBox
    ? plannedShipmentPackages.map((pkg) => dskuToPackageCounts[getPackageItemKey(pkg)])
    : Array(packages.length).fill(0);
};

const pickPackageItem: (pkgItem: InboundPackageItem) => { dsku: string; qty: number } = pick(["dsku", "qty"]);
const pickBoxSize: (pkg: InboundPackage) => BoxSize = pick(["length", "width", "height"]);

const getComparablePackage = (pkg: InboundPackage) => ({
  ...pickBoxSize(pkg),
  items: pkg.items.map(pickPackageItem),
});

const getBoxSizes = (packages: InboundPackage[]): BoxSize[] => packages.map(pickBoxSize);

const getSelectedBoxSizes = (plannedShipmentPackages: InboundPackage[]): ReadonlyArray<number> => {
  const boxSizes = getBoxSizes(plannedShipmentPackages);
  return plannedShipmentPackages.map((pkg) => boxSizes.findIndex((boxSize) => isEqual(boxSize, pickBoxSize(pkg))));
};

const getOneSkuPackages = (packages: InboundPackage[]): InboundPackage[] =>
  sortBy(
    ({ items }) => items[0].dsku,
    uniqWith<InboundPackage>((pkgA, pkgB) => isEqual(getComparablePackage(pkgA), getComparablePackage(pkgB)), packages)
  );

export const createExistingDraftShipment = (
  shipment: InboundShipment,
  packages: InboundPackage[],
  plan: ShippingPlan,
  crossdockQuote?: CrossdockInboundQuote
): PlannedShipment => {
  const { shippingOption } = shipment;
  const shippingMethod = getShippingMethodFromOption(shippingOption);
  const isConfirmed = isConfirmedShipmentStatus(shipment.status);
  const isOneSkuPerBox = packages.every(({ items }) => items.length === 1);
  const plannedShipmentPackages: InboundPackage[] = isOneSkuPerBox ? getOneSkuPackages(packages) : packages;
  const isCrossdockChargeable = isCrossdockChargeablePlanAndShipment(
    Boolean(isShipToOnePlan(plan)),
    shipment.id,
    crossdockQuote
  );
  const isLtlDeliverr = getIsLtlDeliverr(shippingMethod);
  const isSpdDeliverr = getIsSpdDeliverr(shippingMethod);
  const hasChargesAccepted = (isSpdDeliverr || isLtlDeliverr || isCrossdockChargeable) && isConfirmed;

  return {
    id: shipment.id,
    shippingMethod,
    cargoType: CargoType.PALLETIZED,
    boxArrangement: isOneSkuPerBox ? BoxArrangement.OneSKUPerBox : BoxArrangement.MultipleSKUsPerBox,
    packages: plannedShipmentPackages.map(convertPackageToPackageData),
    packageCount: plannedShipmentPackages.length,
    identicalPackageCounts: getIdenticalPackageCounts(isOneSkuPerBox, packages, plannedShipmentPackages),
    boxSizes: getBoxSizes(plannedShipmentPackages),
    selectedBoxSizes: getSelectedBoxSizes(plannedShipmentPackages),
    boxConfirmAttempted: false,
    boxesConfirmed: isConfirmed,
    hasChargesAccepted,
    isValid: isConfirmed,
    boxSaveConfigurationAttempted: false,
    sellerLPNsList: [],
  };
};

/* Set the qty for a DSKU in a given package, adding it if not already present */
export const setPackageDskuQty = (pkg: InboundPackageData, dsku: string, qty: number): InboundPackageData => {
  let items = [...(pkg.items ?? [])];
  const itemIndex = items.findIndex((item) => item.dsku === dsku);

  if (itemIndex >= 0) {
    items[itemIndex] = { ...items[itemIndex], qty };
  } else {
    // Push new box item if SKU wasn't yet in box
    items = [...items, { dsku, qty }];
  }
  return { ...pkg, items };
};

const getShipmentDskus = (shipment: InboundShipment): string[] => uniq(shipment.items.map((item) => item.dsku));

const combineBoxSizes = (packages: InboundPackageSummary[]) => {
  const boxSizes: BoxSize[] = [];
  const selectedBoxSizes: number[] = [];
  const boxSizeDic = {};
  packages.forEach((packageSummary) => {
    const { width, length, height } = packageSummary;
    const box: BoxSize = { width: width ?? 0, length: length ?? 0, height: height ?? 0 };
    const boxSizeKey = `${box.width}_${box.length}_${box.height}`;
    if (boxSizeDic[boxSizeKey] === undefined) {
      boxSizes.push(box);
      boxSizeDic[boxSizeKey] = boxSizes.length - 1;
    }
    selectedBoxSizes.push(boxSizeDic[boxSizeKey]);
  });
  return {
    boxSizes,
    selectedBoxSizes,
  };
};

const getPlannedPackages = (
  shipment: InboundShipment,
  casePackDefaults: { [dsku: string]: Partial<CasePackDefault> },
  packages: InboundPackageSummary[]
) => {
  return {
    packageCount: packages.length,
    identicalPackageCounts: packages.map((packageSummary) => packageSummary.numberOfPkgs),
    sellerLPNsList: packages.map((packageSummary) => packageSummary.sellerLPNs ?? []),
    packages: packages.map((packageSummary) => {
      let pkg = newPackage(
        shipment,
        EMPTY_FROM_ADDRESS,
        packageSummary.items.length === 1 ? casePackDefaults[packageSummary.items[0].dsku] : undefined
      );
      packageSummary.items.forEach((item) => {
        pkg = setPackageDskuQty(pkg, item.dsku, item.qty);
      });
      const weight = packageSummary.weight && packageSummary.weight > 0 ? packageSummary.weight : pkg.weight;
      return {
        ...pkg,
        weight,
      };
    }),
    ...combineBoxSizes(packages),
  };
};

export const createNewDraftShipment = (
  shipment: InboundShipment,
  casePackDefaults: { [dsku: string]: Partial<CasePackDefault> },
  packages?: InboundPackageSummary[]
): PlannedShipment => {
  const dskus = getShipmentDskus(shipment);
  const dskuToQty = fromPairs(shipment.items.map(({ dsku, qty }) => [dsku, qty]));
  /* We default to one-sku-per-box, so we create N boxes for N dskus, each box with its own size */
  const isMultiSkuPerBox = packages?.find((packageSummary) => packageSummary.items.length > 1);
  const plannedPackages =
    packages && packages.length > 0
      ? getPlannedPackages(shipment, casePackDefaults, packages)
      : {
          packages: dskus.map((dsku) =>
            setPackageDskuQty(
              newPackage(shipment, EMPTY_FROM_ADDRESS, casePackDefaults[dsku]),
              dsku,
              casePackDefaults[dsku]?.unitsPerCase ?? 0
            )
          ),
          packageCount: dskus.length,
          identicalPackageCounts: dskus.map((dsku) =>
            casePackDefaults[dsku]?.unitsPerCase ? dskuToQty[dsku] / casePackDefaults[dsku].unitsPerCase! : 0
          ),
          // "# of boxes" in one-sku-per-box mode
          boxSizes: dskus.map((dsku) => ({
            height: casePackDefaults[dsku]?.height ?? 0,
            length: casePackDefaults[dsku]?.length ?? 0,
            width: casePackDefaults[dsku]?.width ?? 0,
          })),
          selectedBoxSizes: dskus.map((_, dskuIndex) => dskuIndex),
          sellerLPNsList: [],
        };

  return {
    id: shipment.id,
    shippingMethod: undefined,
    boxArrangement: isMultiSkuPerBox ? BoxArrangement.MultipleSKUsPerBox : BoxArrangement.OneSKUPerBox,
    cargoType: CargoType.PALLETIZED,
    ...plannedPackages,
    boxConfirmAttempted: false,
    boxesConfirmed: false,
    hasChargesAccepted: false,
    isValid: false,
    boxSaveConfigurationAttempted: false,
  };
};

interface PackageData {
  boxSizes: BoxSize[];
  packages: ReadonlyArray<InboundPackageData>;
  identicalPackageCounts: ReadonlyArray<number>;
  selectedBoxSizes: ReadonlyArray<number>;
}

const getPackageDataWithAddedSkus = (plannedShipment: PlannedShipment, shipment: InboundShipment): PackageData => {
  const { boxSizes, identicalPackageCounts, packages, selectedBoxSizes } = plannedShipment;

  const packageDskus = uniq<string>(packages.map(({ items }) => items[0]?.dsku).filter(Boolean));
  const dskus = getShipmentDskus(shipment);
  const newDskus = difference(dskus, packageDskus);

  if (newDskus.length > 0) {
    const newPackages = newDskus.map((dsku) => setPackageDskuQty(newPackage(shipment, EMPTY_FROM_ADDRESS), dsku, 0));
    const newPackageSlots = Array(newPackages.length);

    return {
      packages: [...packages, ...newPackages],
      identicalPackageCounts: [...identicalPackageCounts, ...newPackageSlots.fill(0)],
      boxSizes: [...boxSizes, ...newPackageSlots.fill(EMPTY_BOX_SIZE)],
      selectedBoxSizes: [...selectedBoxSizes, ...newPackageSlots.map((_, i) => boxSizes.length + i)],
    };
  }

  return { identicalPackageCounts, packages, boxSizes, selectedBoxSizes };
};

const removeAtIndices = <T>(indices: number[], items: T[] | ReadonlyArray<T>) =>
  items.filter((_, i) => !indices.includes(i));

const getPackageDataWithoutRemovedSkus = (pkgData: PackageData, shipment: InboundShipment): PackageData => {
  const { boxSizes, packages, identicalPackageCounts, selectedBoxSizes } = pkgData;
  const dskus = getShipmentDskus(shipment);

  const packagesWithoutRemovedItems = packages.map((pkg) => ({
    ...pkg,
    items: pkg.items.filter(({ dsku }) => dskus.includes(dsku)),
    shipmentId: shipment.id,
  }));

  const removedPackageIndices: number[] = packagesWithoutRemovedItems.reduce(
    (indices, { items }, i) => (isEmpty(items) ? [...indices, i] : indices),
    []
  );

  return {
    boxSizes,
    packages: removeAtIndices(removedPackageIndices, packagesWithoutRemovedItems),
    selectedBoxSizes: removeAtIndices(removedPackageIndices, selectedBoxSizes),
    identicalPackageCounts: removeAtIndices(removedPackageIndices, identicalPackageCounts),
  };
};

export const transferPlannedShipmentData = (
  oldPlannedShipment: PlannedShipment,
  shipment: InboundShipment,
  casePackDefaults: { [dsku: string]: Partial<CasePackDefault> },
  packages?: InboundPackageSummary[]
): PlannedShipment => {
  const packageDataWithAddedSkus = getPackageDataWithAddedSkus(oldPlannedShipment, shipment);
  const newPackageData =
    packages && packages.length > 0
      ? getPlannedPackages(shipment, casePackDefaults, packages)
      : getPackageDataWithoutRemovedSkus(packageDataWithAddedSkus, shipment);
  const isMultiSkuPerBox = packages?.find((packageSummary) => packageSummary.items.length > 1);
  const boxArrangement =
    packages && packages.length > 0
      ? isMultiSkuPerBox
        ? BoxArrangement.MultipleSKUsPerBox
        : BoxArrangement.OneSKUPerBox
      : oldPlannedShipment.boxArrangement;

  return {
    ...oldPlannedShipment,
    ...newPackageData,
    id: shipment.id,
    packageCount: newPackageData.packages.length,
    boxArrangement,
  };
};

// saved draft shipment can become out of sync with data from saved shipment
export const updateSavedDraftShipment = (
  savedPlannedShipment: PlannedShipment,
  shipmentStatus: InboundShipmentStatus,
  plan: ShippingPlan,
  shippingOption: InboundShipment["shippingOption"],
  crossdockQuote?: CrossdockInboundQuote
): PlannedShipment => {
  const isConfirmed = isConfirmedShipmentStatus(shipmentStatus);
  const isCrossdockChargeable = isCrossdockChargeablePlanAndShipment(
    Boolean(isShipToOnePlan(plan)),
    savedPlannedShipment.id,
    crossdockQuote
  );
  const shippingMethod = getShippingMethodFromOption(shippingOption);
  const isLtlDeliverr = getIsLtlDeliverr(shippingMethod);
  const isSpdDeliverr = getIsSpdDeliverr(shippingMethod);

  return {
    ...savedPlannedShipment,
    shippingMethod,
    cargoType: CargoType.PALLETIZED,
    boxesConfirmed: isConfirmed,
    hasChargesAccepted: shippingOption
      ? isConfirmed && (isSpdDeliverr || isCrossdockChargeable || isLtlDeliverr)
      : false,
    isValid: isConfirmed,
  };
};
