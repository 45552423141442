import React, { FC } from "react";
import { DefaultTheme, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { FbaLotAndExpiryPickerModal } from "./FbaLotAndExpiryPickerModal";
import { FbaLotAndExpiryPickerLabels } from "./FbaLotAndExpiryPickerLabels";
import { BorderlessButton } from "common/components/BorderlessButton";
import { getFbaLotAndExpiryPickerModalIdForSku } from "../commons/FbaV3Constants";
import { useModal } from "common/hooks/useModal";
import { useFbaLotExpiryPicker } from "./useFbaLotAndExpiryPicker";
import { isNil } from "lodash";

const StyledButton = styled(BorderlessButton)<{}, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors[theme.config.colorByAppearance.PRIMARY][300]};

    &:hover {
      color: #11bfe3;
    }
  `
);

interface FbaLotAndExpiryPickerProps {
  dsku: string;
}

export const FbaLotAndExpiryPicker: FC<FbaLotAndExpiryPickerProps> = ({ dsku }) => {
  const { showModal, hideModal } = useModal(getFbaLotAndExpiryPickerModalIdForSku(dsku));
  const { onExpiryChange, expirationDate } = useFbaLotExpiryPicker(dsku);

  return (
    <>
      <FbaLotAndExpiryPickerModal
        dsku={dsku}
        onCloseModal={hideModal}
        expirationDate={expirationDate}
        onExpiryChange={onExpiryChange}
      />
      <StyledButton onClick={showModal}>
        <Text bold>
          <FormattedMessage
            {...(isNil(expirationDate)
              ? FbaLotAndExpiryPickerLabels.setLotAndExpiryButtonText
              : FbaLotAndExpiryPickerLabels.updateLotAndExpiryButtonText)}
          />
        </Text>
      </StyledButton>
    </>
  );
};
