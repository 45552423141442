export enum NonComplianceListActionTypes {
  SET_UNKNOWN_BARCODE_NC_CASES = "SET_UNKNOWN_BARCODE_NC_CASES",
  SET_MISSING_BARCODE_NC_CASES = "SET_MISSING_BARCODE_NC_CASES",
  SET_UNEXPECTED_BARCODE_NC_CASES = "SET_UNEXPECTED_BARCODE_NC_CASES",
  SET_UNEXPECTED_SKU_NC_CASES = "SET_UNEXPECTED_SKU_NC_CASES",
  SET_CURRENT_NON_COMPLIANCE_SEARCH_TERM = "SET_CURRENT_NON_COMPLIANCE_SEARCH_TERM",
  SET_CURRENT_NON_COMPLIANCE_PAGE = "SET_CURRENT_NON_COMPLIANCE_PAGE",
  SEARCH_NON_COMPLIANCE_SUCCESS = "SEARCH_NON_COMPLIANCE_SUCCESS",
  SEARCH_NON_COMPLIANCE_ERROR = "SEARCH_NON_COMPLIANCE_ERROR",
  LOAD_NON_COMPLIANCE_LIST = "LOAD_NON_COMPLIANCE_LIST",
  SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS",
}
