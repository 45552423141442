import { Box, DefaultTheme, Icon, IconsProps, Text, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";
import { PackInfo } from "common/components/ProductSearch/Packs/components/PackInfo";
import styled from "@emotion/styled";
import { LightDivider } from "./ReplenishmentOrderCreationErrorStyles";
import { CustomReplenishmentProductErrorHandlers } from "./custom-product-errors/CustomReplenishmentProductErrorHandlers";
import { ReplenishmentProductWithErrors } from "./ReplenishmentProductErrorTypes";
import { FlexDiv } from "common/components/FlexDiv";
import { ReplenishmentErrorDescription } from "./ReplenishmentOrderError";
import { isEmpty } from "lodash";

const RemoveRowIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.RED[300]};
  margin: ${theme.spacing.S2};
  cursor: pointer;
`
);

const ProductDescriptionText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[300]};
    font-size: ${theme.font.size.F2};
  `
);

const ProductDescriptionContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    padding-right: ${theme.spacing.S2};
    align-self: start;
    width: 35%;
  `
);

interface ReplenishmentProductErrorProps {
  productWithErrors: ReplenishmentProductWithErrors;
  removeProduct: (dsku: string) => void;
}

export const ReplenishmentProductError: FC<ReplenishmentProductErrorProps> = ({ productWithErrors, removeProduct }) => {
  const { product } = productWithErrors;

  return (
    <>
      <FlexDiv alignItems="top">
        <ProductDescription product={product} />
        <ProductErrorsTable productWithErrors={productWithErrors} />
        <RemoveRowIcon type="times-circle" size="2x" onClick={() => removeProduct(product.dsku)} />
      </FlexDiv>

      <LightDivider />
    </>
  );
};

const ProductDescription = ({ product }: { product: ReplenishmentProductWithErrors["product"] }) => {
  const { name: productName } = product;

  return (
    <ProductDescriptionContainer>
      <Box paddingBottom={"S1"}>
        <Text bold as="span">
          {productName}
        </Text>
      </Box>

      <ProductDescriptionText>{getProductSku(product)}</ProductDescriptionText>

      <ProductDescriptionText>
        <PackInfo product={product} />
      </ProductDescriptionText>
    </ProductDescriptionContainer>
  );
};

const ProductErrorsTable = ({ productWithErrors }: { productWithErrors: ReplenishmentProductWithErrors }) => {
  const { errors, product } = productWithErrors;

  return (
    <Box width={"55%"} paddingLeft={"S2"}>
      <table>
        <tbody>
          {errors.map(({ errorMessage, errorCode }, idx) => (
            <tr key={idx}>
              <Box paddingLeft={"S2"} paddingRight={"S2"}>
                <ReplenishmentErrorDescription errorMessage={errorMessage} errorCode={errorCode} />
              </Box>

              {!isEmpty(errorCode) && (
                <Box paddingLeft={"S6"} paddingRight={"S2"}>
                  <CustomReplenishmentProductErrorHandlers errorCode={errorCode!} product={product} />
                </Box>
              )}

              {idx !== errors.length - 1 ? <LightDivider /> : null}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};
