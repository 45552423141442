import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { InboundStepTitle, InboundStepDescription } from "inbounds/steps/InboundStepElements";
import { FEFO_CONTENT, GENERAL_CONTENT, INVALID_KIT_CONTENT, LOT_TRACKING_CONTENT } from "./constants";
import { isEmpty } from "lodash";
import { LotInfoFefoInvalidKits } from "../types/LotInfoFefoInvalidKits";

export const LotInformationHeader: FC<{
  hasFefoEnabledProducts: boolean;
  hasLotTrackingEnabledProducts: boolean;
  invalidKitsWithComponentsLotOrFefoDisabled?: LotInfoFefoInvalidKits[] | undefined;
}> = ({ hasFefoEnabledProducts, hasLotTrackingEnabledProducts, invalidKitsWithComponentsLotOrFefoDisabled }) => {
  const hasBoth = hasFefoEnabledProducts && hasLotTrackingEnabledProducts;
  const hasInvalidKits = !isEmpty(invalidKitsWithComponentsLotOrFefoDisabled);
  const content = hasInvalidKits
    ? INVALID_KIT_CONTENT
    : hasBoth
    ? GENERAL_CONTENT
    : hasFefoEnabledProducts
    ? FEFO_CONTENT
    : LOT_TRACKING_CONTENT;

  return (
    <>
      <InboundStepTitle>
        <FormattedMessage {...content.TITLE} />
      </InboundStepTitle>
      <InboundStepDescription appearance="INFO">
        <FormattedMessage
          {...content.DESCRIPTION}
          values={{
            learnMoreLink: <ElevioLearnMoreButton articleId={ElevioArticleId.LotTracking} />,
          }}
        />
      </InboundStepDescription>
    </>
  );
};
