import { FBAPrepCategory, FBAPrepType, ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import React from "react";
import { FormattedMessage } from "react-intl";
import { replenishmentShipmentCreateLabels } from "../../common/replenishment/replenishmentShipmentCreate.labels";

export const PACKING_OPTIONS_POLLING_INTERVAL_MS = 5000;
export const PACKING_OPTIONS_FETCH_MAX_RETRIES = 10;

export const PLACEMENT_OPTIONS_POLLING_INTERVAL_MS = 8000;
export const PLACEMENT_OPTIONS_FETCH_MAX_RETRIES = 45;

export const TRANSPORTATION_OPTIONS_POLLING_INTERVAL_MS = 5000;
export const TRANSPORTATION_OPTIONS_FETCH_MAX_RETRIES = 40;

export const PLACEMENT_OPTION_CONFIRMATION_FAILURE_DELAY = 5000;

export const ORDER_CONFIRMATION_POLLING_INTERVAL_MS = 5000;
export const ORDER_CONFIRMATION_FETCH_MAX_RETRIES = 25;

export const PACKING_OPTION_GROUP_DETAILS_MODAL_ID = "packingOptionGroupDetailsModal";

export const FBA_V3_MIN_DAYS_FOR_PRODUCT_EXPIRATION = 106; // 105 days + 1 day for the current date / timezone issues

// Product validation constants
export const FBA_V3_MAX_SKU_QUANTITY_IN_INBOUND_PLAN = 10000;
export const FBA_V3_MAX_CASE_DIMENSION_IN = 25;
export const FBA_V3_MAX_CASE_WEIGHT_LB = 50;
export const FBA_V3_MAX_QUANTITY_IN_CASE = 10000;

export enum FbaV3EntityName {
  PACKING_OPTION = "PACKING_OPTION",
  PLACEMENT_OPTION = "PLACEMENT_OPTION",
  TEMPORARY_SHIPMENT = "TEMPORARY_SHIPMENT",
  TRANSPORTATION_OPTION = "TRANSPORTATION_OPTION",
  ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
}

export enum FbaV3Loaders {
  FETCH_PACKING_OPTIONS = "FETCH_PACKING_OPTIONS",
  CONFIRM_PACKING_OPTION = "CONFIRM_PACKING_OPTION",

  FETCH_PLACEMENT_OPTIONS = "FETCH_PLACEMENT_OPTIONS",
  CONFIRM_PLACEMENT_OPTION = "CONFIRM_PLACEMENT_OPTION",

  FETCH_TRANSPORTATION_OPTIONS = "FETCH_TRANSPORTATION_OPTIONS",

  RETRY_OPERATION = "RETRY_OPERATION",
  GET_ORDER_OPERATIONS = "GET_ORDER_OPERATIONS",

  CONFIRM_ORDER = "CONFIRM_ORDER",
  GET_CONFIRM_ORDER_STATUS_AND_SUBMIT_ORDER = "GET_CONFIRM_ORDER_STATUS_AND_SUBMIT_ORDER",

  SET_PREP_DETAILS = "SET_PREP_DETAILS",
}

export const EligibleShippingTypesForFbaV3: {
  [key in ShippingPartner]?: ShippingType[];
} = {
  [ShippingPartner.DELIVERR]: [ShippingType.LTL, ShippingType.PARCEL],
  [ShippingPartner.FBA]: [ShippingType.LTL, ShippingType.PARCEL],
};

export const FbaV3ShippingPartnerOptions = [
  {
    value: ShippingPartner.DELIVERR,
    label: <FormattedMessage {...replenishmentShipmentCreateLabels.steps.shipping.options.fbaWithOptions.deliverr} />,
  },
  {
    value: ShippingPartner.FBA,
    label: <FormattedMessage {...replenishmentShipmentCreateLabels.steps.shipping.options.fbaWithOptions.amazon} />,
  },
];

export const SelectablePrepCategories: FBAPrepCategory[] = [
  FBAPrepCategory.ADULT,
  FBAPrepCategory.BABY,
  FBAPrepCategory.FRAGILE,
  FBAPrepCategory.GRANULAR,
  FBAPrepCategory.HANGER,
  FBAPrepCategory.LIQUID,
  FBAPrepCategory.PERFORATED,
  FBAPrepCategory.SET,
  FBAPrepCategory.SHARP,
  FBAPrepCategory.SMALL,
  FBAPrepCategory.TEXTILE,
  FBAPrepCategory.NONE,
];

export const SelectablePrepTypes: FBAPrepType[] = [
  FBAPrepType.ITEM_BLACK_SHRINKWRAP,
  FBAPrepType.ITEM_BUBBLEWRAP,
  FBAPrepType.ITEM_BOXING,
  FBAPrepType.ITEM_CAP_SEALING,
  FBAPrepType.ITEM_LABELING,
  FBAPrepType.ITEM_POLYBAGGING,
  FBAPrepType.ITEM_RMOVHANG,
  FBAPrepType.ITEM_SETCREAT,
  FBAPrepType.ITEM_SETSTK,
  FBAPrepType.ITEM_SIOC,
  FBAPrepType.ITEM_SUFFOSTK,
  FBAPrepType.ITEM_TAPING,
  FBAPrepType.ITEM_DEBUNDLE,
  FBAPrepType.ITEM_NO_PREP,
  FBAPrepType.ITEM_HANG_GARMENT,
];

export const getFbaLotAndExpiryPickerModalIdForSku = (dsku: string) => `set_fba_lot_and_expiry_modal_${dsku}`;

export const getSetPrepDetailsModalIdForSku = (dsku: string) => `set_fba_prep_details_modal_${dsku}`;
