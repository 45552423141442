import React, { FC } from "react";
import { Stack, Text } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { InboundStepTable, InboundStepTableWrapped } from "inbounds/components/InboundStepTable/InboundStepTable";
import { LotInformationTableRow } from "./LotInformationTableRow";
import { Product } from "@deliverr/commons-clients";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { isEmpty } from "lodash";
import { LotInfoFefoInvalidKits } from "../types/LotInfoFefoInvalidKits";
import { InvalidKitsType, INVALID_KITS_TYPE_LABELS } from "./InvalidKitType";

export const LotInformationTable: FC<{
  products: Product[];
  hasFefoEnabledProducts: boolean;
  hasLotTrackingEnabledProducts: boolean;
  isLoadingProducts?: boolean;
  hasLoadingProductsFailed?: boolean;
  invalidKitsWithComponentsLotOrFefoDisabled?: LotInfoFefoInvalidKits[] | undefined;
}> = ({
  products,
  hasFefoEnabledProducts,
  hasLotTrackingEnabledProducts,
  isLoadingProducts,
  hasLoadingProductsFailed,
  invalidKitsWithComponentsLotOrFefoDisabled,
}) => {
  const getInvalidKitType = (invalidKit: LotInfoFefoInvalidKits) => {
    if (invalidKit.hasFefoIssue && invalidKit.haslotInfoIssue) {
      return InvalidKitsType.LOT_AND_FEFO;
    } else if (invalidKit.hasFefoIssue) {
      return InvalidKitsType.FEFO;
    } else if (invalidKit.haslotInfoIssue) {
      return InvalidKitsType.LOT;
    }
    return InvalidKitsType.ALL_ENABLED;
  };

  if (isLoadingProducts) {
    return <LoadingSpinner center />;
  }

  if (hasLoadingProductsFailed) {
    return (
      <Text size="caption">
        <FormattedMessage
          id="inbounds.lotInformationTable.loadingFailed"
          defaultMessage="Something went wrong while fetching products. Please try again."
        />
      </Text>
    );
  }

  if (!isEmpty(invalidKitsWithComponentsLotOrFefoDisabled)) {
    return (
      <InboundStepTableWrapped>
        <thead>
          <tr>
            <td>
              <Text bold size="caption">
                <FormattedMessage id="inbounds.lotInformationTable.skuHeader" defaultMessage="SKU" />
              </Text>
            </td>
            <td>
              <Text bold size="caption">
                <FormattedMessage id="inbounds.lotInformationTable.skuComponentsHeader" defaultMessage="Components" />
              </Text>
            </td>
            <td>
              <Text bold size="caption">
                <FormattedMessage id="inbounds.lotInformationTable.skuComponentsHeader" defaultMessage="Disabled" />
              </Text>
            </td>
          </tr>
        </thead>
        <tbody>
          {invalidKitsWithComponentsLotOrFefoDisabled?.map((invalidKit) => (
            <tr key={invalidKit.product.dsku}>
              <td>
                <Stack gap="S1">
                  <Text bold>
                    <FormattedMessage
                      id="inbounds.lotInformationTable.skuComponentsDskuProduct"
                      defaultMessage={invalidKit.product.name}
                    />
                  </Text>
                </Stack>
              </td>
              <td>
                <Text>
                  <FormattedMessage
                    id="inbounds.lotInformationTable.skuComponentsDskuProductComponents"
                    defaultMessage={invalidKit.product.kitResponse?.kitComponents
                      ?.map((component) => component.dsku)
                      .join(", ")}
                  />
                </Text>
              </td>
              <td>
                <Text>
                  <FormattedMessage {...INVALID_KITS_TYPE_LABELS[getInvalidKitType(invalidKit)]} />
                </Text>
              </td>
            </tr>
          ))}
        </tbody>
      </InboundStepTableWrapped>
    );
  }

  return (
    <InboundStepTable>
      <thead>
        <tr>
          <td>
            <Text bold size="caption">
              <FormattedMessage id="inbounds.lotInformationTable.skuHeader" defaultMessage="SKU" />
            </Text>
          </td>
          {hasLotTrackingEnabledProducts && (
            <td>
              <Text bold size="caption">
                <FormattedMessage id="inbounds.lotInformationTable.lotNumber" defaultMessage="Lot number" />
              </Text>
            </td>
          )}
          {hasFefoEnabledProducts && (
            <>
              <td>
                <Text bold size="caption">
                  <FormattedMessage
                    id="inbounds.lotInformationTable.expirationDateFormatHeader"
                    defaultMessage="Expiration date format"
                  />
                </Text>
              </td>
              <td>
                <Text bold size="caption">
                  <FormattedMessage
                    id="inbounds.lotInformationTable.expirationDateHeader"
                    defaultMessage="Expiration date"
                  />
                </Text>
              </td>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <LotInformationTableRow
            key={product.dsku}
            product={product}
            hasFefoEnabledProducts={hasFefoEnabledProducts}
            hasLotTrackingEnabledProducts={hasLotTrackingEnabledProducts}
          />
        ))}
      </tbody>
    </InboundStepTable>
  );
};
