import { DeliverrAddress } from "@deliverr/commons-objects";
import {
  DistributionChannel,
  IngestionChannel,
  NonComplianceStatus,
  OrderStatus,
  Source,
  OrderItemDto,
} from "@deliverr/replenishment-client";

export enum OpenSearchOrderAttributeName {
  STORE_NAME = "STORE_NAME",
  SELLER_ENTITY_NAME = "SELLER_ENTITY_NAME",
}

type OpenSearchOrderAttributes = Partial<Record<OpenSearchOrderAttributeName, string>>;

export interface ReplenishmentIndexRow {
  id: string;
  name?: string;
  orderId: string;
  createdAt: Date;
  status: OrderStatus;
  sellerId: string;
  version: string;
  updatedAt: Date;
  source?: Source;
  errors?: string[];
  distributionChannel: DistributionChannel;
  distributionChannelOrderId?: string;
  ingestionChannel?: IngestionChannel;
  originWarehouseAddress?: DeliverrAddress;
  nonComplianceStatus?: NonComplianceStatus;
  isWholesaleMigrationOrder?: boolean;
  invalidOrderItemStatus?: string;
  orderItems: Pick<OrderItemDto, "dsku" | "packOf" | "totalUnits" | "unitsPerPack">[];
  shipments?: {
    originWarehouseId?: string;
    originWarehouseAddress?: DeliverrAddress;
    destinationAddress?: DeliverrAddress;
    shipBy?: string | null;
    poNumber?: string;
    distributionChannelShipmentId?: string;
    shipmentId: string;
    destinationWarehouseId?: string;
  }[];
  orderAttributes?: OpenSearchOrderAttributes;
}
