import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { defaultTheme, DefaultTheme, ThemeProps } from "../shared";
import { Text } from "../Text";
import { Radio } from "../Radio";
import { Checkbox } from "../Checkbox";
import { NoOtherProps } from "../types";
import { IconV2, IconSizes } from "../IconV2";
import { Grid } from "../Grid";

export type PopoverMenuAlign = "left" | "right" | "center";

export type PopoverComponentType = "radio" | "checkbox";

export interface PopoverMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  expanded: boolean;
  align?: PopoverMenuAlign;
}

export interface PopoverMenuItemProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  popoverComponentType?: PopoverComponentType;
  secondary?: ReactNode;
  onClick?: () => void;
  onMouseDown?: (e: any) => void;
}

export interface PopoverMenuSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  sectionTitle: string | JSX.Element;
}

const Container = styled.div<PopoverMenuProps, DefaultTheme>(
  ({ theme, expanded, align = "left" }) => `
  background-color: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${theme.border.radius.R2};
  box-shadow: ${theme.shadow.S3};
  display: ${expanded ? `inline-block` : `none`};
  min-width: 200px;
  position: absolute;
  z-index: ${theme.zindex.Z5};
  padding: ${theme.spacing.S1};
  top: 110%;
  color: ${theme.colors.NEUTRAL[500]};
  ${
    align === "right"
      ? `right: 0;`
      : align === "left"
      ? `left: 0;`
      : `left: 50%;
    translateX(-50%);
    `
  }
`
);

const ItemContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F2};
  white-space: nowrap;
  cursor: default;
  font-weight: ${theme.font.weight.REGULAR};
  text-align: left;

  :last-child {
    border-bottom: none;
  }

  :hover {
    background-color: ${theme.colors.NEUTRAL[20]};
    font-weight: ${theme.font.weight.BOLD};
  }
`
);

const Caption = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S1};
  `
);

interface UnstyledButtonProps extends ThemeProps {
  disabled?: boolean;
}

const UnstyledButton = styled.button<UnstyledButtonProps>(
  ({ theme, disabled }) => `
    width: 100%;
    appearance: none;
    border: 0;
    background-color: transparent;
    font-size: ${theme.font.size.F2};
    font-weight: ${theme.font.weight.REGULAR};
    text-align: left;
    cursor: ${disabled ? "not-allowed" : "pointer"};
    min-height: 43px;
    padding: 10px ${theme.spacing.S4};
    opacity: ${disabled ? 0.5 : 1};
    pointer-events: ${disabled ? "none" : "auto"};
  `
);

const SectionContainer = styled.div<NoOtherProps, DefaultTheme>(
  ({ theme }) => `
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]};
    text-align: left;

    :last-child {
      border-bottom: none;
    }
  `
);

const SectionTitle = styled(Text)<NoOtherProps, DefaultTheme>(
  ({ theme }) => `
    margin: 14px ${defaultTheme.spacing.S4} 10px ${defaultTheme.spacing.S4};
  `
);

const Span = styled.span<ThemeProps>(
  ({ theme }) => `
  height: 14px;
  display: flex;
  align-items: center;
`
);

const popoverComponentStyles = `
  width: 100%;
  height: 100%;
  color: ${defaultTheme.colors.NEUTRAL[500]};
  padding: 12px 16px;
`;

const PopoverComponents = (type: PopoverComponentType, props) => {
  const components: Record<PopoverComponentType, JSX.Element> = {
    radio: <Radio {...props} labelStyles={popoverComponentStyles} />,
    checkbox: <Checkbox {...props} labelStyles={popoverComponentStyles} />,
  };

  return components[type];
};

const Section: React.FC<PopoverMenuSectionProps> = ({ sectionTitle, children, ...props }) => (
  <SectionContainer {...props}>
    <SectionTitle bold={true} size="caption">
      {sectionTitle}
    </SectionTitle>
    {children}
  </SectionContainer>
);

const Item: React.FC<PopoverMenuItemProps> = ({
  popoverComponentType,
  label,
  secondary,
  checked,
  onClick,
  onMouseDown,
  disabled,
  ...props
}) => {
  return (
    <ItemContainer>
      {popoverComponentType && PopoverComponents(popoverComponentType, { onClick, label, checked, ...props })}
      {!popoverComponentType && (
        <UnstyledButton
          onClick={(event) => {
            event.stopPropagation();
            onClick?.();
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            onMouseDown?.(e);
          }}
          disabled={disabled}
        >
          <Grid gap="S4" columns="1fr 20px" alignItems="center">
            <div>
              <Span>{label}</Span>
              {secondary && (
                <Caption appearance="INFO" size="caption">
                  {secondary}
                </Caption>
              )}
            </div>
            {checked && <IconV2 type={"check"} size={IconSizes.lg} data-testid="popover-menu-check" />}
          </Grid>
        </UnstyledButton>
      )}
    </ItemContainer>
  );
};

export const PopoverMenu = {
  Container,
  Item,
  Section,
};

PopoverMenu.Container.displayName = "PopoverMenu.Container";
PopoverMenu.Item.displayName = "PopoverMenu.Item";
PopoverMenu.Section.displayName = "PopoverMenu.Section";
