import { DeliverrAddress } from "@deliverr/commons-objects";
import { flowRight, join, map, split, compact, trim } from "lodash/fp";
import React, { FC } from "react";

import { capitalizeWordHtml } from "common/StringUtils";
import { Optional } from "utility-types";
import { Text } from "./ui";
import { capitalize } from "lodash";
import { isInternationalAddress } from "common/AddressUtils";
import HtmlToReact from "./HtmlToReact";
import { useDemoMode } from "common/hooks/useDemoMode";

export const capitalizeField = flowRight(trim, join(" "), map(capitalizeWordHtml), split(" "));

export type MinimizedAddress = Optional<DeliverrAddress, "name">;

export const formatAddresssMinimal = (address: MinimizedAddress) =>
  `${capitalizeField(address.city)}, ${capitalizeField(address.country)}`;

export const formatAddress = ({
  address,
  hideStreet = false,
  hideName = false,
  hideCompany = false,
  hideCountry = true,
  minimalIntl = false,
  hideCompanyPrefix = true,
  hidePhone = true,
}: {
  address: MinimizedAddress;
  hideStreet?: boolean;
  hideName?: boolean;
  hideCompany?: boolean;
  hideCountry?: boolean;
  minimalIntl?: boolean;
  hideCompanyPrefix?: boolean;
  hidePhone?: boolean;
}): string => {
  const isInternational = isInternationalAddress(address as DeliverrAddress);
  if (isInternational && minimalIntl) {
    return formatAddresssMinimal(address);
  }
  const emptyAddress = "";
  const {
    name = emptyAddress,
    company = emptyAddress,
    street1 = emptyAddress,
    street2 = emptyAddress,
    city = emptyAddress,
    state = emptyAddress,
    zip = emptyAddress,
    country = emptyAddress,
  } = address;
  const lines = [
    hideName ? undefined : `${capitalizeField(name)}`,
    hideCompany || hideCompanyPrefix ? undefined : `c/o `,
    hideCompany ? undefined : `${capitalizeField(company)}`,
    hideStreet ? undefined : `${capitalizeField(street1)}`,
    hideStreet ? undefined : `${capitalizeField(street2)}`,
    `${capitalizeField(city)}, ${state} ${zip}`,
    hideCountry ? undefined : `${capitalizeField(country)}`,
    hidePhone ? undefined : `${address.phone ?? ""}`,
  ];
  return compact(lines).join("\n");
};

interface FormatAddressProps {
  address: MinimizedAddress;
  hideStreet?: boolean;
  hideName?: boolean;
  boldName?: boolean;
  hideCompany?: boolean;
  hideCountry?: boolean;
  minimalIntl?: boolean;
}

export const FormatAddress: FC<FormatAddressProps> = ({
  address,
  hideStreet,
  hideName,
  hideCompany,
  hideCountry,
  boldName,
  minimalIntl,
}) => {
  const isInternational = isInternationalAddress(address as DeliverrAddress);
  if (isInternational && minimalIntl) {
    return <Text>{formatAddresssMinimal(address)}</Text>;
  }
  return (
    <>
      {!hideCompany && <Text>{address.company}</Text>}
      {!hideName && <Text bold={boldName}>{address.name}</Text>}
      {!hideStreet && <Text>{address.street1}</Text>}
      <Text>
        {capitalize(address.city)}, {address.state} {address.zip}
      </Text>
      {!hideCountry && <Text>{address.country}</Text>}
    </>
  );
};
interface Props {
  address: MinimizedAddress;
  hideStreet?: boolean;
  hideName?: boolean;
  hideCompany?: boolean;
  hideCountry?: boolean;
  showPhone?: boolean;
  minimalIntl?: boolean;
}

const Address: React.FC<Props> = (props) => {
  const { isDemoMode, maskIfDemoMode } = useDemoMode();
  return (
    <>
      {props.address &&
        (!isDemoMode ? (
          <HtmlToReact
            as="span"
            className="address"
            html={formatAddress({
              address: props.address,
              hideStreet: props.hideStreet,
              hideName: props.hideName,
              hideCompany: props.hideCompany,
              hideCountry: props.hideCountry,
              minimalIntl: props.minimalIntl,
            })}
          ></HtmlToReact>
        ) : (
          maskIfDemoMode(props.address.city)
        ))}
      {!isDemoMode && props.showPhone && props.address.phone && (
        <div data-testid="address-phone">{props.address.phone}</div>
      )}
    </>
  );
};
export default Address;
