import React from "react";
import classNames from "classnames/dedupe";
import { removeBarcodesDraft } from "barcodes/BarcodeDraft";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Grid, Stack, ThemeProps } from "../../ui";
import styled from "@emotion/styled";
import { SelectedProductsListProps } from "../SelectedProductList";
import { InboundPackageItemData } from "@deliverr/inbound-client";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { ProductLink } from "common/components/ProductLink";
import { Text } from "common/components/ui";
import { ControlledNumberInput } from "common/components/reactHookForm";
import cls from "../ProductChooser.less";
import { FieldArrayWithId } from "react-hook-form";
import { CreateShipmentFormState } from "inbounds/createShipment/store";
import { SelectedProductData } from "../SelectedProductData";

const Divider = styled.hr<ThemeProps>(
  ({ theme }) => `
    border-width: ${theme.border.width.B1} 0 0 0;
    border-color: ${theme.colors.NEUTRAL[60]};
    margin: 0;
    width: 100%;
  `
);

const StyledBox = styled(Box)<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.spacing.S3};
  padding: 0 ${theme.spacing.S3} ${theme.spacing.S1} ${theme.spacing.S3};
`
);

const StyledRemoveButtonBox = styled(StyledBox)`
  align-self: center;
  justify-self: center;
`;

const StyledGrid = styled(Grid)<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  margin-top: ${theme.spacing.S5};
  text-align: left;
`
);

export const DtcMixedSkuSelectedProductList: React.FC<
  SelectedProductsListProps & {
    mixedSkuPackage?: FieldArrayWithId<CreateShipmentFormState, `mixedSkuPackages.${number}.items`, "id">[];
    index: number;
    selectedProductsByDsku: {
      [dsku: string]: SelectedProductData;
    };
  }
> = ({ disabled, removeProduct, mixedSkuPackage, index, selectedProductsByDsku }) => {
  const { formatMessage } = useIntl();
  const onRemoveItemClick = async ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
    const sku = currentTarget.dataset.sku;
    const itemIndex = Number(currentTarget.dataset.itemindex);

    if (!sku) {
      return;
    }

    if (!disabled) {
      removeBarcodesDraft(sku);
      removeProduct(sku, itemIndex);
    }
  };

  return (
    <Stack>
      {(mixedSkuPackage?.length ?? 0) > 0 ? (
        <>
          <StyledGrid columns="15fr 2fr 1fr" fullWidth>
            <StyledBox>
              <FormattedMessage id="skus" defaultMessage="Product" />
            </StyledBox>
            <StyledBox>
              <FormattedMessage id="inbounds.productChooser.units" defaultMessage="Units" />
            </StyledBox>
            <StyledBox></StyledBox>
          </StyledGrid>
          <Divider />
          {mixedSkuPackage?.map((row: InboundPackageItemData, itemIndex) => {
            if (!selectedProductsByDsku[row.dsku]) {
              return null;
            }
            return (
              <Box margin={null} key={row.dsku}>
                <StyledGrid columns="15fr 2fr 1fr" fullWidth>
                  <StyledBox>
                    <ProductLink product={selectedProductsByDsku[row.dsku]} shouldOpenInNewTab>
                      <Text bold appearance="PRIMARY" as="div">
                        {selectedProductsByDsku[row.dsku].name}
                      </Text>
                      <Text appearance="INFO" as="div">
                        {selectedProductsByDsku[row.dsku].msku}
                      </Text>
                    </ProductLink>
                  </StyledBox>
                  <StyledBox marginTop={"S1"}>
                    <ControlledNumberInput
                      step="1"
                      name={`${CreateShipmentInputName.MIXED_SKU_PACKAGES}.${index}.items.${itemIndex}.qty`}
                      min={0}
                      defaultValue={row.qty}
                      data-sku={row.dsku}
                      inputSize="medium"
                    />
                  </StyledBox>
                  <StyledRemoveButtonBox marginBottom={"S1"}>
                    <div className={cls.removeItemColumn}>
                      <div
                        className={classNames(cls.removeButton, {
                          [cls.disabledRemoveButton]: disabled,
                        })}
                        role="button"
                        aria-label={formatMessage({
                          id: "productChooser.selectedProduct.removeItem",
                          defaultMessage: "Remove Item",
                        })}
                        onClick={onRemoveItemClick}
                        data-sku={row.dsku}
                        data-itemindex={itemIndex}
                      />
                    </div>
                  </StyledRemoveButtonBox>
                </StyledGrid>
                {itemIndex !== mixedSkuPackage.length - 1 && (
                  <Box paddingTop="S3">
                    <Divider />
                  </Box>
                )}
              </Box>
            );
          })}
        </>
      ) : (
        <Box marginTop="S4"></Box>
      )}
    </Stack>
  );
};
