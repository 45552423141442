import { InboundBookingRequest } from "@deliverr/inbound-client";
import { InternationalRateSteps, ShipmentSteps } from "inbounds/createShipment/CreateShipmentTypes";
import pick from "lodash/pick";
import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";

// we extract sellerId from booking request as its required for all steps
// this helps ensure we dont accidentally add it as a key for a step and get undefined
type StepRequestKey = keyof Omit<InboundBookingRequest, "sellerId">;

type StepRequestKeyMap = {
  [K in Exclude<InternationalRateSteps, ShipmentSteps.PriceView>]: StepRequestKey[];
};

export const selectBookingRequestByStep = (step: ShipmentSteps, shouldSubmit: boolean) =>
  createSelector(selectBookingRequest(shouldSubmit), ({ sellerId, ...bookingRequest }) => {
    // there is a bug in the SMB NIS where omitting any of these date fields will result in an estimate being used
    const relatedDateFields: StepRequestKey[] = ["targetDeliveryDate", "flexibleDeliveryDate", "cargoReadyDate"];

    const requestMap: StepRequestKeyMap = {
      [ShipmentSteps.OriginSearch]: ["from", "originIsPort", "originPortGrouping"],
      [ShipmentSteps.SupplierSelect]: ["supplier"],
      [ShipmentSteps.DestinationSelect]: ["bookingType", ...relatedDateFields],
      [ShipmentSteps.ReserveStorageFcSelect]: ["to"],
      [ShipmentSteps.DestinationSearch]: [
        "bookingType",
        "to",
        "destinationIsPort",
        "destinationPortGrouping",
        "isLiftgateRequired",
        ...relatedDateFields,
      ],
      [ShipmentSteps.CargoDetails]: [
        "bookingProducts",
        "cargoUnitSystem",
        "cargoTotalWeight",
        "cargoTotalVolume",
        "cargoTotalBoxes",
        "palletCount",
        "cargoTotalItems",
        "dangerousGoods",
        "lithiumBatteries",
        "nonLithiumBatteries",
        "otherDangerousGoods",
        "lithiumBatteryPackaging",
        ...relatedDateFields,
      ],
      [ShipmentSteps.PackagingRequirements]: [],
    };

    // we return all values for checkout as an added measure of protection from failures in prior saving
    // and to ensure backwards compatibility with non-draft bookings
    const request: InboundBookingRequest =
      step === ShipmentSteps.PriceView || shouldSubmit
        ? { sellerId, ...bookingRequest }
        : { sellerId, ...pick(bookingRequest, requestMap[step]) };

    return request;
  });
