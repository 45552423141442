import log from "Logger";
import { RootState } from "RootReducer";
import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ListType } from "common/list";
import { searchServiceFor } from "common/search/Factory";
import { batch } from "react-redux";
import { SortDir } from "../../../../common/models";
import { NonComplianceListActionTypes } from "./NonComplianceListActionTypes";
import { searchNonComplianceSuccess } from "./searchNonComplianceSuccess";
import { SearchRequest } from "common/search/SearchService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";

export interface SearchNonComplianceProps {
  page?: number;
}

export const searchNonCompliance =
  ({ page }: SearchNonComplianceProps = {}): SPThunkAction =>
  async (dispatch, getState: () => RootState) => {
    const { nonComplianceList, user } = getState();
    const { searchTerm, searchInfo, searchFilters } = nonComplianceList;
    const { resultsPerPage } = user;

    const nonComplianceSearchService = searchServiceFor(ListType.NonCompliance);

    const ctx = { fn: "searchNonCompliance" };
    log.info(ctx, "Initiating NonCompliance search");

    dispatch(addLoader(NonComplianceListActionTypes.LOAD_NON_COMPLIANCE_LIST));
    const activeFilters: QueryDslQueryContainer[] = [];
    if (searchFilters.problemTypes?.length) {
      activeFilters.push({
        bool: {
          minimum_should_match: 1,
          should: searchFilters.problemTypes.map((problemType) => ({
            term: { type: problemType },
          })),
        },
      });
    }
    if (searchFilters.problemStatuses?.length) {
      activeFilters.push({
        bool: {
          minimum_should_match: 1,
          should: searchFilters.problemStatuses.map((status) => ({
            term: { status },
          })),
        },
      });
    }

    try {
      const searchParameters: SearchRequest = {
        searchTerm,
        page: searchInfo?.currentPage ?? page ?? 0,
        pageSize: resultsPerPage.inboundProblemsList,
        customizedOpenSearchFilters: activeFilters,
        sort: { fieldName: "createdAt", direction: SortDir.DESC },
      };
      const { response, hits } = await nonComplianceSearchService.execute(searchParameters);
      log.info(ctx, "successfully retrieved NonCompliance jobs");

      batch(() => {
        dispatch(searchNonComplianceSuccess(response, hits));
        dispatch(clearLoader(NonComplianceListActionTypes.LOAD_NON_COMPLIANCE_LIST));
      });
    } catch (err) {
      log.error({ ...ctx, parcel: getState().parcelList }, "Error during NonCompliance search");
      dispatch({ type: NonComplianceListActionTypes.SEARCH_NON_COMPLIANCE_ERROR });
    }
  };
