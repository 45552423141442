import { useEffect, useState } from "react";
import { batch, useSelector } from "react-redux";
import {
  setUnitSystem,
  setShipmentTotals,
  setDangerousGoods,
  setCargoReadyDate,
  setHasDangerousGoods,
  setLithiumBatteryPackaging,
  setCargoInputType,
  setSingleSkuPackages,
  setReserveStorage,
  setOrigin,
  setMixedSkuPackages,
  setIsCargoMixedSku,
} from "inbounds/createShipment/store/actions";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { selectHasInvalidSelectedProducts } from "storage/inbounds/create/store/selector/selectHasInvalidSelectedProducts";
import { useSelectedProductTotals } from "./useSelectedProductTotals";
import { useShipmentTotalsValidation } from "./useShipmentTotalsValidation";
import { getBookingProducts } from "inbounds/createShipment/store/actions/getBookingProducts";
import { useSPDispatch } from "common/ReduxUtils";
import { useCreateShipmentContext } from "inbounds/createShipment/CreateShipmentContext";
import { useSmbAccelerateFeatureOn } from "common/featureFlags";
import { FeatureName } from "common/Split";
import { trackSmbBookingCreationEvent } from "inbounds/createShipment/common/analytics/trackSmbBookingCreationEvent";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import { logError } from "Logger";
import { useCargoDetailsStepLatLngQuery$data } from "common/relay/__generated__/useCargoDetailsStepLatLngQuery.graphql";
import { selectDestinationTypes, selectOrigin, selectReserveStorage } from "inbounds/createShipment/store/selectors";
import { BookingAddress } from "@deliverr/inbound-client";
import { mapDtcPackageCollectionToPackageSummary } from "inbounds/createShipment/store/utils";
import { productClient } from "Clients";
import { Product, ProductPrepCategory } from "@deliverr/commons-clients";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { hasInvalidSingleSkuPackages } from "./hasInvalidSingleSkuPackages";
import { CargoInputType } from "inbounds/createShipment/CreateShipmentTypes";

const packageSchema = Yup.array(
  Yup.object({
    numberOfPkgs: Yup.number().positive().required(),
    length: Yup.number().positive().required(),
    width: Yup.number().positive().required(),
    height: Yup.number().positive().required(),
  })
).min(1);

const CARGO_DETAILS_STEP_FIELDS = [
  CreateShipmentInputName.CARGO_INPUT_TYPE,
  CreateShipmentInputName.UNIT_SYSTEM,
  CreateShipmentInputName.TOTAL_UNITS,
  CreateShipmentInputName.TOTAL_VOLUME,
  CreateShipmentInputName.TOTAL_WEIGHT,
  CreateShipmentInputName.TOTAL_PALLETS,
  CreateShipmentInputName.CARGO_READY_DATE,
  CreateShipmentInputName.HAS_DANGEROUS_GOODS,
  CreateShipmentInputName.SINGLE_SKU_PACKAGES,
  CreateShipmentInputName.MIXED_SKU_PACKAGES,
];

// general controller name used to set errors
export const DANGEROUS_GOODS_CHECKLIST = "dangerousGoodsChecklist" as CreateShipmentInputName;
export const LITHIUM_BATTERIES_CHECKLIST = "lithiumBatteriesChecklist" as CreateShipmentInputName;

// if the field is not registered/mounted, getFieldsValid can error out
const CONDITIONAL_DANGEROUS_GOODS_FIELDS = [DANGEROUS_GOODS_CHECKLIST];
const CONDITIONAL_LITHIUM_BATTERY_FIELDS = [LITHIUM_BATTERIES_CHECKLIST];

const useFetchLatLngQuery = (
  address: BookingAddress | null
): useCargoDetailsStepLatLngQuery$data["addressLatLngSearch"] | undefined => {
  const fetchLatLngQuery = graphql`
    query useCargoDetailsStepLatLngQuery($query: String!) {
      addressLatLngSearch(query: $query) {
        lat
        lng
      }
    }
  `;
  const { city, state, street1, street2, country, zip, latLng } = address ?? {};
  const fullAddress = [street2, street1, city, state, country, zip]
    .filter((partialAddress) => !isEmpty(partialAddress))
    .join(", ");
  const [latLngResult, setLatLngResult] = useState<useCargoDetailsStepLatLngQuery$data | undefined>(undefined);
  const environment = useRelayEnvironment();
  const ctx = { fn: "useFetchLatLng" };

  useEffect(() => {
    if (isEmpty(fullAddress) || latLng) {
      return;
    }
    const subscription = fetchQuery(environment, fetchLatLngQuery, { query: fullAddress }).subscribe({
      next: (data: useCargoDetailsStepLatLngQuery$data) => {
        setLatLngResult(data);
      },
      error: (error) => {
        logError(ctx, error, "Error fetching origin address latLng");
      },
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [fullAddress]);

  return latLngResult?.addressLatLngSearch;
};

export const useCargoDetailsStep = () => {
  const dispatch = useSPDispatch();
  const { isOverVolume, isOverWeight } = useShipmentTotalsValidation();
  const { getValues, setValueIfUnsaved, setValue, getFieldsValid, clearErrors, watch } = useCreateShipmentFormContext();
  const singleSkuPackages = watch(CreateShipmentInputName.SINGLE_SKU_PACKAGES);
  const mixedSkuPackages = watch(CreateShipmentInputName.MIXED_SKU_PACKAGES);
  const [isValidated, setIsValidated] = useState(false);
  const [showProductCategoriesModal, setShowProductCategoriesModal] = useState(false);
  const isLithiumBatteriesWorkflowOn = useSmbAccelerateFeatureOn(FeatureName.SmbAccelerateLithiumBatteriesWorkflow);
  const [isIncompletedPackages, setIsIncompletedPackages] = useState(false);
  const [productsWithNoCategory, setProductsWithNoCategory] = useState<Product[]>([]);
  const destinationSelect = getValues(CreateShipmentInputName.DESTINATION_SELECT);
  const isCargoMixedSku = watch(CreateShipmentInputName.IS_CARGO_MIXED_SKU);
  const isDtcNetworkBooking = destinationSelect?.isDTCNetwork;
  const packages = isCargoMixedSku ? mixedSkuPackages : mapDtcPackageCollectionToPackageSummary(singleSkuPackages);
  const { hasSelectedProducts, totals, totalSkus } = useSelectedProductTotals(isDtcNetworkBooking, packages);
  const { totalUnits, totalVolume, totalWeight, totalBoxes } = totals;
  const hasInvalidSelectedProducts = useSelector(selectHasInvalidSelectedProducts);
  const { productDetails } = useSelector(getStorageInboundCreate);

  const cargoInputTypeValue = watch(CreateShipmentInputName.CARGO_INPUT_TYPE);
  const shouldShowProductsSection = cargoInputTypeValue === CargoInputType.PRODUCTS;

  const hasInvalidProducts = isDtcNetworkBooking
    ? isCargoMixedSku
      ? false
      : hasInvalidSingleSkuPackages(singleSkuPackages)
    : hasInvalidSelectedProducts;
  const hasInvalidProductsSection = shouldShowProductsSection && hasInvalidProducts && !isCargoMixedSku;

  const { isSaving, composeSaveStep } = useCreateShipmentContext();

  const isRsSelectionFeatureOn = useSmbAccelerateFeatureOn(FeatureName.IntlInboundRsSelection);
  const origin = useSelector(selectOrigin);
  const { isByFlexport, isByAmazon } = useSelector(selectDestinationTypes);
  const reserveStorage = useSelector(selectReserveStorage);
  const originLatLng = useFetchLatLngQuery(origin.value.address);
  const reserveStorageLatLng = useFetchLatLngQuery(reserveStorage.address);
  const isLatLngLoaded =
    isByFlexport && !isByAmazon && isRsSelectionFeatureOn
      ? !!origin.value.address.latLng && !!reserveStorage.address?.latLng
      : !!origin.value.address.latLng;

  // TODO(pgao1): It's better to get lat and lng from booking response, need to replace when backend supports it, ticket: https://flexport.atlassian.net/browse/SMBIPB-1072
  useEffect(() => {
    if (originLatLng) {
      dispatch(
        setOrigin({
          ...origin,
          value: {
            ...origin.value,
            address: {
              ...origin.value.address,
              latLng: originLatLng,
            },
          },
        })
      );
    }
    if (reserveStorageLatLng && reserveStorage.address) {
      dispatch(
        setReserveStorage({
          ...reserveStorage,
          address: {
            ...reserveStorage.address,
            latLng: reserveStorageLatLng,
          },
        })
      );
    }
  }, [originLatLng, reserveStorageLatLng]);

  useEffect(() => {
    setIsValidated(false);
    clearErrors("shipmentTotals");
    clearErrors("hasDangerousGoods");
    clearErrors(DANGEROUS_GOODS_CHECKLIST);
    clearErrors(LITHIUM_BATTERIES_CHECKLIST);
  }, [shouldShowProductsSection, clearErrors]);

  useEffect(() => {
    // we only synchronize values on new inputs to avoid overwriting saved values
    if (hasSelectedProducts) {
      setValueIfUnsaved(CreateShipmentInputName.TOTAL_VOLUME, totalVolume);
      setValueIfUnsaved(CreateShipmentInputName.TOTAL_WEIGHT, totalWeight);
    }
  }, [hasSelectedProducts, totalVolume, totalWeight, setValueIfUnsaved]);

  useEffect(() => {
    // for total boxes and units, they will not have an input if they've selected products, so we always update the form state
    if (hasSelectedProducts) {
      setValue(CreateShipmentInputName.TOTAL_UNITS, totalUnits);
      setValue(CreateShipmentInputName.TOTAL_BOXES, totalBoxes);
    }
  }, [hasSelectedProducts, totalBoxes, setValue, totalUnits]);

  const validateStep = async () => {
    setIsValidated(true);

    const hasLithiumBatteries = getValues(CreateShipmentInputName.DANGEROUS_GOODS_LITHIUM_BATTERIES);
    const hasValidFields = await getFieldsValid([
      ...CARGO_DETAILS_STEP_FIELDS,
      ...(isLithiumBatteriesWorkflowOn ? CONDITIONAL_DANGEROUS_GOODS_FIELDS : []),
      ...(hasLithiumBatteries ? CONDITIONAL_LITHIUM_BATTERY_FIELDS : []),
    ]);
    if (!hasValidFields || hasInvalidProductsSection || isOverVolume || isOverWeight) {
      return false;
    }
    if (isDtcNetworkBooking && shouldShowProductsSection) {
      const singleSkuPackageList = mapDtcPackageCollectionToPackageSummary(singleSkuPackages);
      const packagesList = isCargoMixedSku ? mixedSkuPackages : singleSkuPackageList;
      const isPkgValid = await packageSchema.isValid(packagesList);
      if (!isPkgValid) {
        setIsIncompletedPackages(true);
        return false;
      }
      const bookingProductDskus = [...new Set(packagesList.map((pkg) => pkg.items.map((item) => item.dsku)).flat())];
      const productData = await productClient.getProducts(bookingProductDskus, {
        includeProductPreparation: true,
      });
      const productsWithNoCategoryList = bookingProductDskus
        .map((dsku) => productData?.[dsku])
        .filter((product) => !product.productPreparation?.category && !product.brandedPackaging);

      if (productsWithNoCategoryList.length > 0) {
        setProductsWithNoCategory(productsWithNoCategoryList);
        setShowProductCategoriesModal(true);
        return false;
      }
    }

    return true;
  };

  /** we save the values to the redux store */
  /** will be called on next click, you can return data if you want it passed to the next handler in createShipmentSteps */
  const submitData = composeSaveStep(async () => {
    const hasLithiumBatteries = getValues(CreateShipmentInputName.DANGEROUS_GOODS_LITHIUM_BATTERIES);

    // clearing the lithium battery packaging form if, on next, they didn't select lithium batteries
    if (!hasLithiumBatteries) {
      setValue(CreateShipmentInputName.LITHIUM_BATTERIES_IN_EQUIPMENT, false);
      setValue(CreateShipmentInputName.LITHIUM_BATTERIES_IN_VEHICLE, false);
      setValue(CreateShipmentInputName.LITHIUM_BATTERIES_PACKED_LOOSE, false);
      setValue(CreateShipmentInputName.LITHIUM_BATTERIES_PACKED_EQUIPMENT, false);
    }

    const [
      cargoInputType,
      unitSystem,
      shipmentTotals,
      hasDangerousGoods,
      dangerousGoods,
      cargoReadyDate,
      lithiumBatteryPackaging,
      singleSkuPackage,
      mixedSkuPackage,
    ] = getValues([
      "cargoInputType",
      "unitSystem",
      "shipmentTotals",
      "hasDangerousGoods",
      "dangerousGoods",
      "cargoReadyDate",
      "lithiumBatteryPackaging",
      "singleSkuPackages",
      "mixedSkuPackages",
    ]);

    batch(() => {
      dispatch(setCargoInputType(cargoInputType));
      dispatch(setUnitSystem(unitSystem));
      dispatch(setShipmentTotals(shipmentTotals));
      dispatch(setHasDangerousGoods(hasDangerousGoods));
      dispatch(setDangerousGoods(dangerousGoods));
      dispatch(setCargoReadyDate(cargoReadyDate));
      dispatch(setLithiumBatteryPackaging(lithiumBatteryPackaging));
      dispatch(setSingleSkuPackages(singleSkuPackage));
      dispatch(setMixedSkuPackages(mixedSkuPackage));
      dispatch(setIsCargoMixedSku(isCargoMixedSku));
    });

    const dtcProductDskus = isDtcNetworkBooking
      ? isCargoMixedSku
        ? mixedSkuPackage.map((pkg) => pkg.items.map((item) => item.dsku)).flat()
        : mapDtcPackageCollectionToPackageSummary(singleSkuPackage)
            .map((pkg) => pkg.items.map((item) => item.dsku))
            .flat()
      : undefined;
    await dispatch(getBookingProducts(isDtcNetworkBooking, dtcProductDskus));

    trackSmbBookingCreationEvent("next.cargo_details", {
      unitSystem,
      totalBoxes: shipmentTotals.boxes,
      totalUnits: shipmentTotals.units,
      totalSkus,
      totalPallets: shipmentTotals.pallets,
      totalWeight: shipmentTotals.weight,
      totalVolume: shipmentTotals.volume,
      hasDangerousGoods: Boolean(hasDangerousGoods).toString(),
    });

    const bookingProductDskus = [...new Set(packages.map((pkg) => pkg.items.map((item) => item.dsku)).flat())];
    const productData = await productClient.getProducts(bookingProductDskus, {
      includeProductPreparation: true,
    });
    const filterOutOtherCategoryProducts = bookingProductDskus
      .map((dsku) => productData?.[dsku])
      .filter((product) => product.productPreparation?.category !== ProductPrepCategory.OTHER);
    const shouldShowPackageRequirementStep =
      isDtcNetworkBooking && shouldShowProductsSection && !!filterOutOtherCategoryProducts.length;

    return { shouldShowPackageRequirementStep };
  });

  return {
    isDtcNetworkBooking,
    isCargoMixedSku,
    validateStep,
    submitData,
    shouldShowProductsSection,
    isValidated,
    isSaving,
    singleSkuPackages,
    mixedSkuPackages,
    isIncompletedPackages,
    isLatLngLoaded,
    productsWithNoCategory,
    showProductCategoriesModal,
    productDetails,
    setShowProductCategoriesModal,
  };
};
