import { combineReducers } from "redux";
import {
  internationalShippingInitialState,
  internationalShippingReducer,
  InternationalShippingState,
} from "settings/InternationalShipping/InternationalShippingReducer";
import {
  shippingOptionInitialState,
  ShippingOptionMappingState,
  shippingOptionReducer,
} from "settings/shipping-options/ServiceOptionReducer";
import { initialInventoryState, InventoryState, inventoryReducer } from "settings/inventory/InventoryReducer";

export const settingsReducer = combineReducers<SettingsState>({
  shippingOptionMapping: shippingOptionReducer,
  internationalShipping: internationalShippingReducer,
  inventory: inventoryReducer,
});

export interface SettingsState {
  shippingOptionMapping: ShippingOptionMappingState;
  internationalShipping: InternationalShippingState;
  inventory: InventoryState;
}

export const initialSettingsData: SettingsState = {
  shippingOptionMapping: shippingOptionInitialState,
  internationalShipping: internationalShippingInitialState,
  inventory: initialInventoryState,
};
