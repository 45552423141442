import { DtcPrepOption } from "@deliverr/inbound-client/lib/booking/v2/SubmitBookingRequest";
import { PrepType } from "@deliverr/prep-service-client";
import { UnitPrepService } from "inbounds/createShipment/CreateShipmentTypes";

export const _parseDtcPrepOption = (inboundDtcPrepOption?: DtcPrepOption) => {
  return {
    boxLabelingUnitsSelected: inboundDtcPrepOption?.prepTypes?.includes(PrepType.BOX_CONTENT_LABELS) ?? false,
    unitPrepService: inboundDtcPrepOption?.prepTypes?.includes(PrepType.SKU_LABELS)
      ? UnitPrepService.UNIT_BARCODING
      : Object.keys(UnitPrepService).includes(inboundDtcPrepOption?.prepCombo ?? "")
      ? (inboundDtcPrepOption?.prepCombo as UnitPrepService)
      : null,
  };
};
