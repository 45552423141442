import { DeliverrAddress } from "@deliverr/commons-objects";
import { Box, Select, Text, SelectComponentOption } from "common/components/ui";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { OptionProps as SelectOptionProps } from "@deliverr/react-select-v1";
import styled from "@emotion/styled";

export interface SelectedWarehouseOption {
  value: WarehouseDetail;
  label: string;
}

export interface WarehouseDetail {
  warehouseId: string;
  address: DeliverrAddress;
}

interface SelectDestinationWarehouseProps {
  isLoading?: boolean;
  options: SelectedWarehouseOption[];
  selectedOption: SelectedWarehouseOption | undefined;
  handleChange: ({ value }) => void;
}

const SelectWrapper = styled.div`
  min-width: 90%;
`;

const Option: FC<SelectOptionProps> = (props) => {
  const { warehouseId, address } = props.data.value as WarehouseDetail;
  return (
    <SelectComponentOption {...props}>
      <Box paddingTop="S1" paddingBottom="S1">
        <Text>{warehouseId}</Text>
        <Text appearance="INFO" size="caption">
          {address.street1}
        </Text>
        <Text appearance="INFO" size="caption">
          {address.city}, {address.state} {address.zip}
        </Text>
      </Box>
    </SelectComponentOption>
  );
};

export const SelectDestinationWarehouse: FC<SelectDestinationWarehouseProps> = ({
  handleChange,
  options,
  selectedOption,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  return (
    <SelectWrapper>
      <Select
        placeholder={formatMessage(transfersShipmentCreateLabels.steps.placement.direct.location)}
        maxMenuHeight={300}
        options={options}
        value={selectedOption}
        customComponents={{ Option }}
        onChange={handleChange}
        isLoading={isLoading}
      />
    </SelectWrapper>
  );
};
