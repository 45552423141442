import { FeatureName, isFeatureOn } from "common/Split";
import { selectIsSellerSuspended } from "organization/OrganizationSelectors";
import { useSelector } from "react-redux";
import { RootState } from "RootReducer";

export const useShouldHideIfSellerIsSuspended = () => {
  const isSellerSuspended = useSelector(selectIsSellerSuspended);
  const isSellerAccountSuspensionOn = isFeatureOn(FeatureName.SellerAccountSuspension);
  const currentUser = useSelector((state: RootState) => state.user);
  const shouldHideIfSellerIsSuspended = isSellerSuspended && isSellerAccountSuspensionOn && !currentUser.isAdmin;
  return {
    shouldHideIfSellerIsSuspended,
  };
};
