// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "th {\n  white-space: nowrap;\n}\nth.ColumnHeader__sortable--yt9gq {\n  cursor: pointer;\n}\nth.ColumnHeader__sortable--yt9gq::after {\n  position: relative;\n  top: 2px;\n  display: inline-block;\n  width: 10px;\n  margin-left: 6px;\n}\n.ColumnHeader__alignCenter--_Vv3v {\n  text-align: center;\n}\n.ColumnHeader__alignRight--XBJcN {\n  text-align: right;\n}\nth.ColumnHeader__sortable--yt9gq.ColumnHeader__alignRight--XBJcN::after {\n  float: left;\n}\nth.ColumnHeader__sortable--yt9gq.ColumnHeader__sorted--BJuxz::after {\n  float: none;\n  content: \" ▾\";\n  -webkit-transform: rotate(180deg);\n          transform: rotate(180deg);\n}\nth.ColumnHeader__sortable--yt9gq.ColumnHeader__sorted--BJuxz.ColumnHeader__sortedDesc--SmF6G::after {\n  -webkit-transform: rotate(0deg);\n          transform: rotate(0deg);\n  top: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/common/components/lists/Table/ColumnHeader.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,eAAA;AAAF;AAIA;EACE,kBAAA;EACA,QAAA;EACA,qBAAA;EACA,WAAA;EACA,gBAAA;AAFF;AAKA;EACE,kBAAA;AAHF;AAMA;EACE,iBAAA;AAJF;AAQA;EACE,WAAA;AANF;AASA;EACE,WAAA;EACA,aAAA;EACA,iCAAA;UAAA,yBAAA;AAPF;AAUA;EACE,+BAAA;UAAA,uBAAA;EACA,MAAA;AARF","sourcesContent":["th {\n  white-space: nowrap;\n}\n\nth.sortable {\n  cursor: pointer;\n}\n\n// Reserve space for arrow\nth.sortable::after {\n  position: relative;\n  top: 2px;\n  display: inline-block;\n  width: 10px;\n  margin-left: 6px;\n}\n\n.alignCenter {\n  text-align: center;\n}\n\n.alignRight {\n  text-align: right;\n}\n\n// For right-aligned columns we reserve space at the left\nth.sortable.alignRight::after {\n  float: left;\n}\n\nth.sortable.sorted::after {\n  float: none;\n  content: \" ▾\";\n  transform: rotate(180deg);\n}\n\nth.sortable.sorted.sortedDesc::after {\n  transform: rotate(0deg);\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortable": "ColumnHeader__sortable--yt9gq",
	"alignCenter": "ColumnHeader__alignCenter--_Vv3v",
	"alignRight": "ColumnHeader__alignRight--XBJcN",
	"sorted": "ColumnHeader__sorted--BJuxz",
	"sortedDesc": "ColumnHeader__sortedDesc--SmF6G"
};
export default ___CSS_LOADER_EXPORT___;
