import styled from "@emotion/styled";
import React from "react";
import { Appearance, ThemeProps, DefaultTheme } from "../shared";
import { Icon, IconsProps, IconTypes } from "../Icon";
import { useTheme } from "emotion-theming";
import { transitionFast } from "../shared/helpers/css";
import { customPropsFilter } from "../utils";
import Tooltip from "rc-tooltip";

export type ButtonAppearance = Extract<keyof typeof Appearance, "DEFAULT" | "DANGER">;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Type of button */
  appearance?: ButtonAppearance;
  /** Non-primary action button */
  secondary?: boolean;
  /** Width to full length of container */
  block?: boolean;
  /** Adds an icon to the button */
  iconType?: IconTypes;
  /** IconProps */
  iconProps?: Exclude<IconsProps, "type">;
  /** Depreciated */
  asLink?: boolean;
  /** Show loading indicator */
  loading?: boolean;
  /** Tooltip message */
  tooltipMessage?: string;
  /** Tooltip placement */
  placement?: "top" | "bottom" | "left" | "right";
  /** Tooltip trigger */
  trigger?: "hover" | "click" | "focus";
}

const StyledIcon = styled(Icon)<IconsProps, DefaultTheme>`
  margin-right: ${({ theme }) => theme.spacing.S2};
  width: 14px;
  pointer-events: none;
`;

const getAppearanceConstants = (theme: DefaultTheme, appearance: ButtonAppearance = Appearance.DEFAULT) => {
  const appearanceColor = theme?.config.colorByAppearance[appearance];
  const color = theme.colors[appearanceColor];
  const disabledColor = appearance === "DANGER" ? color[200] : color[100];
  const pressedColor = color[400];
  const shadow = `0px 0px 2px ${SHADOW_COLOR[appearance]}`;
  return { color, disabledColor, pressedColor, shadow };
};

type LoaderProps = Pick<ButtonProps, "loading" | "appearance" | "secondary">;

const StyledLoader = styled(
  "div",
  customPropsFilter<LoaderProps>(["appearance", "loading", "secondary"])
)<LoaderProps, DefaultTheme>(({ theme, appearance, secondary, loading }) => {
  const { color } = getAppearanceConstants(theme, appearance);
  return `
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: ${loading ? "auto" : "none"};
    opacity: ${loading ? 1 : 0};
    background-color: ${secondary ? theme.colors.NEUTRAL["00"] : color[100]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.border.radius.R2};
    ${transitionFast()}
  `;
});

const SHADOW_COLOR: Record<ButtonAppearance, string> = {
  DEFAULT: "rgba(45, 140, 237, 0.72)",
  DANGER: "rgba(247, 67, 67, 0.72)",
};

const setAppearance = ({
  appearance = Appearance.DEFAULT,
  secondary,
  asLink,
  theme,
  loading,
}: ThemeProps<ButtonProps>) => {
  const { color, disabledColor, pressedColor, shadow } = getAppearanceConstants(theme, appearance);
  const secondaryStyles = `
    background-color: ${theme.colors.NEUTRAL["00"]};
    color: ${color[300]};

    :enabled {
      :active {
        color: ${pressedColor};
      }
    }

    :disabled, &[disabled] {
      color: ${disabledColor};
    }
  `;

  const defaultStyles = `
    background-color: ${color[300]};
    color: ${theme.colors.NEUTRAL["00"]};

    :enabled {
      :active {
        background-color: ${pressedColor};
      }
    }

    :disabled, &[disabled] {
      background-color: ${disabledColor};
    }
  `;

  const linkStyles = `
    background-color: transparent;
    color: ${color[300]};

    :active {
      background-color: transparent;
      border-color: transparent;
      outline: 0;
      color: ${color[400]};
    }

    :hover {
      :active {
        background-color: transparent;
        border-color: transparent;
      }
    }
  `;
  return `
    border-color: ${color[300]};

    :enabled {
      :active, :hover {
        outline: 0;
        box-shadow: ${shadow};
        border-color: ${pressedColor};
      }
    }

    :disabled,
    &[disabled] {
      cursor: not-allowed;
      border-color: ${loading && !secondary ? color[100] : disabledColor};
    }

    ${asLink ? linkStyles : secondary ? secondaryStyles : defaultStyles}
  `;
};

export const StyledButton = styled(
  "button",
  customPropsFilter<ButtonProps>(["appearance", "block", "loading", "secondary"])
)<ButtonProps, DefaultTheme>(
  ({ appearance, secondary, block = false, theme, loading }) => `
    cursor: pointer;
    line-height: inherit;
    border-width: ${theme.border.width.B1};
    border-radius: ${theme.border.radius.R3};
    border-style: ${theme.border.type};
    font-family: ${theme.font.family.STANDARD};
    font-weight: ${theme.font.weight.BOLD};
    font-size: ${theme.font.size.F2};
    height: ${theme.input.height};
    padding-left: ${theme.spacing.S4};
    padding-right: ${theme.spacing.S4};
    width: ${block ? "100%" : "auto"};
    position: relative;
    ${transitionFast()}
    ${setAppearance({ appearance, secondary, theme, loading })}
  `
);

export const Button: React.FC<ButtonProps> = ({
  children,
  type = "button",
  iconType,
  iconProps,
  loading,
  disabled,
  tooltipMessage,
  placement = "top",
  trigger = "hover",
  ...props
}) => {
  const theme = useTheme<DefaultTheme>();

  // Showing/hiding the loader creates an abrupt transition. Instead
  // we always leave it in the DOM and change it's opacity and pointer-events.
  const loader = (
    <StyledLoader loading={loading} secondary={props.secondary} appearance={props.appearance ?? Appearance.DEFAULT}>
      <Icon size="lg" type="circle-notch" color={theme.colors.BLUE[300]} spin />
    </StyledLoader>
  );

  const button = (
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    <StyledButton loading={loading} type={type} disabled={disabled || loading} {...props}>
      {loader}
      {iconType && <StyledIcon type={iconType} {...iconProps} />}
      {children}
    </StyledButton>
  );

  return tooltipMessage ? (
    <Tooltip overlay={tooltipMessage} placement={placement} trigger={[trigger]}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};
