import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import {
  ModalForm,
  ModalFormButtonsContainer,
  ModalHeading,
  StyledModalFormLabel,
  StyledModalSpan,
} from "./ModalStyles";
import { Button, Input, Select } from "common/components/ui";
import {
  CarrierAccount,
  CreateCarrierAccountRequest,
  UpdateCarrierAccountRequest,
} from "@deliverr/replenishment-client";
import { SPThunkAction } from "common/ReduxUtils";
import { useReplenishmentCarrierAccountForm } from "../../hooks/useReplenishmentCarrierAccountForm";

export interface ReplenishmentCarrierAccountFormProps {
  onCreateCarrierAccount: (createCarrierAccountRequest: CreateCarrierAccountRequest) => SPThunkAction<Promise<void>>;
  onUpdateCarrierAccount: (
    carrierAccountId: number,
    updateCarrierAccountRequest: UpdateCarrierAccountRequest
  ) => SPThunkAction<Promise<void>>;
  onClose: () => void;
  isUpdateMode: boolean;
  carrierAccount?: CarrierAccount;
}

export const ReplenishmentCarrierAccountForm: FC<ReplenishmentCarrierAccountFormProps> = ({
  onCreateCarrierAccount,
  onUpdateCarrierAccount,
  onClose,
  isUpdateMode,
  carrierAccount,
}) => {
  const {
    formData: { displayName, postalCode, carrier, accountNumber, paymentType },
    isSaveDisabled,
    isLoading,
    errors,
    carrierOptions,
    paymentTypeOptions,
    handleInputChange,
    handleCarrierChange,
    handlePaymentTypeChange,
    handleSave,
  } = useReplenishmentCarrierAccountForm(
    onCreateCarrierAccount,
    onUpdateCarrierAccount,
    onClose,
    isUpdateMode,
    carrierAccount
  );

  return (
    <>
      <ModalHeading>
        {isUpdateMode ? (
          <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.editCarrierAccount} />
        ) : (
          <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.addCarrierAccount} />
        )}
      </ModalHeading>

      <ModalForm>
        <Input
          type="text"
          name="displayName"
          value={displayName}
          onChange={handleInputChange}
          hasError={!!errors.displayName}
          label={<FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.displayName} />}
          aria-label="Display Name"
          errorMessage={errors.displayName}
        />

        <StyledModalFormLabel>
          <StyledModalSpan>
            <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.carrier} />
          </StyledModalSpan>
          <Select
            onChange={handleCarrierChange}
            value={carrier}
            options={carrierOptions}
            aria-label="Carrier"
            disabled={isUpdateMode}
          />
        </StyledModalFormLabel>

        <Input
          type="text"
          name="accountNumber"
          value={accountNumber}
          onChange={handleInputChange}
          hasError={!!errors.accountNumber}
          label={<FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.accountNumber} />}
          aria-label="Account Number"
          errorMessage={errors.accountNumber}
          disabled={isUpdateMode}
        />

        <Input
          type="text"
          name="postalCode"
          value={postalCode}
          onChange={handleInputChange}
          hasError={!!errors.postalCode}
          label={<FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.postalCode} />}
          aria-label="Postal Code"
          errorMessage={errors.postalCode}
        />

        <StyledModalFormLabel>
          <StyledModalSpan>
            <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.paymentType} />
          </StyledModalSpan>
          <Select
            onChange={handlePaymentTypeChange}
            value={paymentType}
            options={paymentTypeOptions}
            aria-label="Payment Type"
          />
        </StyledModalFormLabel>
      </ModalForm>

      <ModalFormButtonsContainer>
        <Button onClick={onClose} block secondary aria-label="Cancel">
          <FormattedMessage defaultMessage="Cancel" id="wholesale.create.steps.address.cancelAddCarrierAccount" />
        </Button>

        <Button
          type="submit"
          block
          disabled={isSaveDisabled}
          loading={isLoading}
          onClick={handleSave}
          aria-label="Save"
        >
          <FormattedMessage defaultMessage="Save" id="wholesale.create.steps.address.saveCarrierAccount" />
        </Button>
      </ModalFormButtonsContainer>
    </>
  );
};
