import { datadogRum } from "@datadog/browser-rum";
import { InboundBookingType } from "@deliverr/inbound-client";
import { trackHeapEvent } from "common/utils/heap/trackHeapEvent";
import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";
import { CheckoutStepId } from "inbounds/createShipment/steps/priceViewStep/selectShipping/selectShippingFlow/types";
import { ClickNextProperties, EVENT_PREFIX, SmbBookingCreationEvent } from "./analytics.types";

/**
 * Conditionally add properties to action/event
 * For the CreateShipment flow, the useSmbBookingContext already adds default properties to the global datadog context
 * In most cases, you will not need custom properties (refer to the SmbBookingCreationContext interface)
 */
type CustomEventProperties<EventType extends SmbBookingCreationEvent> = EventType extends "submit_booking"
  ? { bookingType?: InboundBookingType; bookingId: string; shippingPlanId: number }
  : EventType extends "booking_quote"
  ? { bookingType?: InboundBookingType; subtotalCost: number }
  : EventType extends "no_freight_services"
  ? { origin: string; destination: string; shipmentTotals: string; unitSystem: UnitSystem; dangerousGoods: string }
  : EventType extends "view_freight_service_list"
  ? { numServices: number }
  : EventType extends "change_freight_service"
  ? { prevService: string; selectedService: string; selectedServiceLabel?: string }
  : EventType extends "collapse_checkout_section"
  ? { id: CheckoutStepId }
  : {};

type IPBEventProperties<EventType extends SmbBookingCreationEvent> = {
  clientFid?: string;
} & CustomEventProperties<EventType> &
  ClickNextProperties<EventType>;

export const trackSmbBookingCreationEvent = <EventType extends SmbBookingCreationEvent>(
  event: EventType,
  properties?: IPBEventProperties<EventType>
) => {
  datadogRum.addAction(`${EVENT_PREFIX}.${event}`, { event: properties });
  trackHeapEvent(`${EVENT_PREFIX}.${event}`, properties);
};
