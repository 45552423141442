import { InboundShipment } from "@deliverr/inbound-client";
import { getIsFreightDeliverrShippingOption } from "./shippingOption";

interface ParseFreightDeliverrShipmentIdsReturnType {
  freightDeliverrShipmentIds: number[];
  nonFreightDeliverrShipmentIds: number[];
}

/**
 * Compiles array of shipment ids categorized into Freight and non-Freight ids
 * Used for initializing necessary state for LTL inbounds
 */
export const parseFreightDeliverrShipmentIds = (shipments: InboundShipment[]) =>
  shipments.reduce<ParseFreightDeliverrShipmentIdsReturnType>(
    (shipmentIds, shipment) => {
      if (shipment.shippingOption && getIsFreightDeliverrShippingOption(shipment.shippingOption)) {
        shipmentIds.freightDeliverrShipmentIds.push(shipment.id);
      } else {
        shipmentIds.nonFreightDeliverrShipmentIds.push(shipment.id);
      }
      return shipmentIds;
    },
    { freightDeliverrShipmentIds: [], nonFreightDeliverrShipmentIds: [] }
  );
