import React, { FC } from "react";
import { Select, SelectProps, SelectComponents, DefaultTheme, Text, Stack, useAboveLarge } from "common/components/ui";
import styled from "@emotion/styled";
import { css, cx } from "emotion";
import { isNil } from "lodash";
import { MessageDescriptor, useIntl } from "react-intl";
import Pill from "common/components/Pill";

const TextContainer = styled.div<{ hasError: boolean; hasHighlight: boolean }>(
  ({ hasError, hasHighlight }) =>
    `
    ${
      hasError
        ? `
        display: flex;
        justify-content: space-between;
      `
        : ""
    }
    ${hasHighlight ? "width: 12rem;" : ""}
    `
);

export interface DetailedSelectOption {
  value: string;
  label: string;
  subtitle: string;
  isLink?: boolean;
  onLinkClick?: () => void;
  error?: MessageDescriptor;
}

interface DetailedSelectProps extends SelectProps {
  options: DetailedSelectOption[];
}

const SelectOption = styled(Stack)<{ hasError: boolean; width: number }, DefaultTheme>(
  ({ hasError, width, theme }) => `
    color: ${theme.colors.NEUTRAL["500"]};
    padding: ${theme.spacing.S2} ${theme.spacing.S1};
    width: ${width}rem;
    word-wrap: break-word;
    ${hasError ? `color: ${theme.colors.NEUTRAL[200]}` : ""}
  `
);

const StyledPill = styled(Pill)<{}, DefaultTheme>(
  ({ theme }) => `
    margin-right: ${theme.spacing.S2}
  `
);

const TitleContainer = styled(Stack)<{}, DefaultTheme>(
  ({ theme }) => `
    margin-right: 0;
  `
);

const notAllowedCursor = css("cursor: not-allowed !important;"); // Override default react-select styling

const CustomOption = (props) => {
  const { label, subtitle, isLink, onLinkClick, error, highlightText, highlightPillColor } = props.data;
  const isAboveLarge = useAboveLarge();
  const { formatMessage } = useIntl();
  const width = props.selectProps.width || 20;

  return (
    <SelectComponents.Option {...props} className={cx({ [notAllowedCursor]: !!error })}>
      <SelectOption gap="S1" hasError={!!error} width={width}>
        <TitleContainer direction="HORIZONTAL" justifyContent="space-between" alignItems="flex-start">
          {isLink ? (
            <a onClick={onLinkClick}>
              <Text bold>{label}</Text>
            </a>
          ) : (
            <TextContainer hasError={!!error} hasHighlight={!!highlightText}>
              <Text>{label}</Text>
              {!isNil(error) && <Text>{formatMessage(error)}</Text>}
            </TextContainer>
          )}
          {highlightText && (
            <StyledPill color={highlightPillColor}>
              <Text size="navigation">{highlightText}</Text>
            </StyledPill>
          )}
        </TitleContainer>
        <Text appearance="INFO" size={isAboveLarge ? "caption" : "label"}>
          {subtitle}
        </Text>
      </SelectOption>
    </SelectComponents.Option>
  );
};

export const DetailedSelect: FC<DetailedSelectProps> = (props) => (
  <Select customComponents={{ Option: CustomOption }} {...props} />
);
