import { InboundCostEstimatorParams } from "@deliverr/commons-clients/lib/transportation/legacy-copy-pasta";
import { ShippingPlan, InboundShipment } from "@deliverr/inbound-client";
import { transportationClient } from "common/clients/instances";
import { addAllToById } from "common/ById";
import { SPThunkAction } from "common/ReduxUtils";
import {
  fetchCrossdockInboundShipment,
  fetchCrossdockInboundQuote,
  generateCrossdockInboundQuote,
} from "inbounds/crossdock/store/actions";
import { isConfirmedShipmentStatus } from "inbounds/ShipmentStatus";
import { isShipToOnePlan } from "inbounds/steps/ship/InboundUtils";
import log, { logStart } from "Logger";
import { RootState } from "RootReducer";
import { getCrossdockQuoteRequest } from "../selectors/quote";
import { CrossdockInboundQuote } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuote";
import { CrossdockInboundShipment } from "common/clients/crossdock/CrossdockInboundShipment/CrossdockInboundShipment";

export interface CrossdockInboundObjects {
  crossdockInboundShipment?: CrossdockInboundShipment;
  crossdockInboundQuote?: CrossdockInboundQuote;
}

export const loadCrossdockInboundObjects =
  (
    sellerId: string,
    plan: ShippingPlan,
    loadedShipment?: InboundShipment
  ): SPThunkAction<Promise<CrossdockInboundObjects>> =>
  async (dispatch, getState): Promise<CrossdockInboundObjects> => {
    const ctx = { fn: "loadCrossdockInboundObjects", sellerId, plan, loadedShipment };
    logStart(ctx);

    // no crossdock objects will be available if the plan does not have a fromAddress yet.
    if (!plan.fromAddress) {
      return {};
    }

    if (!isShipToOnePlan(plan)) {
      log.warn(ctx, "Attempting to get crossdock objects for non-crossdock Shipping Plan");
      return {};
    }

    let crossdockInboundShipment: CrossdockInboundShipment | undefined;
    let crossdockInboundQuote: CrossdockInboundQuote | undefined;

    // don't need to bother querying for crossdockShipment if there's no shipment or it hasn't been confirmed
    if (loadedShipment && isConfirmedShipmentStatus(loadedShipment.status)) {
      crossdockInboundShipment = await dispatch(fetchCrossdockInboundShipment(sellerId, plan.id));
      crossdockInboundQuote = crossdockInboundShipment?.quote;
    }

    // if we don't have a crossdockShipment that we can grab the quote from, we need to query for it still
    // but only if the fromAddress has been set
    if (!crossdockInboundQuote) {
      crossdockInboundQuote = await dispatch(fetchCrossdockInboundQuote(sellerId, plan.id));
    }

    // We should ensure that a Forwarding plan should have a quote no matter what if there is a loadedShipment.
    // We will let the server determine whether or not it should be fully discounted.
    if (!crossdockInboundQuote && loadedShipment) {
      const state: RootState = { ...getState() };

      // state is not fully constructed by this point, so have to plug in values
      const planItems = addAllToById(
        plan.items.map(({ id }) => id),
        plan.items
      );
      state.user = { ...state.user, sellerId };
      state.inbound = { ...state.inbound, plan, planItems, fromAddress: plan.fromAddress };

      // get dims and weights fallbacks
      let dimsFallbacks: InboundCostEstimatorParams | undefined;
      try {
        dimsFallbacks = await transportationClient.getCostEstimatorParams();
      } catch (err) {
        log.error({ ...ctx, err }, "Failed to get CrossdockInboundQuote dims and weights fallbacks");
      }

      const quoteRequest = getCrossdockQuoteRequest(state)(dimsFallbacks);
      log.info({ ...ctx, quoteRequest }, "creating new quote for Forwarding plan that was missing one.");

      crossdockInboundQuote = await dispatch(generateCrossdockInboundQuote(quoteRequest));
    }

    return {
      crossdockInboundQuote,
      crossdockInboundShipment,
    };
  };
