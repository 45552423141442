import { QcReturnItemMinimal, ReturnOrderMinimal, ReturnStatus } from "@deliverr/returns-client";
import { ReturnsDetailTypes, ReturnsQcItem } from "./ReturnsDetailStore.types";
import { SPThunkAction, createActionCreator } from "common/ReduxUtils";
import {
  aggregateItemsByDskuAndInspectionStatus,
  aggregateUnknownItemsByBarcodeAndStatus,
  getQcStatus,
  isQcEnabledAndProcessed,
  isQcEnabledAndStarted,
} from "returns/utils/qcDetails";
import { forEach, isEmpty, keyBy, partition, reduce, values } from "lodash";
import { productClient, returnsClient } from "Clients";

import { Path } from "paths";
import { SkuPair } from "@deliverr/commons-objects";
import { generatePath } from "react-router";
import history from "BrowserHistory";
import log from "Logger";

const UNKNOWN_DSKU = "DSKUUNKNOWN";

export const getReturn =
  (returnId: string, orderId): SPThunkAction =>
  async (dispatch) => {
    try {
      dispatch({ type: ReturnsDetailTypes.RETURNS_GET_DETAILS });
      const response = await returnsClient.getReturnById(returnId);
      const returnOrder = response?.value as ReturnOrderMinimal;
      const { qcId, items, status } = returnOrder;
      let { inspectedItems = [] } = returnOrder;
      let returnsQcItems: ReturnsQcItem[] = [];
      let nullishDskuQcItems: QcReturnItemMinimal[] = [];

      [inspectedItems, nullishDskuQcItems] = partition(
        inspectedItems,
        (item) => !isEmpty(item.dsku) && item?.dsku !== UNKNOWN_DSKU
      );

      let unknownReturnsQcItems: ReturnsQcItem[] = aggregateUnknownItemsByBarcodeAndStatus(nullishDskuQcItems) ?? [];
      unknownReturnsQcItems = unknownReturnsQcItems.filter((item) => item?.quantity && item.quantity > 0);

      inspectedItems = values(
        reduce(
          inspectedItems,
          (acc, inspectedItem) => {
            let key: string = `${inspectedItem.dsku}-${inspectedItem.status}`;
            if (!isEmpty(inspectedItem.selectedNotes) || !isEmpty(inspectedItem.images)) {
              key = `${key}-${inspectedItem.id}`;
            }
            if (acc[key]) {
              (acc[key].quantity as number) += inspectedItem.quantity as number;
            } else {
              acc[key] = inspectedItem;
            }
            return acc;
          },
          {} as Record<string, QcReturnItemMinimal>
        )
      );
      const returnsItems: any = keyBy(items, (item) => item.dsku);

      if (isQcEnabledAndStarted(qcId, status)) {
        forEach(inspectedItems, (inspectedItem) => {
          returnsItems[inspectedItem.dsku] = {
            ...(returnsItems[inspectedItem.dsku] || { ...inspectedItem, isUnexpected: true }),
          };
          const returnItem = returnsItems[inspectedItem.dsku];
          returnsQcItems.push({
            status: inspectedItem.status,
            dsku: inspectedItem.dsku,
            isUnexpected: returnItem.isUnexpected,
            quantity: Math.min(inspectedItem.quantity, returnItem.quantity),
            selectedNotes: inspectedItem.selectedNotes,
            images: inspectedItem.images,
            destroyedAt: inspectedItem.destroyedAt,
            returnReason: returnItem.returnReason,
            details: returnItem.details,
          });
          if (returnItem.quantity - inspectedItem.quantity < 0) {
            returnsQcItems.push({
              status: inspectedItem.status,
              dsku: inspectedItem.dsku,
              quantity: inspectedItem.quantity - returnItem.quantity,
              isUnexpected: true,
              selectedNotes: inspectedItem.selectedNotes,
              images: inspectedItem.images,
              destroyedAt: inspectedItem.destroyedAt,
              returnReason: returnItem.returnReason,
              details: returnItem.details,
            });
            returnItem.quantity = 0;
          } else {
            returnItem.quantity = returnItem.quantity - inspectedItem.quantity;
          }
        });

        forEach(returnsItems, (value, key) => {
          if (value.quantity > 0) {
            returnsQcItems.push({
              status: [ReturnStatus.ARRIVED].includes(status) ? "AWAITING" : "NOT_RECEIVED",
              dsku: key,
              quantity: value.quantity,
              returnReason: value.returnReason,
              details: value.details,
            });
          }
        });
      }

      returnsQcItems = aggregateItemsByDskuAndInspectionStatus(returnsQcItems);
      returnsQcItems = returnsQcItems.filter((item) => item?.quantity && item.quantity > 0);
      returnsQcItems.sort((a, b) => {
        return a.dsku.localeCompare(b.dsku);
      });
      returnsQcItems = returnsQcItems.concat(unknownReturnsQcItems);

      let qcStatus;
      const isQcProcessed = isQcEnabledAndProcessed(qcId, status);
      if (isQcProcessed) {
        qcStatus = getQcStatus(status, inspectedItems ?? []);
      }

      dispatch({
        type: ReturnsDetailTypes.RETURNS_GET_DETAILS_SUCCESS,
        returnsDetail: { ...response?.value, status },
        returnsQcDetails: { returnsQcItems, isQcProcessed, qcStatus },
      });
      dispatch(getReturnItemDetails(values(returnsItems) ?? []));
    } catch (err) {
      log.error({ err, returnId, orderId }, "error getting return details for returnId, orderId");
      dispatch({ type: ReturnsDetailTypes.RETURNS_GET_DETAILS_ERROR, err });
      history.push(generatePath(Path.orderDetail, { orderId }));
    }
  };

export const getReturnItemDetails =
  (returnItems: SkuPair[]): SPThunkAction =>
  async (dispatch) => {
    try {
      const dskus = returnItems.map((orderItem) => orderItem.dsku);
      const returnItemDetails = await productClient.getUnifiedProducts(dskus);
      dispatch({
        type: ReturnsDetailTypes.RETURN_GET_ITEM_DETAILS_SUCCESS,
        returnItemDetails,
      });
    } catch (err) {
      log.error({ err }, "error getting return item details");
      dispatch({ type: ReturnsDetailTypes.RETURN_GET_ITEM_DETAILS_ERROR, err });
    }
  };

export const resetReturn = createActionCreator(ReturnsDetailTypes.RESET_RETURN_DETAILS);
