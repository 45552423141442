import { QcPackageDetails } from "@deliverr/returns-client";

export enum ReturnsPackageActionType {
  FETCH_RETURN_PACKAGE_SUCCESS = "FETCH_RETURNS_PACKAGE_SUCCESS",
  FETCH_RETURN_PACKAGE_FAIL = "FETCH_RETURNS_PACKAGE_FAIL",
  UPDATE_RETURN_PACKAGE = "UPDATE_RETURN_PACKAGE",
}

export interface PackagesListState {
  currentPageItems: QcPackageDetails[];
  pagingInfo: {
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
  };
  error?: string;
}
