import React, { FC } from "react";
import { TableControls } from "common/components/lists/Table/TableControls";
import { NonComplianceListFilters } from "./NonComplianceListFilters/NonComplianceListFilter";

import { useNonComplianceListSearch } from "./useNonComplianceListSearch";

export const NonComplianceListTableControls: FC = () => {
  const { handleOnChange, currentSearchTerm = "" } = useNonComplianceListSearch();

  return (
    <TableControls
      searchTerm={currentSearchTerm}
      onSearchChange={handleOnChange}
      searchPlaceholder={"Search problems by Issue ID, CDSKU, DSKU..."}
      selectionCount={0}
      onClear={() => {}}
      searchBarId="NonComplianceSpvList.searchBar"
      singleSelectionActions={<></>}
      filters={<NonComplianceListFilters />}
      actions={<></>}
    />
  );
};
