import { ListActionTypes } from "common/list/ListActions";
import { appendListType, ListType } from "common/list/ListType";
import { getSelectedListItemValues } from "common/list/ListUtils";
import { SortDir } from "common/models";
import { handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { compact, mapKeys } from "lodash";

export interface ListState<ListItem = any> {
  searchTerm: string;
  listItems: ListItem[];
  page: number;
  listItemCount: number;
  itemsPerPage: number;
  totalPages: number;
  sort?:
    | {
        fieldName: keyof ListItem;
        direction: SortDir;
      }
    | undefined;
  pageChecked: boolean;
  selectedListItems: {
    [page: number]: {
      [itemIndex: number]: any;
    };
  };
  expandedRows: { [index: number]: boolean };
  selectedListItemCount: number;
}

export const listInitialState: ListState = {
  searchTerm: "",
  listItems: [],
  page: 0,
  listItemCount: 0,
  itemsPerPage: 25,
  totalPages: 0,
  pageChecked: false,
  selectedListItems: {},
  selectedListItemCount: 0,
  expandedRows: {},
};

const countSelectedListItems = (selectedListItems: ListState["selectedListItems"]) =>
  getSelectedListItemValues(selectedListItems).length;

const reducers: ReducerDictionary<ListState> = {
  ...handleSimpleReducerUpdates([
    ListActionTypes.SEARCH_INDEX,
    ListActionTypes.SET_SORT,
    ListActionTypes.SET_PAGE,
    ListActionTypes.SET_SEARCH_TERM,
  ]),

  [ListActionTypes.SET_PAGE_CHECKED]: (state, { checked }) => {
    const newSelectedHits = {
      ...state.selectedListItems,
      [state.page]: checked ? state.listItems.slice() : [],
    };

    return {
      ...state,
      selectedListItems: newSelectedHits,
      selectedListItemCount: countSelectedListItems(newSelectedHits),
      pageChecked: checked,
    };
  },

  [ListActionTypes.SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST]: (state, { checked }) => {
    const newSelectedHits = {
      ...state.selectedListItems,
      [state.page]: checked
        ? state.listItems.map((item) =>
            item.onHandQty > 0 && item.onHandQty - item.unfilledOrderQty - item.unavailableQty > 0 ? item : undefined
          )
        : [],
    };

    return {
      ...state,
      selectedListItems: newSelectedHits,
      selectedListItemCount: countSelectedListItems(newSelectedHits),
      pageChecked: checked,
    };
  },

  [ListActionTypes.SET_HIT_CHECKED]: (state, { checked, hitIndex }) => {
    const { page, listItems, selectedListItems } = state;

    const newSelectedHits = {
      ...selectedListItems,
      [page]: {
        ...selectedListItems[page],
        [hitIndex]: checked ? listItems[hitIndex] : undefined,
      },
    };

    return {
      ...state,
      selectedListItems: newSelectedHits,
      selectedListItemCount: countSelectedListItems(newSelectedHits),
      pageChecked: compact(Object.values(newSelectedHits[page])).length === listItems.length,
    };
  },

  [ListActionTypes.CLEAR_ALL_CHECKED]: (state) => ({
    ...state,
    selectedListItems: {},
    selectedListItemCount: 0,
    pageChecked: false,
  }),

  [ListActionTypes.SET_ROW_EXPAND]: (state, { rowIndex, expanded }) => {
    return {
      ...state,
      expandedRows: {
        ...state.expandedRows,
        [rowIndex]: expanded,
      },
    };
  },

  [ListActionTypes.CLEAR_ALL_EXPANDED]: (state) => ({
    ...state,
    expandedRows: {},
  }),
};

export const createListReducers = (listType: ListType) =>
  mapKeys(reducers, (_, actionType) => appendListType(actionType, listType));
