import React, { FC } from "react";
import { Pill, PillColors } from "common/components/Pill";
import { NonComplianceShipmentStatus } from "@deliverr/commons-clients";
import { NC_SHIPMENT_STATUS_DISPLAY_TEXT } from "../../NCShipmentStatus";

const shipmentStatusToPillColor = {
  [NonComplianceShipmentStatus.ACTION_NEEDED]: PillColors.RED,
};

interface NCShipmentStatusPillProps {
  className?: string;
  status: NonComplianceShipmentStatus;
}

export const NCShipmentStatusPill: FC<NCShipmentStatusPillProps> = ({ className, status }) => (
  <Pill className={className} color={shipmentStatusToPillColor[status]}>
    {NC_SHIPMENT_STATUS_DISPLAY_TEXT[status]}
  </Pill>
);
