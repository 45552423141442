import styled from "@emotion/styled";
import { Text, DefaultTheme } from "common/components/ui";
import React, { FC } from "react";
import { CarrierAccount, CarrierPaymentType } from "@deliverr/replenishment-client";
import HtmlToReact from "common/components/HtmlToReact";
import { getCarrierLabel, getPaymentTypeLabel } from "../../hooks/useReplenishmentCarrierAccountForm";

interface CarrierAccountDetailsProps {
  carrierAccount: CarrierAccount;
}

export const TruncatedText = styled(Text)<{ marginBottom?: string }, DefaultTheme>(
  ({ theme, marginBottom = theme.spacing.S1 }) => `
    margin-bottom: ${marginBottom};
    width: 100%;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

const formatAccountDetails = (carrierAccount: CarrierAccount): string => {
  const carrier = getCarrierLabel(carrierAccount.carrier);
  const accountNumber = carrierAccount.accountNumber;
  const paymentType = getPaymentTypeLabel(carrierAccount.paymentType ?? CarrierPaymentType.THIRD_PARTY);

  return `${carrier}, ${accountNumber}, ${paymentType}`;
};

export const CarrierAccountSelectDetails: FC<CarrierAccountDetailsProps> = ({ carrierAccount }) => (
  <div>
    <TruncatedText data-testid="truncated-text">
      <HtmlToReact as="span" html={carrierAccount.displayName} />
    </TruncatedText>
    <TruncatedText data-testid="truncated-text" appearance="INFO" marginBottom="0">
      {formatAccountDetails(carrierAccount)}
    </TruncatedText>
  </div>
);
