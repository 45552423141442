import { DistributionChannel, OrderResponse, ShipmentResponse } from "@deliverr/replenishment-client";
import { inboundClient } from "Clients";
import { useDispatch, useSelector } from "react-redux";
import { transferCreateSetReplenishmentOrder } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SelectedWarehouseOption, WarehouseDetail } from "../SelectDestinationWarehouse";
import { logError } from "Logger";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { useLoader } from "common/components/WithLoader/useLoader";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { getSellerId } from "common/user/UserSelectors";
import { FeatureName, useFeatureOn } from "common/Split";

const createWarehouseOption = (warehouseDetail: WarehouseDetail): SelectedWarehouseOption => ({
  value: warehouseDetail,
  label: warehouseDetail.warehouseId,
});

const getSelectedWarehouseDetail = (replenishmentOrder?: OrderResponse): WarehouseDetail | undefined => {
  const shipment = replenishmentOrder?.shipments?.[0];
  return shipment?.destinationWarehouseId && shipment?.destinationWarehouseAddress
    ? { warehouseId: shipment.destinationWarehouseId, address: shipment.destinationWarehouseAddress }
    : undefined;
};

const mapToSelectedOption = (warehouseDetail?: WarehouseDetail): SelectedWarehouseOption | undefined => {
  return warehouseDetail ? createWarehouseOption(warehouseDetail) : undefined;
};

export const useTransferSelectPlacementPlanStep = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { replenishmentOrder, selectedProducts } = useSelector(selectTransferCreate);
  const sellerId = useSelector(getSellerId);

  const selectedWarehouseDetail = useMemo(() => getSelectedWarehouseDetail(replenishmentOrder), [replenishmentOrder]);

  const [isWarehouseDetailsLoading, setIsWarehouseDetailsLoading] = useState(false);
  const [warehouseDetails, setWarehouseDetails] = useState<WarehouseDetail[]>([]);
  const [selectedWarehouseOption, setSelectedWarehouseOption] = useState<SelectedWarehouseOption | undefined>(
    mapToSelectedOption(selectedWarehouseDetail)
  );

  const dskus = useMemo(() => Object.keys(selectedProducts || {}), [selectedProducts]);

  const fetchWarehouseDetails = useCallback(async () => {
    setIsWarehouseDetailsLoading(true);
    try {
      const capableWarehouses = await inboundClient.getCapableWarehouses(sellerId, dskus);
      const details = capableWarehouses.map(({ id, address }) => ({
        warehouseId: id,
        address,
      }));
      setWarehouseDetails(details);
    } catch (error) {
      logError(
        { dskus, fn: "useTransferSelectPlacementPlanStep.fetchWarehouseDetails" },
        error,
        "Failed to fetch warehouse details"
      );
      toast.error(formatMessage(transfersShipmentCreateLabels.steps.placement.error.fetchWarehouses), {
        autoClose: false,
        toastId: "useTransferSelectPlacementPlanStep.fetchWarehouseDetails",
      });
    } finally {
      setIsWarehouseDetailsLoading(false);
    }
  }, [dskus, sellerId, formatMessage]);

  const currentPlan = replenishmentOrder?.distributionChannel ?? DistributionChannel.ECOM_VIA_CROSSDOCK;

  const warehouseOptions = useMemo(() => warehouseDetails.map(createWarehouseOption), [warehouseDetails]);

  const isNextDisabled = currentPlan === DistributionChannel.ECOM_DIRECT_TO_FC && !selectedWarehouseOption;

  const isLoading = useLoader(CreateTransferLoader.CreateTransferOrderLoader);

  const shouldShowForwardingPlan = useFeatureOn(FeatureName.SellerEnabledForRsToEcomForwardingPlan);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchWarehouseDetails();
  }, [fetchWarehouseDetails]);

  const handlePlanChange = useCallback(
    (event) => {
      if (!replenishmentOrder) {
        return;
      }

      const updatedReplenishmentOrder = {
        ...replenishmentOrder,
        distributionChannel: event.currentTarget.value as DistributionChannel,
        shipments: [],
      };

      dispatch(transferCreateSetReplenishmentOrder(updatedReplenishmentOrder));
    },
    [dispatch, replenishmentOrder]
  );

  const handleDestinationChange = useCallback(
    ({ value }: { value: WarehouseDetail }) => {
      if (!replenishmentOrder || selectedWarehouseOption?.value === value) {
        return;
      }

      const updatedReplenishmentOrder: OrderResponse = {
        ...replenishmentOrder,
        distributionChannel: DistributionChannel.ECOM_DIRECT_TO_FC,
        shipments: [
          {
            ...replenishmentOrder?.shipments?.[0],
            destinationWarehouseId: value.warehouseId,
            destinationWarehouseAddress: value.address,
          } as ShipmentResponse,
        ],
      };
      dispatch(transferCreateSetReplenishmentOrder(updatedReplenishmentOrder));
      setSelectedWarehouseOption(createWarehouseOption(value));
    },
    [dispatch, replenishmentOrder, selectedWarehouseOption]
  );

  return {
    currentPlan,
    warehouseOptions,
    selectedWarehouseOption,
    isWarehouseDetailsLoading,
    isNextDisabled,
    isLoading,
    shouldShowForwardingPlan,
    handlePlanChange,
    handleDestinationChange,
  };
};
