import { DistributionChannel } from "@deliverr/replenishment-client";
import { ListType } from "common/list";
import { searchServiceFor } from "common/search/Factory";
import { SearchService } from "common/search/SearchService";
import { constructOpenSearchFilters } from "common/search/services/InventorySearchUtils";
import { InboundSearchResult } from "inbounds/InboundTypes";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { logError } from "Logger";
import { OrderPageItem } from "order/listV2/OrderListReducer";
import { ParcelPageItem } from "parcel/screens/list/types";
import { PrepSearchResult } from "prep/types";
import { ReplenishmentIndexRow } from "transfers/listV2/ReplenishmentIndexRow";
import { getRsOutboundsSearchTermFilters } from "transfers/listV2/search/constructReplenishmentListOpenSearchFilters";

export enum ServiceNames {
  REPLENISHMENT = "REPLENISHMENT",
  PREP = "PREP",
  PARCEL = "PARCEL",
  INBOUND = "INBOUND",
  WHOLESALE = "WHOLESALE",
  INVENTORY = "INVENTORY",
  ECOM = "ECOM",
}
interface ServiceToSearch {
  searchService: SearchService;
  serviceName: ServiceNames;
}

export interface GlobalSearchResults {
  [ServiceNames.REPLENISHMENT]?: ReplenishmentIndexRow[];
  [ServiceNames.PREP]?: PrepSearchResult[];
  [ServiceNames.PARCEL]?: ParcelPageItem[];
  [ServiceNames.INBOUND]?: InboundSearchResult[];
  [ServiceNames.WHOLESALE]?: ReplenishmentIndexRow[];
  [ServiceNames.INVENTORY]?: ProductListItem[];
  [ServiceNames.ECOM]?: OrderPageItem[];
}

const defaultServicesToSearch: ServiceToSearch[] = [
  { searchService: searchServiceFor(ListType.InventoryV2), serviceName: ServiceNames.INVENTORY },
  { searchService: searchServiceFor(ListType.InboundListV2), serviceName: ServiceNames.INBOUND },
  { searchService: searchServiceFor(ListType.ReserveStorageOutbounds), serviceName: ServiceNames.REPLENISHMENT },
  { searchService: searchServiceFor(ListType.Orders), serviceName: ServiceNames.ECOM },
  { searchService: searchServiceFor(ListType.Parcel), serviceName: ServiceNames.PARCEL },
  { searchService: searchServiceFor(ListType.Prep), serviceName: ServiceNames.PREP },
];

const resultsPerPage = 2;

const buildReplenishmentFilters = (searchTerm: string) => ({
  pageSize: 4,
  customizedOpenSearchFilters: [
    {
      bool: {
        ...getRsOutboundsSearchTermFilters(searchTerm),
      },
    },
  ],
});

const buildInventoryFilters = (searchTerm: string) => ({
  pageSize: resultsPerPage,
  customizedOpenSearchFilters: constructOpenSearchFilters({ searchTerm, currentTab: "PRODUCTS" }),
});

const buildSearchRequest = (serviceName: ServiceNames, searchTerm: string) => {
  const baseRequest = {
    searchTerm,
    pageSize: resultsPerPage,
  };

  switch (serviceName) {
    case ServiceNames.REPLENISHMENT:
      return buildReplenishmentFilters(searchTerm);
    case ServiceNames.INVENTORY:
      return buildInventoryFilters(searchTerm);
    default:
      return baseRequest;
  }
};

export const searchIndexes = async (
  searchTerm: string,
  servicesToSearch: ServiceToSearch[] = defaultServicesToSearch
): Promise<GlobalSearchResults> => {
  try {
    const searchPromises = servicesToSearch.map(async ({ searchService, serviceName }) => {
      const searchRequest = buildSearchRequest(serviceName, searchTerm);
      // eslint-disable-next-line
      return searchService.execute(searchRequest);
    });

    const results = await Promise.all(searchPromises);

    const response: GlobalSearchResults = results.reduce((acc: GlobalSearchResults, { hits }, index) => {
      const serviceName = servicesToSearch[index].serviceName;
      if (hits.length > 0) {
        if (serviceName === ServiceNames.REPLENISHMENT) {
          const wholesaleHits = hits.filter((hit) => hit.distributionChannel === DistributionChannel.WHOLESALE);
          const replenishmentHits = hits.filter((hit) => hit.distributionChannel !== DistributionChannel.WHOLESALE);
          if (replenishmentHits.length > 0) {
            acc[serviceName] = replenishmentHits;
          }
          if (wholesaleHits.length > 0) {
            acc[ServiceNames.WHOLESALE] = wholesaleHits;
          }
        } else {
          acc[serviceName] = hits;
        }
      }

      return acc;
    }, {});

    return response;
  } catch (error) {
    logError({ fn: "searchIndexes", searchTerm }, error);
    throw error;
  }
};
