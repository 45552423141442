import React, { FC } from "react";
import { Anchor, Stack, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedDate, FormattedMessage } from "react-intl";
import { DamageFault } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import isEmpty from "lodash/isEmpty";

import { DetailItemSection } from "common/components/ProblemBlade/components/DetailItemSection";
import { ProblemDetailItem } from "common/components/ProblemBlade/components/ProblemDetailItem";
import { NonComplianceProductPhotoSection } from "inbounds/non-compliance/components";
import { MappedSkuPair, DamagedProductProblemRow } from "common/components/ProblemsTable/types";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { ShippingPlanNameDisplay } from "common/components/ShippingPlanNameDisplay";
import { ProductLink } from "common/components/ProductLink";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";
import { useDemoMode } from "common/hooks/useDemoMode";

const SpacedProblemDetailItem = styled(ProblemDetailItem)<ThemeProps>(
  ({ theme }) => `
  min-width: 50%;
  display: inline-block;

  dt {
    font-weight: ${theme.font.weight.BOLD};
  }
`
);

const ShippingPlanLink = styled(Anchor)`
  cursor: pointer;
`;

interface DamagedProductDetailsProps {
  product?: MappedSkuPair;
  shipmentProblem: DamagedProductProblemRow;
  onShippingPlanClick?: () => void;
  shippingPlanId?: number;
  shippingPlanName?: string;
  isExpired?: boolean;
}

export const DamagedProductDetails: FC<DamagedProductDetailsProps> = ({
  product,
  shipmentProblem,
  onShippingPlanClick,
  shippingPlanId,
  shippingPlanName,
  isExpired,
}) => {
  const { id, createdAt, photos, damageFault, isDisposed, damagedQty } = shipmentProblem;
  const { msku, dsku, productName } = product ?? {};
  const { isDemoMode } = useDemoMode();
  return (
    <DetailItemSection>
      <Stack gap="S4">
        <div>
          <Text size="bodyLarge" as="span">
            <FormattedMessage id="damagedProductBlade.detailsTitle" defaultMessage="Details" />
          </Text>
        </div>
        {shippingPlanId !== undefined && (
          <SpacedProblemDetailItem
            label={<FormattedMessage id="damagedProductBlade.shippingPlan" defaultMessage="Shipping plan" />}
            value={
              <>
                <ShippingPlanLink onClick={onShippingPlanClick}>
                  <ShippingPlanNameDisplay shippingPlanId={shippingPlanId} shippingPlanName={shippingPlanName} />
                </ShippingPlanLink>
              </>
            }
          />
        )}
        {dsku && dsku.length > 0 && (
          <SpacedProblemDetailItem
            label={<FormattedMessage id="damagedProductBlade.verifiedSku" defaultMessage="SKU" />}
            value={
              <ProductLink product={{ dsku: dsku! }}>
                <Text bold appearance="PRIMARY" as="span">
                  {getProductSku({ dsku, msku: msku ?? "" })}
                </Text>
              </ProductLink>
            }
          />
        )}
        {!isEmpty(productName) && (
          <SpacedProblemDetailItem
            label={<FormattedMessage id="damagedProductBlade.nonCompliantProduct" defaultMessage="Product" />}
            value={productName}
          />
        )}
        <SpacedProblemDetailItem
          label={<FormattedMessage id="damagedProductBlade.quantity" defaultMessage="Quantity" />}
          value={`${damagedQty}`}
        />
        {!isExpired && (
          <SpacedProblemDetailItem
            label={<FormattedMessage id="damagedProductBlade.damageFault" defaultMessage="Damage by" />}
            value={
              damageFault === DamageFault.SELLER ? (
                <FormattedMessage id="damagedProductBlade.sellerCarrier" defaultMessage="Seller's carrier" />
              ) : (
                <FormattedMessage id="common.flexport" defaultMessage="Flexport" />
              )
            }
          />
        )}
        <SpacedProblemDetailItem
          label={<FormattedMessage id="damagedProductBlade.disposalStatus" defaultMessage="Disposal status" />}
          value={
            isDisposed ? (
              <FormattedMessage id="damagedProductBlade.disposed" defaultMessage="Disposed" />
            ) : (
              <FormattedMessage id="damagedProductBlade.pendingDisposal" defaultMessage="Pending disposal" />
            )
          }
        />
        <SpacedProblemDetailItem
          label={<FormattedMessage id="damagedProductBlade.dateFound" defaultMessage="Date found" />}
          value={<FormattedDate value={new Date(createdAt)} year="numeric" month="long" day="numeric" />}
        />
        {!isDemoMode && (
          <SpacedProblemDetailItem
            label={<FormattedMessage id="damagedProductBlade.photoSection" defaultMessage="Photos" />}
            value={<NonComplianceProductPhotoSection key={id} photos={photos} isSmall />}
          />
        )}
        <Text>
          <FormattedMessage
            id="damagedProductBlade.learnMore"
            defaultMessage="Learn more about {learnMoreLink}."
            values={{
              learnMoreLink: (
                <ElevioBorderlessButton articleId={ElevioArticleId.DamagedUnits}>
                  <FormattedMessage id="damagedProductBlade.shipmentDamages" defaultMessage="shipment damages" />
                </ElevioBorderlessButton>
              ),
            }}
          />
        </Text>
      </Stack>
    </DetailItemSection>
  );
};
