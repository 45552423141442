import { InboundBookingType } from "@deliverr/inbound-client";
import { defineMessages } from "react-intl";

export const FLEXPORT_CLIENT_APP_BILLING_URL = "https://app.flexport.com/billing";

export const FBA_PREP_FORWARDING_INBOUND_BOOKING_TYPES: InboundBookingType[] = [
  InboundBookingType.INTL_STORAGE_FBASINGLE,
  InboundBookingType.INTL_STORAGE_FBAAUTOMATED,
];

export const CREDIT_CARD_FEE_RATE = 0.03;
export const CREDIT_CARD_FEE_PERCENT = CREDIT_CARD_FEE_RATE * 100;
export const CREDIT_CARD_FEE_MESSAGES = defineMessages({
  creditCardFee: {
    id: "inbounds.createShipment.freightChargesBreakdown.creditCardFee",
    defaultMessage: "Credit card processing fee",
  },
  creditCardRate: {
    id: "inbounds.createShipment.freightChargesBreakdown.creditCardFee",
    defaultMessage: "{percent}% of charges",
  },
});
