import React, { FC } from "react";
import { Stack, Text, Box } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import { FormattedMessage } from "react-intl";

import { CompletedIcon, PendingIcon } from "common/components/ProblemBladeIcon";
import { DamagedProductBladeTitle } from "./DamagedProductBladeTitle";
import { DamagedProductBladeDescription } from "./DamagedProductBladeDescription";
import { DamagedProductProblemRow } from "common/components/ProblemsTable/types";
import { BUSINESS_DAYS_BEFORE_DAMAGED_PRODUCT_DISPOSAL } from "inbounds/non-compliance/list/utils/getIsDamagedProductDisposed";
import { SellerProblemStatus } from "@deliverr/inbound-client";

interface DamagedProductBladeHeaderProps
  extends Pick<
    DamagedProductProblemRow,
    "status" | "damageFault" | "isDisposed" | "disputeOpenedAt" | "disputeClosedAt" | "disputeAutoResolvedAt"
  > {
  hasDisputes: boolean;
  hasReimbursementCost: boolean;
  isExpired: boolean;
}

export const DamagedProductBladeHeader: FC<DamagedProductBladeHeaderProps> = ({
  status,
  damageFault,
  isExpired,
  isDisposed,
  disputeOpenedAt,
  disputeClosedAt,
  disputeAutoResolvedAt,
  hasDisputes,
  hasReimbursementCost,
}) => {
  const isPending = status === SellerProblemStatus.SUPPORT_CONTACTED;
  const hasAutoResolvedDispute = Boolean(disputeAutoResolvedAt);

  return (
    <Box paddingTop="S4">
      <Stack center gap="S4">
        {isPending ? <PendingIcon /> : <CompletedIcon />}
        <Text size="bodyLarge" bold>
          <DamagedProductBladeTitle
            isPending={isPending}
            damageFault={damageFault}
            hasDisputes={hasDisputes}
            hasReimbursementCost={hasReimbursementCost}
            isExpired={isExpired}
          />
        </Text>
        <Text textAlign={TextAlign.center}>
          <DamagedProductBladeDescription
            isPending={isPending}
            damageFault={damageFault}
            isExpired={isExpired}
            hasDisputes={hasDisputes}
            hasReimbursementCost={hasReimbursementCost}
            disputeOpenedAt={disputeOpenedAt}
            disputeClosedAt={disputeClosedAt}
            hasAutoResolvedDispute={hasAutoResolvedDispute}
          />
        </Text>
        <Text textAlign={TextAlign.center}>
          {isDisposed ? (
            <FormattedMessage
              id="damagedProductBlade.header.disposedMessage"
              defaultMessage="Unsellable units were disposed of at no cost."
            />
          ) : (
            <FormattedMessage
              id="damagedProductBlade.header.disposalPendingMessage"
              defaultMessage="Unsellable units will be disposed of {daysBeforeDisposal} business days after the date they were found at no cost."
              values={{ daysBeforeDisposal: BUSINESS_DAYS_BEFORE_DAMAGED_PRODUCT_DISPOSAL }}
            />
          )}
        </Text>
      </Stack>
    </Box>
  );
};
