import { FBAPrepType } from "@deliverr/replenishment-client";
import { Box, Select, SelectOptionType, Text, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { FbaPrepDetailsLabels } from "./FbaPrepDetailsLabels";
import styled from "@emotion/styled";

const LightText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
    overflow-wrap: anywhere;
  `
);

const PrepTypeOption: FC<{ option: { label: string; value: FBAPrepType } }> = (props) => {
  const prepType = props.option;

  return (
    <Box width={"22rem"} paddingLeft={"S1"}>
      <Text bold>{prepType.value}</Text>

      <Box paddingTop={"S1"} paddingBottom={"S1"}>
        <LightText>{prepType.label}</LightText>
      </Box>
    </Box>
  );
};

const StyledSelect = styled(Select)<{}, ThemeProps>(`
  width: 100%;
`);

export const PrepTypesSelect: FC<{
  prepTypeOptions: { value: FBAPrepType; label: string }[];
  selectedPrepTypeOptions: { value: FBAPrepType; label: string }[];
  onChangePrepTypes: (fbaPrepTypes?: SelectOptionType[]) => void;
}> = ({ prepTypeOptions, selectedPrepTypeOptions, onChangePrepTypes }) => {
  return (
    <Box paddingTop={"S4"} paddingBottom={"S4"}>
      <Text bold>
        <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsTypeTitle} />
      </Text>

      <Box paddingTop={"S2"} paddingBottom={"S3"}>
        <LightText>
          <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsTypeDescription} />
        </LightText>
      </Box>

      <StyledSelect
        multiple
        placeholder={<FormattedMessage {...FbaPrepDetailsLabels.prepTypeSelectPlaceholder} />}
        options={prepTypeOptions.map((opt, idx) => ({
          value: opt.value,
          label: <PrepTypeOption option={opt} key={idx} />,
        }))}
        value={selectedPrepTypeOptions}
        onChange={onChangePrepTypes}
        pluralEntityName="Prep Types"
      />
    </Box>
  );
};
