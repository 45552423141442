import { EntityName } from "@deliverr/replenishment-client";
import { chain, groupBy, isEmpty, isNil } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { transferCreateRemoveProduct, transferCreateSetReplenishmentError } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useLoader } from "common/components/WithLoader/useLoader";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { useUnmount } from "react-use";
import { ReplenishmentProductWithErrors } from "./ReplenishmentProductErrorTypes";
import { getFlattenedEntityErrors } from "transfers/create/steps/fbaV3/commons/FbaV3Utils";

export const useReplenishmentOrderCreationError = () => {
  const dispatch = useDispatch();

  const { selectedProducts, replenishmentError } = useSelector(selectTransferCreate);
  const flattenedErrors = getFlattenedEntityErrors(replenishmentError?.errors);

  const [itemEntityErrors, orderEntityErrors] = chain(flattenedErrors)
    .filter((err) => err.code !== "UNKNOWN_ERROR")
    .partition(
      // If an error is related to an item, it should have an entityId, ie; dsku
      (err) =>
        (err.entity === EntityName.ORDER_ITEM || err.entity === EntityName.SHIPMENT_ITEM) && !isEmpty(err.entityId)
    )
    .value();

  const orderErrors = orderEntityErrors.map((err) => ({
    errorMessage: err.message,
    errorCode: err.code,
  }));

  const dskuToItemErrors = groupBy(itemEntityErrors, (itemError) => itemError.entityId as string);
  const productsWithErrors: ReplenishmentProductWithErrors[] = chain(selectedProducts)
    .values()
    .filter((product) => !isNil(dskuToItemErrors[product.packOf ?? product.dsku]))
    .map((product) => {
      const errors = dskuToItemErrors[product.packOf ?? product.dsku].map((itemError) => ({
        errorMessage: itemError.message,
        errorCode: itemError.code,
      }));

      return {
        errors,
        product,
      };
    })
    .value();

  const isNextLoading = useLoader(CreateTransferLoader.CreateTransferOrderLoader);

  const onRemoveProduct = (dsku: string) => {
    return dispatch(transferCreateRemoveProduct(dsku));
  };

  useUnmount(() => {
    dispatch(transferCreateSetReplenishmentError({ errors: [] }));
  });

  return {
    productsWithErrors,
    orderErrors,
    onRemoveProduct,
    isNextLoading,
  };
};
