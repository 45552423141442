import { selectDemoMode } from "common/components/DemoMode/DemoModeSelector";
import log from "Logger";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

export const useDemoMode = () => {
  const isDemoMode = useSelector(selectDemoMode);

  const maskIfDemoMode = (
    data: string | number,
    customMask?: string | number | ReactElement,
    returnAsString?: boolean
  ) => {
    if (!isDemoMode) {
      return data;
    }
    const DEFAULT_MASK = "xxx...";

    let maskedData: string | number | ReactElement;

    if (typeof data === "string") {
      maskedData = customMask ?? (/[a-zA-Z]/.test(data.charAt(0)) ? data.charAt(0) + DEFAULT_MASK : DEFAULT_MASK);
    } else if (typeof data === "number") {
      maskedData = customMask ?? 0;
    } else {
      maskedData = customMask ?? DEFAULT_MASK;
    }
    if (returnAsString) {
      if (typeof maskedData === "string" || typeof maskedData === "number") {
        return maskedData.toString();
      } else {
        log.warn({ fn: "maskIfDemoMode" }, "Attempted to convert a ReactElement to a string.");
        return <span>{maskedData}</span>;
      }
    }
    return <span>{maskedData}</span>;
  };
  return {
    isDemoMode,
    maskIfDemoMode,
  };
};
