import { ListType, appendListType } from "common/list/ListType";
import { SPThunkAction, createActionCreator } from "common/ReduxUtils";
import log, { logError } from "Logger";

import { SearchRequest } from "common/search/SearchService";
import { Sort } from "common/models";
import { notifyUserOfError } from "common/ErrorToast";
import { searchServiceFor } from "common/search/Factory";

export enum ListActionTypes {
  SEARCH_INDEX = "SEARCH_INDEX",
  SET_SEARCH_TERM = "SET_SEARCH_TERM",
  SET_PAGE_CHECKED = "SET_PAGE_CHECKED",
  SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST = "SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST",
  SET_HIT_CHECKED = "SET_HIT_CHECKED",
  SET_SORT = "SET_SORT",
  SET_PAGE = "SET_PAGE",
  CLEAR_ALL_CHECKED = "CLEAR_ALL_CHECKED",
  SET_ROW_EXPAND = "SET_ROW_EXPAND",
  CLEAR_ALL_EXPANDED = "CLEAR_ALL_EXPANDED",
}

export const createListActions = (listType: ListType) => {
  const isLogisticsSearchProductsReadEnabled = true;
  const isLogisticsSearchBundlesReadEnabled = true;
  const searchIndexLocally = async ({
    searchTerm,
    filters = [],
    numericFilter,
    customizedOpenSearchFilters,
    clearCacheOnSearch,
    pageSize,
    page,
    sort,
  }: SearchRequest) => {
    const ctx = { fn: "searchIndexLocally", listType, searchTerm };
    log.info({ ...ctx }, "searching index");

    // Override searchService if listType is inventory and feature is on
    const searchService = getSearchService(listType);
    try {
      const newSearchTerm = searchTerm ?? "";
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const newPage = page || 0;

      const searchResult = await searchService.execute({
        searchTerm: newSearchTerm,
        page: newPage,
        sort,
        filters,
        numericFilter,
        customizedOpenSearchFilters,
        clearCacheOnSearch,
        pageSize,
      });

      log.info(ctx, "retrieved results");
      return searchResult;
    } catch (err) {
      logError(ctx, err, "error searching index");
      throw new Error(err);
    }
  };

  const searchIndex =
    ({
      searchTerm,
      filters = [],
      numericFilter,
      customizedOpenSearchFilters,
      clearCacheOnSearch,
      page,
      sort,
    }: SearchRequest): SPThunkAction =>
    async (dispatch, getState) => {
      const state = getState();
      const {
        user: { resultsPerPage },
      } = state;
      try {
        const { response, hits: listItems } = await searchIndexLocally({
          searchTerm,
          filters,
          numericFilter,
          customizedOpenSearchFilters,
          clearCacheOnSearch,
          pageSize: resultsPerPage[listType],
          page,
          sort,
        });
        dispatch({
          type: appendListType(ListActionTypes.SEARCH_INDEX, listType),
          listItems,
          page: response.page,
          totalPages: response.nbPages,
          listItemCount: response.nbHits,
          itemsPerPage: response.hitsPerPage,
        });
      } catch (err) {
        notifyUserOfError({ err, toastId: "searchIndexError" });
      }
    };

  const setSearchTerm = createActionCreator<string>(
    appendListType(ListActionTypes.SET_SEARCH_TERM, listType),
    "searchTerm"
  );

  const setPageChecked = createActionCreator<boolean>(
    appendListType(ListActionTypes.SET_PAGE_CHECKED, listType),
    "checked"
  );

  const setRemovalPagePartiallyChecked = createActionCreator<boolean>(
    appendListType(ListActionTypes.SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST, listType),
    "checked"
  );

  const setHitChecked = createActionCreator<number, boolean>(
    appendListType(ListActionTypes.SET_HIT_CHECKED, listType),
    "hitIndex",
    "checked"
  );

  const clearAllChecked = createActionCreator(appendListType(ListActionTypes.CLEAR_ALL_CHECKED, listType));

  const setRowExpand = createActionCreator<number, boolean>(
    appendListType(ListActionTypes.SET_ROW_EXPAND, listType),
    "rowIndex",
    "expanded"
  );

  const clearAllExpanded = createActionCreator(appendListType(ListActionTypes.CLEAR_ALL_EXPANDED, listType));

  const setSort = createActionCreator<Sort>(appendListType(ListActionTypes.SET_SORT, listType), "sort");

  const setPage = createActionCreator<number>(appendListType(ListActionTypes.SET_PAGE, listType), "page");

  const clearSearchCache = (): SPThunkAction => (_, getState) => {
    getSearchService(listType).clearCache();
  };

  const overrideListType = (lt: ListType): ListType => {
    if (lt === ListType.Inventory && isLogisticsSearchProductsReadEnabled) {
      return ListType.InventoryV2;
    }
    if (lt === ListType.Bundle && isLogisticsSearchBundlesReadEnabled) {
      return ListType.BundleV2;
    }
    return lt;
  };

  const getSearchService = (lt: ListType) => {
    const finalListType = overrideListType(lt);
    // Get search service singleton function
    return searchServiceFor(finalListType);
  };

  return {
    getSearchService,
    searchIndex,
    searchIndexLocally,
    setSearchTerm,
    setPageChecked,
    setRemovalPagePartiallyChecked,
    setHitChecked,
    clearAllChecked,
    setRowExpand,
    clearAllExpanded,
    setSort,
    setPage,
    clearSearchCache,
  };
};
