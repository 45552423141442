import { DefaultTheme } from "common/components/ui";
import { Card } from "common/components/ui/Card";
import { Stack } from "common/components/ui/Stack";
import { Title } from "common/components/ui/Title";
import styled from "@emotion/styled";
import React, { FC, ReactNode } from "react";
import { useIntl } from "react-intl";
import HtmlToReact from "./HtmlToReact";

const EmptyListMessageCard = styled(Card)<{ mode: EmptyListMessageMode }, DefaultTheme>(
  ({ theme, mode }) => `
    padding: 5rem ${theme.spacing.S4};
    text-align: center;

    ${
      mode === "BASE" &&
      `p {
      color: ${theme.colors.NEUTRAL[200]};
      max-width: 350px;
      margin: ${theme.spacing.S2} auto ${theme.spacing.S4} auto;
      font-size: ${theme.font.size.F2};
    }`
    }
  `
);

const NoMarginTitle = styled(Title)`
  margin: 0;
`;

const Image = styled.img<{ imageWidth?: string }, DefaultTheme>(
  ({ imageWidth, theme }) => `
    width: ${imageWidth ?? "109px"};
    margin-bottom: ${theme.spacing.S5};
  `
);

const SvgContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) =>
    `
    width: 4rem;
    margin-bottom: ${theme.spacing.S5};
  `
);

const CircledSvgContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) =>
    `
    height: ${theme.spacing.S9};
    width: ${theme.spacing.S9};
    padding: ${theme.spacing.S4};
    border-radius: 50%;
    background-color: ${theme.colors.INDIGO[100]}4C;
    margin-bottom: ${theme.spacing.S5};
  `
);

const IconContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S5};
  `
);

interface EmptyListMessageProps {
  title: ReactNode;
  content: ReactNode;
  imageUrl?: string;
  imageWidth?: string;
  svg?: string;
  icon?: ReactNode;
  circledSvg?: boolean;
  mode?: EmptyListMessageMode;
}

export type EmptyListMessageMode = "BASE" | "NONE";

const EmptyListMessage: FC<EmptyListMessageProps> = ({
  title,
  content,
  imageUrl,
  svg,
  icon,
  circledSvg,
  imageWidth,
  mode = "BASE",
}) => {
  const { formatMessage } = useIntl();
  const imageAlt = formatMessage({ id: "emptyList", defaultMessage: "Empty list" });

  const EffectiveSvgContainer = circledSvg ? CircledSvgContainer : SvgContainer;

  return (
    <EmptyListMessageCard mode={mode}>
      <Stack gap={null} center>
        {imageUrl && <Image src={imageUrl} alt={imageAlt} imageWidth={imageWidth} />}
        {svg && (
          <EffectiveSvgContainer>
            <HtmlToReact html={svg} />
          </EffectiveSvgContainer>
        )}
        {icon && <IconContainer>{icon}</IconContainer>}
        <NoMarginTitle displayAs="h3">{title}</NoMarginTitle>
        {content}
      </Stack>
    </EmptyListMessageCard>
  );
};

export default EmptyListMessage;
