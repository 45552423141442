import { billingClient } from "common/clients/instances";
import { notifyUserOfError } from "common/ErrorToast";
import { SPThunkAction } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import log, { logError, logStart, logSuccess } from "Logger";
import { billingClientV2 } from "Clients";

export enum BillingActionTypes {
  SET_CHARGEBEE_SESSION = "SET_CHARGEBEE_SESSION",
  FETCH_BILLING_SUMMARY_START = "FETCH_BILLING_SUMMARY_START",
  FETCH_BILLING_SUMMARY_SUCCESS = "FETCH_BILLING_SUMMARY_SUCCESS",
  FETCH_BILLING_SUMMARY_ERROR = "FETCH_BILLING_SUMMARY_ERROR",
  FETCH_INVOICES_START = "FETCH_INVOICES_START",
  FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS",
  FETCH_INVOICES_ERROR = "FETCH_INVOICES_ERROR",
  FETCH_CREDITS_START = "FETCH_CREDITS_START",
  FETCH_CREDITS_SUCCESS = "FETCH_CREDITS_SUCCESS",
  FETCH_CREDITS_ERROR = "FETCH_CREDITS_ERROR",
  RESET_BILLING = "RESET_BILLING",
  FETCH_PRIMARY_PAYMENT_SOURCE_START = "FETCH_PRIMARY_PAYMENT_SOURCE_START",
  FETCH_PRIMARY_PAYMENT_SOURCE_SUCCESS = "FETCH_PRIMARY_PAYMENT_SOURCE_SUCCESS",
  FETCH_PRIMARY_PAYMENT_SOURCE_ERROR = "FETCH_PRIMARY_PAYMENT_SOURCE_ERROR",
  FETCH_CONTRA_REVENUE_START = "FETCH_CONTRA_REVENUE_START",
  FETCH_CONTRA_REVENUE_SUCCESS = "FETCH_CONTRA_REVENUE_SUCCESS",
  FETCH_CONTRA_REVENUE_ERROR = "FETCH_CONTRA_REVENUE_ERROR",
  FETCH_SELLER_CONFIG_START = "FETCH_SELLER_CONFIG_START",
  FETCH_SELLER_CONFIG_SUCCESS = "FETCH_SELLER_CONFIG_SUCCESS",
  FETCH_SELLER_CONFIG_ERROR = "FETCH_SELLER_CONFIG_ERROR",
  FETCH_SELLER_DUES_START = "FETCH_SELLER_DUES_START",
  FETCH_SELLER_DUES_SUCCESS = "FETCH_SELLER_DUES_SUCCESS",
  FETCH_SELLER_DUES_ERROR = "FETCH_SELLER_DUES_ERROR",
}

export const setChargebeeSession = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = { fn: "setChargebeeSession" };
  log.info(ctx, "setting chargebee session");
  const {
    billing: { session },
  } = getState();

  const chargebeeInstance = window.Chargebee.getInstance();
  if (!session || session.expires_at * 1000 <= new Date().getTime()) {
    try {
      const newSession = await billingClientV2.getChargebeeSession();
      dispatch({
        type: BillingActionTypes.SET_CHARGEBEE_SESSION,
        session: newSession,
      });
      chargebeeInstance.setPortalSession(newSession);
    } catch (err) {
      log.error({ ...ctx, err }, "error getting chargebee portal session");
      notifyUserOfError({
        err,
        explanation: "Error getting billing details",
        toastId: "getBillingDetailsError",
      });
    }
  } else {
    chargebeeInstance.setPortalSession(session);
  }
};

// https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-portal-instance-object
const openSection = async (dispatch, section: string, onPaymentAdd?: () => void) => {
  await dispatch(setChargebeeSession());
  const instance = window.Chargebee.getInstance();
  const portal = instance.createChargebeePortal();

  const callbacks = onPaymentAdd
    ? {
        paymentSourceAdd: onPaymentAdd,
      }
    : {};

  portal.openSection(
    {
      sectionType: window.Chargebee.getPortalSections()[section],
    },
    callbacks
  );
};

export const showBillingAddress = (): SPThunkAction => async (dispatch) => await openSection(dispatch, "ADDRESS");
export const showPaymentMethods =
  (onPaymentAdd?: () => void): SPThunkAction =>
  async (dispatch) =>
    await openSection(dispatch, "PAYMENT_SOURCES", onPaymentAdd);

export const fetchBillingSummary = (): SPThunkAction => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const loading = state.billing.summary.loading;

  if (loading) {
    return;
  }

  const ctx = logStart({ fn: "fetchBillingSummary" });
  dispatch({ type: BillingActionTypes.FETCH_BILLING_SUMMARY_START });
  try {
    const result = await billingClientV2.getBillingSummary(sellerId);
    log.info(ctx, "successfully retrieved billing summary");
    dispatch({ type: BillingActionTypes.FETCH_BILLING_SUMMARY_SUCCESS, payload: result });
  } catch (err) {
    logError(ctx, err);
    dispatch({ type: BillingActionTypes.FETCH_BILLING_SUMMARY_ERROR });
  }
};

export const DEFAULT_INVOICES_PER_PAGE = 25;

export const fetchInvoices =
  (page: number): SPThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const sellerId = getSellerId(state);
    const pageSize = state.user.resultsPerPage.billing ?? DEFAULT_INVOICES_PER_PAGE;
    const loading = state.billing.invoices.loading;

    if (loading) {
      return;
    }

    const limit = pageSize;
    const offset = pageSize * page;

    const ctx = logStart({ fn: "fetchInvoices" });
    dispatch({ type: BillingActionTypes.FETCH_INVOICES_START });
    try {
      const { invoices } = await billingClientV2.getInvoiceHistory(sellerId, limit, offset);
      log.info(ctx, "successfully retrieved invoices");
      dispatch({ type: BillingActionTypes.FETCH_INVOICES_SUCCESS, payload: { invoices, page } });
    } catch (err) {
      logError(ctx, err);
      dispatch({ type: BillingActionTypes.FETCH_INVOICES_ERROR });
    }
  };

export const CREDITS_PER_PAGE = 20;

export const fetchCredits = (): SPThunkAction => async (dispatch, getState) => {
  const state = getState();

  if (!state.billing.credits.hasNextPage || state.billing.credits.loading) {
    return;
  }

  const sellerId = getSellerId(state);
  const limit = CREDITS_PER_PAGE;
  const offset = state.billing.credits.nextPage * CREDITS_PER_PAGE;

  const ctx = logStart({ fn: "fetchCredits" });
  dispatch({ type: BillingActionTypes.FETCH_CREDITS_START });
  try {
    const { creditHistory: list } = await billingClientV2.getCreditsHistory(sellerId, limit, offset);
    log.info(ctx, "successfully retrieved credits");
    dispatch({ type: BillingActionTypes.FETCH_CREDITS_SUCCESS, payload: list });
  } catch (err) {
    logError(ctx, err);
    dispatch({ type: BillingActionTypes.FETCH_CREDITS_ERROR });
  }
};

export const fetchContraRevenueHistoryForInvoice =
  (invoiceId: number): SPThunkAction =>
  async (dispatch, getState) => {
    const state = getState();

    if (state.billing.contraRevenue.loading) {
      return;
    }

    const sellerId = getSellerId(state);

    const ctx = logStart({ fn: "fetchContraRevenueItemsForInvoice" });
    dispatch({ type: BillingActionTypes.FETCH_CONTRA_REVENUE_START });
    try {
      const contraRevenueItems = await billingClientV2.getContraRevenueItemsForInvoice(sellerId, invoiceId.toString());
      log.info(ctx, "successfully retrieved contra revenue items for invoice");
      dispatch({ type: BillingActionTypes.FETCH_CONTRA_REVENUE_SUCCESS, payload: contraRevenueItems });
    } catch (err) {
      logError(ctx, err);
      dispatch({ type: BillingActionTypes.FETCH_CONTRA_REVENUE_ERROR });
    }
  };

export const resetBilling = (): SPThunkAction => async (dispatch) => {
  dispatch({ type: BillingActionTypes.RESET_BILLING });
  dispatch(fetchBillingSummary());
};

export const fetchPrimaryPaymentSource = (): SPThunkAction => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const loading = state.billing.primaryPaymentSource.loading;

  if (loading) {
    return;
  }
  const ctx = logStart({ fn: "fetchPrimaryPaymentSource" });
  dispatch({ type: BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_START });
  try {
    const result = await billingClient.getPrimaryPaymentSource(sellerId);
    log.info(ctx, "successfully retrieved primary payment source");
    dispatch({ type: BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_SUCCESS, payload: result });
  } catch (err) {
    logError(ctx, err);
    dispatch({ type: BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_ERROR });
  }
};

export const fetchSellerConfig = (): SPThunkAction => async (dispatch, getState) => {
  const state = getState();
  const loading = state.billing.sellerConfig.loading;
  if (loading) {
    return;
  }
  const sellerId = getSellerId(state);
  const ctx = logStart({ fn: "fetchSellerConfig" });
  dispatch({ type: BillingActionTypes.FETCH_SELLER_CONFIG_START });
  try {
    const result = await billingClientV2.getSellerConfig(sellerId);
    logSuccess(ctx, "successfully retrieved seller config");
    dispatch({ type: BillingActionTypes.FETCH_SELLER_CONFIG_SUCCESS, payload: result });
  } catch (err) {
    logError(ctx, err);
    dispatch({ type: BillingActionTypes.FETCH_SELLER_CONFIG_ERROR });
  }
};
export const fetchSellerDues = (): SPThunkAction => async (dispatch, getState) => {
  const state = getState();
  const loading = state.billing.sellerDues.loading;
  if (loading) {
    return;
  }
  const sellerId = getSellerId(state);
  const ctx = logStart({ fn: "fetchSellerDues" });
  dispatch({ type: BillingActionTypes.FETCH_SELLER_DUES_START });
  try {
    const result = await billingClientV2.getSellerDues(sellerId);
    log.info(ctx, "successfully retrieved seller dues");
    dispatch({ type: BillingActionTypes.FETCH_SELLER_DUES_SUCCESS, payload: result });
  } catch (err) {
    logError(ctx, err);
    dispatch({ type: BillingActionTypes.FETCH_SELLER_DUES_ERROR });
  }
};
