import { forEach, isEmpty, mapValues, min, reduce, values } from "lodash";
import { productClient } from "Clients";
import { inventoryClient } from "common/clients/instances";
import { SelectedProductData } from "common/components/ProductChooser";
import { ATPMap } from "common/clients/inventory/ATP/ATPMap";
import {
  AvailableInventoryMap,
  KitComponentInfoResponse,
  MaxInventoryByWarehouse,
} from "common/components/ProductChooser/ProductChooserReducer";
import { ProductSearchResultItem } from "../ProductSearchResult";
import { KitComponentResponse, Product } from "@deliverr/business-types";

export interface AvailableToKitResponse {
  selectedWarehouse: string;
  kitComponents: KitComponentInfoResponse[];
  potentialInventory: number;
  availableInventoryMap: AvailableInventoryMap;
}

interface MaxInventoryMap {
  [key: string]: number;
}

interface UniqueWarehousesMap {
  warehouses: string[];
  warehouseMap: { [warehouse: string]: ATPMap };
}

// Get max inventory for a given set of child DSKUs
const getMaxInventoryByWarehouse = (
  childDSKUs: string[],
  warehouseData: ATPMap,
  componentDetails: Product
): MaxInventoryByWarehouse[] => {
  const casePackMap = new Map(componentDetails?.productCasePacks?.map((val) => [val.dsku, val.quantity]) ?? []);

  return childDSKUs.reduce<MaxInventoryByWarehouse[]>((acc, childDSKU) => {
    const warehouseInfo = warehouseData?.[childDSKU];
    if (warehouseInfo) {
      acc.push({
        dsku: childDSKU,
        availableToPurchase: warehouseInfo.availableToPurchase,
        quantity: casePackMap.get(childDSKU) ?? 0,
      });
    }
    return acc;
  }, []);
};

// Get unique warehouses and their inventory data
const getUniqueWarehouses = (inventoryData: ATPMap): UniqueWarehousesMap => {
  const warehouseMap = reduce(
    inventoryData,
    (acc, warehouseDataATP, sku) => {
      forEach(warehouseDataATP, (atpDetails, warehouse) => {
        acc[warehouse] = {
          ...acc[warehouse],
          [sku]: atpDetails,
        };
      });
      return acc;
    },
    {}
  );

  return {
    warehouses: Object.keys(warehouseMap),
    warehouseMap,
  };
};

// Fetch available inventory for kit components
const getInventoryForComponents = async (parentDSKUs: string[]) => {
  // Fetch the case pack details of the components to determine the available inventory
  const componentDetails = await productClient.getProducts(parentDSKUs, {
    includeProductCasePacks: true,
  });

  // Map the parent DSKUs to the child DSKUs
  const mappedCasePacks = mapValues(
    componentDetails,
    (value) => value.productCasePacks?.map((pack) => pack.dsku) ?? []
  );

  const inventory = await inventoryClient.getATPByWarehouse(Object.values(mappedCasePacks).flat(), "STORAGE");

  if (isEmpty(inventory)) {
    throw new Error(`Inventory is not available to be Kitted`);
  }

  const { warehouses, warehouseMap } = getUniqueWarehouses(inventory);

  for (const warehouse of warehouses) {
    const inventoryData = parentDSKUs.map((parentDSKU) => {
      const childDSKUs = componentDetails[parentDSKU]?.productCasePacks?.map((pack) => pack.dsku) ?? [];
      const maxInventoryByWarehouse = getMaxInventoryByWarehouse(
        childDSKUs,
        warehouseMap[warehouse],
        componentDetails[parentDSKU]
      );
      const availableInventory = maxInventoryByWarehouse.map((child) => child.availableToPurchase ?? 0);

      const maxInventory = Math.max(0, ...availableInventory);
      const maxAvailableInventoryWarehouse = maxInventoryByWarehouse.find(
        (child) => child.availableToPurchase === maxInventory
      );

      return {
        parentDSKU,
        maxInventory,
        maxAvailableInventoryWarehouse,
      };
    });

    const maxInventoryMap: MaxInventoryMap = Object.fromEntries(
      inventoryData.map(({ parentDSKU, maxInventory }) => [parentDSKU, maxInventory])
    );
    const availableInventoryMap: AvailableInventoryMap = Object.fromEntries(
      inventoryData
        .filter(({ maxAvailableInventoryWarehouse }) => maxAvailableInventoryWarehouse !== undefined)
        .map(({ parentDSKU, maxAvailableInventoryWarehouse }) => [
          parentDSKU,
          maxAvailableInventoryWarehouse as MaxInventoryByWarehouse,
        ])
    );

    if (Object.values(maxInventoryMap).every((val) => val > 0)) {
      return { selectedWarehouse: warehouse, maxInventoryMap, availableInventoryMap, componentDetails };
    }
  }

  throw new Error(`No components available to be Kitted`);
};

export const fetchKitComponentsFromProduct = async (
  kitParentDSKU: string
): Promise<KitComponentResponse[] | undefined> => {
  const productDetails = await productClient.getProduct(kitParentDSKU, { includeKitComponents: true });
  return productDetails?.kitResponse?.kitComponents;
};

export const fetchAvailableToKitQty = async (
  product: ProductSearchResultItem | SelectedProductData
): Promise<AvailableToKitResponse> => {
  if (!product.isKit || !product.packOf) {
    throw new Error("Product is not a kit or does not have a packOf value");
  }

  // Fetch the details of the kit components
  const kitProductComponents = await fetchKitComponentsFromProduct(product.packOf);

  if (!kitProductComponents) {
    throw new Error(`No kit components found for ${product.packOf}`);
  }

  const parentDSKUs = kitProductComponents.map((component) => component.dsku); // Extract the parent DSKUs of the kit components
  try {
    const { maxInventoryMap, availableInventoryMap, selectedWarehouse, componentDetails } =
      await getInventoryForComponents(parentDSKUs); // Fetch the available inventory for each Child DSKU within the Parent DSKU

    const kitComponents: KitComponentInfoResponse[] = Object.values(componentDetails).map((component) => ({
      dsku: availableInventoryMap[component.dsku].dsku,
      qty: kitProductComponents.find((kitComponent) => kitComponent.dsku === component.dsku)?.qty ?? 0,
      caseQty: availableInventoryMap[component.dsku].quantity,
      packOf: component.packOf ?? component.dsku,
      kittedDSKU: product.packOf,
      isFefoEnabled: component.isFefoEnabled,
      isLotTrackingEnabled: component.isLotTrackingEnabled,
      name: component.name,
    }));

    const maxAvailableComponentMultiplier = {};
    if (selectedWarehouse) {
      for (const kitComponent of kitProductComponents) {
        const maxQty = maxInventoryMap[kitComponent.dsku] || 0;
        if (maxQty > 0) {
          maxAvailableComponentMultiplier[kitComponent.dsku] = Math.floor(maxQty / kitComponent.qty || 1);
        }
      }
    }

    return {
      selectedWarehouse,
      kitComponents,
      potentialInventory: min(values(maxAvailableComponentMultiplier)) ?? 0,
      availableInventoryMap,
    };
  } catch (error) {
    throw new Error(`Error fetching inventory for kit components: ${error}`);
  }
};
