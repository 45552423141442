import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";
import { CreateBookingRequest } from "@deliverr/inbound-client";

export const selectBookingCreateRequest = () =>
  createSelector(selectBookingRequest(false), (bookingRequest) => {
    const { from, originIsPort, originAddressFid, originPortFid, originPortGrouping } = bookingRequest;
    const createBookingRequest: CreateBookingRequest = {
      from,
      originIsPort,
      originAddressFid,
      originPortFid,
      originPortGrouping,
    };
    return createBookingRequest;
  });
