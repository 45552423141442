import classNames from "classnames";
import Tooltip from "rc-tooltip";
import React, { ComponentProps, FC } from "react";
import { Box, LocalizedMessage, ThemeProps } from "common/components/ui";
import { DateFormat } from "common/date/DateFormat";
import { useDatePicker } from "./useDatePicker";

import cls from "./DatePicker.less";
import { Calendar } from "../Calendar";
import { isAfter } from "date-fns";
import styled from "@emotion/styled";

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RemoveButton = styled.div<ThemeProps>(
  ({ theme: { font, border } }) => `
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: ${border.radius.R5};
  background: #d3d3d3;
  font-size: ${font.size.F2};
  font-weight: ${font.weight.BOLD};
  text-align: center;
  line-height: ${font.lineHeight.LH2};
  cursor: pointer;
  &:before {
    content: "✕";
  }
`
);

const StyledBox = styled(Box)<ThemeProps>(
  ({ theme: { spacing } }) => `
  padding: 0 ${spacing.S3};
`
);

const StyledUnitBox = styled(StyledBox)`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;

export interface DatePickerProps
  extends Pick<
    ComponentProps<typeof Calendar>,
    "minDate" | "maxDate" | "getIsDateDisabled" | "defaultActiveStartDate"
  > {
  date?: Date; // no date will be shown if undefined, date selector will default to current day.
  disabled?: boolean;
  onDateChange: (date: Date) => void;
  format?: DateFormat;
  className?: string; // for styling the input element
  calendarClassName?: string; // for styling the calendar popover
  hasError?: boolean;
  suffixMessage?: LocalizedMessage;
  noLaterThanToday?: boolean;
  adjustUTCOffset?: boolean;
  nextYearButton?: string | null;
  prevYearButton?: string | null;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  hideRemoveButton?: boolean;
}

export const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => {
  const {
    date,
    disabled,
    minDate,
    maxDate,
    format,
    onDateChange,
    getIsDateDisabled,
    className,
    calendarClassName,
    hasError,
    suffixMessage,
    noLaterThanToday,
    adjustUTCOffset,
    defaultActiveStartDate,
    nextYearButton,
    prevYearButton,
    onKeyDown,
    hideRemoveButton,
  } = props;
  const { visible, handleSelect, handleVisibleChange, formatDate, placeholder } = useDatePicker({
    onDateChange,
    format,
    adjustUTCOffset,
  });

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const disableDate = (dt: Date) => Boolean(getIsDateDisabled?.(dt) || (noLaterThanToday && isAfter(dt, new Date())));

  return (
    <DateRangeContainer data-testid="tooltip-date-picker" tabIndex={0} onKeyDown={onKeyDown}>
      <Tooltip
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={visible && !disabled}
        placement="bottom"
        overlayClassName={cls.datePickerTooltip}
        overlay={
          <Calendar
            locale="en-US" // makes Sunday the first day of the week
            getIsDateDisabled={disableDate}
            value={date}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleSelect}
            className={calendarClassName}
            defaultActiveStartDate={defaultActiveStartDate}
            minDetail="month"
            maxDetail="month"
            prev2Label={prevYearButton}
            next2Label={nextYearButton}
          />
        }
      >
        <div
          className={classNames(cls.dateInput, className, {
            [cls.datePresent]: date,
            [cls.disabled]: disabled,
            [cls.hasError]: hasError,
          })}
        >
          {date ? (
            <>
              {formatDate(date)} {suffixMessage}
            </>
          ) : (
            <span className={cls.placeholder}>{placeholder}</span>
          )}
        </div>
      </Tooltip>
      {!hideRemoveButton && (
        <StyledUnitBox>
          <RemoveButton role="button" onClick={() => handleSelect(null)} />
        </StyledUnitBox>
      )}
    </DateRangeContainer>
  );
};
