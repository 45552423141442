import { InboundBooking, InboundBookingType } from "@deliverr/inbound-client";
import { productClient } from "Clients";
import pick from "lodash/pick";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import { StorageInboundProductItemDetails } from "storage/inbounds/create/store/StorageInboundProductItemDetails.types";
import { CaseDimensionCollection } from "storage/inbounds/create/dimensions/CaseDimensionCollection.types";
import { logError, logStart } from "Logger";
import { ProductCasePack } from "@deliverr/commons-clients";

export const fetchStorageProductDetails = async (booking: InboundBooking) => {
  const bookingProducts = booking.bookingProducts ?? [];
  const ctx = logStart({
    fn: "ipb.fetchStorageProductDetails",
    shippingPlanId: booking.shippingPlanId,
    bookingProducts,
  });
  const isDtcBooking = booking.bookingType === InboundBookingType.INTL_DTC;

  if (isDtcBooking) {
    const productDskus = bookingProducts.map((product) => product.dsku);
    const productDetails = await productClient
      .getUnifiedProducts(productDskus, {
        includeProductCasePacks: true,
      })
      .catch((error) => {
        logError({ ...ctx, failedAt: "productClient.getUnifiedProducts" }, error);
        throw error;
      });
    const bookingProductsByProductSku = keyBy(bookingProducts, "dsku");
    const productCasePacks = Object.values(productDetails)
      .map((product) => product.productCasePacks)
      .flat()
      .reduce<{
        [dsku: string]: ProductCasePack;
      }>((acc, casePack) => {
        if (casePack) {
          acc[casePack.dsku] = casePack;
        }
        return acc;
      }, {});

    const selectedProductsCasePacks = keyBy(
      Object.values(productCasePacks).map((pack) => omit(pack, "productDetails")),
      "packOf"
    );

    const selectedProducts = productDskus.reduce<Record<string, StorageInboundProductItemDetails>>(
      (map, productSku) => {
        const bookingProduct = bookingProductsByProductSku[productSku];
        const selectedCasePack = selectedProductsCasePacks[productSku];

        map[productSku] = {
          dsku: productSku,
          caseQty: selectedCasePack.quantity,
          qty: bookingProduct.qty,
          numberOfCases: Math.floor(bookingProduct.qty / selectedCasePack.quantity),
          productCasePackOptions: keyBy(productDetails[productSku].productCasePacks ?? [], "dsku"),
        };
        return map;
      },
      {}
    );
    /* For single sku in DTC flow we need case pack but we don't store case pack dsku in inbound service so the only solution is to find it based on the quantity 
    because every case pack belongs to one product has different quantity */
    const intlDtcSelectedProductsCasePackDskus = Object.values(productCasePacks).reduce<
      Record<string, Record<number, string>>
    >((acc, casePack) => {
      const productDsku = casePack.packOf;
      if (!acc[productDsku]) {
        acc[productDsku] = {};
      }
      acc[productDsku][casePack.quantity] = casePack.dsku;
      return acc;
    }, {});

    return {
      productDetails,
      selectedProducts,
      selectedProductsCasePacks,
      intlDtcSelectedProductsCasePackDskus,
    };
  }
  /** we passed the case pack skus as case packs are required in International reserve storage flow */
  const casePackSkus = bookingProducts.map((product) => product.dsku).filter(Boolean);

  if (!casePackSkus.length) {
    return;
  }

  const bookingProductsByCaseSku = keyBy(bookingProducts, "dsku");

  /** we fetch product case pack info which will return the product skus */
  const productCasePacks = await productClient
    .getProductCasePacks(casePackSkus, {
      includeProductInformation: true,
    })
    .catch((error) => {
      logError({ ...ctx, failedAt: "productClient.getProductCasePacks" }, error);
      throw error;
    });

  const caseSkuToProductSku = Object.values(productCasePacks).reduce<Record<string, string>>((acc, pack) => {
    const productSku = pack?.productDetails?.dsku;
    if (productSku) {
      acc[pack.dsku] = productSku;
    }
    return acc;
  }, {});

  /** creates a map of product skus to their selected case pack */
  /** maps to storageInboundCreateDraft.selectedProductsCasePacks */
  const selectedProductsCasePacks = keyBy(
    Object.values(productCasePacks).map((pack) => omit(pack, "productDetails")),
    "packOf"
  );

  /** we need the product details including all case pack options */
  /** maps to storageInboundCreateDraft.productDetails */
  const productDetails = await productClient
    .getUnifiedProducts(Object.values(caseSkuToProductSku), {
      includeProductCasePacks: true,
    })
    .catch((error) => {
      logError({ ...ctx, failedAt: "productClient.getUnifiedProducts" }, error);
      throw error;
    });

  /** maps to storageInboundCreateDraft.selectedProducts */
  const selectedProducts = casePackSkus.reduce<Record<string, StorageInboundProductItemDetails>>((map, caseSku) => {
    const bookingProduct = bookingProductsByCaseSku[caseSku];
    const productSku = caseSkuToProductSku[caseSku];
    const selectedCasePack = selectedProductsCasePacks[productSku];

    map[productSku] = {
      dsku: productSku,
      caseQty: selectedCasePack.quantity,
      qty: bookingProduct.qty,
      numberOfCases: Math.floor(bookingProduct.qty / selectedCasePack.quantity),
      productCasePackOptions: keyBy(productDetails[productSku].productCasePacks ?? [], "dsku"),
    };
    return map;
  }, {});

  /** maps to storageInboundCreateDraft.selectedProductsCaseDimensions */
  const selectedProductsCaseDimensions: CaseDimensionCollection = casePackSkus.reduce((map, caseSku) => {
    const dsku = caseSkuToProductSku[caseSku];
    const selectedCasePack = selectedProductsCasePacks[dsku];
    map[dsku] = pick(selectedCasePack, ["height", "width", "length", "weight", "lengthUnit", "weightUnit"]);
    return map;
  }, {});

  return {
    productDetails,
    selectedProducts,
    selectedProductsCasePacks,
    selectedProductsCaseDimensions,
  };
};
