import { defineMessages } from "react-intl";

export const ReturnsDetailTableHeader = defineMessages({
  productName: {
    id: "returns.returnsDetail.productName",
    defaultMessage: "Product Name",
  },
  quantity: {
    id: "returns.returnsDetail.returnsItemQuantity",
    defaultMessage: "Quantity",
  },
  inspectionStatus: {
    id: "returns.returnsDetail.returnsItemQcStatus",
    defaultMessage: "Inspection Status",
  },
  disposedAt: {
    id: "returns.returnsDetail.returnsItemDisposedAt",
    defaultMessage: "Disposed At",
  },
  returnReason: {
    id: "returns.returnsDetail.returnReason",
    defaultMessage: "Return Reason",
  },
  details: {
    id: "returns.returnsDetail.returnItemDetails",
    defaultMessage: "Details",
  },
});

export const ReturnsDetailTableContent = defineMessages({
  aToZClaim: {
    id: "returns.returnsDetail.detail.aToZClaim",
    defaultMessage: "A to Z Claim : {aToZClaim}",
  },
  safeTActionReason: {
    id: "returns.returnsDetail.detail.safeTActionReason",
    defaultMessage: "SafeT Action Reason : {safeTActionReason}",
  },
});
