import { useSelector } from "react-redux";
import { RootState } from "../../RootReducer";
import { useSellerInfo } from "../seller/useSellerInfo";
import { Disposition } from "@deliverr/returns-client";

export const usePackageRtmDetails = () => {
  const { packageDetail } = useSelector((state: RootState) => state.returnsPackageDetail);
  const { sellerInfo } = useSellerInfo();

  const rtmAddressFromDisposition = sellerInfo?.dispositions?.find((disposition) => disposition.rtvAddress)?.rtvAddress;

  const isRTMPackage = packageDetail?.boxDisposition === Disposition.RTV;

  const packageSourceAddress = packageDetail?.qcFacilityAddress;

  const packageDestinationAddress = isRTMPackage ? packageDetail?.shipment?.to ?? rtmAddressFromDisposition : undefined;

  const trackingCode = packageDetail?.shipment?.trackingCode;

  const carrier = packageDetail?.shipment?.carrier;

  const labelUrl = packageDetail?.shipment?.labelUrl;

  return {
    isRTMPackage,
    packageSourceAddress,
    packageDestinationAddress,
    trackingCode,
    carrier,
    labelUrl,
  };
};
