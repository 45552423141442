import { defineMessages } from "react-intl";

export const FbaV3ProductErrorLabels = defineMessages({
  caseDimensionsExceeded: {
    id: "fbaV3.productError.caseDimensionsExceeded",
    defaultMessage:
      "A box containing standard-size units cannot exceed {maxDimensionInInches} inch on any side. Please reach out to support for assistance.",
  },
  caseWeightExceeded: {
    id: "fbaV3.productError.caseWeightExceeded",
    defaultMessage:
      "A box containing standard-size units cannot exceed {maxWeightInLbs} lb. Please reach out to support for assistance.",
  },
  skuQuantityExceeded: {
    id: "fbaV3.productError.skuQuantityExceeded",
    defaultMessage: "The number of units of an SKU cannot exceed {maxUnitsOfSku} in an FBA inbound plan.",
  },
});
