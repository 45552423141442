import { SelectedProductData } from "common/components/ProductChooser";
import React, { FC } from "react";
import { FbaLotAndExpiryPicker } from "transfers/create/steps/fbaV3/lot-expiry-picker/FbaLotAndExpiryPicker";
import { SetPrepDetails } from "transfers/create/steps/fbaV3/prep-details/SetFbaPrepDetails";

export const CustomReplenishmentProductErrorHandlers: FC<{
  errorCode: string;
  product: SelectedProductData;
}> = ({ errorCode, product }) => {
  switch (errorCode) {
    case "FBA_INB_0182": // Amazon Prep classification missing error
      return <SetPrepDetails dsku={product.packOf ?? product.dsku} />;

    case "FBA_INB_0180": // Amazon Expiry date missing error
      return <FbaLotAndExpiryPicker dsku={product.dsku} />;

    default:
      return null;
  }
};
