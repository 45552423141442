import {
  QueryDslBoolQuery,
  QueryDslQueryContainer,
} from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { ReplenishmentListSearchFilters, ReplenishmentOrderListTabFilter } from "../store/ReplenishmentListState";
import { ReplenishmentDestinationType } from "../store/ReplenishmentDestinationType";
import { DistributionChannel, NonComplianceStatus, OrderStatus } from "@deliverr/replenishment-client";
import { ReplenishmentListStatus } from "../ReplenishmentListStatuses";
import { compact } from "lodash";
import {
  OpenSearchInvalidOrderItemStatus,
  ReplenishmentAmazonDistributionChannels,
  ReplenishmentEcomDistributionChannels,
  ReplenishmentWalmartDistributionChannels,
} from "../ReplenishmentListUtils";
import { escapeSpecialCharsOpenSearchQuery } from "common/StringUtils";
import { createAddressSearchQuery } from "common/components/AddressInput/fetchAddress";
import { OpenSearchOrderAttributeName } from "../ReplenishmentIndexRow";

export const constructReplenishmentListOpenSearchFilters = (
  searchTerm: string,
  searchFilters: ReplenishmentListSearchFilters
): QueryDslQueryContainer[] => {
  const escapedSearchTerm = escapeSpecialCharsOpenSearchQuery(searchTerm);
  const searchTermFilters = getRsOutboundsSearchTermFilters(escapedSearchTerm);
  const statusFilter = getOrderStatusFilter(searchFilters.orderStatuses, searchFilters.tabFilter);
  const destinationTypeFilter = getDestinationTypeFilter(searchFilters.destinationType);
  const tabFilter = getTabFilter(searchFilters.tabFilter);
  const mustNotFilters = getMustNotFilters();

  const filters = compact([destinationTypeFilter, statusFilter, tabFilter]);

  return [
    {
      bool: {
        ...searchTermFilters,
        filter: filters,
        must_not: mustNotFilters,
      },
    },
  ];
};

export const getRsOutboundsSearchTermFilters = (searchTerm: string): QueryDslBoolQuery => {
  if (!searchTerm) {
    return {};
  }

  return {
    should: [
      {
        query_string: {
          query: `orderId:*${searchTerm}* OR distributionChannelOrderId:*${searchTerm}* OR name:*${searchTerm}*`,
          analyze_wildcard: true,
        },
      },
      {
        nested: {
          path: "shipments",
          query: {
            query_string: {
              query: `shipments.distributionChannelShipmentId:*${searchTerm}* OR shipments.poNumber:*${searchTerm}* OR shipments.shipmentId:*${searchTerm}*`,
              analyze_wildcard: true,
            },
          },
        },
      },
      {
        nested: {
          path: "orderItems",
          query: {
            term: {
              "orderItems.dsku": `${searchTerm}`,
            },
          },
        },
      },
      {
        nested: {
          path: "orderItems",
          query: {
            term: {
              "orderItems.packOf": `${searchTerm}`,
            },
          },
        },
      },
      {
        nested: createAddressSearchQuery(searchTerm, "shipments.destinationAddress"),
      },
      {
        query_string: {
          query: `${Object.keys(OpenSearchOrderAttributeName)
            .map((key) => `orderAttributes.${key}:*${searchTerm}*`)
            .join(" OR ")}`,
          analyze_wildcard: true,
        },
      },
    ],
    minimum_should_match: 1,
  };
};

const getTabFilter = (tabFilter?: ReplenishmentOrderListTabFilter): QueryDslQueryContainer | null => {
  return tabFilter === ReplenishmentOrderListTabFilter.HAS_PROBLEM
    ? {
        bool: {
          should: [
            {
              term: {
                nonComplianceStatus: NonComplianceStatus.CREATED,
              },
            },
            {
              bool: {
                must: [
                  {
                    term: {
                      invalidOrderItemStatus: OpenSearchInvalidOrderItemStatus.ACTION_NEEDED,
                    },
                  },
                  {
                    term: {
                      status: OrderStatus.DRAFT,
                    },
                  },
                ],
              },
            },
          ],
          minimum_should_match: 1,
        },
      }
    : null;
};

const getDestinationTypeFilter = (destinationType: ReplenishmentDestinationType): QueryDslQueryContainer => {
  let distributionChannels: DistributionChannel[] = [];

  switch (destinationType) {
    case ReplenishmentDestinationType.FBA:
      distributionChannels = ReplenishmentAmazonDistributionChannels;
      break;
    case ReplenishmentDestinationType.WFS:
      distributionChannels = ReplenishmentWalmartDistributionChannels;
      break;
    case ReplenishmentDestinationType.FLEXPORT_ECOM:
      distributionChannels = ReplenishmentEcomDistributionChannels;
      break;
    case ReplenishmentDestinationType.ALL:
      distributionChannels = [
        ...ReplenishmentAmazonDistributionChannels,
        ...ReplenishmentEcomDistributionChannels,
        ...ReplenishmentWalmartDistributionChannels,
      ];
      break;
  }

  return {
    terms: {
      distributionChannel: distributionChannels,
    },
  };
};

const getOrderStatusFilter = (
  orderStatuses: ReplenishmentListStatus[],
  tabFilter: ReplenishmentOrderListTabFilter
): QueryDslQueryContainer | null => {
  if (tabFilter === ReplenishmentOrderListTabFilter.HAS_PROBLEM) {
    return null;
  }

  return {
    terms: {
      // CREATED status behaves exactly like DRAFT for UI purposes
      status: orderStatuses.includes(OrderStatus.DRAFT) ? [...orderStatuses, OrderStatus.CREATED] : orderStatuses,
    },
  };
};

const getMustNotFilters = (): QueryDslQueryContainer[] => [
  {
    term: {
      distributionChannel: DistributionChannel.WHOLESALE,
    },
  },
  {
    terms: {
      status: [OrderStatus.ARCHIVED, OrderStatus.ERROR],
    },
  },
];
