import React, { FC } from "react";
import cx from "classnames";

import cls from "./List.less";

export enum ListType {
  ol = "ol",
  ul = "ul",
}

interface OwnProps extends React.OlHTMLAttributes<HTMLOListElement | HTMLUListElement> {
  as?: ListType;
  items: (string | JSX.Element)[];
  narrow?: boolean;
}

export const List: FC<OwnProps> = ({ as: As = ListType.ul, className, items, narrow }) => (
  <As
    className={cx(
      {
        [ListType.ul]: cls.ul,
        [ListType.ol]: cls.ol,
      },
      cls.list,
      className
    )}
  >
    {items.map((item, key) => (
      <li className={cx(cls.listItem, { [cls.narrowItem]: narrow })} key={key}>
        {item}
      </li>
    ))}
  </As>
);
