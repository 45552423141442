import { LabelLanguage } from "common/clients/inbound/LabelLanguage";
import { defineMessages, MessageDescriptor } from "react-intl";

export const LANGUAGE_DOWNLOAD_OPTIONS_TITLES: Record<LabelLanguage, MessageDescriptor> = defineMessages({
  [LabelLanguage.ENGLISH]: {
    id: "ShipmentHeader.languageDropdown.englishLabelNative",
    defaultMessage: "English (US)",
  },
  [LabelLanguage.MANDARIN]: {
    id: "ShipmentHeader.languageDropdown.mandarinLabelNative",
    defaultMessage: "简体中文",
  },
  [LabelLanguage.SPANISH]: {
    id: "ShipmentHeader.languageDropdown.spanishLabelNative",
    defaultMessage: "Español",
  },
});

export const LANGUAGE_DOWNLOAD_OPTIONS_SUBTITLES: Record<LabelLanguage, MessageDescriptor> = defineMessages({
  [LabelLanguage.ENGLISH]: {
    id: "ShipmentHeader.languageDropdown.englishLabelEnglish",
    defaultMessage: "English (US)",
  },
  [LabelLanguage.MANDARIN]: {
    id: "ShipmentHeader.languageDropdown.mandarinLabelEnglish",
    defaultMessage: "Chinese, Simplified",
  },
  [LabelLanguage.SPANISH]: {
    id: "ShipmentHeader.languageDropdown.spanishLabelEnglish",
    defaultMessage: "Spanish",
  },
});
