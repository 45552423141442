import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { EntityError, OperationStatus } from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { FbaV3Loaders } from "../../commons/FbaV3Constants";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { replenishmentClient } from "Clients";
import { setPlacementOptions, setPlacementOptionsError } from "./PlacementOptionsActions";
import { onPlacementOptionsLoadSuccess } from "./onPlacementOptionsLoadSuccess";
import { getFlattenedEntityErrors } from "../../commons/FbaV3Utils";

export const getPlacementOptions = (): SPThunkAction => async (dispatch, getState) => {
  dispatch(addLoader(FbaV3Loaders.FETCH_PLACEMENT_OPTIONS));

  try {
    const { replenishmentOrder } = selectTransferCreate(getState());
    const { value: placementOptionsResponse } = await replenishmentClient.getPlacementOptions(
      replenishmentOrder!.orderId
    );

    if (placementOptionsResponse.placement.operationSummary.operationStatus === OperationStatus.FAILED) {
      dispatch(
        setPlacementOptionsError(
          getFlattenedEntityErrors(placementOptionsResponse.placement.operationSummary.operationErrors),
          OperationStatus.FAILED,
          placementOptionsResponse.placement.operationSummary.operationId
        )
      );
    } else {
      dispatch(
        setPlacementOptions(
          placementOptionsResponse.placement.placementOptions,
          placementOptionsResponse.placement.operationSummary.operationStatus,
          placementOptionsResponse.placement.operationSummary.operationId
        )
      );

      if (placementOptionsResponse.placement.operationSummary.operationStatus === OperationStatus.SUCCESS) {
        onPlacementOptionsLoadSuccess(placementOptionsResponse.placement.placementOptions, dispatch, getState);
      }
    }
  } catch (err) {
    const error = err?.response?.data?.error ?? err;
    logError({ fn: "getPackingOptions" }, err, "Failed to fetch packing options");

    error instanceof EntityError
      ? dispatch(
          setPlacementOptionsError(
            getFlattenedEntityErrors([error]),
            OperationStatus.FAILED,
            error?.payload?.operationId
          )
        )
      : dispatch(
          setPlacementOptionsError(
            [
              new EntityError({
                message: error?.message,
                code: error?.code,
              }),
            ],
            OperationStatus.FAILED,
            undefined
          )
        );
  } finally {
    dispatch(clearLoader(FbaV3Loaders.FETCH_PLACEMENT_OPTIONS));
  }
};
