import { DeliverrAddress } from "@deliverr/commons-objects";

import { inboundClient } from "Clients";
import { notifyUserOfError } from "common/ErrorToast";
import { createActionCreator, SPThunkAction } from "common/ReduxUtils";
import { dispatchThenSaveInbound } from "inbounds/steps/InboundSaveActions";
import log, { logError, logStart, logSuccess } from "Logger";
import { withLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { ShippingPlanAddressType } from "@deliverr/inbound-client";
import { parseAddress } from "inbounds/utils/parseAddress";

export enum AddressActionTypes {
  COPY_ADDRESS_TO_ALL_SHIPMENTS = "COPY_ADDRESS_TO_ALL_SHIPMENTS",
  SET_FROM_ADDRESS = "SET_FROM_ADDRESS",
  GET_ADDRESSES = "GET_ADDRESSES",
}

export const setFromAddress = dispatchThenSaveInbound(
  createActionCreator<DeliverrAddress>(AddressActionTypes.SET_FROM_ADDRESS, "fromAddress")
);

export const setShippingPlanFromAddress = (): SPThunkAction => async (_, getState) => {
  const {
    inbound: { fromAddress, plan, externalShippingPlanId },
    user: { sellerId },
  } = getState();
  const ctx = logStart({ fn: "setShippingPlanFromAddress", fromAddress, externalShippingPlanId });

  try {
    await inboundClient.updateShippingPlan(sellerId, plan.id, {
      fromAddress: parseAddress(fromAddress),
      externalShippingPlanId,
    });
  } catch (err) {
    logError(ctx, err);
    notifyUserOfError({
      err,
      explanation: "Oops, there was a problem updating your shipping address",
      toastId: "updateShippingAddressError",
    });
    throw err;
  }
};

export const copyAddressToAllShipments = dispatchThenSaveInbound(
  createActionCreator(AddressActionTypes.COPY_ADDRESS_TO_ALL_SHIPMENTS)
);

const getMostFrequentlyUsedAddressesWithoutLoader =
  (addressType: ShippingPlanAddressType): SPThunkAction =>
  async (dispatch, getState) => {
    const ctx = logStart({ fn: "getMostFrequentlyUsedAddresses" });
    log.info(ctx, "getting addresses");
    const {
      user: { sellerId },
      inbound: { addresses: existingAddresses, addressesFilter },
    } = getState();

    if (existingAddresses.length > 0 && addressesFilter === addressType) {
      logSuccess(ctx, "addresses already fetched");
      return;
    }

    try {
      dispatch({
        type: AddressActionTypes.GET_ADDRESSES,
        addresses: await inboundClient.getMostFrequentlyUsedAddresses(sellerId, addressType),
        addressesFilter: addressType,
      });
      logSuccess(ctx, "successfully fetched addresses");
    } catch (err) {
      logError(ctx, err, "error getting addresses");
    }
  };

export const getMostFrequentlyUsedAddresses = withLoader(
  InboundLoaderId.fromAddressSelect,
  getMostFrequentlyUsedAddressesWithoutLoader
);
