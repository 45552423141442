import { ReducerDictionary, createReducer } from "common/ReduxUtils";
import { EntityError, OperationStatus } from "@deliverr/replenishment-client";
import { OrderConfirmationActionTypes } from "./OrderConfirmationActionTypes";

export interface OrderConfirmationState {
  orderConfirmationOperationStatus: OperationStatus;
  orderConfirmationOperationId?: number;
  orderConfirmationErrors: EntityError[];
}

export const orderConfirmationInitialState: OrderConfirmationState = {
  orderConfirmationOperationStatus: OperationStatus.CREATED,
  orderConfirmationOperationId: undefined,
  orderConfirmationErrors: [],
};

const reducers: ReducerDictionary<OrderConfirmationState> = {
  [OrderConfirmationActionTypes.ORDER_CONFIRMATION_SUCCESS]: (_state, action) => ({
    orderConfirmationOperationStatus: action.operationStatus,
    orderConfirmationOperationId: action.operationId,
    orderConfirmationErrors: [],
  }),
  [OrderConfirmationActionTypes.ORDER_CONFIRMATION_ERROR]: (_state, action) => ({
    orderConfirmationOperationStatus: action.operationStatus,
    orderConfirmationOperationId: action.operationId,
    orderConfirmationErrors: action.errors,
  }),
  [OrderConfirmationActionTypes.ORDER_CONFIRMATION_RESET_STATE]: () => orderConfirmationInitialState,
};

export const orderConfirmationReducer = createReducer<OrderConfirmationState>(orderConfirmationInitialState, reducers);
