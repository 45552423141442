export interface PermissionLevel {
  read: boolean;
  write: boolean;
  mail: boolean;
}

export enum Domain {
  INBOUNDS = "INBOUNDS",
  NON_COMPLIANCE = "NON_COMPLIANCE",
  CLAIMS = "CLAIMS",
  ORDERS = "ORDERS",
  INVENTORY = "INVENTORY",
  REPLENISHMENT = "REPLENISHMENT",
  WHOLESALE = "WHOLESALE",
  RETURNS = "RETURNS",
  PREP = "PREP",
  PARCEL = "PARCEL",
  FREIGHT = "FREIGHT",
  BILLING = "BILLING",
  CHANNELS = "CHANNELS",
  PRODUCT = "PRODUCT",
  REPORTS = "REPORTS",
  FAST_TAGS = "FAST_TAGS",
  INTEGRATIONS = "INTEGRATIONS",
  SETTINGS = "SETTINGS",
}

export type UserScopeMapping = {
  [key in Domain]: PermissionLevel;
};
