import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormGroup, Input, Icon, defaultTheme } from "common/components/ui";
import { InfoIcon } from "common/components/icons";

interface ExternalShippingPlanIdInputProps {
  externalShippingPlanId?: string;
  onChange: (value: string) => void;
}

export const ExternalShippingPlanIdInput = ({ externalShippingPlanId, onChange }: ExternalShippingPlanIdInputProps) => {
  const { formatMessage } = useIntl();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value.trim());
  };

  const label = (
    <>
      <FormattedMessage id="inbounds.externalShippingPlanId.label" defaultMessage="External Shipping Plan ID" />
      <InfoIcon
        messageWidth={200}
        wrap
        placement={"top"}
        message={
          <FormattedMessage
            id="inbounds.externalShippingPlanId.tooltip"
            defaultMessage="Enter your external shipping plan ID for reference. This will be saved with your shipping plan."
          />
        }
      >
        <Icon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[400]} />
      </InfoIcon>
    </>
  );

  return (
    <FormGroup label={label}>
      <Input
        value={externalShippingPlanId}
        onChange={handleChange}
        placeholder={formatMessage({
          id: "inbounds.externalShippingPlanId.placeholder",
          defaultMessage: "Enter external shipping plan ID",
        })}
      />
    </FormGroup>
  );
};
