import { SelectedProductData } from "common/components/ProductChooser";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { getFbaV3ProductError } from "./fbaV3/productErrors/fbaValidations";
import { useIntl } from "react-intl";

export const getErrorForProductFn = (
  product: SelectedProductData,
  formatMessage: ReturnType<typeof useIntl>["formatMessage"],
  distributionChannel: DistributionChannel
): string | undefined => {
  switch (distributionChannel) {
    case DistributionChannel.FBA_V3:
    case DistributionChannel.FBA_MANUAL:
      return getFbaV3ProductError(product, formatMessage);
    default:
      return undefined;
  }
};
