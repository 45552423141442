import { SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest } from "../SearchService";
import { OpenSearchService } from "common/search/services/OpenSearchService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";

export class NonComplianceSearchService extends OpenSearchService {
  private searchableAttributes = ["issueId", "shipmentId", "sellerAsnLabel", "dsku", "affectedCdskus"];

  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const searchOptions = super.buildSearchOptions(request);

    const shouldQueries: QueryDslQueryContainer[] = [];
    if (request.searchTerm) {
      const searchTerm: string = request.searchTerm;
      shouldQueries.push({
        multi_match: {
          query: `${searchTerm}`,
          type: "bool_prefix",
          fields: this.searchableAttributes,
          operator: "and",
        },
      });
    }

    const additionalSearchOptions: Partial<SearchOptions> = {
      query: {
        bool: {
          ...(searchOptions?.query?.bool ?? {}),
          should: shouldQueries,
          filter: request.customizedOpenSearchFilters,
          minimum_should_match: shouldQueries.length > 0 ? 1 : 0,
        },
      },
    };

    return {
      ...searchOptions,
      ...additionalSearchOptions,
    };
  }
}
