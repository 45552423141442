import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { getStorageInboundDetail } from "../../../store";
import {
  FLEXPORT_CARRIER_CODE,
  FLEXPORT_TRACKING_PREFIX,
} from "inbounds/steps/ship/view/FlexportAutomatedTracking/constants";
import { getClosestWeekdayDate } from "common/date/getClosestWeekdayDate";

import { freightApiAdapter, storageClient } from "common/clients/instances";
import React, { useEffect } from "react";
import { useMount } from "react-use";
import { FreightTrackingInfo } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfo";
import { toast } from "common/components/ui";
import { FreightTrackingInfoExternalIdType } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoExternalIdType";
import { FreightTrackingInfoSaveData } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoSaveData";
import { isEqual } from "date-fns/fp";
import { validateFlexportID } from "common/FlexportValidationUtil";
import isEmail from "validator/lib/isEmail";
import { setStorageCarrierEmailAddress } from "storage/inbounds/detail/store/actions/setStorageCarrierEmailAddress";

export const useSendAndTrackShipmentDetail = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { storageInboundOrder } = useSelector(getStorageInboundDetail);
  const [initialFreightInfo, setInitialFreightInfo] = React.useState<FreightTrackingInfo | undefined>();
  const [freightInfo, setFreightInfo] = React.useState<FreightTrackingInfo | undefined>();
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);
  const [isFlexport, setIsFlexport] = React.useState<boolean>(false);
  const [containerId, setContainerId] = React.useState<string | undefined>(storageInboundOrder.containerId);

  const [isCarrierEmailValid, setIsCarrierEmailValid] = React.useState<boolean>(true);
  const [carrierEmail, setCarrierEmail] = React.useState<string | undefined>(storageInboundOrder?.carrierEmailAddress);
  const [isError, setIsError] = React.useState<boolean>(false);

  useMount(async () => {
    if (storageInboundOrder.sellerId) {
      await loadFreightTrackingInfo();
    }
  });

  const updateContainerId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContainerId(event.target.value);
  };

  const loadFreightTrackingInfo = async () => {
    const fullFreightInfo = await freightApiAdapter.getFreightTrackingInfo(
      storageInboundOrder.sellerId,
      String(storageInboundOrder.shipmentId),
      FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT
    );
    setInitialFreightInfo({
      ...fullFreightInfo,
      estimatedShipDate: fullFreightInfo?.estimatedShipDate ? new Date(fullFreightInfo?.estimatedShipDate) : undefined,
    } as FreightTrackingInfo);
    setFreightInfo({
      ...fullFreightInfo,
      estimatedShipDate: fullFreightInfo?.estimatedShipDate ? new Date(fullFreightInfo?.estimatedShipDate) : undefined,
    } as FreightTrackingInfo);
    setIsFlexport(fullFreightInfo?.shippingProvider === FLEXPORT_CARRIER_CODE);
  };

  useEffect(() => {
    if (isError) {
      setIsSubmitDisabled(true);
    } else if (
      (initialFreightInfo?.bolId ?? "") !== (freightInfo?.bolId ?? "") ||
      (initialFreightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : "")) !==
        (freightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : "")) ||
      initialFreightInfo?.shippingProvider !== freightInfo?.shippingProvider ||
      checkIfEstimatedDateChanged() ||
      storageInboundOrder.containerId !== containerId ||
      carrierEmail !== storageInboundOrder.carrierEmailAddress ||
      isError
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [freightInfo, containerId, carrierEmail, isError]);

  const checkIfEstimatedDateChanged = () => {
    return (
      !(
        initialFreightInfo?.estimatedShipDate &&
        freightInfo?.estimatedShipDate &&
        isEqual(initialFreightInfo?.estimatedShipDate, freightInfo?.estimatedShipDate)
      ) && !!(initialFreightInfo?.estimatedShipDate ?? freightInfo?.estimatedShipDate)
    );
  };

  const setProId = (val: string) => {
    if (isFlexport) {
      const validated = validateFlexportID(val);
      if (validated) {
        setFreightInfo({
          ...freightInfo,
          proId: validated,
        } as FreightTrackingInfo);
      }
    } else {
      setFreightInfo({
        ...freightInfo,
        proId: val,
      } as FreightTrackingInfo);
    }
  };

  const setBolId = (val: string) => {
    setFreightInfo({
      ...freightInfo,
      bolId: val,
    } as FreightTrackingInfo);
  };

  const onDateChange = (date: Date) => {
    setFreightInfo({
      ...freightInfo,
      estimatedShipDate: date,
    } as FreightTrackingInfo);
  };

  const handleCarrierSelectionChange = (option: string) => {
    setFreightInfo({
      ...freightInfo,
      shippingProvider: option,
    } as FreightTrackingInfo);
    setIsFlexport(option === FLEXPORT_CARRIER_CODE);
  };

  const minDate = getClosestWeekdayDate();

  const bolIdPlaceholder = formatMessage({
    id: "storage.inbounds.detail.sendAndTrackShipment.bolIdPlaceholder",
    defaultMessage: "Input your BOL #",
  });

  const proIdPlaceholder = formatMessage({
    id: "storage.inbounds.detail.sendAndTrackShipment.proIdPlaceholder",
    defaultMessage: "Input your PRO #",
  });

  const containerIdPlaceholder = formatMessage({
    id: "storage.inbounds.detail.sendAndTrackShipment.containerIdPlaceholder",
    defaultMessage: "Input your Container Id",
  });

  const submitButtonMessage = formatMessage({
    id: "inbounds.shipmentInstructionStep.freight.trackShipment.submitButtonLabel",
    defaultMessage: "Confirmed",
  });

  const getFreightTrackingInfoToBeSaved = () => {
    let freightTrackingInfoSaveData: FreightTrackingInfoSaveData = {
      externalId: String(storageInboundOrder.shipmentId),
      externalIdType: FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT,
    };
    if ((initialFreightInfo?.bolId ?? "") !== (freightInfo?.bolId ?? "")) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        bolId: freightInfo?.bolId,
      };
    }
    if (
      (initialFreightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : "")) !==
      (freightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : ""))
    ) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        proId: freightInfo?.proId ?? "",
      };
    }
    if ((initialFreightInfo?.shippingProvider ?? "") !== (freightInfo?.shippingProvider ?? "")) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        shippingProvider: freightInfo?.shippingProvider ?? "",
      };
    }
    if (checkIfEstimatedDateChanged()) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        estimatedShipDate: freightInfo?.estimatedShipDate,
      };
    }
    return freightTrackingInfoSaveData;
  };

  const onSubmit = async () => {
    const freightTrackingInfoSaveData: FreightTrackingInfoSaveData = getFreightTrackingInfoToBeSaved();

    try {
      await freightApiAdapter.updateFreightTrackingInfo(storageInboundOrder.sellerId, freightTrackingInfoSaveData);
      if (containerId !== storageInboundOrder.containerId) {
        await storageClient.patchStorageInbound(storageInboundOrder.sellerId, storageInboundOrder.id, { containerId });
      }
      await loadFreightTrackingInfo();
      setIsSubmitDisabled(true);
      toast.success(
        formatMessage({
          id: "storage.inbounds.details.sendAndTrackShipment.success",
          defaultMessage: "Shipment details updated successfully.",
        })
      );
    } catch (err) {
      toast.error(
        formatMessage({
          id: "storage.inbounds.details.sendAndTrackShipment.error",
          defaultMessage: "Failed to update shipment details",
        })
      );
    }
  };

  const validateEmail = (inputEmail: string) => {
    if (isEmail(inputEmail)) {
      setIsCarrierEmailValid(true);
      setIsSubmitDisabled(false);
    } else {
      setIsCarrierEmailValid(false);
      setIsSubmitDisabled(true);
    }
  };

  const handleBlur = () => {
    if (!isCarrierEmailValid) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const handleFocus = () => {
    setIsError(false);
  };

  const updateDeliveryAppointmentDetails = async () => {
    try {
      if (carrierEmail) {
        await storageClient.requestForInboundAppointment({ carrierEmailAddress: carrierEmail }, storageInboundOrder.id);

        setCarrierEmail(carrierEmail ?? "");
        await dispatch(setStorageCarrierEmailAddress(carrierEmail));
        toast.success(
          formatMessage({
            id: "storage.inbounds.details.schedule.appointment.success",
            defaultMessage: "Delivery appointment details updated successfully",
          })
        );
      }
      if (initialFreightInfo?.shippingProvider !== freightInfo?.shippingProvider) {
        const freightTrackingInfoSaveData: FreightTrackingInfoSaveData = getFreightTrackingInfoToBeSaved();
        await freightApiAdapter.updateFreightTrackingInfo(storageInboundOrder.sellerId, freightTrackingInfoSaveData);
        await loadFreightTrackingInfo();
      }
      setIsSubmitDisabled(true);
    } catch (e) {
      toast.error(
        formatMessage({
          id: "storage.inbounds.details.schedule.appointment.error",
          defaultMessage: "Failed to update delivery appointment details",
        })
      );
    }
  };

  const emailInputPlaceHolder = formatMessage({
    id: "storage.inbounds.detail.storage.inbounds.detail.emailInputPlaceholder",
    defaultMessage: "Enter email",
  });

  const onCarrierEmailChange = (e) => {
    setCarrierEmail(e.target.value);
    validateEmail(e.target.value);
  };

  return {
    bolId: freightInfo?.bolId,
    setBolId,
    proId: freightInfo?.proId ?? undefined,
    setProId,
    bolIdPlaceholder,
    proIdPlaceholder,
    containerIdPlaceholder,
    submitDisabled: isSubmitDisabled,
    onSubmit,
    date: freightInfo?.estimatedShipDate,
    onDateChange,
    minDate,
    isFlexport,
    submitButtonMessage,
    carrier: freightInfo?.shippingProvider,
    handleSelectionChange: handleCarrierSelectionChange,
    containerId,
    updateContainerId,
    updateDeliveryAppointmentDetails,
    carrierEmail,
    emailInputPlaceHolder,
    onCarrierEmailChange,
    handleBlur,
    isError,
    handleFocus,
  };
};
