import { MessageDescriptor, defineMessages } from "react-intl";

import { PillColors } from "common/components/ui";
import { QcItemStatus } from "@deliverr/returns-client";

export const enum IssueType {
  UNKNOWN_BARCODE = "UNKNOWN_BARCODE",
  UNEXPECTED_PRODUCT = "UNEXPECTED_PRODUCT",
}

export const returnsInspectionStatusPillColor: Record<QcItemStatus | string, PillColors> = {
  [QcItemStatus.INSPECTION_APPROVED]: PillColors.GREEN,
  [QcItemStatus.INSPECTION_REJECTED]: PillColors.YELLOW,
  [QcItemStatus.LOST_BY_CARRIER]: PillColors.RED,
  [QcItemStatus.LOST_DURING_INSPECTION]: PillColors.RED,
  AWAITING: PillColors.BLUE,
  DESTROYED: PillColors.YELLOW,
  DISPOSED: PillColors.YELLOW,
  DONATED: PillColors.YELLOW,
  NOT_RECEIVED: PillColors.YELLOW,
  RETURN_TO_SELLER: PillColors.GREEN,
};

const approvedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.approved",
  defaultMessage: "RESTOCKED",
};

const rejectedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.rejected",
  defaultMessage: "REJECTED",
};

const donatedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.donated",
  defaultMessage: "DONATED",
};

const destroyedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.destroyed",
  defaultMessage: "TO BE DISPOSED",
};

const disposedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.disposed",
  defaultMessage: "DISPOSED",
};

const liquidatedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.liquidated",
  defaultMessage: "LIQUIDATED",
};

const returnToSellerMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.returnToSeller",
  defaultMessage: "RETURN TO SELLER",
};

const lostMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.lost",
  defaultMessage: "LOST",
};

const awaitingMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.awaiting",
  defaultMessage: "AWAITING",
};

const notReceivedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.notReceived",
  defaultMessage: "NOT RECEIVED",
};

const unexpectedItemMessage = defineMessages({
  issueMessage: {
    id: "returns.returnsDetail.unexpectedItemMessage",
    defaultMessage: "Unexpected product received",
  },
});

const unknownProductMessages = defineMessages({
  displayProductName: {
    id: "returns.returnsDetail.issue.unknownProduct.title",
    defaultMessage: "Unknown Product",
  },
  issueMessage: {
    id: "returns.returnsDetail.issue.unknownProduct.message",
    defaultMessage: "Unexpected product received.",
  },
});

const defaultStatusMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.default",
  defaultMessage: "UNKNOWN_STATUS",
};

export const returnsDetailIssueMessages: Record<IssueType, Record<string, MessageDescriptor>> = {
  [IssueType.UNKNOWN_BARCODE]: unknownProductMessages,
  [IssueType.UNEXPECTED_PRODUCT]: unexpectedItemMessage,
};

export const inspectionStatusTitles: Record<QcItemStatus | string, MessageDescriptor> = {
  [QcItemStatus.INSPECTION_APPROVED]: approvedMessage,
  [QcItemStatus.INSPECTION_REJECTED]: rejectedMessage,
  [QcItemStatus.LOST_BY_CARRIER]: lostMessage,
  [QcItemStatus.LOST_DURING_INSPECTION]: lostMessage,
  AWAITING: awaitingMessage,
  DESTROYED: destroyedMessage,
  DONATED: donatedMessage,
  LIQUIDATED: liquidatedMessage,
  RETURN_TO_SELLER: returnToSellerMessage,
  NOT_RECEIVED: notReceivedMessage,
  [QcItemStatus.DISPOSED]: disposedMessage,
  DEFAULT: defaultStatusMessage,
};

export const returnsDetailNotesMessages = defineMessages({
  EXPIRED: { id: "warehouse.receiving.cannedNotes.expired", defaultMessage: "Expired" },
  BROKEN_SEAL: { id: "warehouse.receiving.cannedNotes.brokenSeal", defaultMessage: "Open/Broken factory seal " },
  NO_PACKAGING: { id: "warehouse.receiving.cannedNotes.noPackaging", defaultMessage: "No packaging" },
  DAMAGED_PACKAGING: {
    id: "warehouse.receiving.cannedNotes.damagedPackaging",
    defaultMessage: "Major Damage, packaging  ",
  },
  MISSING_COMPONENTS: { id: "warehouse.receiving.cannedNotes.missingComponents", defaultMessage: "Missing components" },
  DAMAGED_UNIT: { id: "warehouse.receiving.cannedNotes.damagedUnit", defaultMessage: "Major Damage, unit   " },
  LIGHTLY_USED: { id: "warehouse.receiving.cannedNotes.lightlyUsed", defaultMessage: "Unit lightly used" },
  HEAVILY_USED: { id: "warehouse.receiving.cannedNotes.heavilyUsed", defaultMessage: "Unit heavily Used" },
  UNKNOWN_STAIN: { id: "warehouse.receiving.cannedNotes.unknownStain", defaultMessage: "Unknown stain" },
  UNKNOWN_SMELL: { id: "warehouse.receiving.cannedNotes.unknownSmell", defaultMessage: "Unknown smell" },
  EMPTY_PACKAGE: { id: "warehouse.receiving.cannedNotes.emptyPackage", defaultMessage: "Empty package" },
  OTHER: { id: "warehouse.receiving.cannedNotes.other", defaultMessage: "Other" },
});
