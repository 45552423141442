import { DefaultTheme } from "common/components/ui";
import { Text } from "common/components/ui/Text";
import styled from "@emotion/styled";
import React, { FC, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useClickAway } from "react-use";
import { signOut } from "common/utils/SSO";
import { Path } from "paths";
import { BorderlessButton } from "../BorderlessButton";
import { HistoryLink } from "../HistoryLink";
import { NavBarButton } from "../NavBarButton";
import { User } from "common/clients/seller/User/User";

export interface AccountMenuProps {
  user: User | undefined;
}

const ARROW_SIZE = "12px";

const MenuContainer = styled.div`
  position: relative;
  z-index: 1000;
`;

const MenuArrow = styled.div`
  position: absolute;
  display: block;
  top: 34px;
  right: 8px;
  height: 0;
  width: 0;
  border-left: ${ARROW_SIZE} solid transparent;
  border-right: ${ARROW_SIZE} solid transparent;
  border-bottom: ${ARROW_SIZE} solid rgba(0, 0, 0, 0.2);

  &:after {
    position: absolute;
    top: 1px;
    margin-left: -${ARROW_SIZE};
    width: 0;
    height: 0;
    content: " ";
    border-left: ${ARROW_SIZE} solid transparent;
    border-right: ${ARROW_SIZE} solid transparent;
    border-bottom: ${ARROW_SIZE} solid white;
    z-index: 1;
  }
`;

const Menu = styled.ul`
  position: absolute;
  padding: 0;
  top: 34px;
  right: -10px;
  margin-top: 10px;
  margin-bottom: 0;
  background: white;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const MenuSpace = styled.div`
  height: 3px;
`;

const MenuItem = styled.div<{ divider?: boolean }, DefaultTheme>(
  ({ theme, divider }) => `
    display: flex;
    flex-direction: column;
    text-align: left;
    color: black;
    padding: ${theme.spacing.S2} ${theme.spacing.S4};
    min-width: 150px;
    border-top: ${divider ? `1px solid ${theme.colors.NEUTRAL["80"]}` : "none"};

    &:nth-child(3) {
      cursor: pointer;
    }

    &:hover {
      background-color: ${theme.colors.NEUTRAL["40"]};
    }
  `
);

export const AccountMenu: FC<AccountMenuProps> = ({ user }) => {
  const { formatMessage } = useIntl();
  const [isProfileMenuShown, setIsProfileMenuShown] = useState<boolean>(false);
  const menuRef = useRef(null);

  const openMenu = () => setIsProfileMenuShown(true);
  const closeMenu = () => setIsProfileMenuShown(false);

  useClickAway(menuRef, () => {
    closeMenu();
  });

  return (
    <>
      <NavBarButton
        role="button"
        id="profile"
        onClick={openMenu}
        aria-haspopup="true"
        aria-controls="profile-menu"
        aria-expanded={isProfileMenuShown}
        aria-label={formatMessage({ id: "navbar.openAccountMenu", defaultMessage: "Open account menu" })}
        data-testid="profile"
        iconType="user-circle"
      />
      <div ref={menuRef} id="profile-menu" aria-labelledby="profile" data-testid="profile-menu">
        {isProfileMenuShown && (
          <MenuContainer>
            <MenuArrow />
            <Menu>
              <MenuSpace />
              {user ? (
                <HistoryLink href={Path.settingsUsersList}>
                  <MenuItem>
                    <Text as="span" bold>
                      {user.email}
                    </Text>
                    {user.firstName && <span>{`${user.firstName ?? ""} ${user.lastName ?? ""}`}</span>}
                  </MenuItem>
                </HistoryLink>
              ) : null}
              <HistoryLink href={Path.settings}>
                <MenuItem>
                  <FormattedMessage id="navbar.settings" defaultMessage="Settings" />
                </MenuItem>
              </HistoryLink>
              <BorderlessButton onClick={signOut}>
                <MenuItem divider>
                  <FormattedMessage id="navbar.logout" defaultMessage="Log out" />
                </MenuItem>
              </BorderlessButton>
            </Menu>
          </MenuContainer>
        )}
      </div>
    </>
  );
};
