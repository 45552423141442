import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { toast } from "common/components/ui";
import { RootState } from "RootReducer";
import { getMostFrequentlyUsedAddresses, setFromAddress } from "../AddressActions";
import { setExternalShippingPlanId } from "inbounds/InboundActions";
import { InboundAddressErrorToast } from "./InboundAddressErrorToast";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { addressRequiredFieldsValidation } from "common/AddressUtils";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
import { ShippingPlanAddressType } from "@deliverr/inbound-client";
import { convertAddressToDeliverrAddressType } from "inbounds/createShipment/store/utils";

interface Options {
  addressType?: ShippingPlanAddressType;
}

export const useInboundAddressForm = ({ addressType }: Options = {}) => {
  const dispatch = useDispatch();

  const { addresses, fromAddress, externalShippingPlanId } = useSelector(({ inbound }: RootState) => ({
    addresses: inbound.addresses,
    fromAddress: inbound.fromAddress,
    externalShippingPlanId: inbound.externalShippingPlanId,
  }));

  const isSelectLoading = useSelector(selectAnyIsLoading)(InboundLoaderId.fromAddressSelect);

  useEffect(() => {
    dispatch(getMostFrequentlyUsedAddresses(addressType));
  }, [dispatch, addressType]);

  const validate = () => {
    const requiredFields = addressRequiredFieldsValidation(fromAddress);
    if (requiredFields.length) {
      toast.error(<InboundAddressErrorToast requiredFields={requiredFields} />, {
        autoClose: 5000,
        toastId: "inbounds.fromAddress.requiredFields",
      });
      return false;
    }
    return true;
  };

  const handleAddressSelectChange = ({ value }) => {
    dispatch(setFromAddress(convertAddressToDeliverrAddressType(value)));
  };

  const handleAddressInputChange = (address: DeliverrAddress) => {
    dispatch(setFromAddress(convertAddressToDeliverrAddressType(address)));
  };

  const handleExternalShippingPlanIdChange = (value: string) => {
    dispatch(setExternalShippingPlanId(value || undefined));
  };

  return {
    addresses,
    isSelectLoading,
    fromAddress,
    externalShippingPlanId,
    handleAddressSelectChange,
    handleAddressInputChange,
    handleExternalShippingPlanIdChange,
    validate,
  };
};
