import { FBAPrepCategory, FBAPrepType } from "@deliverr/replenishment-client";
import { defineMessages } from "react-intl";

export const FbaPrepDetailsLabels = defineMessages({
  setPrepDetailsButtonText: {
    id: "fbaV3.prepDetails.setPrepDetailsButtonText",
    defaultMessage: "Set prep details in Amazon",
  },
  setPrepDetailsFormTitle: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.title",
    defaultMessage: "Set Prep Details in Amazon",
  },
  setPrepDetailsCategoryTitle: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.prepCategoryTitle",
    defaultMessage: "Set Item Prep Category",
  },
  setPrepDetailsCategoryDescription: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.prepCategoryDescription",
    defaultMessage: "The preparation category for shipping an item to Amazon's fulfillment network.",
  },
  setPrepDetailsTypeTitle: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.prepTypeTitle",
    defaultMessage: "Set Item Prep Type",
  },
  setPrepDetailsTypeDescription: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.prepTypeDescription",
    defaultMessage:
      "Preparation instructions for shipping an item to Amazon's fulfillment network. For more information about preparing items for shipment to Amazon's fulfillment network, refer to Amazon Seller Central Help for your marketplace.",
  },
  setPrepDetailsFormSubmitText: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.submitButtonText",
    defaultMessage: "Submit",
  },
  setPrepDetailsFormCancelText: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.cancelButtonText",
    defaultMessage: "Cancel",
  },
  setPrepDetailsSubmitError: {
    id: "fbaV3.prepDetails.setPrepDetailsButton.submitError",
    defaultMessage: "Failed to set prep details in Amazon",
  },
  setPrepDetailsSubmitSuccess: {
    id: "fbaV3.prepDetails.setPrepDetailsButton.submitSuccess",
    defaultMessage: "Successfully set prep details in Amazon",
  },
  prepCategorySelectPlaceholder: {
    id: "fbaV3.prepDetails.prepCategorySelect.placeholder",
    defaultMessage: "Select Prep category",
  },
  prepTypeSelectPlaceholder: {
    id: "fbaV3.prepDetails.prepTypeSelect.placeholder",
    defaultMessage: "Select Prep types",
  },
  setPrepDetailsFormErrorTitle: {
    id: "fbaV3.prepDetails.setPrepDetailsForm.errorTitle",
    defaultMessage: "Please correct the following errors:",
  },
  prepCategoryLabels: defineMessages<FBAPrepCategory, any>({
    [FBAPrepCategory.ADULT]: {
      id: "fbaV3.prepDetails.prepCategory.adult",
      defaultMessage: "Displays potentially offensive material such as profanity or nudity.",
    },
    [FBAPrepCategory.BABY]: {
      id: "fbaV3.prepDetails.prepCategory.baby",
      defaultMessage:
        "Made for a child aged three years or younger, packaging with cutouts greater than one square inch.",
    },
    [FBAPrepCategory.FRAGILE]: {
      id: "fbaV3.prepDetails.prepCategory.fragile",
      defaultMessage: "Glass or otherwise fragile, or a liquid in a glass container.",
    },
    [FBAPrepCategory.GRANULAR]: {
      id: "fbaV3.prepDetails.prepCategory.granular",
      defaultMessage: "Made of powder, pellets, or granular material.",
    },
    [FBAPrepCategory.HANGER]: {
      id: "fbaV3.prepDetails.prepCategory.hanger",
      defaultMessage: "Made of cloth or fabric and intended to be put on a hanger.",
    },
    [FBAPrepCategory.LIQUID]: {
      id: "fbaV3.prepDetails.prepCategory.liquid",
      defaultMessage: "Liquid or viscous without a double seal.",
    },
    [FBAPrepCategory.PERFORATED]: {
      id: "fbaV3.prepDetails.prepCategory.perforated",
      defaultMessage: "In packaging that has a perforated opening.",
    },
    [FBAPrepCategory.SET]: {
      id: "fbaV3.prepDetails.prepCategory.set",
      defaultMessage: "Multiple items that are sold as one unit.",
    },
    [FBAPrepCategory.SHARP]: {
      id: "fbaV3.prepDetails.prepCategory.sharp",
      defaultMessage: "Sharp and easily exposed, not already contained in protective packaging.",
    },
    [FBAPrepCategory.SMALL]: {
      id: "fbaV3.prepDetails.prepCategory.small",
      defaultMessage: "Longest side less than 2 1/8 inches (width of a credit card).",
    },
    [FBAPrepCategory.TEXTILE]: {
      id: "fbaV3.prepDetails.prepCategory.textile",
      defaultMessage: "Made of cloth or fabric that could be damaged by dirt, dust, moisture, or liquid.",
    },
    [FBAPrepCategory.NONE]: {
      id: "fbaV3.prepDetails.prepCategory.none",
      defaultMessage: "Does not require prep.",
    },
  }),
  prepTypeLabels: defineMessages({
    [FBAPrepType.ITEM_BLACK_SHRINKWRAP]: {
      id: "fbaV3.prepDetails.prepType.itemBlackShrinkwrap",
      defaultMessage: "The item requires black shrink wrapping.",
    },
    [FBAPrepType.ITEM_BOXING]: {
      id: "fbaV3.prepDetails.prepType.itemBoxing",
      defaultMessage:
        "Products may require overboxing when there are safety concerns over sharp items, fragile items, hazardous liquids, and vinyl records. For items over 4.5 kg, use double-wall corrugated boxes.",
    },
    [FBAPrepType.ITEM_BUBBLEWRAP]: {
      id: "fbaV3.prepDetails.prepType.itemBubblewrap",
      defaultMessage: "The item requires bubble wrapping.",
    },
    [FBAPrepType.ITEM_CAP_SEALING]: {
      id: "fbaV3.prepDetails.prepType.itemCapSealing",
      defaultMessage:
        "To prevent leakage, the product needs to have a secondary seal in one of the following types: Induction seal, safety ring, clips, heat shrink plastic band, or boxing.",
    },
    [FBAPrepType.ITEM_DEBUNDLE]: {
      id: "fbaV3.prepDetails.prepType.itemDebundle",
      defaultMessage:
        "The item requires taking apart a set of items labeled for individual sale. Remove tape or shrink wrap that groups multiple inventory units together.",
    },
    [FBAPrepType.ITEM_HANG_GARMENT]: {
      id: "fbaV3.prepDetails.prepType.itemHangGarment",
      defaultMessage: "The item must be placed on a hanger.",
    },
    [FBAPrepType.ITEM_LABELING]: {
      id: "fbaV3.prepDetails.prepType.itemLabeling",
      defaultMessage: "The FNSKU label must be applied to the item.",
    },
    [FBAPrepType.ITEM_NO_PREP]: {
      id: "fbaV3.prepDetails.prepType.itemNoPrep",
      defaultMessage: "The item does not require any prep.",
    },
    [FBAPrepType.ITEM_POLYBAGGING]: {
      id: "fbaV3.prepDetails.prepType.itemPolybagging",
      defaultMessage: "The item requires polybagging.",
    },
    [FBAPrepType.ITEM_RMOVHANG]: {
      id: "fbaV3.prepDetails.prepType.itemRmovhang",
      defaultMessage: "The item cannot be shipped on a hanger.",
    },
    [FBAPrepType.ITEM_SETCREAT]: {
      id: "fbaV3.prepDetails.prepType.itemSetcreat",
      defaultMessage:
        "Units that are sets must be labeled as sets on their packaging. The barcodes on the individual items must not face outward and must not require covering.",
    },
    [FBAPrepType.ITEM_SETSTK]: {
      id: "fbaV3.prepDetails.prepType.itemSetstk",
      defaultMessage:
        "Products that are sets must be marked as sets on their packaging. Add a label to the unit that clearly states that the products must be received and sold as a single unit. For example, if a set of six unique toy cars is sold as one unit, the packaging for each car must indicate that it is part of the set.",
    },
    [FBAPrepType.ITEM_SIOC]: {
      id: "fbaV3.prepDetails.prepType.itemSioc",
      defaultMessage: "The item ships in its original product packaging.",
    },
    [FBAPrepType.ITEM_SUFFOSTK]: {
      id: "fbaV3.prepDetails.prepType.itemSuffostk",
      defaultMessage:
        "Poly bags with an opening of 12 cm or larger (measured when flat) must have a suffocation warning. This warning must be printed on the bag or attached as a label.",
    },
    [FBAPrepType.ITEM_TAPING]: {
      id: "fbaV3.prepDetails.prepType.itemTaping",
      defaultMessage: "Indicates that taping is required.",
    },
  }),
});
