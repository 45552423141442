import { Input, ThemeProps, Text, Grid } from "common/components/ui";
import styled from "@emotion/styled";
import React, { ChangeEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useUserScopeMapping } from "common/user/useUserScopes";
import { TOOLTIP_MESSAGES } from "common/utils/tooltipMessages/tooltipMessages";
import { CarrierSelect } from "common/components/CarrierSelect";
import { ButtonType, LoaderButton } from "common/components/WithLoader/LoaderButton";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { useSelector } from "react-redux";
import { getStorageInboundDetail } from "storage/inbounds/detail/store";
import { carrierInputLabels } from "./CarrierEmailInputScreen.labels";

const SpacedText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

const StyledGrid = styled(Grid)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

const StyledInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S2};
    height: 44px;
  `
);
export interface CarrierEmailInputProps {
  onSubmit: () => void;
  carrierEmail: string | undefined;
  carrier: string | undefined;
  submitDisabled: boolean;
  onCarrierEmailChange: (inputEmail: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: () => void;
  isError: boolean;
  handleFocus: () => void;
  handleCarrierSelectionChange: (selectedCarrier: string) => void;
}

export const CarrierEmailInputScreen: React.FC<CarrierEmailInputProps> = ({
  onSubmit,
  carrierEmail,
  submitDisabled,
  onCarrierEmailChange,
  handleBlur,
  isError,
  handleFocus,
  carrier,
  handleCarrierSelectionChange,
}) => {
  const userScopesMapping = useUserScopeMapping();
  const hasInboundsWriteAccess = userScopesMapping.INBOUNDS?.write;
  const { formatMessage } = useIntl();
  const { storageInboundOrder } = useSelector(getStorageInboundDetail);

  return (
    <>
      <div>
        <SpacedText>
          <FormattedMessage
            {...carrierInputLabels.emailDescription}
            values={{
              a: (chunks) => <a href={storageInboundOrder?.warehouseAppointmentLink}>{chunks}</a>,
            }}
          />
        </SpacedText>
        <StyledGrid columns="1fr 1fr">
          <label data-testid="carrierSelect">
            <FormattedMessage {...carrierInputLabels.selectCarrier} />
            <CarrierSelect handleSelectionChange={handleCarrierSelectionChange} carrier={carrier} />
          </label>
          <label>
            <FormattedMessage {...carrierInputLabels.carrierEmail} />
            <StyledInput
              data-testid="inbound-delivery-appointment-carrier-email-input"
              type="email"
              value={carrierEmail}
              onChange={onCarrierEmailChange}
              placeholder={formatMessage(carrierInputLabels.carrierEmailPlaceholder)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              hasError={isError || !carrierEmail}
              errorMessage={
                carrierEmail ? (
                  <FormattedMessage {...carrierInputLabels.invalidEmail} />
                ) : (
                  <FormattedMessage {...carrierInputLabels.carrierEmailMandatory} />
                )
              }
            />
          </label>
        </StyledGrid>
        <LoaderButton
          name={InboundLoaderId.updateDeliveryAppointment}
          buttonType={ButtonType.PrimaryReversed}
          disabled={submitDisabled || !hasInboundsWriteAccess}
          onClick={onSubmit}
          tooltipMessage={!hasInboundsWriteAccess ? TOOLTIP_MESSAGES.insufficientAccess : undefined}
        >
          <FormattedMessage {...carrierInputLabels.confirmButton} />
        </LoaderButton>
      </div>
    </>
  );
};
