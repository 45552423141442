import { storageClient } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { CreateTransferLoader } from "../CreateTransferLoader.types";
import { setAvailableInventoryInEachStorageWarehouse } from "./setAvailableInventoryInEachStorageWarehouse";
import { transferCreateSetReplenishmentError } from "../actions";
import { batch } from "react-redux";
import { StorageWarehouseInventory } from "common/clients/storage/StorageWarehouseInventory";
import { ReplenishmentPickProductErrorCodes } from "../steps/common/replenishment/errors/pick-products-errors/ReplenishmentPickProductErrorCodes";
import { isEmpty } from "lodash";
import { DskuQty } from "@deliverr/commons-clients";
import { selectTransferCreate } from "./TransferCreateSelectors";
import { FeatureName, getFeatureSelector } from "common/Split";
import { WarehousePoolName } from "@deliverr/business-types";

export const getAvailableInventoryInEachStorageWarehouse =
  (dskuQuantities: DskuQty[], includeInventoryBreakdown?: boolean, isKit?: boolean): SPThunkAction =>
  async (dispatch, getState) => {
    batch(() => {
      dispatch(transferCreateSetReplenishmentError({ errors: [] }));
      dispatch(addLoader(CreateTransferLoader.FetchStorageInventoryLoader));
    });

    let availableInventoryInEachStorageWarehouse: StorageWarehouseInventory[] | undefined;
    const state = getState();
    const isDefaultPoolB2BMerchant = getFeatureSelector(FeatureName.DefaultPoolB2BMerchant)(state);
    const { isEachPickingOrder } = selectTransferCreate(state);
    try {
      availableInventoryInEachStorageWarehouse = includeInventoryBreakdown
        ? (
            await storageClient.getUnifiedAvailablePackInventoryByWarehouse(
              dskuQuantities,
              isEachPickingOrder,
              isDefaultPoolB2BMerchant ? WarehousePoolName.DEFAULT : WarehousePoolName.STORAGE
            )
          )?.value
        : (await storageClient.validateAndReturnInventoryAvailableInEachStorageWarehouse(dskuQuantities))?.value;

      if (!isEmpty(availableInventoryInEachStorageWarehouse)) {
        dispatch(setAvailableInventoryInEachStorageWarehouse(availableInventoryInEachStorageWarehouse!));
      } else {
        if (isKit) {
          dispatch(
            transferCreateSetReplenishmentError({
              errors: [
                {
                  code: ReplenishmentPickProductErrorCodes.KIT_COMPONENTS_NOT_AVAILABLE_AT_SAME_WAREHOUSE,
                  message: "All Kit Components not available in single warehouse",
                  name: "AllComponentsNotAvailableInAnyWarehouse",
                },
              ],
            })
          );
        } else if (dskuQuantities.length === 1) {
          dispatch(
            transferCreateSetReplenishmentError({
              errors: [
                {
                  code: ReplenishmentPickProductErrorCodes.PRODUCT_NOT_AVAILABLE_IN_ANY_WAREHOUSE,
                  message: "Product not available in any warehouse",
                  name: "SingleProductNotAvailableInAnyWarehouse",
                },
              ],
            })
          );
        } else {
          dispatch(
            transferCreateSetReplenishmentError({
              errors: [
                {
                  code: ReplenishmentPickProductErrorCodes.PRODUCTS_NOT_AVAILABLE_AT_SAME_WAREHOUSE,
                  message: "Products are not available at the same warehouse",
                  name: "ProductsNotAvailableAtSameWarehouse",
                },
              ],
            })
          );
        }
      }
    } catch (err) {
      logError({ fn: "getAvailableInventoryInEachStorageWarehouse" }, err);
      dispatch(
        transferCreateSetReplenishmentError({ errors: err?.response?.data?.error ? [err?.response?.data?.error] : [] })
      );
    } finally {
      dispatch(clearLoader(CreateTransferLoader.FetchStorageInventoryLoader));
    }

    return availableInventoryInEachStorageWarehouse;
  };
